import React from "react";
import styled from "styled-components";
import moment from 'moment';

moment.locale('es');

const DivWrapper = styled('div')`
margin:auto;
  width: 330x;
  justify-content: space-between;
  background: #FFF;
	color: rgb(117, 117, 117);
  text-transform:capitalize;
`;

const TextWrapper = styled('span')`
Padding-bottom:10px;
  margin:auto;
  font-size: 25px;
`;

const TitleWrapper = styled(TextWrapper)`
margin:auto;
`;

const ButtonsWrapper = styled('div')`
  margin:auto;
  display: none;
  height: 30px;
  width: 100x;
  align-items: center;
`;

const ButtonWrapper = styled('p')`
margin:auto;
  border: unset;
 
	height: 30px;
	color: rgb(117, 117, 117);
	outline: unset;
	cursor:pointer;
`;

const TodayButton = styled(ButtonWrapper)`
margin:auto;
background: #837BEC;

color:#FFF;
font-size:16px;
border-radius:10px;
`;

const Monitor = ({today,prevHandler,todayHandler,nextHandler}) => (
  <div>
	<DivWrapper className="text-center">
    <div className="form-inline titulosCalen">
      <ButtonWrapper onClick={prevHandler}> &lt; </ButtonWrapper>
      <TitleWrapper>{today.format('MMMM')}</TitleWrapper>
      <TextWrapper>{today.format('YYYY')}</TextWrapper>
      <ButtonWrapper onClick={nextHandler}> &gt; </ButtonWrapper>
    </div>
    <div>
    <ButtonsWrapper>
      <TodayButton onClick={todayHandler}>Mes actual</TodayButton>
    </ButtonsWrapper>
    </div>
  </DivWrapper>
 
  
  </div>
);

export { Monitor };
