import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory ,Link} from "react-router-dom";
import './Dashboard.css';
import { db } from "../firebase"
import 'moment/locale/es'
import moment from 'moment';
import Calendario from "./App/index"
import { PayPalButton } from "react-paypal-button-v2";
import Menu from "./menu";
import HeaderPrin from "./header"
moment.locale('es');



export default function Directorio() {
  const [currentDatosPa_, setcurrenDatosPa_] = useState("");
  const [currentPerfil, setCurrentPerfil] = useState("");
  const [ActiveBoton, setActiveBoton] = useState("Especialista")
  const [currentDatos_porce, setcurrenDatos_porce] = useState(0);
  const [currentDatos_3, setcurrenDatos_3] = useState("");
  const [BotonPypal, setpypal] = useState("")
  const { currentUser } = useAuth("");
  const [Reintentar, setReintentar] = useState(false)
  const [Conferencia_1, setConferencias] = useState("");
  const [mostrarPlanes, setmostrarPlanes] = useState(false)
  const history = useHistory()
  const [panelPerfil, setpanelPerfil] = useState("")
  const[BotonpypalLista,setBotonpypalLista]=useState(false)
  const [busqueda, setBusqueda]= useState("");
  const [test, settest]= useState("");
  
const Hamil = async () => {
    db.collection("Usuarios")
      .doc(currentUser.uid)
      .get().then(function (doc) {
        let users = doc.data()
        let sugerido = doc.data().especialista_sugerido
        users.$key = doc.id
        setcurrenDatos_3(users);
        settest(sugerido)
      });
    db.collection("configuracion").onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push(doc.data().PorcentajesGanancia);
      });
      setcurrenDatos_porce(docs);
    });

    if (ActiveBoton === "Especialista") {
      const array_to = [];
      db.collection("Usuarios").where("tipouser", "==", "Especialista").where("aprobado", "==", true).where("activo", "==", true).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let use = doc.data()
          array_to.push(use);
        });
        setcurrenDatosPa_(array_to);
      });
    }

  }

      /////////////////Buscador////////////////////////////////////////
const handleChange=e=>{
  setBusqueda(e.target.value);
  filtrar(e.target.value);
}
const filtrar=(terminoBusqueda)=>{
  var resultadosBusqueda=currentDatosPa_.filter((elemento)=>{
    // let nombre=elemento.nombres + elemento.apellidos
    let busqueda=terminoBusqueda.trim()
    console.log(busqueda)
    // console.log(nombre)
    if(elemento.nombres.toString().toLowerCase().includes(busqueda.toLowerCase())
    ||elemento.apellidos.toString().toLowerCase().includes(busqueda.toLowerCase())
    ){
      return elemento;
    }
  });
  if(terminoBusqueda!==""){
    setcurrenDatosPa_(resultadosBusqueda);
}else if(terminoBusqueda===""){
  const array_to = [];
      db.collection("Usuarios").where("tipouser", "==", "Especialista").where("aprobado", "==", true).where("activo", "==", true).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let use = doc.data()
          array_to.push(use);
        });
        setcurrenDatosPa_(array_to);
      });
}
}

/////////////////Buscador////////////////////////////////////////

  useEffect(() => {
    Hamil();
  }, []);

  const [panelAgenda, setpanelAgenda] = useState("")



  function ObtenerId(e) {
    if(test===""){
      history.push("/Service")
    }else if( test!==""){
    setpanelAgenda("1")
    setpanelPerfil("4")
    localStorage.setItem("id", JSON.stringify(e))
  }
}
  const [misHorarios, setMishorarios] = useState("");

  function ObtenerDatos(e) {
    history.push("/Profile")
    localStorage.setItem("IdPerfilMedicos", JSON.stringify(e))
  }


  function tipoEspecialista(e) {
    setActiveBoton(e)
    if (e === "Psiquiatría") {
      const array_to = [];
      db.collection("Usuarios").where("especialidad", "==", "Psiquiatría").where("aprobado", "==", true).where("activo", "==", true).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let use = doc.data()
          array_to.push(use);
        });
        setcurrenDatosPa_(array_to);
      });
    }
    if (e === "Psicología") {
      const array_to = [];
      db.collection("Usuarios").where("especialidad", "==", "Psicología").where("aprobado", "==", true).where("activo", "==", true).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let use = doc.data()
          array_to.push(use);
        });
        setcurrenDatosPa_(array_to);
      });
    }
    if (e === "Especialista") {
      const array_to = [];
      db.collection("Usuarios").where("tipouser", "==", "Especialista").where("aprobado", "==", true).where("activo", "==", true).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let use = doc.data()
          array_to.push(use);
        });
        setcurrenDatosPa_(array_to);
      });
    }

  }
  const [cambiarVistas, setCambiarVistas] = useState("")
  
  const uidNewSala=Math.random().toString(20).substring(7);
  const uidNewHisto=Math.random().toString(20).substring(7); 
const clMedicalal = (e, details, data, precio, nombres, apellidos, foto) => {
    let r = Math.random().toString(36).substring(7);
    let link = 'https://meet.jit.si' + '/' + currentUser.uid + r

    db.collection("SalaEspera").doc().set({
      uid:uidNewSala,
            paciente_abandona:false,
            fecha_noti:new Date(),
            especialista_acepto: false,
            notificacion: true,
            estatus:"Agendada",
            uid_especialista: e,
            notificacion_especialista: true,
            notificacion_paciente: false,
            fecha_creacion: moment().format("DD/MM/YYYY"),
            fecha: moment().format("DD/MM/YYYY"),
            foto_paciente: currentDatos_3.foto_personal,
            foto_especialista: foto,
            nombres_paciente: currentDatos_3.nombres,
            apellidos_paciente: currentDatos_3.apellidos,
            nombre_especialista: nombres,
            apellido_especialista: apellidos,
            uid_paciente: currentDatos_3.uid,
            room_link: link,
            recordatorio: false,
            num_reintentar: 0,
            hora_notificacion: moment().format("hh:mm A")
    }).then((resp) => {
      console.log(resp)
    })

    let datos = currentDatos_3
    datos.emergenciaPaga = true
    datos.montoEmergenciaPagado = precio
    datos.uid_pago_emergencia=uidNewHisto
    console.log(datos)

    db.collection("Usuarios").doc(currentDatos_3.$key).set(datos)
      .then(resp => {
        db.collection("HistorialPagos").doc(datos.uid_pago_emergencia).set({
          uid:datos.uid_pago_emergencia,
                estatusAdmin:false,
                tipo_pago: "Emergencia",
                nombres_paciente:currentDatos_3.nombres,
                uid_paciente:currentDatos_3.uid,
                cedula_paciente: currentDatos_3.cedula,
                nombre_especialista: '',
                    uid_especialista: '',
                    apellido_especialista: '',
                    uid_especialista: '',
                    fecha_cancelacion:'',
                    pago_cancelado:false,
                monto_emergencia:precio,
                monto_emergencia_especialista:precio,
                monto_porcentaje_terapia:precio / 100 * currentDatos_porce,
                apellidos_paciente: currentDatos_3.apellidos,
                estatusPago: details.status,
                num_principal_paciente: currentDatos_3.num_principal,
                fecha_Pago: moment().format("DD/MM/YYYY"),
                idPago: details.id,
                fechaPagoPaypal: details.create_time,
                direccionPagador: details.payer.address,
                emailPagador: details.payer.name,
                monto: details.purchase_units[0].amount,
                facilitatorAccessToken: data.facilitatorAccessToken,
                orderID: data.orderID,
                payerID: data.payerID,
                Plan: "Individual",
                tipo_plan: "Individual",
        }).then(() => {
          alert("¡Se realizo su emergencia exitosamente!")
          history.push("/DoctorParaEmergencia")
        })
      })
  };

  function Mostrarpaypal(){
    setBotonpypalLista(true)
  }
  
  function ObtenerIdEmergencia(e) {
    localStorage.setItem("idEmergencia", JSON.stringify(e))
    history.push("/PagarEmergencia")
}

  return (
    <>
     <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
      {panelPerfil === "" ?
        <div>
          <section className="recent">
            <h2 className="dash-title">Especialistas</h2>
            <div className="form-inline headerPrincipal_tabla">
              <div className="header_tabla form-inline ">
                <div className="col-6">
                  <img className="mr-2" src="Buscar_SVG.svg" /><input onChange={handleChange} className="form-control" placeholder="Buscar" type="seach" />
                </div>
                <div className={ActiveBoton == "Especialista" ? "col-2 botones_header_Activo a" : "col-2 botones_header a"} onClick={(e) => tipoEspecialista("Especialista", e)}>Todos</div>
                <div className={ActiveBoton == "Psiquiatría" ? "col-2 botones_header_Activo_medio b" : "col-2 botones_header b"} onClick={(e) => tipoEspecialista("Psiquiatría", e)}>Psiquiatras</div>
                <div className={ActiveBoton == "Psicología" ? "col-2 botones_header_Activo_right c" : "col-2 botones_header c"} onClick={(e) => tipoEspecialista("Psicología", e)}>Psicólogos</div>
              </div>
              <div className="header_vista form-inline">
                <div className={cambiarVistas === "" ? "col-6 text-center  VistaCuadriculaColor" : "col-6 text-center  VistaCuadricula"} title="Vista cuadricula" onClick={() => setCambiarVistas("") }onClickCapture={() => setBotonpypalLista(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.82 54.61"><g id="Capa_2" data-name="Capa 2"><g id="Icons"><path d="M14.25,0H1.58A1.58,1.58,0,0,0,0,1.58V14.25a1.58,1.58,0,0,0,1.58,1.58H14.25a1.58,1.58,0,0,0,1.58-1.58V1.58A1.58,1.58,0,0,0,14.25,0Z" /><path d="M52.24,0H39.57A1.58,1.58,0,0,0,38,1.58V14.25a1.58,1.58,0,0,0,1.58,1.58H52.24a1.58,1.58,0,0,0,1.58-1.58V1.58A1.58,1.58,0,0,0,52.24,0Z" /><path d="M14.25,38.78H1.58A1.59,1.59,0,0,0,0,40.37V53a1.58,1.58,0,0,0,1.58,1.58H14.25A1.58,1.58,0,0,0,15.83,53V40.37A1.59,1.59,0,0,0,14.25,38.78Z" /><path d="M52.24,38.78H39.57A1.59,1.59,0,0,0,38,40.37V53a1.58,1.58,0,0,0,1.58,1.58H52.24A1.58,1.58,0,0,0,53.82,53V40.37A1.59,1.59,0,0,0,52.24,38.78Z" /></g></g></svg></div>
                <div className={cambiarVistas === "Lista" ? "col-6 text-center VistaListaColor" : "col-6 text-center  VistaLista"} title="Vista Lista" onClick={() => setCambiarVistas("Lista")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.78 57.65"><g id="Capa_2" data-name="Capa 2"><g id="Icons"><path d="M54.67,18.94H17.72a1.94,1.94,0,0,0-2.12,1.68,1.94,1.94,0,0,0,2.12,1.67h37a1.93,1.93,0,0,0,2.11-1.67A1.93,1.93,0,0,0,54.67,18.94Z" /><circle cx="4.19" cy="20.62" r="4.19" /><path d="M17.72,5.87h37a1.93,1.93,0,0,0,2.11-1.68,1.93,1.93,0,0,0-2.11-1.67H17.72A1.94,1.94,0,0,0,15.6,4.19,1.94,1.94,0,0,0,17.72,5.87Z" /><circle cx="4.19" cy="4.19" r="4.19" /><path d="M54.67,35.36H17.72A1.94,1.94,0,0,0,15.6,37a1.94,1.94,0,0,0,2.12,1.67h37A1.93,1.93,0,0,0,56.78,37,1.93,1.93,0,0,0,54.67,35.36Z" /><circle cx="4.19" cy="37.04" r="4.19" /><path d="M54.67,51.78H17.72a1.73,1.73,0,1,0,0,3.36h37a1.72,1.72,0,1,0,0-3.36Z" /><circle cx="4.19" cy="53.46" r="4.19" /></g></g></svg></div>
              </div>
            </div>
            <div className="cuadro_tabla">
              {cambiarVistas === "" ?
                <div className="dash-cards_tabla">
                  {currentDatosPa_.length ? (
                    currentDatosPa_.map(array => (
                      <div className="card-single_tabla">
                        <div className="icon_target_tabla form-inline">
                          {array.disponible_emergencia === true ? <img title="Disponible para emergencias" className="icono_Emergencia_tabla" src="Emergency_icon_on_SVG.svg" /> : ""}
                          {array.disponible_emergencia === false ? <img title="No disponible para emergencias" className="icono_Emergencia_tabla" src="Emergency_icon_off_SVG.svg" /> : ""}
                          <img onClickCapture={() => ObtenerDatos(array.uid)} title="Ver perfil" className="iconoUs" src="View_profile_SVG.svg" />
                        </div>

                        <div className="card-body">
                          <div className="updaCard_tabla text-center mb-2" ><img onClick={() => setpanelPerfil("3")} onClickCapture={() => ObtenerDatos(array.uid)} src={array.foto_personal} ></img></div>
                          <div className="" id="body_datos">
                            <div className="body_Nombres"><p>{array.nombres}&nbsp;&nbsp;{array.apellidos}</p></div>
                            <div className="body_especialista"></div>
                          </div>
                        </div>
                        <div className="form-inline col-12 mb-2 medio">
                          <div className="Em col-6 text-center">
                            <p>
                              Profesión
                              <p>{array.especialidad}</p>
                            </p>
                          </div>
                          <div className="Ban col-6 text-center">
                            <p>
                              Precios por consulta
                              <p>${array.precio_consulta / 100 * currentDatos_porce + array.precio_consulta}</p>
                            </p>
                          </div>
                        </div>
                        <p className="text-center">Especialidades</p>
                        <div className="form-inline  mb-2 iconosTerapias_tabla">
                          <div className="ml-3">{array.tipos_terapia[3].estado === false ? <img src="parejas_off_SVG.svg" title="Terapia de pareja" /> : <img src="parejas_on_SVG.svg" title="Terapia de pareja" />}</div>
                          <div className="ml-3">{array.tipos_terapia[0].estado === false ? <img src="infanto_off_SVG.svg" title="Infanto-juvenil" /> : <img src="infanto_on_SVG.svg" title="Infanto-juvenil" />}</div>
                          <div className="ml-2">{array.tipos_terapia[1].estado === false ? <img src="geriatrico_off_SVG.svg" title="Geriátrico" /> : <img src="geriatrico_on_SVG.svg" title="Geriátrico" />}</div>
                          <div className="ml-2 mr-3">{array.tipos_terapia[2].estado === false ? <img src="adulto_off_SVG.svg" title="Adulto" /> : <img src="adulto_on_SVG.svg" title="Adulto" />}</div>
                          <div className="">{array.tipos_terapia[4].estado === false ? <img src="Adicciones_off_SVG.svg" title="Adicciones" /> : <img src="Adicciones_on_SVG.svg" title="Adicciones" />}</div>
                        </div>
                        <Link to="/Index">
                        <div className="card-footer_1 botonAgendar_tabla">
                        <button className="" onClick={() => ObtenerId(array.uid)}> Agendar cita</button>
                        </div>
                        </Link>
                      </div>
                    )
                    )) : (
                    <div></div>
                  )}
                </div>
                : ""}
              {cambiarVistas === "Lista" ?
                <div className="table-responsive mt-2">
                  <div className="table_1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th><img src="View_profile_SVG.svg" /></th>
                          <th>Nombres</th>
                          <th>Apellidos</th>
                          <th>Profesión</th>
                          <th>Especialidades</th>
                          <th>Honorarios</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentDatosPa_.length ? (
                          currentDatosPa_.map(array => (
                            <tr>
                              <td>
                                <div className="updaPrin_tabla"><img onClickCapture={() => ObtenerDatos(array.uid)} src={array.foto_personal}></img></div>
                              </td>
                              <td>{array.nombres}</td>
                              <td>{array.apellidos}</td>
                              <td>{array.especialidad}</td>
                              <td>
                                <div className="form-inline  mb-2 iconosTerapias">
                                  <div className="ml-1">{array.tipos_terapia[3].estado === false ? <img src="parejas_off_SVG.svg" title="Terapia de pareja" /> : <img src="parejas_on_SVG.svg" title="Terapia de pareja" />}</div>
                                  <div className="ml-1">{array.tipos_terapia[0].estado === false ? <img src="infanto_off_SVG.svg" title="Infanto-juvenil" /> : <img src="infanto_on_SVG.svg" title="Infanto-juvenil" />}</div>
                                  <div className="ml-1">{array.tipos_terapia[1].estado === false ? <img src="geriatrico_off_SVG.svg" title="Geriátrico" /> : <img src="geriatrico_on_SVG.svg" title="Geriátrico" />}</div>
                                  <div className="ml-1 mr-1">{array.tipos_terapia[2].estado === false ? <img src="adulto_off_SVG.svg" title="Adulto" /> : <img src="adulto_on_SVG.svg" title="Adulto" />}</div>
                                  <div className="">{array.tipos_terapia[4].estado === false ? <img src="Adicciones_off_SVG.svg" title="Adicciones" /> : <img src="Adicciones_on_SVG.svg" title="Adicciones" />}</div>
                                </div>
                              </td>
                              <td>{array.especialidad==='Psiquiatría'?<div>${array.precio_emergencia/ 100 * currentDatos_porce + array.precio_emergencia}</div>:<div>${array.precio_consulta / 100 * currentDatos_porce + array.precio_consulta}</div>}</td>
                              <td><div className="agendar" onClick={(e) => ObtenerId(array.uid, e)}><button>Agendar consulta</button></div></td>
                              <td>
                                <div>
                                {array.disponible_emergencia === true ? <div className="agendar"><button className="" onClick={() => ObtenerIdEmergencia(array.uid)}>Emergencia</button></div> : <img title="No disponible para emergencias" className="icono_Emergencia_tabla" src="Emergency_icon_off_SVG.svg" />}
                                </div>
                              
                              
                               
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                : ""}
            </div>
          </section>
        </div>

        : ""}

        </main>
        </div>  
    
    </>

  )
}
