import React from "react";
import styled from "styled-components";
import moment from 'moment';

moment.locale('es');

const DivWrapper = styled('div')`
  background-color: #837BEC;
`;

const Title = () => (
	<DivWrapper></DivWrapper>
);

export { Title };
