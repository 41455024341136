import React from "react"
import './Dashboard.css';
import Signup from "./Signup"
import SignupAdmin from "./signupAdmin"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route, HashRouter } from "react-router-dom"
import Dashboard from "./Dashboard"
import DashboardEs from "./DashboardEs"
import Login from "./Login"
import Service from "./Service"
import AdminCL from "./AdminCL"
import AdminCO from "./AdminCO"
import PrivateRoute from "./PrivateRoute"
import SaludoEs from './SaludoEspecialista'
import ForgotPassword from "./ForgotPassword"
import './App.css';

import Admin from "./admin"
import Planes from "./Planes";
import EmergenciasAgendas from "./Emergencias";
import Calendario from "./App/index"
import DoctorParaEmergencia from "./DoctorParaEmergencia";
import Directorio from "./directoriomedico";
import MisCitas from "./MisCitas";
import MisRecipes from "./MisRecipes";
import UpdateProfilePa from "./UpdateProfile";
import AppConta from "./contactos";
import Perfil from "./Perfil";
import CambioEspecialista from "./CambioEspecialista";
import CambioEspecialistaInicio from "./CambioEspeInicio";
import Agendas from "./Agendas";
import Video from "./videoConferencia";
import Horarios from "./Horarios";
import HistoriaMedica from "./HistoriasMedicas";
import HistorialPagosEspecialista from "./PagosEspecialista";
import ContacApp from "./ContactosEspecialista";
import UpdateProfile from "./UpdateProfilePa";
import HistorialPacientes from "./HistorialPaciente";
import MisRecipesAdmin from "./RecipesAdmin";
import PorcentajesPrecio from "./PorcentajePrecios";
import HistorialPagos from "./Pagos";
import HistorialCitas from "./HistorialCitas";
import PagarEmergencia from "./PagarEmergencia";
import SignupLandi from "./signUpLandig";

function App() {
  return (
    <div>
      <HashRouter>
        <AuthProvider>
          <Switch>
            <Route exact path="/"  >
              <Login />
            </Route>
            <PrivateRoute path="/Dashboard">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/Register">
              <SignupLandi></SignupLandi>
            </PrivateRoute>
            <PrivateRoute path="/AdminCL"  >
              <AdminCL />
            </PrivateRoute>
            <PrivateRoute path="/AdminCO" >
              <AdminCO />
              {/*/////////////////////////////////////7{Pacientes} ////////////////////////*/}
            </PrivateRoute>
            <Route path="/signup"  >
              <Signup />
            </Route>
            <Route path="/signupAdmin">
              <SignupAdmin />
            </Route>
            <Route path="/DoctorParaEmergencia">
              <DoctorParaEmergencia />
            </Route>
            <Route path="/Admin" >
              <Admin />
            </Route>
            <Route path="/Planes" >
              <Planes />
            </Route>
            <Route path="/Index">
              <Calendario />
            </Route>
            <Route path="/Service" >
              <Service />
            </Route>
            <Route path="/directoriomedico" >
              <Directorio />
            </Route>
            <Route path="/MisCitas" >
              <MisCitas />
            </Route>
            <Route path="/MisRecipes" >
              <MisRecipes />
            </Route>
            <Route path="/Update" >
              <UpdateProfilePa />
            </Route>
            <Route path="/Contactos" >
              <AppConta />
            </Route>
            <Route path="/Profile" >
              <Perfil />
            </Route>
            <Route path="/Cambio" >
              <CambioEspecialistaInicio />
            </Route>
            <Route path="/forgot-password" >
              <ForgotPassword />
            </Route>
            <Route path="/PagarEmergencia" >
              <PagarEmergencia />
            </Route>
            {/*/////////////////////////////////////7{Espeialistas} ////////////////////////*/}
            <Route path="/Emergencias" >
              <EmergenciasAgendas />
            </Route>
            <Route path="/Agenda" >
              <Agendas />
            </Route>
            <Route path="/Conferencia" >
              <Video />
            </Route>
            <Route path="/Horarios" >
              <Horarios />
            </Route>
            <Route path="/Historias" >
              <HistoriaMedica />
            </Route>
            <Route path="/Historial" >
              <HistorialPagosEspecialista />
            </Route>
            <Route path="/Contacto" >
              <ContacApp />
            </Route>
            <Route path="/UpdateE" >
              <UpdateProfile />
            </Route>
            {/*/////////////////////////////////////7{Administrador} ////////////////////////*/}
            <Route path="/Pacientes" >
              <HistorialPacientes />
            </Route>
            <Route path="/AdminRecipes" >
              <MisRecipesAdmin />
            </Route>
            <Route path="/Porcentajes" >
              <PorcentajesPrecio />
            </Route>
            <Route path="/HistorialPlanes" >
              <HistorialPagos />
            </Route>
            <Route path="/HistorialCitas" >
              <HistorialCitas />
            </Route>
          </Switch>
        </AuthProvider>
      </HashRouter>
    </div>
  )
}

export default App
