import React, { useRef, useState, useEffect } from "react"
import { useAuth } from "../contexts/AuthContext";
import { useHistory, Link } from "react-router-dom";
import './Dashboard.css';
import { Alert } from "react-bootstrap"
import { db } from "../firebase"
import firebase from "../firebase"
import Menu from "./menu";
import HeaderPrin from "./header"

export default function UpdateProfilePa() {

  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { logout } = useAuth("");
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [input, setErrorInput] = useState("")
  const [input_2, setErrorInput_2] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory("")
  const [DatosNombre, setDatos] = useState("");

  const [Exito, setExito] = useState("")
  const [Email, setEmail] = useState("")
  const [Nombres, setNombres] = useState("")
  const [Cedula, setCedula] = useState("")
  const [Edad, setEdad] = useState("")
  const [Sexo, setSexo] = useState("")
  const [Pais, setPais] = useState("")
  const [Otro, setOtro] = useState("")
  const [OtroMostrar, setOtroMostrar] = useState(false)
  const [Ciudad, setCiudad] = useState("")
  const [Religion, setReligion] = useState("")
  const [Direccion, setDireccion] = useState("")
  const [numeroPri, setnumeroPri] = useState("")
  const [numeroSe, setnumeroSe] = useState("")
  const [Apellidos, setApellidos] = useState("")
  const [Mostrarba, setMostrarba] = useState("")
  const [statebarra, setStatebarra] = useState(
    { barra: 0 })
  const [state, setState] = useState({
    picture: ""
  })
  const [show, setShow] = useState(true);

  useEffect(() => {
    const TraerDatos = () => {
      db.collection("Usuarios")
        .doc(currentUser.uid)
        .get().then((doc) => {
          if (doc.exists) {
            let users = doc.data()
            setDatos(users);
            setEmail(users.correo)
            setNombres(users.nombres)
            setCedula(users.cedula)
            setEdad(users.edad)
            setSexo(users.sexo)
            setPais(users.pais)
            setReligion(users.religion)
            setCiudad(users.ciudad)
            setDireccion(users.direccion)
            setnumeroPri(users.num_principal)
            setnumeroSe(users.num_secundario)
            setApellidos(users.apellidos)
            setState({
              picture: users.foto_personal
            })
          }
        }).catch(function (error) {
          console.log("Error getting User:", error);
          alert(error);
        });
    };
    TraerDatos();
  }, []);


  function handleSubmit() {
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }
    const promisesPa = []
    setLoading(true)
    setError("")
    db.collection("Usuarios").doc(currentUser.uid).update({
      "nombres": Nombres,
      "apellidos": Apellidos,
      "cedula": Cedula,
      "edad": Edad,
      "correo": Email,
      "sexo": Sexo,
      "personalizado":Otro,
      "religion": Religion,
      "pais": Pais,
      "ciudad": Ciudad,
      "direccion": Direccion,
      "num_principal": numeroPri,
      "num_secundario": numeroSe,
      "foto_personal": state.picture,
    })
    if (passwordRef.current.value) {
      promisesPa.push(updatePassword(passwordRef.current.value))
    }

    Promise.all(promisesPa)
      .then(() => {
        setExito("Actualizado con éxito")
        setTimeout(res => { setExito(true) }, 3000);
        
      })
      .catch(() => {
        setError("No se pudo actualizar la cuenta")
      })
      .finally(() => {
        setLoading(false)
        history.push("Dashboard")
      })
  }
  function mostrar(){
    if(Sexo==="Personalizado"){
      setOtroMostrar(true)
    }else if(Sexo!=="Personalizado"){
      setOtroMostrar(false)
    }
  }
  function handlkeyPress() {
    var ele = document.querySelectorAll('#validanumericos')[0];
    var ele_2 = document.querySelectorAll('#validanumericos_2')[0];
    ele.onkeypress = function (e) {
      if (isNaN(this.value + String.fromCharCode(e.charCode))) {
        setErrorInput("En este campo solo es permitido numeros")
        return false;
      }
    }
    ele.onpaste = function (e) {
      e.preventDefault();
    }
    ele_2.onkeypress = function (e) {
      if (isNaN(this.value + String.fromCharCode(e.charCode))) {
        setErrorInput_2("En este campo solo es permitido numeros")
        return false;
      }
    }
    ele_2.onpaste = function (e) {
      e.preventDefault();
    }
  }



  function handleChange(e) {
    setMostrarba(e)
    const file = e.target.files[0]
    const storageRef = firebase.storage().ref(`Usuarios/${currentUser.uid}/foto_personal/${file.name}`)
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      const porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setStatebarra({
        barra: porcentage
      })
    }, error => {
      setState({
        message: `error:${error.message}`
      })
    }, () => {
      task.snapshot.ref.getDownloadURL().then((url) => {
        const dato = url
        setState({
          message: "Foto cargada con Exito",
          picture: dato
        })
      })
    })

  }



  return (
    <>
 <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        <section className="contact_info_area sec_pad bg_color">
        <div className="row">
          <div className="upda PanelFoto">
            {state.piture === DatosNombre.foto_personal ? <div className="imge"><img src={DatosNombre.foto_personal}></img></div> : ""}{state.piture !== DatosNombre.foto_personal ? <div className="imge"> <img src={state.picture}></img></div> : ""}
            <div className="iconoImgen"><label for="files"><img src="Camera_SVG.svg" title="Editar foto del perfil" /></label></div>
            <div className="correo">{DatosNombre.correo}</div>
            <div className="form-group barra" id="email">
              <br />
              <input id="files" onChange={handleChange} style={{ visibility: "hidden" }} name='foto' type="file" />
              {Mostrarba !== "" ?
                <div >
                  {<progress className="barramensaje" value={statebarra.barra} max='100'></progress>}
                </div>
                : ""}
              <div className="mensajeExito">{state.message}</div>
            </div>
          </div>

          <div className="PanelDatos">
            <div className="text-center a" ><h1 >Actualizar perfil</h1></div>
            {error && <Alert variant="danger">{error}</Alert>}
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="ml-2 mb-4 mr-5" id="email">
                  <div><label>Nombre</label></div>
                  <div>
                    <input className=""
                      type="text"
                      onInput={(e) => setNombres(e.target.value)}
                      defaultValue={DatosNombre.nombres}
                    />
                  </div>
                </div>
                <div className="" id="email">
                  <div><label>Apellido</label></div>
                  <div>
                    <input className=""
                      type="text"
                      onInput={(e) => setApellidos(e.target.value)}
                      defaultValue={DatosNombre.apellidos}
                    />
                  </div>
                </div>
                <div className="Cedu ml-2  mr-3" id="email">
                  <div><label>Cédula</label></div>
                  <div>
                    <input className=""
                      type="text"
                      defaultValue={DatosNombre.cedula}
                      onChange={(e) => setCedula(e.target.value)}
                      placeholder="Agregue su Cedula de identidad"
                    /></div>
                </div>
                <div className="Edad  mb-4 mr-3" id="email">
                  <div><label>Edad</label></div>
                  <div><input className=""
                    defaultValue={DatosNombre.edad}
                    type="text"
                    onChange={(e) => setEdad(e.target.value)}
                    placeholder="Agregue su edad"
                  /></div>
                </div>
                <div className="" id="email">
                  <div><label>Género</label></div>
                  <div>
                    <select type="select" className="" onClick={()=>mostrar()} onChange={(e) => setSexo(e.target.value)}>
                      <option value={Sexo}>Seleccionar</option>
                      <option value="Hombre">Hombre</option>
                      <option value="Femenino">Mujer</option>
                      <option value="Personalizado">Personalizado</option>
                    </select>
                    <p><label>Actúal:  {DatosNombre.sexo}</label></p>
                  </div>
                  </div>
                  {OtroMostrar === true ?
                    <div className="ml-2  Ciudad" id="email">
                      <div><label>Especifique</label></div>
                      <div> <input className=""
                        type="text"
                        onChange={(e) => setOtro(e.target.value)}
                        placeholder="Especifique"
                      /></div>
                    </div>
                    : ""}
                
                <div className="ml-2 mr-3" id="email">
                  <div><label>País</label></div>
                  <div className="styleSelect ">
                     <select type="select" id="Select" onChange={(e) => setPais(e.target.value)}>
                    <option selected>{DatosNombre.pais}</option>
                    <option value="Afganistán">Afganistán</option>
                    <option value="Albania">Albania</option>
                    <option value="Alemania">Alemania</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antártida">Antártida</option>
                    <option value="Antigua y Barbuda">Antigua y Barbuda</option>
                    <option value="Antillas Holandesas">Antillas Holandesas</option>
                    <option value="Arabia Saudí">Arabia Saudí</option>
                    <option value="Argelia">Argelia</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaiyán">Azerbaiyán</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrein">Bahrein</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Bélgica">Bélgica</option>
                    <option value="Belice">Belice</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermudas">Bermudas</option>
                    <option value="Bielorrusia">Bielorrusia</option>
                    <option value="Birmania">Birmania</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia y Herzegovina">Bosnia y Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brasil">Brasil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Bután">Bután</option>
                    <option value="Cabo Verde">Cabo Verde</option>
                    <option value="Camboya">Camboya</option>
                    <option value="Camerún">Camerún</option>
                    <option value="Canadá">Canadá</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Chipre">Chipre</option>
                    <option value="Ciudad del Vaticano (Santa Sede)">Ciudad del Vaticano (Santa Sede)</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comores">Comores</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, República Democrática">Congo, República Democrática</option>
                    <option value="Corea">Corea</option>
                    <option value="Corea del Norte">Corea del Norte</option>
                    <option value="Costa de Marfíl">Costa de Marfíl</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Croacia (Hrvatska)">Croacia (Hrvatska)</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Dinamarca">Dinamarca</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egipto">Egipto</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Emiratos Árabes Unidos">Emiratos Árabes Unidos</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Eslovenia">Eslovenia</option>
                    <option value="España" >España</option>
                    <option value="Estados Unidos">Estados Unidos</option>
                    <option value="Etiopía">Etiopía</option>
                    <option value="Francia">Francia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Grecia">Grecia</option>
                    <option value="Groenlandia">Groenlandia</option>
                    <option value="Guadalupe">Guadalupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guayana">Guayana</option>
                    <option value="Guayana Francesa">Guayana Francesa</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea Ecuatorial">Guinea Ecuatorial</option>
                    <option value="Haití">Haití</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hungría">Hungría</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Irak">Irak</option>
                    <option value="Irán">Irán</option>
                    <option value="Irlanda">Irlanda</option>
                    <option value="Isla Bouvet">Isla Bouvet</option>
                    <option value="Isla de Christmas">Isla de Christmas</option>
                    <option value="Islandia">Islandia</option>
                    <option value="Italia">Italia</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japón">Japón</option>
                    <option value="Jordania">Jordania</option>
                    <option value="Kazajistán">Kazajistán</option>
                    <option value="Letonia">Letonia</option>
                    <option value="Líbano">Líbano</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libia">Libia</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lituania">Lituania</option>
                    <option value="Luxemburgo">Luxemburgo</option>
                    <option value="Malasia">Malasia</option>
                    <option value="México">México</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk">Norfolk</option>
                    <option value="Noruega">Noruega</option>
                    <option value="Nueva Caledonia">Nueva Caledonia</option>
                    <option value="Nueva Zelanda">Nueva Zelanda</option>
                    <option value="Omán">Omán</option>
                    <option value="Países Bajos">Países Bajos</option>
                    <option value="Panamá">Panamá</option>
                    <option value="Papúa Nueva Guinea">Papúa Nueva Guinea</option>
                    <option value="Paquistán">Paquistán</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Perú">Perú</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Polinesia Francesa">Polinesia Francesa</option>
                    <option value="Polonia">Polonia</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reino Unido">Reino Unido</option>
                    <option value="República Centroafricana">República Centroafricana</option>
                    <option value="República Checa">República Checa</option>
                    <option value="República de Sudáfrica">República de Sudáfrica</option>
                    <option value="República Dominicana">República Dominicana</option>
                    <option value="República Eslovaca">República Eslovaca</option>
                    <option value="Reunión">Reunión</option>
                    <option value="Ruanda">Ruanda</option>
                    <option value="Rumania">Rumania</option>
                    <option value="Rusia">Rusia</option>
                    <option value="Sahara Occidental">Sahara Occidental</option>
                    <option value="Saint Kitts y Nevis">Saint Kitts y Nevis</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa Americana">Samoa Americana</option>
                    <option value="San Marino">San Marino</option>
                    <option value="San Vicente y Granadinas">San Vicente y Granadinas</option>
                    <option value="Santa Helena">Santa Helena</option>
                    <option value="Santa Lucía">Santa Lucía</option>
                    <option value="Santo Tomé y Príncipe">Santo Tomé y Príncipe</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leona">Sierra Leona</option>
                    <option value="Singapur">Singapur</option>
                    <option value="Siria">Siria</option>
                    <option value="omalia">Somalia</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="St Pierre y Miquelon">St Pierre y Miquelon</option>
                    <option value="Suazilandia">Suazilandia</option>
                    <option value="Sudán">Sudán</option>
                    <option value="Suecia">Suecia</option>
                    <option value="Suiza">Suiza</option>
                    <option value="Surinam">Surinam</option>
                    <option value="Tailandia">Tailandia</option>
                    <option value="Taiwán">Taiwán</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Tayikistán">Tayikistán</option>
                    <option value="Territorios franceses del Sur">Territorios franceses del Sur</option>
                    <option value="Timor Oriental">Timor Oriental</option>
                    <option value="Togo">Togo</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad y Tobago">Trinidad y Tobago</option>
                    <option value="Túnez">Túnez</option>
                    <option value="Turkmenistán">Turkmenistán</option>
                    <option value="Turquía">Turquía</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Ucrania">Ucrania</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistán">Uzbekistán</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Yugoslavia">Yugoslavia</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabue">Zimbabue</option>
                  </select>
                  </div>
                </div>
                <div className="Ciudad mr-3" id="email">
                  <div> <label>Ciudad</label></div>
                  <div>  <input className=""
                    type="text"
                    onChange={(e) => setCiudad(e.target.value)}
                    placeholder="Ciudad"
                    defaultValue={DatosNombre.ciudad}
                  /></div>
                </div>
                <div className="Ciudad mr-5 ml-2 mb-4"  id="email">
                  <div> <label>Religión</label></div>
                  <div>  <input className=""
                    type="text"
                    onChange={(e) => setReligion(e.target.value)}
                    placeholder="Religión"
                    defaultValue={DatosNombre.religion}
                  /></div>
                </div>
                <div className="mb-4 ml-2 " id="email">
                  <div><label>Dirección</label></div>
                  <div><textarea className=""
                    type="text"
                    onChange={(e) => setDireccion(e.target.value)}
                    placeholder="Agregar dirección"
                    defaultValue={DatosNombre.direccion}
                  /></div>
                </div>

                <div className="Numer mr-5 ml-2" id="email">
                  <div><label>Número principal</label></div>
                  <div><input className=" "
                    type="text"
                    name="codigo"
                    id="validanumericos"
                    defaultValue={DatosNombre.num_principal}
                    onChange={(e) => setnumeroPri(e.target.value)}
                    placeholder="+58 Número telefónico"
                    onKeyPress={handlkeyPress}
                  /></div>
                  <div className="validarInputt">{input && <div className="validarInput">{input}</div>}</div>
                </div>
                <div className="Numer ml-2  " id="email">
                  <div><label>Número secundario</label></div>
                  <div><input className=""
                    defaultValue={DatosNombre.num_secundario}
                    name="codigo"
                    type="text"
                    id="validanumericos_2"
                    onChange={(e) => setnumeroSe(e.target.value)}
                    placeholder="+58 Número telefónico"
                    onKeyPress={handlkeyPress}
                  /></div>
                  <div className="validarInputt">{input_2 && <div className="validarInput">{input_2}</div>}</div>
                </div>
                <div className="ml-2 mr-4 Pasw" id="password">
                  <div><label>Contraseña</label></div>
                  <div> <input className=""
                    type="password"
                    ref={passwordRef}
                    placeholder="Dejar en blanco para mantener la misma"
                  /></div>
                </div>
                <div className="ml-2  Pasw" id="password-confirm">
                  <div> <label>Confirmar contraseña</label></div>
                  <div> <input className=""
                    type="password"
                    ref={passwordConfirmRef}
                    placeholder="Dejar en blanco para mantener la misma"
                  /></div>
                </div>
                <div className="save_perfil text-right">
                  <img src="delete_SVG.svg" title="Guardar cambios" disabled={loading} className="mt-2" onClick={() => handleSubmit()} />
                </div>
                <div>{Exito &&
                  <div>
                    <div className="text-left">{Exito}</div>
                  </div>
                }
                </div>
              </div>
            </form>
          </div>

        </div>
      </section>  
        </main>
        </div> 
      
    </>
  )
}
