import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { faTrash, faWindowClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db } from "../firebase"
import firebase from "../firebase"
import 'moment/locale/es'
import moment from 'moment';
import { useAuth } from "../contexts/AuthContext";
import Menu from "./menu";
import HeaderPrin from "./header"
moment.locale('es');

export default function MisRecipes() {
  const [currentDatosre, setcurrenDatosre] = useState("");
  const history = useHistory("")
  const { currentUser } = useAuth("");
  const [currentDatosPaDere, setLinksre] = useState([]);
  const [Exito, setExito] = useState("")
  const [idc, setidc] = useState("")
  const [ActiveBoton, setActiveBoton] = useState("Todos")
  const [input, setErrorInput] = useState("")
  const [direccion, setdireccion] = useState("");
  const [Correo, setCorreo] = useState("");
  const [Telefono, setTelefono] = useState("");
  const [solicitar, setsolicitar] = useState("");
  const [Datosreci, setDatosReci] = useState("")
  const [Datosrecimos, setDatosReciMos] = useState("")

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      db.collection("Usuarios")
        .doc(user.uid)
        .get().then(function (doc) {
          let users = doc.data()
          setcurrenDatosre(users);
        });
    })

    if (ActiveBoton === "Todos") {
      db.collection("Recipes").where("uid_paciente", "==", currentUser.uid).onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setLinksre(docs);
      });
    }

  }, []);




  function aprobar() {
    if(direccion!==""&&Correo!==""&& Telefono!==""){
    db.collection("Recipes").doc(solicitar).update({
      "estatus": "Solicitado",
      "direccion_destino": direccion,
      "email_destino": Correo,
      "numero_destino": Telefono,
      fecha_estatus: moment().format('DD/MM/YYYY')
    }).then(() => {
      history.push("/Dashboard")
      setExito("Estatus actalizado")
      setsolicitar("")
    })
  }
   
  }

  function quitar() {
    setExito("")
  }
  function Recipes(e) {
    setActiveBoton(e)
    if (e === "Pendiente") {
      const array_toss = [];
      db.collection("Recipes").where("uid_paciente", "==", currentDatosre.uid).where("estatus", "==", "Pendiente").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
         
          array_toss.push({ ...doc.data(), id: doc.id });
        });
        setLinksre(array_toss);

      });
    }
    if (e === "Pendiente") {
      const array_toss = [];
      db.collection("Recipes").where("uid_paciente", "==", currentDatosre.uid).where("estatus", "==", "Pendiente").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          array_toss.push({ ...doc.data(), id: doc.id });
        });
        setLinksre(array_toss);

      });
    }
   
    if (e === "Completados") {
      const array_toss = [];
      db.collection("Recipes").where("uid_paciente", "==", currentDatosre.uid).where("estatus", "==", "Completado").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let usess = doc.data()
          array_toss.push({ ...doc.data(), id: doc.id });
        });
        setLinksre(array_toss);

      });
    }
    if (e === "Todos") {
      const array_tos = [];
      db.collection("Recipes").where("uid_paciente", "==", currentDatosre.uid).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let uses = doc.data()
          array_tos.push({ ...doc.data(), id: doc.id });
        });
        setLinksre(array_tos);

      });
    }
  }
  function DatosReci(e) {
    db.collection("Recipes")
      .doc(e)
      .get().then(function (doc) {
        let users = doc.data()
        let $key = doc.id
        setDatosReci(users);
        setidc($key)
      });
    setDatosReciMos("1")
  }
  function handlkeyPress() {
    var ele = document.querySelectorAll('#validanumericos')[0];
    ele.onkeypress = function (e) {
      if (isNaN(this.value + String.fromCharCode(e.charCode))) {
        setTimeout(res => { setErrorInput(true) }, 3000);
        setErrorInput("En este campo solo es permitido numeros")
        return false;
      }
    }
    ele.onpaste = function (e) {
      e.preventDefault();
    }
    
  }
  return (
    <>
      <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {solicitar !== "" ?
        <div>
          <form>
          <h2>Solicitar</h2>
          <div className="PanelDatos_recipes  text-center">
            <div className="text-right cerrar" onClick={() => setsolicitar("")} ><img src="save_SVG.svg" /></div>
            <div className="">
              <div className="col-4 text-center">
                <label className=""><b>Telefono:</b></label>
                <input className="form-control"  id="validanumericos" onKeyPress={handlkeyPress} placeholder="Escribir" required type="text" onChange={(e) => setTelefono(e.target.value, e)} />
               
              </div>
              <div className="validarInpu">{input && <p className="">{input}</p>}</div>
              <div className="col-4 text-center">
                <label className=""><b>Dirección:</b></label>
                <textarea className="form-control" placeholder="Escribir" onChange={(e) => setdireccion(e.target.value, e)} required/>
              </div>
              <div className="col-4 text-center">
                <label className=""><b>Correo:</b></label>
                <input className="form-control" placeholder="Escribir" type="email" onChange={(e) => setCorreo(e.target.value, e)}  required/>
              </div>
            </div>
            {/* <div className="botonAgendar_profile mt-5"><button  className="btn" type="submit" onClick={() => aprobar()}>Solicitar</button></div> */}
          </div>
          </form>
        </div>
        : ""}
      {solicitar === "" ?
        <div>
          <h2 className="">Listado de récipes</h2>
          <div className="form-inline headerPrincipal_tabla mb-3">
            <div className="header_tabla form-inline ">
              <div className={ActiveBoton === "Todos" ? "col-4 botones_header_Activo a" : "col-4 botones_header a"} onClick={(e) => Recipes("Todos", e)}>Todos</div>
              <div className={ActiveBoton === "Pendiente" ? "col-4 botones_header_Activo_medio b" : "col-4 botones_header b"} onClick={(e) => Recipes("Pendiente", e)}>Pendientes</div>
              <div className={ActiveBoton === "Completados" ? "col-4 botones_header_Activo_right c" : "col-4 botones_header c"} onClick={(e) => Recipes("Completados", e)}>Completados</div>
            </div>
          </div>
          <div className="cuadro_tabla_reci">
            {Datosrecimos === "" ? <div>
              {Exito && <div variant="success">{Exito}&nbsp;&nbsp;<FontAwesomeIcon icon={faTrash} onClick={quitar} /></div>}
              {currentDatosPaDere.length ? (
                currentDatosPaDere.map(arraya => (
                  <div>
                    <div className="Vacio">
                    </div>
                    <div className="EstiloRecipes" id="Recipes" onClick={(e) => DatosReci(arraya.id, e)}>
                      <div className="form-inline itemsReci">
                        <div className="iconoPrin col-2"><img src="Recipes_SVG.svg" /></div>
                        <div className="col-5 ico">{arraya.estatus}</div>
                        <div className="col-3 ico">{arraya.fecha}</div>
                        <div className="iconoFlecha col-2 text-right"><img src="Down_SVG.svg" /></div>
                      </div>
                    </div>
                  </div>
                )
                )
              ) : (
                <p></p>
              )}
            </div> : ""}
            {Datosrecimos === "1" ?
              <div>
                <div className="Vacio">
                </div>
                <div className="EstiloRecipesDescri" onClick={() => setDatosReciMos("")} id="Recipes">
                  <div className="itemsReci_principales row">
                    <div className="col-6" id="NomEspecia">
                      <label>Especialista</label>
                      <p>{Datosreci.nombres_especialista}&nbsp;&nbsp;{Datosreci.apellidos_especialista}</p>
                    </div>
                    <div className="col-3" id="">
                      <label>Fecha</label>
                      <p>{Datosreci.fecha}</p>
                    </div>
                    <div className="col-3" id="">
                      <label>Estado</label>
                      <p>{Datosreci.estatus}</p>
                    </div>
                  </div>
                  <div className="mt-5 itemsReci_descripcion row">
                    <div className="col-6" id="NomEspecia">
                      <label>Descripcíon</label>
                      <p>{Datosreci.descripcion}</p>
                    </div>
                    <div className="botonAgendar col-2" id="">
                      {Datosreci.estatus == "Pendiente" ? <button className="btn" onClick={() => setsolicitar(idc)}>Solicitar recipe</button> : ""}
                    </div>
                    <div className="col-2 text-right" id="Up"><img src="Up_SVG.svg" /></div>
                  </div>
                </div>
              </div>
              : ""}
          </div>
        </div>
        : ""}

        </main>
        </div>  
      
    </>


  )
}