import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase"
import { PayPalButton } from "react-paypal-button-v2";
import moment from 'moment';
import firebase from "../firebase"
import Calendario from "./App/index"
import CambioEspecialista from "./CambioEspecialista";
import Menu from "./menu";
import HeaderPrin from "./header"

moment.locale('es');

export default function DoctorParaEmergencia() {
    const [currentDatosEmergencia, setcurrenEmergencia] = useState([]);
    const history = useHistory()
    const { currentUser } = useAuth();
    const [currentPerfil, setCurrentPerfil] = useState("");
    const [currentDatosPaDe, setLinks] = useState([]);
    const [BotonPypal, setpypal] = useState("")
    const [mostrarBotoPypal, setpypalBoton] = useState(true)
    /**nuevas variables */



    const [mostrarPlanes, setmostrarPlanes] = useState(false)
    const [Cambiar, setCambiar] = useState(false)
    const [currentDatos_3, setcurrenDatos_3] = useState("");
    const [currentDatos_porce, setcurrenDatos_porce] = useState(0);
    const [Conferencia_1, setConferencias] = useState("");
    const [Reintentar, setReintentar] = useState(false)
    const [busqueda, setBusqueda] = useState("");
    const Emerge = async () => {
        
            db.collection("Usuarios")
                .doc(currentUser.uid)
                .get().then(function (doc) {
                    let users = doc.data()
                    setLinks(users);

                    db.collection("Usuarios").where("especialidad", "==", "Psiquiatría").where("disponible_emergencia", "==", true).where("activo", "==", true).where("aprobado", "==", true).get().then((querySnapshot) => {
                        const array_to = [];
                        querySnapshot.forEach((doc) => {
                            let use = doc.data()
                            array_to.push(use);
                        });
                        setcurrenEmergencia(array_to);
                    });

                });
        


    }
    /////////////////Buscador////////////////////////////////////////
    const handleChange = e => {
        setBusqueda(e.target.value);
        filtrar(e.target.value);
    }
    const filtrar = (terminoBusqueda) => {
        var resultadosBusqueda = currentDatosEmergencia.filter((elemento) => {
            // let nombre=elemento.nombres + elemento.apellidos
            let busqueda = terminoBusqueda.trim()
            console.log(busqueda)
            // console.log(nombre)
            if (elemento.nombres.toString().toLowerCase().includes(busqueda.toLowerCase())
                || elemento.apellidos.toString().toLowerCase().includes(busqueda.toLowerCase())
            ) {
                return elemento;
            }
        });
        if (terminoBusqueda !== "") {
            setcurrenEmergencia(resultadosBusqueda);
        } else if (terminoBusqueda === "") {
            db.collection("Usuarios").where("especialidad", "==", "Psiquiatría").where("disponible_emergencia", "==", true).where("aprobado", "==", true).get().then((querySnapshot) => {
                const array_to = [];
                querySnapshot.forEach((doc) => {
                    let use = doc.data()
                    array_to.push(use);
                });
                setcurrenEmergencia(array_to);
            });
        }
    }

    /////////////////Buscador////////////////////////////////////////
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            db.collection("Usuarios")
                .doc(user.uid)
                .get().then(function (doc) {
                    let users = doc.data()
                    users.$key = doc.id
                    setcurrenDatos_3(users);
                });
        })
        db.collection("configuracion")
            .doc("am8lciDPAWtxD1tzDyik")
            .get().then(function (doc) {
                let users = doc.data().PorcentajesGanancia

                setcurrenDatos_porce(users);
                // GET PLANES
            });
        db.collection("SalaEspera").where("uid_paciente", "==", currentUser.uid).where("paciente_abandona", "==", false)
            .onSnapshot(function (querySnapshot) {
                let Conferencias = [];
                querySnapshot.forEach(function (doc) {
                    Conferencias.push({ ...doc.data(), id: doc.id })
                    if (doc.exists) {
                        setTimeout(res => { setReintentar(true) }, 7000);
                    }
                })
                setConferencias(Conferencias);
                if(Conferencias.length!==0){
                    setmostrarPlanes("0")
                }
                
            })

        // GET PLANES
        Emerge();
    }, []);


    const jitsiContainerStyle = {
        width: '100%',
        height: '100%',
    }
    const uidNewHisto=Math.random().toString(20).substring(7);
  
  
    const clMedicalal = (e, details, data, precio, nombres, apellidos, foto) => {
        let r = Math.random().toString(36).substring(7);
        let link = 'https://meet.jit.si' + '/' + currentUser.uid + r

        db.collection("SalaEspera").doc(uidNewHisto).set({
            uid:uidNewHisto,
            fecha_notificacion: moment().format("DD/MM/YYYY"),
            especialista_acepto: false,
            notificacion: true,
            notificacion_especialista: true,
            notificacion_paciente: false,
            estatus:"Agendada",
            paciente_abandona: false,
            fecha_noti: new Date(),
            uid_especialista: e,
            fecha_creacion: moment().format("DD/MM/YYYY"),
            fecha: moment().format("DD/MM/YYYY"),
            foto_paciente: currentDatosPaDe.foto_personal,
            foto_especialista: foto,
            nombres_paciente: currentDatosPaDe.nombres,
            apellidos_paciente: currentDatosPaDe.apellidos,
            nombre_especialista: nombres,
            apellido_especialista: apellidos,
            uid_paciente: currentUser.uid,
            room_link: link,
            recordatorio: false,
            num_reintentar: 0,
            hora_notificacion: moment().format("hh:mm A"),
            fecha_notificacion: moment().format("DD/MM/YYYY")
        }).then((resp) => {
            console.log(resp)
        })

        let datos = currentDatos_3
        datos.emergenciaPaga = true
        datos.montoEmergenciaPagado = precio
        datos.uid_pago_emergencia=uidNewHisto
        console.log(datos)

        db.collection("Usuarios").doc(currentDatos_3.$key).set(datos)
            .then(resp => {
                db.collection("HistorialPagos").doc(datos.uid_pago_emergencia).set({
                    uid:datos.uid_pago_emergencia,
                    tipo_pago: "Emergencia",
                    estatusAdmin: false,
                    nombres_paciente: currentDatos_3.nombres,
                    uid_paciente: currentDatos_3.$key,
                    cedula_paciente: currentDatos_3.cedula,
                    nombre_especialista: '',
                    uid_especialista: '',
                    fecha_cancelacion:'',
                    pago_cancelado:false,
                    apellido_especialista: '',
                    apellidos_paciente: currentDatos_3.apellidos,
                    estatusPago: details.status,
                    num_principal_paciente: currentDatos_3.num_principal,
                    fecha_Pago: moment().format("DD/MM/YYYY"),
                    idPago: details.id,
                    monto_emergencia: precio,
                    monto_emergencia_especialista: precio,
                    monto_porcentaje_terapia: precio / 100 * currentDatos_porce,
                    fechaPagoPaypal: details.create_time,
                    direccionPagador: details.payer.address,
                    emailPagador: details.payer.name,
                    monto: details.purchase_units[0].amount,
                    facilitatorAccessToken: data.facilitatorAccessToken,
                    orderID: data.orderID,
                    payerID: data.payerID,
                    Plan: "Individual",
                    tipo_plan: "Individual",
                }).then(() => {
                    history.push("/DoctorParaEmergencia")
                    alert("Se realizo su emergencia exitosamente!!!")
                    setTimeout(res => { setReintentar(true) }, 7000);
                    setmostrarPlanes("0")
                    // setExito("Consulta agregada con Éxito")
                    // const domain = 'meet.jit.si';
                    // const options = {
                    //     roomName: currentUser.uid + r,
                    //     height: 400,
                    //     parentNode: document.getElementById('jitsi-container'),
                    //     interfaceConfigOverwrite: {
                    //         filmStripOnly: false,
                    //         SHOW_JITSI_WATERMARK: false,
                    //     },
                    // }

                    // const api = new window.exports.JitsiMeetExternalAPI(domain, options);
                    // api.executeCommand('displayName', 'MyName');

                    // emailjs.send('service_e4olqec', 'template_0ffcydz', contact, 'user_18jqcwflnGbkPgeaoNzWQ')
                    //     .then((response) => {
                    //         console.log('SUCCESS!', response.status, response.text);
                    //         setShowMessage(true);
                    //     }, (err) => {
                    //         console.log('FAILED...', err);
                    //     });
                })
            })
    };
    const uidNew=Math.random().toString(20).substring(7);
    function consultaEmergencia(id, num_recordatorio, room_link, nombres_paciente, apellidos_paciente, fecha_notificacion, hora_notificacion, uid_especialista, uid_paciente, nombre_especialista, apellido_especialista, fecha_creacion, fotoEs) {
        db.collection("Conferencias").doc(uidNew).set({
            tipo_cita: "Emergencia",
            uid: uidNew,
            estatus:"Agendada",
            fecha_creacion: fecha_creacion,
            fecha_noti: new Date(),
            notificacion: false,
            notificacion_especialista: false,
            notificacion_paciente: false,
            foto_especialista: fotoEs,
            fecha: fecha_notificacion,
            uid_especialista: uid_especialista,
            nombres_especialista: nombre_especialista,
            apellidos_especialista: apellido_especialista,
            foto_paciente: currentDatosPaDe.foto_personal,
            nombres_paciente: nombres_paciente,
            apellidos_paciente: apellidos_paciente,
            uid_paciente: uid_paciente,
            room_link: room_link,
            hora: hora_notificacion,
            hora_notificacion: hora_notificacion,
            fecha_notificacion: moment().format("DD/MM/YYYY")
        })
        db.collection("Citas").doc(uidNew).set({
            uid:uidNew,
            notificacion: false,
            tipo_cita: "Emergencia",
            fecha: fecha_creacion,
            room_link: room_link,
            fecha_noti: new Date(),
            fecha_creacion: fecha_creacion,
            estatus:"Agendada",
            notificacion_especialista: false,
            notificacion_paciente: false,
            foto_paciente: currentDatosPaDe.foto_personal,
            foto_especialista: fotoEs,
            nombres_especialista: nombre_especialista,
            apellidos_especialista: apellido_especialista,
            fecha_notificacion: fecha_notificacion,
            uid_especialista: uid_especialista,
            nombres_paciente: nombres_paciente,
            apellidos_paciente: apellidos_paciente,
            uid_paciente: uid_paciente,
            hora: hora_notificacion,
            hora_notificacion: hora_notificacion,
        })
        db.collection("Usuarios").doc(uid_paciente).update({
            "montoEmergenciaPagado": 0,
            "emergenciaPaga": false
        })
            .then(resp => {
                setmostrarPlanes(false)
                console.log(resp)
                db.collection("SalaEspera").doc(id).delete().then(() => {
                }).then(() => {
                    alert("Estatus actualizado exitosamente!!!")
                })
                    .catch((error) => {
                        console.error("Error removing document: ", error);
                    });
            })
    }

    function ObtenerDatos(e) {
        history.push("/Profile")
        localStorage.setItem("IdPerfilMedicos", JSON.stringify(e))
      }

    function ObtenerId(e) {
        setmostrarPlanes("7")
        localStorage.setItem("id", JSON.stringify(e))
    }
    function AceptaConfe(e) {
        window.open(
            e,
            '_blank' // <- This is what makes it open in a new window.
        );
    }
    function Intentos(e, c) {
        let contador = c + 1
        if (contador) {
            db.collection("SalaEspera").doc(e).update({
                "num_reintentar": contador,
                "recordatorio": true,
            })
        }
    }
    function ActualizarSala(e) {
        
        db.collection("SalaEspera").doc(e).update({
          "paciente_abandona":true,
          "notificacion":false,
          
       }).then(res=>{
         history.push("/Cambio")
       setCambiar(true)})
            .catch((error) => {
                console.error("Error removing document: ", error);
            });
       
        
      }
      function CancelarEmergencia(e){
        db.collection("SalaEspera").doc(e).update({
          "paciente_abandona":true,
          "notificacion":false,
       })
       db.collection("HistorialPagos").doc(e).update({
        "fecha_cancelacion": new Date(),
        "pago_cancelado": true,
        
    })
            db.collection("Usuarios").doc(currentUser.uid).update({
              "montoEmergenciaPagado": 0,
              "emergenciaPaga": false,
              "uid_pago_emergencia":""
          })
        .then(resp => {
            history.push("/Contactos")
                
              })
                .catch((error) => {
                    console.error("Error removing document: ", error);
                });
       
      }
      function ObtenerIdEmergencia(e) {
        localStorage.setItem("idEmergencia", JSON.stringify(e))
        history.push("/PagarEmergencia")
    }

    return (
        <>
      <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {mostrarPlanes === "0" ?
                <div class="SalaEspera">
                    <div className="imgSala">
                        <img src="WEB3.png"></img>
                    </div>
                    <div className="imgSalaSvg">
                        <img src="logobg_SVG.svg"></img>
                    </div>
                    {Conferencia_1.length ? (
                        Conferencia_1.map(array => (


                            <div>


                                {array.especialista_acepto=== false ?
                                    <div className="espera">
                                        <div>
                                            <h1>
                                                Por favor espere...
                                            </h1>
                                            <p>Estamos intentando contactar con el especialista</p>
                                            {array.recordatorio === true ?



                                                <p>
                                                    Has enviado {array.num_reintentar} recordatorio...
                                                </p>



                                                : ""}

                                        </div>

                                        {Reintentar === true ?
                                            <div className="botonesSala">
                                                <div className="botonAgendar_otro mb-1" id="Reintentar"><button className="" onClick={() => Intentos(array.id, array.num_reintentar)} >Reintentar</button></div>
                                                <div className="botonAgendar_otroEle mb-1" id="Reintentar"><button className="" onClick={() => ActualizarSala(array.id)} >Elegir otro especialista</button></div>
                                                <div className="botonAgendar_otroEle" id="Reintentar"><button className="" onClick={()=>CancelarEmergencia(array.id)} >Cancelar</button></div>
                                            
                                            </div>

                                            : ""}

                                    </div>

                                    :
                                    <div className="espera">
                                        <h1>
                                            Ya hemos conectado con su especialista
                                        </h1>
                                        <div className="botonAgendar_otro"><div ><button onClickCapture={() => AceptaConfe(array.room_link)} onClick={() => consultaEmergencia(array.id, array.num_recordatorio, array.room_link, array.nombres_paciente, array.apellidos_paciente, array.fecha_notificacion, array.hora_notificacion, array.uid_especialista, array.uid_paciente, array.nombre_especialista, array.apellido_especialista, array.fecha_creacion, array.foto_especialista)}>Entrar</button></div></div>
                                    </div>
                                }
                            </div>


                        ))
                    ) : ""}

                </div>
                : ""}


            {mostrarPlanes === false ?
                <div>
                    <section className="recent">
                        <div className="activity-card">
                            <h2 className="dash-title">Especialistas disponibles para emergencias</h2>
                            <div className="form-inline headerPrincipal_tabla">
                                <div className="buscador_emer">
                                    <div className="ml-5">
                                        <img src="Buscar_SVG.svg" className="mr-2" />
                                        <input className="" onChange={handleChange} placeholder="Buscar" type="seach" />
                                    </div>
                                </div>

                            </div>
                            <div className="cuadro_tabla">
                                <div className="table-responsive mt-2">
                                    <div className="table_1">
                                        <table className="table">

                                            <thead>
                                                <tr>
                                                    <th><img src="View_profile_SVG.svg" /></th>
                                                    <th>Nombres</th>
                                                    <th>Apellidos</th>
                                                    <th>Profesión</th>
                                                    <th>Especialidades</th>
                                                    <th>Honorarios</th>
                                                    <th>Pagar emergencia</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentDatosEmergencia.length ? (
                                                    currentDatosEmergencia.map(array => (
                                                        <tr>
                                                            <td className="">
                                                                <div className="updaPrin_tabla"><img src={array.foto_personal} onClickCapture={() => ObtenerDatos(array.uid)}></img></div>
                                                            </td>
                                                            <td>{array.nombres}</td>
                                                            <td>{array.apellidos}</td>
                                                            <td>{array.especialidad}</td>
                                                            <td>
                                                                <div className="form-inline text-center mb-2 iconosTerapias">
                                                                    <div className="ml-1">{array.tipos_terapia[3].estado === false ? <img src="parejas_off_SVG.svg" title="Terapia de pareja" /> : <img src="parejas_on_SVG.svg" title="Terapia de pareja" />}</div>
                                                                    <div className="ml-1">{array.tipos_terapia[0].estado === false ? <img src="infanto_off_SVG.svg" title="Infanto-juvenil" /> : <img src="infanto_on_SVG.svg" title="Infanto-juvenil" />}</div>
                                                                    <div className="ml-1">{array.tipos_terapia[1].estado === false ? <img src="geriatrico_off_SVG.svg" title="Geriátrico" /> : <img src="geriatrico_on_SVG.svg" title="Geriátrico" />}</div>
                                                                    <div className="ml-1 mr-1">{array.tipos_terapia[2].estado === false ? <img src="adulto_off_SVG.svg" title="Adulto" /> : <img src="adulto_on_SVG.svg" title="Adulto" />}</div>
                                                                    <div className="">{array.tipos_terapia[4].estado === false ? <img src="Adicciones_off_SVG.svg" title="Adicciones" /> : <img src="Adicciones_on_SVG.svg" title="Adicciones" />}</div>
                                                                </div>
                                                            </td>
                                                            <td>${array.precio_emergencia / 100 * currentDatos_porce + array.precio_emergencia}</td>
                                                            <td>
                                                                <div>
                                                                    
                                                                <div className="botonAgendar_otroEle" id="Reintentar"><button className="" onClick={()=>ObtenerIdEmergencia(array.uid)}  >Pagar</button></div>
                                                                   
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    <p></p>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                : ""}
        </main>
        </div> 
            

        </>

    )
}
