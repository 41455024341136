import React, { useState, useEffect } from "react";
import './Dashboard.css';
import { db } from "../firebase"
import Menu from "./menu";
import HeaderPrin from "./header"
import 'moment/locale/es'
import moment from 'moment';


moment.locale('es');


export default function HistorialPagos() {

    const [datoHistorial, setHistorial] = useState("")

    useEffect(() => {
        db.collection("HistorialPagos").where("tipo_pago", "==", "Compra de plan")
            .onSnapshot(function (querySnapshot) {
              var citas = [];
              querySnapshot.forEach(function (doc) {
                let datos = doc.data()
                citas.push(datos);
              });
              setHistorial(citas);
              console.log(citas)
            })
       }, []); 
   
   

    return (
        <>
        <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
   
            
        
                <div>
                    
                        <h2>Historial de Pago</h2>
                        <div className="cuadro_tabla">
                        <div className="table-responsive mt-2">
                  <div className="table_1">
                    <table className="table">
                      <thead>
                                    <tr>
                                        <th>Paciente</th>
                                        <th>Fecha</th>
                                        <th>Plan</th>
                                        <th>Tipo</th>
                                        <th>Id de pago</th>
                                       
                                        <th>Total</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    {datoHistorial.length ? (
                                        datoHistorial.map(array => (
                                            <tr>
                                                <td>{array.nombres_paciente} {array.apellidos_paciente}</td>
                                                <td>{array.fecha_Pago} </td>
                                                <td>{array.Plan}</td>
                                                <td>{array.tipo_pago}</td>
                                                <td>{array.idPago}</td>
                                                <td>{array.monto.value}</td>
                                                
                                            </tr>
                                        )
                                        )
                                    ) : (
                                        <p></p>
                                    )}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
                </div>
                </main>
        </div>  
        </>


    )
}