import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import './Dashboard.css';
import { db,firestore } from "../firebase"
import 'moment/locale/es'
import moment from 'moment';
import { PayPalButton } from "react-paypal-button-v2";
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
init("user_18jqcwflnGbkPgeaoNzWQ");
moment.locale('es');



export default function Inicios() {

  const { currentUser } = useAuth("");
  const [currentDatosPa, setcurrenDatosPa] = useState("");
  const [currentDatosPa_, setcurrenDatosPa_] = useState("");
  const [arrayBuscador,setarrayBuscador]=useState("")
  const history = useHistory("")
  
  const [ActiveBoton, setActiveBoton] = useState("Especialista")
  const [currentDatos_porce, setcurrenDatos_porce] = useState(0);
  const frmContact = { linkRoom: '' };
 
  const [busqueda, setBusqueda]= useState("");
  const [test, settest]= useState("");
  const [panelPerfil, setpanelPerfil] = useState("")
  const [currentDatos_3, setcurrenDatos_3] = useState("");
  const[BotonpypalLista,setBotonpypalLista]=useState(false)
 

 


  const Hamil = async () => {
    db.collection("Usuarios")
      .doc(currentUser.uid)
      .get().then((doc) => {
        let users = doc.data().especialista_sugerido
        let paci = doc.data()
        settest(users)
        setcurrenDatos_3(paci)
        console.log(users)
        if (users == "Psiquiatra") {
          db.collection("Usuarios").where("especialidad", "==", "Psiquiatría").where("aprobado", "==", true).where("activo", "==", true).limit(6)
            .onSnapshot(function (querySnapshot) {
              var ci = [];
              querySnapshot.forEach(function (doc) {
                let da = doc.data()
                da.$key = doc.id
                ci.push(da);
              });
              setcurrenDatosPa(ci)
            });
        }

        if (users == "Psicólogo") {
          db.collection("Usuarios").where("especialidad", "==", "Psicología").where("aprobado", "==", true).where("activo", "==", true).limit(6)
            .onSnapshot(function (querySnapshot) {
              var cit = [];
              querySnapshot.forEach(function (doc) {
                let dat = doc.data()
                dat.$key = doc.id
                cit.push(dat);
              });
              setcurrenDatosPa(cit)
            });
        }
        if (ActiveBoton == "Especialista") {
          db.collection("Usuarios").where("tipouser", "==", "Especialista").where("aprobado", "==", true).where("activo", "==", true)
            .onSnapshot(function (querySnapshot) {
              var citas = [];
              querySnapshot.forEach(function (doc) {
                let datos = doc.data()
                datos.$key = doc.id
                citas.push(datos);
              });
              setcurrenDatosPa_(citas)
            })
        }
      
      });
      console.log(arrayBuscador)
    db.collection("configuracion")
      .doc("am8lciDPAWtxD1tzDyik")
      .get().then(function (doc) {
        let users = doc.data().PorcentajesGanancia
        setcurrenDatos_porce(users);
        // GET PLANES
      });
     
  }

  //Ahora busco todos los especialistas
  // this.firestore.getEspecialistasNotSubscribe().then(res =>{
  //   let data = res
  //   Array.prototype.forEach.call(data, usuario => {
  //     this.especialistas_all = this.especialistas_all.concat(usuario)
  //   })
  //   this.especialistas_sugeridos_all = []
  //   this.especialistas = []
  //   this.especialistas_all.forEach(especialista => {
  //     let ganancia = this.configuracion.PorcentajesGanancia;
  //     let precio = especialista.precio_consulta;
  //     let aumento = (precio * ganancia) / 100;
  //     let precio_final = aumento + precio;
  //     precio_final = Math.round(precio_final);
  //     let nombre_completo =
  //     especialista.nombres + " " + especialista.apellidos;
  //     especialista["nombre_completo"] = nombre_completo;
  //     especialista["precio_consulta_final"] = precio_final;
  //     // ahora evaluo los tipos de terapia
      // let tipos_terapia = [];
      // tipos_terapia = especialista.tipos_terapia;
      // Array.prototype.forEach.call(
      //   especialista.tipos_terapia,
      //   (terapia_user) => {
      //     this.img_terapias.forEach((img_terapia) => {
      //       if (terapia_user.terapia == img_terapia.terapia) {
      //         if (terapia_user.estado == true) {
      //           terapia_user["img"] = img_terapia.img_true;
      //         }
      //         if (terapia_user.estado == false) {
      //           terapia_user["img"] = img_terapia.img_false;
      //         }
      //       }
      //     });
      //   }
      // );
      
  //     //separo los especialistas para solo calcular los indices de los necesarios
  //     //solo analizare los datos de this.especialistas_sugeridos_all

  //     if(this.user.especialista_sugerido == especialista.especialidad){
  //       this.especialistas_sugeridos_all = this.especialistas_sugeridos_all.concat(especialista)
  //     }else if(this.user.especialista_sugerido != especialista.especialidad){
  //       this.especialistas = this.especialistas.concat(especialista)
  //     }
  //   })
    
  //   //Ahora calculare todo lo referente a las fechas

  //   let fecha_hoy = moment().format("DD/MM/YYYY");
  //   let un_mes_atras = moment().subtract(30, "days").format("DD/MM/YYYY");
  //   let un_mes_adelante = moment().add(30, "days").format("DD/MM/YYYY");
  //   let antes_ahora = [];
  //   antes_ahora.push(un_mes_atras);
  //   for (let i1 = 0; antes_ahora[antes_ahora.length - 1] != fecha_hoy; i1++) {
  //     let ultimo_dia = antes_ahora[antes_ahora.length - 1];
  //     let dia_mas = moment(ultimo_dia, "DD/MM/YYYY")
  //       .add(1, "day")
  //       .format("DD/MM/YYYY");
  //     antes_ahora.push(dia_mas);
  //   }
  //   let ahora_despues = [];
  //   ahora_despues.push(moment(fecha_hoy, "DD/MM/YYYY").add(1, "day").format("DD/MM/YYYY"));
  //   for (
  //     let i1 = 0;
  //     ahora_despues[ahora_despues.length - 1] != un_mes_adelante;
  //     i1++
  //   ) {
  //     let ultimo_dia = ahora_despues[ahora_despues.length - 1];
  //     let dia_mas = moment(ultimo_dia, "DD/MM/YYYY")
  //       .add(1, "day")
  //       .format("DD/MM/YYYY");
  //     ahora_despues.push(dia_mas);
  //   }

  // //Consulto citas viejas

  // this.especialistas_sugeridos_all.forEach(especialista =>{
  //   this.firestore.getListadoCitasEspecialistasNotSubscribe(especialista.uid, 'Completada').then(res => {
  //     let citas_viejas = []
  //     citas_viejas = citas_viejas.concat(res)

  //     //Verifico la fecha para solo agregar las que coincidan con las fechas del arrar antes_ahora
        
  //     let citas_viejas_en_rango_de_fecha = []

  //     citas_viejas.forEach(cita => {
  //       let fecha_cita = cita.fecha
        
  //       antes_ahora.forEach(fecha => {

  //         if(fecha_cita == fecha){
  //           citas_viejas_en_rango_de_fecha = citas_viejas_en_rango_de_fecha.concat(cita) 
  //           }
  //         })
  //       })

  //       //agrego datos al especialista

  //       especialista["citas_completadas"] = citas_viejas_en_rango_de_fecha.length;
  //       //saco el promedio de citas entre 30 dias
  //       let promedio = (citas_viejas_en_rango_de_fecha.length * 100) / 30;
  //       //redondeo el promedio a 2 decimales
  //       let promedio_rend = promedio.toFixed(2);
  //       //agrego el valor del promedio al especialista
  //       especialista["promedio_citas_mes_anterior"] = parseFloat(promedio_rend);
  //     }) 
  //   })

  //   //ordeno el array para poder 

  //   setTimeout(() => {

  //   //Ordeno de mayor a menor por el promedio de citas

  //     this.especialistas_sugeridos_all.sort((a, b) => b.promedio_citas_mes_anterior - a.promedio_citas_mes_anterior);

  //   //selecciono solo los primeros para buscar sus proximas citas

  //     console.log(this.especialistas)
  //     this.especialistas_sugeridos = []
  //     for(let i = 0; i<this.especialistas_sugeridos_all.length; i++){
  //       let especialista = this.especialistas_sugeridos_all[i]
  //       if(i<=6){
  //         this.especialistas_sugeridos = 
  //           this.especialistas_sugeridos.concat(especialista)
  //       }else if(i > 6){
  //         this.especialistas = 
  //           this.especialistas.concat(especialista)
  //       }
  //     }

    
  //     //ahora busco los horarios de los especialistas sugeridos para calcular disponibilidad

  //     this.especialistas_sugeridos.forEach(especialista =>{
  //       let cantidad_dias = 0;
  //       let cuposTotal = 0;
  //       let horarios;
  //         this.firestore.horarioEspecialista(especialista.uid).then(res => {
  //             horarios = res
  //             horarios.forEach(horario =>{
  //               let dia_horario = horario.dia;
  //               let cantidad_horas = horario.horas.length;
  //               ahora_despues.forEach(dia_adelante =>{
  //                 let dia_calendario = moment(dia_adelante,"DD/MM/YYYY").format("dddd");
  //                 if (dia_horario == dia_calendario) {
  //                   cantidad_dias = cantidad_dias + 1;
  //                   cuposTotal = cuposTotal + cantidad_horas;
  //                 }
  //               })
  //             })
  //             especialista["disponibilidadTotal"] = cuposTotal;
  //         })
  //         console.log(this.especialistas_sugeridos)

  //         let citas_nuevas_en_rango_de_fecha = []
          
  //         this.firestore.getListadoCitasEspecialistasNotSubscribe(especialista.uid, 'Agendada').then(res =>{
  //           let citas_nuevas = []
  //           citas_nuevas = citas_nuevas.concat(res)
            
  //           // console.log(ahora_despues)
  //           // console.log(citas_nuevas)

  //           //Verifico la fecha para solo agregar las que coincidan con las fechas del arrar ahora_despues  

  //           citas_nuevas.forEach(cita => {
  //             let fecha_cita = cita.fecha
              
  //             ahora_despues.forEach(fecha => {

  //               if(fecha_cita == fecha){
  //                 // console.log(cita)
  //                 citas_nuevas_en_rango_de_fecha = citas_nuevas_en_rango_de_fecha.concat(cita) 
  //                 }
  //               })
  //             })
  //             especialista["citas_agendadas"] = citas_nuevas_en_rango_de_fecha.length;
  //             //saco el promedio de ocupacion en los proximos 30 dias
  //             let promedio =
  //               (citas_nuevas_en_rango_de_fecha.length * 100) /
  //               especialista.disponibilidadTotal;
  //             //redondeo el promedio a 2 decimales
  //             let promedio_rend = promedio.toFixed(2);
  //             //agrego el valor del promedio al especialista
  //             especialista["promedio_ocupacion"] =
  //               parseFloat(promedio_rend); 
  //         })
  //     })
  //     setTimeout(() => {
  //       this.especialistas_sugeridos.sort((a, b) => a.promedio_ocupacion - b.promedio_ocupacion);
  //       this.data_ready = true
  //       console.log(this.especialistas_sugeridos)
  //     }, 2000)
  //   }, 1000);
  // })

  useEffect(() => {
    Hamil();
  }, []);

  function ObtenerId(e) {
    if(test===""){
      history.push("/Service")
    }else if( test!==""){
      history.push("/Index")
    localStorage.setItem('IdCita', JSON.stringify(''))
    localStorage.setItem("id", JSON.stringify(e))  
  }
  }


/////////////////Buscador////////////////////////////////////////
  const handleChange=e=>{
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  }
  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=currentDatosPa_.filter((elemento)=>{
      // let nombre=elemento.nombres + elemento.apellidos
      let busqueda=terminoBusqueda.trim()
      console.log(busqueda)
      // console.log(nombre)
      if(elemento.nombres.toString().toLowerCase().includes(busqueda.toLowerCase())
      ||elemento.apellidos.toString().toLowerCase().includes(busqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    if(terminoBusqueda!==""){
    setcurrenDatosPa_(resultadosBusqueda);
  }else if(terminoBusqueda===""){
    db.collection("Usuarios").where("tipouser", "==", "Especialista").where("aprobado", "==", true).where("activo", "==", true)
    .onSnapshot(function (querySnapshot) {
      var citas = [];
      querySnapshot.forEach(function (doc) {
        let datos = doc.data()
        datos.$key = doc.id
        citas.push(datos);
      });
      setcurrenDatosPa_(citas)
    })
  }
  }

/////////////////Buscador////////////////////////////////////////

  function ObtenerDatos(e) {
    history.push("/Profile")
    localStorage.setItem("IdPerfilMedicos", JSON.stringify(e))
  }


  function tipoEspecialista(e) {
    setActiveBoton(e)
    if (e === "Psiquiatría") {
      const array_to = [];
      db.collection("Usuarios").where("especialidad", "==", "Psiquiatría").where("aprobado", "==", true).where("activo", "==", true).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let use = doc.data()
          array_to.push(use);
        });
        setcurrenDatosPa_(array_to);
      });
    }
    if (e === "Psicología") {
      const array_to = [];
      db.collection("Usuarios").where("especialidad", "==", "Psicología").where("aprobado", "==", true).where("activo", "==", true).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let use = doc.data()
          array_to.push(use);
        });
        setcurrenDatosPa_(array_to);
      });
    }
    if (e === "Especialista") {
      const array_to = [];
      db.collection("Usuarios").where("tipouser", "==", "Especialista").where("aprobado", "==", true).where("activo", "==", true).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let use = doc.data()
          array_to.push(use);
        });
        setcurrenDatosPa_(array_to);
      });
    }
  }
  const [cambiarVistas, setCambiarVistas] = useState("")
  const [BotonPypal,setpypal]=useState("")
  function ObtenerIdEmergencia(e) {
    localStorage.setItem("idEmergencia", JSON.stringify(e))
    history.push("/PagarEmergencia")
}

  const uidNewSala=Math.random().toString(20).substring(7);
  const uidNewHisto=Math.random().toString(20).substring(7); 
const clMedicalal = (e, details, data, precio, nombres, apellidos, foto) => {
    let r = Math.random().toString(36).substring(7);
    let link = 'https://meet.jit.si' + '/' + currentUser.uid + r

    db.collection("SalaEspera").doc().set({
      uid:uidNewSala,
            paciente_abandona:false,
            fecha_noti:new Date(),
            especialista_acepto: false,
            notificacion: true,
            estatus:"Agendada",
            notificacion_especialista: true,
            notificacion_paciente: false,
            uid_especialista: e,
            fecha_creacion: moment().format("DD/MM/YYYY"),
            fecha: moment().format("DD/MM/YYYY"),
            foto_paciente: currentDatos_3.foto_personal,
            foto_especialista: foto,
            nombres_paciente: currentDatos_3.nombres,
            apellidos_paciente: currentDatos_3.apellidos,
            nombre_especialista: nombres,
            apellido_especialista: apellidos,
            uid_paciente: currentDatos_3.uid,
            room_link: link,
            recordatorio: false,
            num_reintentar: 0,
            hora_notificacion: moment().format("hh:mm A")
    }).then((resp) => {
      console.log(resp)
    })

    let datos = currentDatos_3
    datos.emergenciaPaga = true
    datos.montoEmergenciaPagado = precio
    datos.uid_pago_emergencia=uidNewHisto
    console.log(datos)

    db.collection("Usuarios").doc(currentDatos_3.$key).set(datos)
      .then(resp => {
        db.collection("HistorialPagos").doc(datos.uid_pago_emergencia).set({
          uid:datos.uid_pago_emergencia,
                estatusAdmin:false,
                tipo_pago: "Emergencia",
                nombres_paciente:currentDatos_3.nombres,
                uid_paciente:currentDatos_3.uid,
                cedula_paciente: currentDatos_3.cedula,
                nombre_especialista: '',
                    uid_especialista: '',
                    apellido_especialista: '',
                    uid_especialista: '',
                    fecha_cancelacion:'',
                    pago_cancelado:false,
                monto_emergencia:precio,
                monto_emergencia_especialista:precio,
                monto_porcentaje_terapia:precio / 100 * currentDatos_porce,
                apellidos_paciente: currentDatos_3.apellidos,
                estatusPago: details.status,
                num_principal_paciente: currentDatos_3.num_principal,
                fecha_Pago: moment().format("DD/MM/YYYY"),
                idPago: details.id,
                fechaPagoPaypal: details.create_time,
                direccionPagador: details.payer.address,
                emailPagador: details.payer.name,
                monto: details.purchase_units[0].amount,
                facilitatorAccessToken: data.facilitatorAccessToken,
                orderID: data.orderID,
                payerID: data.payerID,
                Plan: "Individual",
                tipo_plan: "Individual",
        }).then(() => {
          alert("¡Se realizo su emergencia exitosamente!")
          history.push("/DoctorParaEmergencia")
        })
      })
  };

  function Mostrarpaypal(){
    setBotonpypalLista(true)
  }
  return (
    <div>
      {panelPerfil === "" ?
        <div>
          <h2 className="dash-title mt-5 mb-3" >Especialistas sugeridos</h2>
          <div className="dash-cards">
            {currentDatosPa.length ? (
              currentDatosPa.map(array => (
                <div className="card-single mb-5">
                  <div className="icon_target form-inline">
                    {array.disponible_emergencia === true ? <img title="Disponible para emergencias" className="icono_Emergencia" src="Emergency_icon_on_SVG.svg" /> : ""}
                    {array.disponible_emergencia === false ? <img title="No disponible para emergencias" className="icono_Emergencia" src="Emergency_icon_off_SVG.svg" /> : ""}
                    <img  onClickCapture={() => ObtenerDatos(array.uid)} title="Ver perfil" className="iconoUs" src="View_profile_SVG.svg" />
                  </div>
                  <div className="card-body">
                    <div className="updaCard text-center mb-4" ><img  onClickCapture={() => ObtenerDatos(array.uid)} src={array.foto_personal} ></img></div>
                    <div className="" id="body_datos">
                      <div className="body_Nombres"><p>{array.nombres}&nbsp;&nbsp;{array.apellidos}</p></div>
                      <div className="body_especialista"></div>
                    </div>
                  </div>
                  <div className="form-inline col-12 mt-3 mb-3 medio">
                    <div className="Em col-6 text-center">
                      <p>
                        Profesión
                        <p>{array.especialidad}</p>
                      </p>
                    </div>
                    <div className="Ban col-6 text-center">
                      <p>
                      Honorarios
                        <p>${array.precio_consulta / 100 * currentDatos_porce + array.precio_consulta}</p>
                      </p>
                    </div>
                  </div>
                  <p className="text-center">Especialidades</p>
                  <div className="card-footer_iconos ">
                  <div className="iconosTerapias_iconos form-inline col-12 ">
                  <div className="col-1"></div>
                    <div className="col-2 ">{array.tipos_terapia[3].estado === false ? <img src="parejas_off_SVG.svg" title="Terapia de pareja" /> : <img src="parejas_on_SVG.svg" title="Terapia de pareja" />}</div>
                    <div className="col-2 ">{array.tipos_terapia[0].estado === false ? <img src="infanto_off_SVG.svg" title="Infanto-juvenil" /> : <img src="infanto_on_SVG.svg" title="Infanto-juvenil" />}</div>
                    <div className="col-2 ">{array.tipos_terapia[1].estado === false ? <img src="geriatrico_off_SVG.svg" title="Geriátrico" /> : <img src="geriatrico_on_SVG.svg" title="Geriátrico" />}</div>
                    <div className="col-2 ">{array.tipos_terapia[2].estado === false ? <img src="adulto_off_SVG.svg" title="Adulto" /> : <img src="adulto_on_SVG.svg" title="Adulto" />}</div>
                    <div className="col-2 ">{array.tipos_terapia[4].estado === false ? <img src="Adicciones_off_SVG.svg" title="Adicciones" /> : <img src="Adicciones_on_SVG.svg" title="Adicciones" />}</div>
                    <div className="col-1 "></div>
                  </div>
                  </div>
                  
                  <div className="card-footer_1 botonAgendar">
                    <button className="btn" onClick={() => ObtenerId(array.uid)}> Agendar cita</button>
                  </div>
                 
                </div>
              )
              )) : (
              <div></div>
            )}
          </div>
          <section className="recent">
            <h2 className="dash-title">Especialistas</h2>
            <div className="form-inline headerPrincipal_tabla">
              <div className="header_tabla form-inline ">
                <div className="col-6">
                  <img src="Buscar_SVG.svg" className="mr-2" /><input className="form-control" onChange={handleChange} placeholder="Buscar" type="seach" />
                </div>
                <div className={ActiveBoton == "Especialista" ? "col-2 botones_header_Activo a" : "col-2 botones_header a"} onClick={(e) => tipoEspecialista("Especialista", e)}>Todos</div>
                <div className={ActiveBoton == "Psiquiatría" ? "col-2 botones_header_Activo_medio b" : "col-2 botones_header b"} onClick={(e) => tipoEspecialista("Psiquiatría", e)}>Psiquiatras</div>
                <div className={ActiveBoton == "Psicología" ? "col-2 botones_header_Activo_right c" : "col-2 botones_header c"} onClick={(e) => tipoEspecialista("Psicología", e)}>Psicólogos</div>
              </div>
              <div className="header_vista form-inline">
                <div className={cambiarVistas === "" ? "col-6 text-center  VistaCuadriculaColor" : "col-6 text-center  VistaCuadricula"} title="Vista cuadricula" onClick={() => setCambiarVistas("")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.82 54.61"><g id="Capa_2" data-name="Capa 2"><g id="Icons"><path d="M14.25,0H1.58A1.58,1.58,0,0,0,0,1.58V14.25a1.58,1.58,0,0,0,1.58,1.58H14.25a1.58,1.58,0,0,0,1.58-1.58V1.58A1.58,1.58,0,0,0,14.25,0Z" /><path d="M52.24,0H39.57A1.58,1.58,0,0,0,38,1.58V14.25a1.58,1.58,0,0,0,1.58,1.58H52.24a1.58,1.58,0,0,0,1.58-1.58V1.58A1.58,1.58,0,0,0,52.24,0Z" /><path d="M14.25,38.78H1.58A1.59,1.59,0,0,0,0,40.37V53a1.58,1.58,0,0,0,1.58,1.58H14.25A1.58,1.58,0,0,0,15.83,53V40.37A1.59,1.59,0,0,0,14.25,38.78Z" /><path d="M52.24,38.78H39.57A1.59,1.59,0,0,0,38,40.37V53a1.58,1.58,0,0,0,1.58,1.58H52.24A1.58,1.58,0,0,0,53.82,53V40.37A1.59,1.59,0,0,0,52.24,38.78Z" /></g></g></svg></div>
                <div className={cambiarVistas === "Lista" ? "col-6 text-center VistaListaColor" : "col-6 text-center  VistaLista"} title="Vista Lista" onClick={() => setCambiarVistas("Lista")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.78 57.65"><g id="Capa_2" data-name="Capa 2"><g id="Icons"><path d="M54.67,18.94H17.72a1.94,1.94,0,0,0-2.12,1.68,1.94,1.94,0,0,0,2.12,1.67h37a1.93,1.93,0,0,0,2.11-1.67A1.93,1.93,0,0,0,54.67,18.94Z" /><circle cx="4.19" cy="20.62" r="4.19" /><path d="M17.72,5.87h37a1.93,1.93,0,0,0,2.11-1.68,1.93,1.93,0,0,0-2.11-1.67H17.72A1.94,1.94,0,0,0,15.6,4.19,1.94,1.94,0,0,0,17.72,5.87Z" /><circle cx="4.19" cy="4.19" r="4.19" /><path d="M54.67,35.36H17.72A1.94,1.94,0,0,0,15.6,37a1.94,1.94,0,0,0,2.12,1.67h37A1.93,1.93,0,0,0,56.78,37,1.93,1.93,0,0,0,54.67,35.36Z" /><circle cx="4.19" cy="37.04" r="4.19" /><path d="M54.67,51.78H17.72a1.73,1.73,0,1,0,0,3.36h37a1.72,1.72,0,1,0,0-3.36Z" /><circle cx="4.19" cy="53.46" r="4.19" /></g></g></svg></div>
              </div>
            </div>
            <div className="cuadro_tabla">
              {cambiarVistas === "" ?
                <div className="dash-cards_tabla">
                  {currentDatosPa_.length ? (
                    currentDatosPa_.map(array => (
                      <div className="card-single_tabla">
                        <div className="icon_target_tabla form-inline">
                          {array.disponible_emergencia === true ? <img title="Disponible para emergencias" className="icono_Emergencia_tabla" src="Emergency_icon_on_SVG.svg" /> : ""}
                          {array.disponible_emergencia === false ? <img title="No disponible para emergencias" className="icono_Emergencia_tabla" src="Emergency_icon_off_SVG.svg" /> : ""}
                          <img  onClick={() => ObtenerDatos(array.uid)} title="Ver perfil" className="iconoUs" src="View_profile_SVG.svg" />
                        </div>

                        <div className="card-body">
                          <div className="updaCard_tabla text-center mb-2" ><img onClick={() => setpanelPerfil("3")} onClickCapture={() => ObtenerDatos(array.uid)} src={array.foto_personal} ></img></div>
                          <div className="" id="body_datos">
                            <div className="body_Nombres"><p>{array.nombres}&nbsp;&nbsp;{array.apellidos}</p></div>
                            <div className="body_especialista"></div>
                          </div>
                        </div>
                        <div className="form-inline col-12 mb-2 medio">
                          <div className="Em col-6 text-center">
                            <p>
                              Profesión
                              <p>{array.especialidad}</p>
                            </p>
                          </div>
                          <div className="Ban col-6 text-center">
                            <p>
                              Honorarios
                              <p>${array.precio_consulta / 100 * currentDatos_porce + array.precio_consulta

                              }</p>
                            </p>
                          </div>
                        </div>
                        <p className="text-center">Especialidades</p>
                  <div className="card-footer_iconos_tabla ">
                  <div className="iconosTerapias_iconos form-inline col-12 ">
                  <div className="col-1"></div>
                          <div className="col-2">{array.tipos_terapia[3].estado === false ? <img src="parejas_off_SVG.svg" title="Terapia de pareja" /> : <img src="parejas_on_SVG.svg" title="Terapia de pareja" />}</div>
                          <div className="col-2">{array.tipos_terapia[0].estado === false ? <img src="infanto_off_SVG.svg" title="Infanto-juvenil" /> : <img src="infanto_on_SVG.svg" title="Infanto-juvenil" />}</div>
                          <div className="col-2">{array.tipos_terapia[1].estado === false ? <img src="geriatrico_off_SVG.svg" title="Geriátrico" /> : <img src="geriatrico_on_SVG.svg" title="Geriátrico" />}</div>
                          <div className="col-2">{array.tipos_terapia[2].estado === false ? <img src="adulto_off_SVG.svg" title="Adulto" /> : <img src="adulto_on_SVG.svg" title="Adulto" />}</div>
                          <div className="col-2">{array.tipos_terapia[4].estado === false ? <img src="Adicciones_off_SVG.svg" title="Adicciones" /> : <img src="Adicciones_on_SVG.svg" title="Adicciones" />}</div>
                          <div className="col-1 "></div>
                  </div>
                  </div>
                        <div className="card-footer_1 botonAgendar_tabla">
                       <button className="" onClick={() => ObtenerId(array.uid)}> Agendar cita</button>
                        </div>
                      </div>
                    )
                    )) : (
                    <div></div>
                  )}
                </div>
                : ""}
              {cambiarVistas === "Lista" ?
                <div className="table-responsive mt-2">
                  <div className="table_1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th><img src="View_profile_SVG.svg" /></th>
                          <th>Nombres</th>
                          <th>Apellidos</th>
                          <th>Profesíon</th>
                          <th>Especialidades</th>
                          <th>Honorarios</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentDatosPa_.length ? (
                          currentDatosPa_.map(array => (
                            <tr>
                              <td>
                                <div className="updaPrin_tabla"><img onClickCapture={() => ObtenerDatos(array.uid)} src={array.foto_personal}></img></div>
                              </td>
                              <td>{array.nombres}</td>
                              <td>{array.apellidos}</td>
                              <td>{array.especialidad}</td>
                              <td>
                                <div className="form-inline  mb-2 iconosTerapias">
                                  <div className="ml-1">{array.tipos_terapia[3].estado === false ? <img src="parejas_off_SVG.svg" title="Terapia de pareja" /> : <img src="parejas_on_SVG.svg" title="Terapia de pareja" />}</div>
                                  <div className="ml-1">{array.tipos_terapia[0].estado === false ? <img src="infanto_off_SVG.svg" title="Infanto-juvenil" /> : <img src="infanto_on_SVG.svg" title="Infanto-juvenil" />}</div>
                                  <div className="ml-1">{array.tipos_terapia[1].estado === false ? <img src="geriatrico_off_SVG.svg" title="Geriátrico" /> : <img src="geriatrico_on_SVG.svg" title="Geriátrico" />}</div>
                                  <div className="ml-1 mr-1">{array.tipos_terapia[2].estado === false ? <img src="adulto_off_SVG.svg" title="Adulto" /> : <img src="adulto_on_SVG.svg" title="Adulto" />}</div>
                                  <div className="">{array.tipos_terapia[4].estado === false ? <img src="Adicciones_off_SVG.svg" title="Adicciones" /> : <img src="Adicciones_on_SVG.svg" title="Adicciones" />}</div>
                                </div>
                              </td>
                              <td>${array.precio_consulta / 100 * currentDatos_porce + array.precio_consulta}</td>
                              <td><div className="agendar"><button  onClick={() => ObtenerId(array.uid)}> Agendar cita</button></div> </td>
                              <td>

                              {array.disponible_emergencia === true ?
                              <div>
                                {BotonpypalLista===false?<div>
                                {array.disponible_emergencia === true ? <div className="agendar"><button className="" onClick={() => ObtenerIdEmergencia(array.uid)}>Emergencia</button></div> : <img title="No disponible para emergencias" className="icono_Emergencia_tabla" src="Emergency_icon_off_SVG.svg" />}
                                </div>
                                :
                                <div className="">
                                  <PayPalButton
                                    amount={array.precio_emergencia / 100 * currentDatos_porce + array.precio_emergencia}
                                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                    onSuccess={(details, data) => {
                                      // OPTIONAL: Call your server to save the transaction
                                      fetch("/paypal-transaction-complete", {
                                        method: "post",
                                        body: JSON.stringify({
                                          orderId: data.orderID
                                        })
                                      })
                                        .then(resp => {
                                          setBotonpypalLista(false)
                                          clMedicalal(array.uid, details, data, array.precio_emergencia, array.nombres,array.apellidos, array.foto_personal)
                                        })
                                    }}
                                    catchError={(error) => {
                                      console.log(error)

                                    }}
                                    options={{
                                      clientId: "AV5h0ivhjvRnCQYyPCjnCzumB7lKEJP1IADFcBoqSRNVGfdTd3EkFFdDUVmFzxr2-gVQKS9evl1_E_Er",
                                      currency: "USD"
                                    }}
                                  />
                                </div>
                                }
                                </div>
                                :<img title="No disponible para emergencias" className="icono_Emergencia_tabla" src="Emergency_icon_off_SVG.svg" /> }
                                 
                                
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                : ""}
            </div>
          </section>
        </div>
        : ""}
    </div>

  )
}
