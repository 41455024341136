import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import './Dashboard.css';
import { db } from "../firebase"
import { faTrash, faWindowClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PayPalButton } from "react-paypal-button-v2";
import moment from 'moment';
import firebase from "../firebase"
import Menu from "./menu";
import HeaderPrin from "./header"

moment.locale('es');

export default function Perfil() {
    const [currentPerfil, setCurrentPerfil] = useState("");
    const [currentTipoPerfil, setTipoPerfil] = useState("");
    const history = useHistory()
    const [currentDatos_3, setcurrenDatos_3] = useState("");
    const [misHorarios, setMishorarios] = useState("");
    const [BotonPypal, setpypal] = useState("")
    const [currentDatos_porce, setcurrenDatos_porce] = useState(0);
    const { currentUser } = useAuth();
    const [mostrarBotoPypal, setpypalBoton] = useState(true)
    const [currentDatosPaDere, setLinksre] = useState([]);
    const [currentDatosHistoria, setLinksHistorial] = useState([]);
    const idPerfil = JSON.parse(localStorage.getItem('IdPerfilMedicos'))
    const [Datosrecimos, setDatosReciMos] = useState("")
    const [Datosreci, setDatosReci] = useState("")
    const [currentDatosPaDe, setLinks] = useState([]);
    const [Exito, setExito] = useState("")

    const [IdHistoria, setId] = useState("")
    /**nuevas variables */

    const Emerge = async () => {
        
            db.collection("Usuarios")
              .doc(idPerfil)
              .get().then(function (doc) {
                let users = doc.data()
                db.collection("historialMedico").where("uid_paciente", "==", idPerfil).onSnapshot((querySnapshot) => {
                  const docs = [];
                  querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                  });
                    let data =docs;
                    let pacienteArray = [];
                    console.clear();
                    for (let i = 0; i <data.length; i++) {
                      let Newdata = {
                        apellido_paciente: data[i].apellido_paciente,
                        nombre_paciente: data[i].nombre_paciente,
                        uid_paciente: data[i].uid_paciente,
                        foto_paciente: data[i].foto_paciente,
                        nombre_completo: data[i].nombre_paciente+' '+data[i].apellido_paciente
                      }
                      pacienteArray.push(Newdata);
                      
                    }
                    let unicos = [];
                    for (var indice = 0; indice < pacienteArray.length; indice++) {
                      const persona = pacienteArray[indice];
                      let esDuplicado = false;
                      for (var i = 0; i < unicos.length; i++) {
                        if (unicos[i].uid_paciente === persona.uid_paciente) {
                          esDuplicado = true;
                          break;
                        }
                      }
              
                      if (!esDuplicado) {
                        unicos.push(persona);
                      }
                    }
                   let pacientes = unicos;
                    console.log(pacientes)
                    setLinks(pacientes)
                });
              });
          
        db.collection("Usuarios")
            .doc(idPerfil)
            .get().then(function (doc) {
                let users = doc.data()
                let Tipousers = doc.data().tipouser
                setCurrentPerfil(users);
                setTipoPerfil(Tipousers);
            })
        db.collection("Usuarios").doc(idPerfil).collection("Horario").orderBy("orden").onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc.data());
            });
            setMishorarios(docs)
        });
        db.collection("Recipes").where("uid_paciente", "==", idPerfil).onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
              docs.push({ ...doc.data(), id: doc.id });
            });
            setLinksre(docs);
          });
          db.collection("historialMedico").where("uid_paciente", "==", idPerfil).onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
              docs.push({ ...doc.data(), id: doc.id });
            });
            setLinksHistorial(docs);
          });


    }
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            db.collection("Usuarios")
                .doc(user.uid)
                .get().then(function (doc) {
                    let users = doc.data()
                    users.$key = doc.id
                    setcurrenDatos_3(users);
                });
        })
        db.collection("configuracion")
            .doc("am8lciDPAWtxD1tzDyik")
            .get().then(function (doc) {
                let users = doc.data().PorcentajesGanancia

                setcurrenDatos_porce(users);
                // GET PLANES
            });
        // GET PLANES
        Emerge();
    }, []);


    const jitsiContainerStyle = {
        width: '100%',
        height: '100%',
    }
    const uidNewHisto = Math.random().toString(20).substring(7);
    const clMedicalal = (e, details, data, precio, nombres, apellidos, foto) => {
        let r = Math.random().toString(36).substring(7);
        let link = 'https://meet.jit.si' + '/' + currentUser.uid + r

        db.collection("SalaEspera").doc(uidNewHisto).set({
            uid:uidNewHisto,
            fecha_notificacion: moment().format("DD/MM/YYYY"),
            especialista_acepto: false,
            notificacion: true,
            estatus:"Agendada",
            notificacion_especialista: true,
            notificacion_paciente: false,
            paciente_abandona: false,
            fecha_noti: new Date(),
            uid_especialista: e,
            fecha_creacion: moment().format("DD/MM/YYYY"),
            fecha: moment().format("DD/MM/YYYY"),
            foto_paciente: currentDatos_3.foto_personal,
            foto_especialista: foto,
            nombres_paciente: currentDatos_3.nombres,
            apellidos_paciente: currentDatos_3.apellidos,
            nombre_especialista: nombres,
            apellido_especialista: apellidos,
            uid_paciente: currentUser.uid,
            room_link: link,
            recordatorio: false,
            num_reintentar: 0,
            hora_notificacion: moment().format("hh:mm A"),
            fecha_notificacion: moment().format("DD/MM/YYYY")
        }).then((resp) => {
            console.log(resp)
        })

        let datos = currentDatos_3
        datos.emergenciaPaga = true
        datos.montoEmergenciaPagado = precio
        datos.uid_pago_emergencia = uidNewHisto
        console.log(datos)

        db.collection("Usuarios").doc(currentDatos_3.$key).set(datos)
            .then(resp => {
                db.collection("HistorialPagos").doc(datos.uid_pago_emergencia).set({
                    uid: datos.uid_pago_emergencia,
                    tipo_pago: "Emergencia",
                    estatusAdmin: false,
                    nombres_paciente: currentDatos_3.nombres,
                    uid_paciente: currentDatos_3.$key,
                    cedula_paciente: currentDatos_3.cedula,
                    nombre_especialista: '',
                    uid_especialista: '',
                    fecha_cancelacion: '',
                    pago_cancelado: false,
                    apellido_especialista: '',
                    apellidos_paciente: currentDatos_3.apellidos,
                    estatusPago: details.status,
                    num_principal_paciente: currentDatos_3.num_principal,
                    fecha_Pago: moment().format("DD/MM/YYYY"),
                    idPago: details.id,
                    monto_emergencia: precio,
                    monto_emergencia_especialista: precio,
                    monto_porcentaje_terapia: precio / 100 * currentDatos_porce,
                    fechaPagoPaypal: details.create_time,
                    direccionPagador: details.payer.address,
                    emailPagador: details.payer.name,
                    monto: details.purchase_units[0].amount,
                    facilitatorAccessToken: data.facilitatorAccessToken,
                    orderID: data.orderID,
                    payerID: data.payerID,
                    Plan: "Individual",
                    tipo_plan: "Individual",
                }).then(() => {
                    history.push("/DoctorParaEmergencia")
                    alert("Se realizo su emergencia exitosamente!!!")
                })
            })
    };


    function ObtenerId(e) {
        localStorage.setItem("id", JSON.stringify(e))
        
    }

    function ObtenerIdEmergencia(e) {
      localStorage.setItem("idEmergencia", JSON.stringify(e))
      history.push("/PagarEmergencia")
  }

    const [descripcion, setdescripcion] = useState("")
    const [editarHistoria,seteditarHistoria]=useState("")

    function DatosReci(e) {
  
        db.collection("Recipes")
          .doc(e)
          .get().then(function (doc) {
            let users = doc.data()
            let $key = doc.id
            setDatosReci(users);
            
          });
        setDatosReciMos("1")
      }
     function Blanquear(){
         window.location.reload()
     }
     function ActualizarDescripcion() {
        db.collection("historialMedico").doc(IdHistoria).update({
          "descripcion": descripcion,
        }).then(() => {
          setExito("Historial Actulizado")
          setTimeout(res => { setExito(true) }, 3000);
          history.push("/Historias")
          setDatosReciMos("")
          seteditarHistoria("")
        }).catch((error) => {
          console.error("Error removing document: ", error);
        });
      }
    
    function DatosReci_1(e) {
        db.collection("historialMedico").where("uid_paciente", "==", e).where("uid_especialista", "==",currentUser.uid).onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
          });
          setDatosReci(docs)
        setDatosReciMos("1")
      });
      }
      function NewVentana(e){
        window.open(e)
      }

    return (
        <>
            <input type="checkbox" id="sidebar-toggle" />
            <div className="sidebar">
                <Menu> </Menu>
            </div>
            <div className="main-content">
                <header className="row">
                    <HeaderPrin></HeaderPrin>
                </header>
                <main>
                    
                                {currentTipoPerfil === "Especialista" ?
                                    <div className="card-single_per">
                                        <div className="text-right cerrar" ><Link to="/Dashboard"><img src="save_SVG.svg"></img></Link></div>
                                        <div className="updaPrin_per form-inline">
                                            <div className="Fo">
                                                <img src={currentPerfil.foto_personal} />&nbsp;{currentPerfil.nombres}&nbsp;{currentPerfil.apellidos}
                                               {currentDatos_3.tipouser!=="Administrador"?
                                                <div className="bt form-inline">
                                                    <div className="Cita ml-3"> <Link to="/Index"><button className="" onClick={() => ObtenerId(currentPerfil.uid)} >Agendar cita</button></Link></div>
                                                    <div className="Emergencia"><button className="" onClick={() =>ObtenerIdEmergencia(currentPerfil.uid)}>Emergencia</button></div>
                                                </div>
                                                :""} 
                                                </div>
                                            {currentDatos_3.tipouser==="Administrador"?
                                            <div className="datos row">
                                            <div className="">
                                                    <div className="titulodatospro"><h5>Documentos</h5></div>
                                                </div>
                                                <div className="">
                                                <div className="form-inline">
                                                <div className="profe col-6">
                                                    {currentPerfil.titulo=== "" ? <p>No posee datos</p> : <a className="VerReci"onClick={()=> NewVentana(currentPerfil.titulo_universitario)} >Titulo</a>}
                                                    <div>
                                                    </div>
                                                </div>
                                                <div className="profe col-6">
                                                    {currentPerfil.firma=== ""? <p>No posee datos</p> : <a className="VerReci" onClick={()=> NewVentana(currentPerfil.firma)}>Firma</a>}
                                                    <div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="form-inline">
                                                <div className="profe col-6">
                                                    {currentPerfil.sello==="" ? <p>No posee datos</p> : <a className="VerReci" onClick={()=> NewVentana(currentPerfil.sello)} >Sello</a>}
                                                    <div>
                                                    </div>
                                                </div>
                                                <div className="profe col-6">
                                                    {currentPerfil.documento_identidad=== "" ? <p>No posee datos</p> : <a className="VerReci" onClick={()=> NewVentana(currentPerfil.documento_identidad)} >Cédula</a>}
                                                    <div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            :""} 
                                            </div>
                                        

                                        <div className="row  mt-2" id="target_datos">
                                            <div className="datos_1_ho">
                                                <div className="titulodatospro"><h5>Horarios</h5></div>
                                                <div className="iconoPerf"><img src="Clock_1_SVG.svg" /></div>
                                                {misHorarios.length ?
                                                    misHorarios.map(hora => (
                                                        <div className="card-body_horarios">
                                                            <div className="tituLoPro ml-1">
                                                                <h5>{hora.dia}</h5>
                                                            </div>
                                                            <div className="form-inline">
                                                                {hora.horas.map((hora) => {
                                                                    return (
                                                                        <div className="form-inline ml-1">
                                                                            <div className="Forma_hora_profile">
                                                                                {hora}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )) : (
                                                        <p></p>
                                                    )}
                                            </div>
                                            <div className="datos_2">
                                                <div className="">
                                                    <div className="titulodatospro"><h5>Datos profesionales</h5></div>
                                                    <div className="iconoPerf">
                                                        <img src="Folio_SVG.svg" />
                                                    </div>
                                                </div>
                                                <div className="profe">
                                                    <label>Profesión</label>
                                                    {currentPerfil.especialidad === "" ? <p>.</p> : <p>{currentPerfil.especialidad}</p>}
                                                </div>
                                                <div className="a_os">
                                                    <label>Años de experiencia</label>
                                                    {currentPerfil.experiencia === "" ? <p>.</p> : <p>{currentPerfil.experiencia}</p>}
                                                </div>
                                                <div className="espe">
                                                    <label>Especialidades</label>
                                                    <div className="form-inline  iconosTerapiasPerfil">
                                                        <div className="text-center col-2">{currentPerfil.tipos_terapia === false ? <img src="parejas_off_SVG.svg" title="Terapia de pareja" /> : <img src="parejas_on_SVG.svg" title="Terapia de pareja" />}</div>
                                                        <div className="text-center col-2">{currentPerfil.tipos_terapia === false ? <img src="infanto_off_SVG.svg" title="infanto-juvenil" /> : <img src="infanto_on_SVG.svg" title="Infanto-juvenil" />}</div>
                                                        <div className="text-center col-2">{currentPerfil.tipos_terapia === false ? <img src="geriatrico_off_SVG.svg" title="Geriátrico" /> : <img src="geriatrico_on_SVG.svg" title="Geriátrico" />}</div>
                                                        <div className="text-center col-2">{currentPerfil.tipos_terapia === false ? <img src="adulto_off_SVG.svg" title="Adulto" /> : <img src="adulto_on_SVG.svg" title="Adulto" />}</div>
                                                        <div className="text-center col-2">{currentPerfil.tipos_terapia === false ? <img src="Adicciones_off_SVG.svg" title="Adicciones" /> : <img src="Adicciones_on_SVG.svg" title="Adicciones" />}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="datos_3">
                                                <div className="">
                                                    <div className="titulodatospro"><h5>Honorarios</h5></div>
                                                    <div className="iconoPerf"><img src="Dollar_SVG.svg" /></div>
                                                </div>
                                                <div className="porCita">
                                                    <label>Por consulta</label>
                                                    <p>${currentPerfil.precio_consulta / 100 * currentDatos_porce + currentPerfil.precio_consulta}</p>
                                                </div>
                                                <div className="porEmer">
                                                    <label>Por emergencia</label>
                                                    <p>${currentPerfil.precio_emergencia / 100 * currentDatos_porce + currentPerfil.precio_emergencia}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    :""}

{currentTipoPerfil === "Paciente" ?
                                    <div className="card-single_per">
                                        <div className="text-right cerrar" ><Link to="/Dashboard"><img src="save_SVG.svg"></img></Link></div>
                                        <div className="updaPrin_per row ml-1">
                                            <div className="Fo form-inline">
                                                <img src={currentPerfil.foto_personal} />&nbsp;{currentPerfil.nombres}&nbsp;{currentPerfil.apellidos}
                                                <div className="Correo">{currentPerfil.correo}</div>
                                            </div>
                                            <div className="datos">
                                                <div className="">
                                                    <div className="titulodatospro"><h5>Documentos</h5></div>
                                                    <div className="iconoPerf"><img src="Folio_SVG.svg" /></div>
                                                </div>
                                                <div className="profe">
                                                    <label>Recipes</label>
                                                    {currentDatosPaDere.length === 0 ? <p>No posee recipes</p> : <p className="VerReci" onClick={()=>setTipoPerfil("")} >Ver recipes</p>}
                                                    <div>
                                                    </div>
                                                </div>
                                                <div className="profe">
                                                    <label>Historia medica</label>
                                                    {currentDatosHistoria.length === 0 ? <p>No posee historia medicas</p> : <p className="VerReci" onClick={()=>setTipoPerfil("1")}>Ver historias medicas</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row  mt-2" id="target_datos">
                                            <div className="datos_1">
                                                <div className="">
                                                    <div className="titulodatospro"><h5>Direcciones</h5></div>
                                                    <div className="iconoPerf"><img src="" /></div>
                                                </div>
                                                <div className="profe">
                                                    <label>País</label>
                                                    <p>{currentPerfil.pais}</p>
                                                </div>
                                                <div className="profe">
                                                    <label>Ciudad</label>
                                                    <p>{currentPerfil.ciudad}</p>
                                                </div>
                                                <div className="profe">
                                                    <label>Dirección</label>
                                                    <p>{currentPerfil.direccion}</p>
                                                </div>
                                            </div>
                                            <div className="datos_2">
                                                <div className="">
                                                    <div className="titulodatospro"><h5>Datos Personales</h5></div>
                                                    <div className="iconoPerf">
                                                        <img src="" />
                                                    </div>
                                                </div>
                                                <div className="profe">
                                                    <label>Cédula</label>
                                                    <p>{currentPerfil.cedula}</p>
                                                </div>
                                                <div className="profe">
                                                    <label>Edad</label>
                                                    <p>{currentPerfil.edad}</p>
                                                </div>
                                                <div className="profe">
                                                    <label>Sexo</label>
                                                    <p>{currentPerfil.sexo}</p>
                                                </div>
                                            </div>
                                            <div className="datos_3">
                                                <div className="">
                                                    <div className="titulodatospro"><h5>Contactos</h5></div>
                                                    <div className="iconoPerf"><img src="" /></div>
                                                </div>
                                                <div className="profe">
                                                    <label>Correo</label>
                                                    <p>{currentPerfil.correo}</p>
                                                </div>
                                                <div className="profe">
                                                    <label>Número principal</label>
                                                    <p>{currentPerfil.num_principal}</p>
                                                </div>
                                                <div className="profe">
                                                    <label>Número secundario</label>
                                                    <p>{currentPerfil.num_secundario}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                               :""}
    {currentTipoPerfil === "" ?
        <div>
          <h2 className="">Récipes</h2>
          <div className="cuadro_tabla_reci">
          <div className="text-right cerrar" ><img onClick={()=>Blanquear()} src="save_SVG.svg"></img></div>

            {Datosrecimos === "" ? <div>
             
              {currentDatosPaDere.length ? (
                currentDatosPaDere.map(arraya => (
                  <div>
                    <div className="Vacio">
                    </div>
                    <div className="EstiloRecipes" id="Recipes" onClick={(e) => DatosReci(arraya.id, e)}>
                      <div className="form-inline itemsReci">
                        <div className="iconoPrin col-2"><img src="Recipes_SVG.svg" /></div>
                        <div className="col-5 ico">{arraya.nombre_paciente}&nbsp;{arraya.apellido_paciente}</div>
                        <div className="col-3 ico">{arraya.fecha}</div>
                        <div className="iconoFlecha col-2 text-right"><img src="Down_SVG.svg" /></div>
                      </div>
                    </div>
                  </div>
                )
                )
              ) : (
                <p></p>
              )}
            </div> : ""}
            {Datosrecimos === "1" ?
              <div>
                <div className="Vacio">
                </div>
                <div className="EstiloRecipesDescri" onClick={() => setDatosReciMos("")} id="Recipes">
                  <div className="itemsReci_principales row">
                    <div className="col-6" id="NomEspecia">
                      <label>Especialista</label>
                      <p>{Datosreci.nombres_especialista}&nbsp;&nbsp;{Datosreci.apellidos_especialista}</p>
                    </div>
                    <div className="col-3" id="">
                      <label>Fecha</label>
                      <p>{Datosreci.fecha}</p>
                    </div>
                    <div className="col-3" id="">
                      <label>Estado</label>
                      <p>{Datosreci.estatus}</p>
                    </div>
                  </div>
                  <div className="mt-5 itemsReci_descripcion row">
                    <div className="col-6" id="NomEspecia">
                      <label>Descripcíon</label>
                      <p>{Datosreci.descripcion}</p>
                    </div>
                    <div className="botonAgendar col-2" id="">
                    </div>
                    <div className="col-2 text-right" id="Up"><img src="Up_SVG.svg" /></div>
                  </div>
                </div>
              </div>
              : ""}
          </div>
        </div>
        : ""}


{currentTipoPerfil=== "1" ?
        <div>
          <h2 className="dash-title a">Historia médica</h2>
          <div className="cuadro_tabla_reci">
          <div className="text-right cerrar" ><img onClick={()=>Blanquear()} src="save_SVG.svg"></img></div>
            {Datosrecimos === "" ? <div>
              {currentDatosPaDe.length ? (
                currentDatosPaDe.map(arraya => (
                  <div>
                    <div className="Vacio">
                    </div>
                    <div className="EstiloRecipes" id="Recipes" onClick={(e) => DatosReci_1(arraya.uid_paciente, e)} >
                      <div className="form-inline itemsReci">
                        <div className="iconoPrin col-2"><img src="Recipes_SVG.svg" /></div>
                        <div className="col-5 ico">{arraya.nombre_completo}</div>
                        <div className="col-3 ico">{arraya.fecha}</div>
                        <div className="iconoFlecha col-2 text-right"><img src="Down_SVG.svg" /></div>
                      </div>
                    </div>
                  </div>
                )
                )
              ) : (
                <p></p>
              )}
            </div> : ""}
            {Datosrecimos === "1" ?
            <div>
            {Datosreci.length?(
              Datosreci.map(array=>(
              <div>        
                <div className="EstiloRecipesDescri"  id="Recipes">
                  <div className="itemsReci_principales form-inline">
                    <div className="text-left col-4">
                    <div id="plabel"><p>Paciente</p></div>
                      <p>{array.nombre_paciente}&nbsp;&nbsp;{array.apellido_paciente}</p>
                    </div>
                    <div className="text-left col-4">
                    <div id="plabel"><p>Origen:</p></div>
                      <p>{array.tipo}</p>
                    </div>
                    <div className="Fecha text-left col-2">
                    <div id="plabel"><p>Fecha</p></div>
                      <p>{array.fecha}</p>
                    </div>
                    <div className="Estado text-left col-2">
                      <div id="plabel"><p>Estado</p></div>
                      <p>Creado el {array.fecha_creacion}</p>
                    </div>
                   
                  </div>

                  {editarHistoria===""?
                    <div id={array.id} className="ml-3">
                      <div id="Editar"><img src="edit_SVG.svg" onClickCapture={() => setId(array.id)} onClick={()=>seteditarHistoria("1")}></img></div>
                      <div  className="Descripcion">
                      <div><label>Historial médico</label></div>
                        <p>{array.descripcion}</p>
                      </div>
                      <div  id="DescripcionPatolo">
                      <div><label>Patología</label></div>
                        <p>{array.patologias.length?(
                        array.patologias.map(array=>
                          <p>{array}</p>)
                        ):(<div></div>)}</p>
                      </div>
                    </div>
                    
                    :""}
                     {editarHistoria==="1"?
                    <div className="form-inline">
                    <div className="Descripcion" id="Descripcion">
                      <textarea defaultValue={array.descripcion} onChange={(e)=>setdescripcion(e.target.value)}></textarea>
                    </div>
                    <div id="EditarActua"><img src="delete_SVG.svg" onClick={()=>ActualizarDescripcion()} ></img></div>
                    </div>
                    :""}
                    
                  <div className="itemsReci_descripcion ">
                  
                    <div id="Up_historia" onClick={() => setDatosReciMos("")} onClickCapture={()=>seteditarHistoria("")}><img src="Up_SVG.svg" /></div>
                  </div>
                </div>
              </div>
                 )
                 )
                 ):(<p></p>)}
                 </div>

                 
              : ""}
             
              
          </div>
        </div>
        : ""}
        
                     
                </main>
            </div>


        </>

    )
}
