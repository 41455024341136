import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { db } from "../firebase"
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
import Menu from "./menu";
import HeaderPrin from "./header"
init("user_Z0IcP4svfiDtq99nnXfGP");

const AppConta = () => {
	const { currentUser } = useAuth("");
    const [currentDatosPare, setcurrenDatosPare] = useState("");
    const history = useHistory("")

   const frmContact = { userEmail:'', concernCategory:'', concernCategory_2:'', emailTitle:'', emailDetails:''};
   const [contact,setContact] = useState(frmContact);
   const [showMessage, setShowMessage] = useState(false);
   const [Exito, setExito] = useState("")

   const handleChange = e => { 
		const {name,value} = e.target;
		setContact({...contact,[name]:value}); 
   };
   const handleSubmit = e =>{
	    e.preventDefault();
	   
		emailjs.send("Terappia","template_0a3e1z9", contact,'user_Z0IcP4svfiDtq99nnXfGP')
		.then((response) => {
				   console.log('SUCCESS!', response.status, response.text);
				   setExito("Enviado con éxito")
				   setTimeout(res => { setExito(true) }, 3000);
				   
		}, (err) => {
				   console.log('FAILED...', err);
		}) .finally(() => {
			setTimeout(re => {  window.location.reload(); }, 3000);
			history.push("/Contactos")
		  })
   }

   const Hamil = async () => {
    db.collection("Usuarios")
      .doc(currentUser.uid)
      .get().then((doc) => {
        let users = doc.data()
        setcurrenDatosPare(users);
      });
  }
  useEffect(() => {
    Hamil();
  }, []);

  return (

    <div>
<input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        <section className="contact_info_area sec_pad bg_color">
    <div className="container">
    	<div className="row">
        <div className="contact_form col-lg-12">
		<h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Contáctanos</h2>
		<div className="">
		<p>Hola,&nbsp;&nbsp;{currentDatosPare.nombres}&nbsp;{currentDatosPare.apellidos}&nbsp;</p><p>¿cómo podemos ayudarte?</p>
		</div>
		<form className="contact_form_box" id="contactForm" onSubmit={handleSubmit}>
			  <div className="row">
				  <div className="col-6 divNombres">
				<div className="col-lg-12 ">
					<div className="form-group text_box"> Nombres <br/>
						<input required className="" type="text" value={contact.concernCategory} onChange={handleChange} id="nombre" name="concernCategory" placeholder="Nombres"/>
					</div>
			 	 </div>
				  <div className="col-lg-12">
					<div className="form-group text_box"> Apellidos <br/>
						<input  required className="" type="text" value={contact.concernCategory_2} onChange={handleChange} name="concernCategory_2" placeholder="Apellidos"/>
					</div>
			  		</div>
					  <div className="col-lg-12">
					<div className="form-group text_box"> Correo <br/>
						<input required type="email" value={contact.userEmail} name="userEmail" onChange={handleChange} className="" placeholder="Correo" />
					</div>
				</div>
				  </div>
				  <div className="col-6">
					<div className="col-lg-12">
						<div className="form-group text_box"> Asunto <br/>
							<input value={contact.emailTitle} required type="text" name="emailTitle" onChange={handleChange}  className="" placeholder="Asunto" />
						</div>
			  		</div>
					<div className="col-lg-12">
					<div className="form-group text_box"> Descripción <br/>
						<textarea required name="emailDetails" onChange={handleChange} className="" placeholder="Describe tu comentario" value={contact.emailDetails}></textarea>
					</div>
					</div>
					</div>
			  
			  </div>
			  {Exito && <div variant="success">{Exito}&nbsp;&nbsp;</div>}
		 <div><button className="btn_three">Enviar</button></div>	
		</form>
		</div>
                    </div>
                </div>
            </section>
        </main>
        </div> 

			</div>
	
  );

}
export default AppConta;