import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { db } from "../firebase"
import { PayPalButton } from "react-paypal-button-v2";
import moment from 'moment';
import firebase from "../firebase"
import Menu from "./menu";
import HeaderPrin from "./header"



moment.locale('es');

export default function Planes() {
    const history = useHistory()
    /**nuevas variables */
 
    const [planes, setplanes] = useState([]);
    const [currentDatos_3, setcurrenDatos_3] = useState("");

    const Emerge = async () => {
        firebase.auth().onAuthStateChanged(function (user) {
            db.collection("Usuarios")
                .doc(user.uid)
                .get().then(function (doc) {
                    let users = doc.data()
                    console.log(users)
                });
        })




    }


    const ShowPlanes = async () => {
        db.collection("Planes")
            .doc("Hi86vjpXy6k9CcfscWvf")
            .get().then(async (doc) => {
                if (doc.exists) {
                    let info = []
                    let datos = doc.data()
                    info.push(datos)
                    console.log(info)

                    for (let i = 0; i < info.length; i++) {
                        // info[i].precios= []
                        // for (let j = 0; j < info[i].cantidadConsultas.length; j++) {
                        //     const porcentaje = ((precioConsulta * info[i].cantidadConsultas[j]) * info[i].descuentoConsultas[j]) / 100;
                        //     const total = precioConsulta * info[i].cantidadConsultas[j]
                        //     console.log(info[i])
                        //     info[i].precios[j] = Math.round(total - porcentaje)  
                        // }
                    }
                    console.log(info)
                    setplanes(info)
                   
                }
            }).catch(function (error) {
                console.log("Error getting User:", error);
                alert(error);
            });

    }
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            db.collection("Usuarios")
                .doc(user.uid)
                .get().then(function (doc) {
                    let users = doc.data()
                    users.$key = doc.id
                    setcurrenDatos_3(users);
                    // GET PLANES
                    Emerge();
                });
        })
       
        ShowPlanes();

    }, []);






    const [NewEstatus, setNewEstatus] = useState("Completado")

    const UpdatePlan = (data, details, Plan, limiteConsultas, precios, descuento) => {
        console.clear()
        let datos = currentDatos_3
        datos.descuentoPagado = descuento
        datos.Plan = Plan
        datos.consultasDisponibles = limiteConsultas
        datos.planActivo = true
        console.log(datos)

        db.collection("Usuarios").doc(currentDatos_3.$key).set(datos)
            .then(resp => {
                if (details.status === "COMPLETED") {
                    db.collection("HistorialPagos").doc().set({
                        tipo_pago: "Compra de plan",
                        nombres_paciente: currentDatos_3.nombres,
                        uid_paciente: currentDatos_3.$key,
                        cedula_paciente: currentDatos_3.cedula,
                        apellidos_paciente: currentDatos_3.apellidos,
                        monto_plan: precios,
                        num_principal_paciente: currentDatos_3.num_principal,
                        fecha_Pago: moment().format("DD/MM/YYYY"),
                        idPago: details.id,
                        estatus:NewEstatus,
                        fechaPagoPaypal: details.create_time,
                        direccionPagador: details.payer.address,
                        emailPagador: details.payer.name,
                        monto: details.purchase_units[0].amount,
                        facilitatorAccessToken: data.facilitatorAccessToken,
                        orderID: data.orderID,
                        payerID: data.payerID,
                        Plan: Plan
                    }).then(() => {
                        history.push("/Dashboard")
                        alert("Se realizo el cambio de plan exitosamente !!!")
                    })
                }
            })
    }

   


    return (
        <>
      <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        <div>
                {planes.length != 0 ? (
                    planes.map(array => (
                        <div>
                            <h1 className="dash-title text-center mb-2">Planes</h1>
                            <div className="dash-cards ">
                                {array.planes.map((planes, index) => (
                                    <div className={planes === "Básico" ? "card-single_planes_basi text-center" : "" || planes === "Premium" ? "card-single_planes_pre text-center" : "" || planes === "Estándar" ? "card-single_planes_esta text-center" : ""}>
                                        <div className="card-body text-center">
                                            {/* <div> */}
                                            <h3 className="mb-3" id="titleBasico">Plan {planes}</h3>
                                            <div className="form-inline">
                                                <div className="TitlePrecios">
                                                    <div className={planes === "Básico" ? "signodolarBasi" : "signodolar"}> <h5>$</h5></div>
                                                    <div className="TitleMonto"><h1>{array.precioPlan[index]}</h1></div>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                {array.descuentoConsultas[index]}%{array.description[index]}
                                            </div>
                                            <div className="mb-5 " >
                                                {/* <button onClick={() => UpdatePlan("data", "details", plan, array.tipo_plan, array.descuentoConsultas[index], array.precios[index])}>x</button> */}
                                                <PayPalButton
                                                    amount={array.precioPlan[index]}
                                                    onSuccess={(details, data) => {
                                                        fetch("/paypal-transaction-complete", {
                                                            method: "post",
                                                            body: JSON.stringify({
                                                                orderId: data.orderID
                                                            })
                                                        })
                                                            .then(resp => {
                                                                // UpdatePlan(data, details, "Basico", "Psicologo", 5, 4)

                                                                UpdatePlan(data, details, planes, array.cantidadConsultas[index], array.precioPlan[index], array.descuentoConsultas[index])
                                                            })
                                                    }}
                                                    catchError={(error) => {
                                                        console.log(error)
                                                        // errorUpdatePlan()
                                                    }}
                                                    options={{
                                                        clientId: "AV5h0ivhjvRnCQYyPCjnCzumB7lKEJP1IADFcBoqSRNVGfdTd3EkFFdDUVmFzxr2-gVQKS9evl1_E_Er",
                                                        currency: "USD"
                                                    }}
                                                />
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                ) : ''
                }
            </div>
        </main>
        </div>  
      

          
          
              
        </>

    )
}
