import React, { useState, useEffect } from "react";
import './Dashboard.css';
import { db } from "../firebase"
import { useHistory } from "react-router-dom";
import 'moment/locale/es'
import moment from 'moment';
import Menu from "./menu";
import HeaderPrin from "./header"
moment.locale('es');


export default function HistorialPacientes() {
    const [currentDatosPaDe, setLinks] = useState([]);
    const [Exito, setExito] = useState("")
    const [datoUID, setUID] = useState("")
    const [datoHistorial, setHistorial] = useState("")
    const [CantidadConsultas, setCantidadConsultas] = useState("")
    const [Error, setError] = useState("")
    const history = useHistory("")
    const [busqueda, setBusqueda]= useState("");

    useEffect(() => {
        db.collection("Usuarios").where("tipouser", "==", "Paciente").onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setLinks(docs);
        });

    }, []);


    /////////////////Buscador////////////////////////////////////////
  const handleChange=e=>{
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  }
  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=currentDatosPaDe.filter((elemento)=>{
      // let nombre=elemento.nombres + elemento.apellidos
      let busqueda=terminoBusqueda.trim()
      console.log(busqueda)
      // console.log(nombre)
      if(elemento.nombres.toString().toLowerCase().includes(busqueda.toLowerCase())
      ||elemento.apellidos.toString().toLowerCase().includes(busqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    if(terminoBusqueda!==""){
        setLinks(resultadosBusqueda);
  }else if(terminoBusqueda===""){
    db.collection("Usuarios").where("tipouser", "==", "Paciente").onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        });
        setLinks(docs);
    });
  }
  }

/////////////////Buscador////////////////////////////////////////

    function datosPagos(e) {
        setUID("1")
        db.collection("HistorialPagos").where("uid_paciente", "==", e).onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setHistorial(docs);
        });
    }

    const [CantidadConsul, setCantidadConsul] = useState("")

    function datosEditarConsultas(e) {
        setUID("2")
        db.collection("Usuarios")
            .doc(e)
            .get().then(function (doc) {
                const docs = [];
                let users = doc.data()
                docs.push(users)
                setCantidadConsultas(docs)
                setCantidadConsul(users.consultasDisponibles)
            });
    }

    function ActualizarConsultas(e) {
        db.collection("Usuarios").doc(e).update(
            {
                "consultasDisponibles": CantidadConsul,
            }).then(() => {
                history.push("/Admin")
                setExito("Cantidad Consulta actualizado con éxito")
                setTimeout(res => { setExito(true) }, 3000);
                setUID("")
            })
            .catch(() => {
                setError("No se pudo actualizar la cuenta")
            })
    }


    return (
        <>
        
      <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {datoUID === "" ?
                <div>
                    <div className="header_tabla form-inline">
                        <div className="col-6">
                            <img src="Buscar_SVG.svg" className="mr-2" /><input className="form-control" onChange={handleChange} placeholder="Buscar" type="seach" />
                        </div>
                    </div>
                    <h2>Historial de Pacientes</h2>
                    <div className="cuadro_tabla">
                        <div className="table-responsive mt-2">
                            <div className="table_1">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Tipo de plan</th>
                                            <th>Citas disponible</th>
                                            <th>Historial de pagos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentDatosPaDe.length ? (
                                            currentDatosPaDe.map(array => (
                                                <tr>
                                                    <td>{array.nombres}&nbsp;{array.apellidos}</td>

                                                    <td>{array.Plan === "" ? <div>No posee</div> : <div>{array.Plan}</div>} </td>
                                                    <td>{array.consultasDisponibles}</td>
                                                    <td className="">
                                                        <div className="agendar">
                                                            <div className="form-inline">
                                                                <div><button className="mr-5" onClick={(e) => datosPagos(array.uid, e)}>Ver</button></div>
                                                                {array.planActivo === true ? <div className="EditarCantidaConsul" onClick={() => datosEditarConsultas(array.id)}><img title="Editar cantidad de consultas" src="edit_SVG.svg" /></div> : ""}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            )
                                        ) : (
                                            <p></p>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                    {Exito && <div variant="success">{Exito}</div>}
                </div>
                : ""}

            {datoUID === "1" ?
                <div>
                    <h3 className=""><h2>Historial de Pago</h2></h3>
                    <div className="cuadro_tabla">
                        <div className="table-responsive mt-2">
                            <div className="table_1">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Tipo de plan</th>
                                            <th>Tipo</th>
                                            <th>Id de pago</th>
                                            <th>Orden Id</th>
                                            <th>Estatus</th>
                                            <th>Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datoHistorial.length ? (
                                            datoHistorial.map(array => (
                                                <tr>
                                                    <td>{array.fecha_Pago} </td>
                                                    {array.Plan === "" ?
                                                        <div>Individual</div> :
                                                        <td>{array.Plan}</td>
                                                    }
                                                    <td>{array.tipo_pago}</td>
                                                    <td>{array.idPago}</td>
                                                    <td>{array.orderID}</td>
                                                    <td>{array.estatusPago}</td>
                                                    <td>{array.monto.value}</td>
                                                </tr>
                                            )
                                            )
                                        ) : (
                                            <p></p>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                : ""}

            {datoUID === "2" ?
                <div className="table_1">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Cantidad de consultas</th>
                                <th>Accion</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CantidadConsultas.length ? (
                                CantidadConsultas.map(array => (
                                    <>
                                        <tr>
                                            <td><input type="text" onChange={(e) => setCantidadConsul(e.target.value)} className="form-control text-center" defaultValue={array.consultasDisponibles}></input></td>
                                            <td className="agendar"><button onClick={() => ActualizarConsultas(array.uid)} className="btn">Actualizar</button></td>
                                        </tr>
                                    </>
                                )
                                )
                            ) : (
                                <p></p>
                            )}
                        </tbody>
                    </table>
                </div>
                : ""}
            
        </main>
        </div>  
           

        </>


    )
}