import React, { useState, useEffect, useContext } from "react";
import '../Dashboard.css';
import { db } from ".././../firebase";
import { useHistory } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from ".././../contexts/AuthContext";
import 'moment/locale/es'
import { PayPalButton } from "react-paypal-button-v2";
import firebase from ".././../firebase"
import moment from 'moment';
import styled from 'styled-components';
import { Info } from "react-bootstrap-icons";

moment.locale('es');

const GridWrapper = styled.div`
margin:auto;
display: grid;
grid-template-columns: repeat(7, 0fr);
grid-template-rows: repeat(6, 1fr);
grid-gap: 1px;
background-color: rgb(233, 233, 233);

`;

const CellWrapper = styled.div`
	height: 50px;
	width: 50px;
	background-color:${props => props.isWeekday ? '#FFF' : '#FFF'};
	color: ${props => props.isWeekday ? '#837BEC' : 'rgb(117, 117, 117)'};
	cursor:${props => props.isWeekday ? 'pointer' : 'normal'};
`;

const RowInCell = styled.div`
	display: flex;
	justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
`;

const DayWrapper = styled.div`
	height: 31px;
	width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
;`

const CurrentDay = styled('div')`
  height: 100%;
  width: 100%;
  background:#837BEC;
  color: #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CalendarGrid = ({ startDay }) => {


	const totalDays = 42;
	const day = startDay.clone().subtract(1, 'day');
	const daysMap = [...Array(totalDays)].map(() => day.add(1, 'day').clone())
	// console.log(daysMap)
	const isCurrentDay = (day) => moment().isSame(day, 'day');

	// console.log(day)

	const history = useHistory("");
	const [error, setError] = useState("");
	const [DatosNombre, setDatos] = useState("");
	const [DatosDoc, setDatosDoc] = useState("");
	const { currentUser } = useAuth("");
	const [Diass, setDias] = useState([]);
	const [Diasss, setDiasss] = useState([]);
	const [HorasDis, setHorasDis] = useState([]);
	const [Exito, setExito] = useState("")
	const [fecha, setFecha] = useState("");
	const [Estatus, setEstatus] = useState("Agendada");
	const [TomarHora, setTomarHoras] = useState("");
	const [mostrarhoras, setmostrarhoras] = useState(false);
	const [ocultarhoras, setocultarhoras] = useState(true);

	const [currentDatos_Confi, setcurrenDatos_Confi] = useState(0);
	const [hasPsicologoPlan, sethasPsicologoPlan] = useState(false);

	const id = JSON.parse(localStorage.getItem('id'))
	const IdCita = JSON.parse(localStorage.getItem('IdCita'))
	const [PanelEdad, setPanelEdad] = useState(false);
	const [SeleccionaNo, setEdadSeleccionaNo] = useState("");


	// variables Evander
	const [HoraDisponible, setHoraDisponible] = useState([])
	const [diaSemanaDisponible, setdiaSemanaDisponible] = useState([])
	const [uidPlan, setuidPlan] = useState("")
	const [nuevoPrecio, setnuevoPrecio] = useState(0)
	const [planes, setplanes] = useState([]);
	const [currentDatos_3, setcurrenDatos_3] = useState("");

	const TraerDatos = () => {
		db.collection("Usuarios")
			.doc(currentUser.uid)
			.get().then((doc) => {
				if (doc.exists) {
					console.log(doc)
					let users = doc.data()
					users.$key = doc.id
					console.log(users)

					if (users.planActivo) {
						sethasPsicologoPlan(true)
					} else {
						sethasPsicologoPlan(false)
					}
					// comentar ////////////////////
					if (users.planActivo != false && users.consultasDisponibles > 0) {

						setuidPlan("1")
					} else {
						setuidPlan("")
					}
					///////////////////////////////////
					setDatos(users);
				}
			}).catch(function (error) {
				console.log("Error getting User:", error);
				alert(error);
			});

		db.collection("Usuarios")
			.doc(id)
			.get().then(async (doc) => {
				if (doc.exists) {
					let users = doc.data()
					let precioConsulta = parseInt(users.precio_consulta)
					console.log(users)
					setDatosDoc(users);

					console.log(precioConsulta)
					db.collection("Planes")
						.doc("Hi86vjpXy6k9CcfscWvf")
						.get().then(async (doc) => {
							if (doc.exists) {
								let info = []
								let datos = doc.data()
								info.push(datos)
								console.log(info)
								for (let i = 0; i < info.length; i++) {
									info[i].precios = []
									for (let j = 0; j < info[i].cantidadConsultas.length; j++) {
										const porcentaje = ((precioConsulta * info[i].cantidadConsultas[j]) * info[i].descuentoConsultas[j]) / 100;
										const total = precioConsulta * info[i].cantidadConsultas[j]
										console.log(info[i])
										info[i].precios[j] = Math.round(total - porcentaje)

									}
								}
								console.log(info)
								setplanes(info)
							}
						})
				}
			}).catch(function (error) {
				console.log("Error getting User:", error);
				alert(error);
			});

	};


	const [Verdad, setVerdad] = useState(false)
	useEffect(() => {
		firebase.auth().onAuthStateChanged(function (user) {
			db.collection("Usuarios")
				.doc(user.uid)
				.get().then(function (doc) {
					let users = doc.data()
					users.$key = doc.id
					let edad = doc.data().edad
					users.$key = doc.id
					console.log(edad)
					if (edad < 18) {
						setPanelEdad(true)
					}
					else if (edad > 17) {
						setPanelEdad(false)
					}
					setcurrenDatos_3(users);
					// GET PLANES
					TraerDatos();
				});
		})
		db.collection("configuracion")
			.doc("am8lciDPAWtxD1tzDyik")
			.get().then(function (doc) {
				let users = doc.data().PorcentajesGanancia

				setcurrenDatos_Confi(users);
				// GET PLANES
			});
		db.collection("Usuarios").doc(id).collection("Horario")
			.onSnapshot(function (querySnapshot) {
				var dias = [];
				querySnapshot.forEach(function (doc) {
					let NewDias = doc.data()
					NewDias.$key = doc.id
					dias.push(NewDias);
				});
				console.clear()
				const info = dias.map(element => {
					return element.dia

				})
				const comidas = info;
				comidas.forEach(function (comida, index) {
					if ((moment(new Date()).format("dddd")) === comida) {
						setVerdad(comida)
					}
				});


				console.log(info)
				setdiaSemanaDisponible(info)
				console.log(diaSemanaDisponible)
				console.log(daysMap)

				console.log((moment(new Date()).format("dddd")))
				console.log(new Date(daysMap[28]))

				console.log(new Date() > new Date(daysMap[5]))

			});

	}, []);

	const fetchPlanes = async () => {
		// console.clear()
		console.log(DatosDoc)
		const response = db.collection('Planes');
		const data = await response.get();
		let info = []
		data.docs.forEach(item => {
			let datos = item.data()
			datos.$key = item.id
			info.push(datos)
		})
		console.log(info)
		setplanes(info)
	}

	function AgregarConsultaRetomada(details, data) {
		db.collection("Citas").doc(IdCita).update({
			"estatus": "Agendada",
			"hora": TomarHora,
			"fecha": fecha,
			fecha_modificacion: moment().format("DD/MM/YYYY")
		}).then(resp => {
			localStorage.setItem('VideoC', JSON.stringify(""))
			setocultarhoras(false)
			setmostrarhoras(false)
			history.push("/MisCitas")
			setExito("Consulta agregada con Éxito")

		})
	}

	const Fecha = (e) => {
		setocultarhoras(false)
		setFecha(moment(e).format("DD/MM/YYYY"))
		db.collection("Usuarios").doc(id).collection("Horario")
			.onSnapshot(function (querySnapshot) {
				var NewDiass = [];
				querySnapshot.forEach(function (doc) {
					let NewDias = doc.data()
					NewDias.$key = doc.id
					NewDiass.push(NewDias);
				});
				setDiasss(NewDiass);
			});
		db.collection("Usuarios").doc(id).collection("Horario").where("dia", "==", moment(e).format("dddd"))
			.onSnapshot(function (querySnapshot) {
				var Horarios = [];
				var Dias = [];
				querySnapshot.forEach(function (doc) {
					let datos = doc.data()
					let datosDias = doc.data()
					datos.$key = doc.id
					Horarios.push(datos);
					Dias.push(datosDias);


					setDias(Dias);

					console.log(datos)
				});
				if (Horarios.length > 0) {
					db.collection("Citas").where("estatus", "==", "Agendada").where("uid_especialista", "==", id).where("fecha", "==", moment(e).format("DD/MM/YYYY"))
						.onSnapshot(function (querySnapshot) {
							var citas = [];
							querySnapshot.forEach(function (doc) {
								let datos = doc.data()
								datos.$key = doc.id
								citas.push(datos);
							});
							console.log(Horarios)
							console.log(citas)
							if (citas.length > 0) {
								setHoraDisponible([])
								let info = []
								Horarios[0].horas.forEach(element => {
									let contador = 0
									citas.forEach(data => {
										if (element == data.hora) {
											contador++
										}
									})
									if (contador == 0) {
										info.push(element)
										
									}
								})
								let horas_total_1 = []
										let horas_total_2 = []
										let horas_total_recibido = info
										Array.prototype.forEach.call(horas_total_recibido, htrecibido => {
											let hora_format_mili = moment(htrecibido, 'hh:mm A').format('HH:mm')
											horas_total_1.push(hora_format_mili)
										})

										horas_total_1.sort()

										Array.prototype.forEach.call(horas_total_1, htotal_1 => {
											let hora_format_meri = moment(htotal_1, 'HH:mm').format('hh:mm A')
											horas_total_2.push(hora_format_meri)
										})

								
								if (citas.length > 0) {
									setHoraDisponible([])
									let info = []
									horas_total_2.forEach(element => {
										let contador = 0
										citas.forEach(data => {
											if (element == data.hora) {
												contador++
											}
										})
										if (contador == 0) {
											info.push(element)
										}
									})
									setHoraDisponible(info)
								setHorasDis(info)
								}
								console.log(HoraDisponible)
								console.log(Dias)
								console.log(HorasDis)
								// setArrayCitas(citas)
							} else {
								setHoraDisponible(Horarios[0].horas)
								setHorasDis(Horarios[0].horas)
							}
						});
				} else {
					setError('Disculpe los dias disponibles son:')
					setHoraDisponible([])
					setHorasDis([])
				}
			});

	}



	const TomarHoraCita = (e) => {
		setTomarHoras(e)
	}

	const porcentajeTera = DatosDoc.precio_consulta / 100 * currentDatos_Confi
	const precioConsulta = porcentajeTera + DatosDoc.precio_consulta
	const descuntoPAciente = parseInt(precioConsulta) * DatosNombre.descuentoPagado / 100
	const total = parseInt(precioConsulta) - descuntoPAciente


	console.log(total)

	const [NewEstatus, setNewEstatus] = useState("Completado")

	const uidNew = Math.random().toString(30).substring(7);

	
	const AgregarConsulta = (details, data) => {
		console.log(details)
		console.log(data)
		if (details.status === "COMPLETED") {
			db.collection("Citas").doc(uidNew).set({
				tipo_cita: "Consulta",
				hora: TomarHora,
				notificacion: true,
				notificacion_especialista: true,
				notificacion_paciente: true,
				foto_paciente: DatosNombre.foto_personal,
				nombres_paciente: DatosNombre.nombres,
				foto_especialista: DatosDoc.foto_personal,
				uid_paciente: DatosNombre.uid,
				nombres_especialista: DatosDoc.nombres,
				fecha: fecha,
				uid: uidNew,
				fecha_noti: new Date(),
				cedula_paciente: DatosNombre.cedula,
				uid_especialista: DatosDoc.uid,
				apellidos_paciente: DatosNombre.apellidos,
				estatus: Estatus,
				apellidos_especialista: DatosDoc.apellidos,
				num_principal_paciente: DatosNombre.num_principal,
				fecha_creacion: moment().format("DD/MM/YYYY"),
				hora_notificacion: moment().format("hh:mm a"),
				fecha_notificacion: moment().format("DD/MM/YYYY")
			})
				.then(() => {
					db.collection("HistorialPagos").doc().set({
						tipo_pago: "Cita",
						estatusAdmin: false,
						nombres_paciente: DatosNombre.nombres,
						uid_paciente: DatosNombre.uid,
						nombre_especialista: DatosDoc.nombres,
						cedula_paciente: DatosNombre.cedula,
						uid_especialista: DatosDoc.uid,
						apellidos_paciente: DatosNombre.apellidos,
						estatusPago: NewEstatus,
						monto_Consulta: DatosDoc.precio_consulta,
						monto_porcentaje_terapia: DatosDoc.precio_consulta / 100 * currentDatos_Confi - descuntoPAciente,
						apellido_especialista: DatosDoc.apellidos,
						num_principal_paciente: DatosNombre.num_principal,
						fecha_Pago: moment().format("DD/MM/YYYY"),
						idPago: details.id,
						fechaPagoPaypal: details.create_time,
						direccionPagador: details.payer.address,
						emailPagador: details.payer.name,
						monto: details.purchase_units[0].amount,
						facilitatorAccessToken: data.facilitatorAccessToken,
						orderID: data.orderID,
						payerID: data.payerID,
						tipo_plan: "Individual",
						Plan: "Individual"
					})
				}).then(resp => {
					let infoFinal = DatosNombre
					console.clear()

					if (DatosNombre.consultasDisponibles > 0) {
						infoFinal.consultasDisponibles = infoFinal.consultasDisponibles - 1
						if (infoFinal.consultasDisponibles == 0) {
							infoFinal.Plan = ""
							infoFinal.consultasDisponibles = 0
							infoFinal.descuentoPagado = 0
							if (infoFinal.consultasDisponibles == 0) {
								infoFinal.planActivo = false
							}
							// infoFinal.emergenciasDisponiblesPsicologo = 0
						}
						db.collection("Usuarios").doc(DatosNombre.$key).set(infoFinal)
							.then(resp => {
								history.push("/Dashboard")
								setExito("Consulta agregada con Éxito")
								setTimeout(res => { setExito(true) }, 3000);
								window.location.reload();

							})
					}
					history.push("/Dashboard")
					setExito("Consulta agregada con Éxito")
					setTimeout(res => { setExito(true) }, 3000);
					window.location.reload();
				})
		}
	}


	function most(e) {
		if (e = "mostrar") {
			setmostrarhoras(true)
			setocultarhoras("")
		}
	}
	function Reiniciar() {
		history.push("/Dashboard");
	}
	function cerrarRe() {
		setmostrarhoras(false)
		setocultarhoras(true)
	}




	return (

		<>
			{PanelEdad === true ?
				<div className="dash-cards_calen">
					<div className="card-single_calen">

						<div className="card-body">
							{SeleccionaNo === "" ?
								<div>
									<div id="title">¿Tu padre, madre o representante está de acuerdo con que pidas la consulta que estás a punto de pagar?</div>
									
									<div id="boton" className="botonAgendar_si mt-2" Style="width: 100%;justify-content: center;display: flex;">
										<button onClick={() => setPanelEdad("1")}>Si</button>
									</div>
									<div id="boton" className="botonAgendar_si" Style="width: 100%;justify-content: center;display: flex;">
										<button onClick={() => setEdadSeleccionaNo(true)}>No</button>
									</div>
								</div>
								:
								<div>
									<div id="title">Debes tener el consentimiento de tu padre, madre o representante para pedir una consulta.
									</div>
									
									<div id="boton" className="botonAgendar_si" Style="width: 100%;justify-content: center;display: flex;">
										<button onClick={() => Reiniciar()}>Ok</button>
									</div>
								</div>
							}
						</div>

					</div>
				</div>

				:
				""
			}
			{PanelEdad === "1" ?
				<div className="dash-cards_calen">
					<div className="card-single_calen">

						<div className="card-body">
							{SeleccionaNo === "" ?
								<div>
									<div id="title">"Recuerda que tu representante debe estar presente en la primera consulta y presentar un documento de identidad
									</div>
									<div id="boton" className="botonAgendar_si mt-2" Style="width: 100%;justify-content: center;display: flex;">
										<button onClick={() => setPanelEdad(false)}>Ok</button>
									</div>
								</div>
								:
								<div>
									<div id="title">Debes tener el consentimiento de tu padre, madre o representante para pedir una consulta.
									</div>
									
									<div id="boton" className="botonAgendar_si" Style="width: 100%;justify-content: center;display: flex;">
										<button onClick={() => Reiniciar()}>Ok</button>
									</div>
								</div>
							}
						</div>

					</div>
				</div>

				:
				""
			}

			{PanelEdad === false ?
				<div>
					<div>{Exito &&
						<div>
							{Exito}
						</div>
					}

					</div>
					{ocultarhoras === true ?
						<div>
							<div className="diasCalendario">
								<div className="diasCeldas">
									<div className="diasRow">
										<div className="diaWape">
											<div className="Lunes">Lun</div>
										</div>
									</div>
								</div>
								<div className="diasCeldas">
									<div className="diasRow">
										<div className="diaWape">
											<div className="martes">Mar</div>
										</div>
									</div>
								</div>
								<div className="diasCeldas">
									<div className="diasRow">
										<div className="diaWape">
											<div className="miercoles">Miér</div>
										</div>
									</div>
								</div>
								<div className="diasCeldas">
									<div className="diasRow">
										<div className="diaWape">
											<div className="jueves">Jue</div>
										</div>
									</div>
								</div>
								<div className="diasCeldas">
									<div className="diasRow">
										<div className="diaWape">
											<div className="viernes">Vie</div>
										</div>
									</div>
								</div>
								<div className="diasCeldas">
									<div className="diasRow">
										<div className="diaWape">
											<div className="sabado">Sáb</div>
										</div>
									</div>
								</div>
								<div className="diasCeldas">
									<div className="diasRow">
										<div className="diaWape">
											<div className="domingo">Dom</div>
										</div>
									</div>
								</div>


							</div>


							<div>
								<GridWrapper>
									{
										daysMap.map((dayItem, index) => (
											<div>

												{(diaSemanaDisponible.includes(dayItem.format('dddd')) && new Date(dayItem) > new Date()) || moment(new Date(dayItem)).format("dddd") == Verdad ?
													<CellWrapper
														// isWeekday={dayItem.day() === 6 || dayItem.day() === 0}
														isWeekday={true}
														key={dayItem.unix()}
														onClick={() => Fecha(dayItem.format('Y/M/D'))}

													>
														<RowInCell justifyContent={'flex-end'}>
															<DayWrapper>
																{!isCurrentDay(dayItem) && dayItem.format('D')}
																{isCurrentDay(dayItem) && <CurrentDay>{dayItem.format('D')}</CurrentDay>}
															</DayWrapper>
														</RowInCell>
													</CellWrapper>
													:
													<CellWrapper
														// isWeekday={dayItem.day() === 6 || dayItem.day() === 0}
														isWeekday={false}
														key={dayItem.unix()}

													>
														<RowInCell justifyContent={'flex-end'}>
															<DayWrapper>
																{/* <p>{"hola"}</p> */}
																{!isCurrentDay(dayItem) && dayItem.format('D')}
																{isCurrentDay(dayItem) && <CurrentDay>{dayItem.format('D')}</CurrentDay>}
															</DayWrapper>
														</RowInCell>
													</CellWrapper>
												}


											</div>
										))
									}
								</GridWrapper>

								{/* <p>{dayItem.format('dddd')}</p> aqui tengo el nombre del dia*/}
								{/* diaSemanaDisponible */}

							</div>

						</div >
						: <div></div>}
					{ocultarhoras === false ?
						<div>
							{Diass.length != undefined ? (
								Diass.map(array => (
									<div className="">
										<div className="dash-cards_calen">
											<div className="card-single_calen">
												<div className="cerrarCaleRe">
													<img src="save_SVG.svg" title="Cerrar" onClickCapture={() => cerrarRe()} onClick={() => setDias([])} />
												</div>
												<div className="card-body">
													<div>
														<div className="form-inline">
															<div id="title"><h3>{array.dia} </h3></div>
															<div id="titleFe">{fecha} </div>
														</div>
													</div>
													<div>
														<div className="form-inline">
															{HoraDisponible != "" ? (
																HoraDisponible.map(hora => (
																	<div className="form-inline">
																		<div className="Forma_hora_dia_cale form-inline">
																			<div className=""> <input type="checkbox" value={hora} onClick={(e) => most("mostrar", e)} onChange={(e) => TomarHoraCita(e.target.value, e)} /></div>
																			<div className="nombreDia">
																				{hora}</div>
																		</div>
																	</div>


																))
															)
																:
																(
																	<div className="titulo">Horas agotadas</div>
																)}
														</div>
													</div>

												</div>

											</div>
										</div>
									</div >
								))



							) : (
								<p></p>
							)}
						</div>
						: <div></div>}
					{
						mostrarhoras === true ?
							<div className="text-center Formato_calen">
								<div className="dash-cards_cale">
									<div className="card-single_cale">
										<div className="cerrarCaleRe">
											<img src="save_SVG.svg" title="Cerrar" onClick={() => cerrarRe()} />
										</div>
										{IdCita === "" ?
											<div className="card-body">
												<div id="title"><label>Especialista:</label></div>
												<div className="mb-3 title">
													{DatosDoc.nombres}&nbsp;&nbsp;{DatosDoc.apellidos}
												</div>
												{/* <div><label>Costo:</label></div> */}



												<div className=" title">
													<div className="title"> Precio: {DatosDoc.precio_consulta + porcentajeTera}$</div>
												</div>
												{hasPsicologoPlan === true ?
													<div className="title">
														<div className="title"> Descuento: {descuntoPAciente}$</div>
													</div>
													: ""}
												{hasPsicologoPlan === true ?
													<div className=" title">
														<div className="title">Total a pagar: {DatosDoc.precio_consulta + porcentajeTera - descuntoPAciente}$</div>
													</div>
													: ""}

												<div className="title">
													{Diass.length ? (
														Diass.map(array => (
															<div id="title">
																Dia: {array.dia}
															</div>
														))) : <p></p>}
													Hora: {TomarHora}
												</div>
											</div>
											:
											<div className="card-body">
												<div id="title"><label>Especialista:</label></div>
												<div className="mb-3 title">
													{DatosDoc.nombres}&nbsp;&nbsp;{DatosDoc.apellidos}
												</div>
												{/* <div><label>Costo:</label></div> */}

												<div className="title">
													{Diass.length ? (
														Diass.map(array => (
															<div id="title">
																Dia: {array.dia}
															</div>
														))) : <p></p>}
													Hora: {TomarHora}
												</div>
											</div>
										}

										<div id="boton" className="botonAgendar_verReg" Style="width: 100%;justify-content: center;display: flex;">
											{IdCita === "" ?
												<PayPalButton
													amount={total}
													// shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
													onSuccess={(details, data) => {
														// OPTIONAL: Call your server to save the transaction
														fetch("/paypal-transaction-complete", {
															method: "post",
															body: JSON.stringify({
																orderId: data.orderID
															})
														})
															.then(resp => {
																AgregarConsulta(details, data)
															})
													}}
													catchError={(error) => {
														console.log(error)

													}}
													options={{
														clientId: "AV5h0ivhjvRnCQYyPCjnCzumB7lKEJP1IADFcBoqSRNVGfdTd3EkFFdDUVmFzxr2-gVQKS9evl1_E_Er",
														currency: "USD"
													}}
												/>

												:

												<button className="" onClick={AgregarConsultaRetomada}>Registrar consulta</button>
											}
										</div>
									</div>
								</div>

							</div>
							: <div></div>
					}
				</div>

				: ""}
		</>
	);
};

export { CalendarGrid };