import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase"
import firebase from "../firebase"

import Menu from "./menu";
import HeaderPrin from "./header"

export default function UpdateProfile() {

  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [input, setErrorInput] = useState("")
  const history = useHistory("")
  const [DatosNombre, setDatos] = useState("");
  const [DatosTera, setDatosTera] = useState("");
  const [Exito, setExito] = useState("")
  const [Email, setEmail] = useState("")
  const [Experiencia, setExperiencia] = useState("")
  const [Profesion, setProfesion] = useState("")
  const [Nombres, setNombres] = useState("")
  const [Cedula, setCedula] = useState("")
  const [Edad, setEdad] = useState("")
  const [Religion, setReligion] = useState("")
  const [Sexo, setSexo] = useState("")
  const [Otro, setOtro] = useState("")
  const [OtroMostrar, setOtroMostrar] = useState(false)
  const [Pais, setPais] = useState("")
  const [Ciudad, setCiudad] = useState("")
  const [Direccion, setDireccion] = useState("")
  const [numeroPri, setnumeroPri] = useState("")
  const [HonorarioCita, setHonorarioCita] = useState(0)
  const [HonorarioEmergencia, setHonorarioEmergencia] = useState(0)
  const [Apellidos, setApellidos] = useState("")

  const [infanto, setinfanto] = useState(false)
  const [Geriatrico, setGeriatrico] = useState(false)
  const [Adulto, setAdulto] = useState(false)
  const [Parejas, setParejas] = useState(false)
  const [Adicciones, setAdicciones] = useState(false)
  const [ActiveBoton, setActiveBoton] = useState("Zelle")

  const [EmailZelle, setEmailZelle] = useState("")
  const [NameZelle, setNameZelle] = useState("")

  const [EmailPaypal, setEmailPaypal] = useState("")

  const [BancoPag, setBancoPag] = useState("")
  const [TelPag, setTelPag] = useState(0)
  const [Doc_iden, setDoc_iden] = useState(0)

  const [Banco, setBanco] = useState("")
  const [CedulaBanco, setCedulaBanco] = useState(0)
  const [Cuenta, setCuenta] = useState("")

  const [state, setState] = useState({
    uploadValue: 0,
    picture: null
  })
  const [stateDocTitulo, setStateDocTitulo] = useState({
    DocTitulo: null
  })
  const [stateDocCarnet, setStateDocCarnet] = useState({
    DocCarnet: null
  })
  const [stateDocCedula, setStateDocCedula] = useState({
    DocCedula: null
  })
  const [stateDocFirma, setStateDocFirma] = useState({
    DocFirma: null
  })
  const [stateDocsello, setStateDocsello] = useState({
    Docsello: null
  })
  useEffect(() => {
    const TraerDatos = () => {
      db.collection("Usuarios")
        .doc(currentUser.uid)
        .get().then((doc) => {
          if (doc.exists) {
            let users = doc.data()
            let terapias = doc.data().tipos_terapia
            let banco = doc.data().datos_bancarios.transferencia
            let Zelle = doc.data().datos_bancarios.zelle
            let Pagomovil = doc.data().datos_bancarios.pagomovil
            let Paypal = doc.data().datos_bancarios.paypal
            console.log(banco)
            console.log(Zelle)
            console.log(Pagomovil)
            console.log()
            setinfanto(terapias[0].estado)
            setGeriatrico(terapias[1].estado)
            setAdulto(terapias[2].estado)
            setParejas(terapias[3].estado)
            setAdicciones(terapias[4].estado)
            console.log(users)
            setDatos(users);

            setBanco(banco.banco)
            setCedulaBanco(banco.doc_identidad)
            setCuenta(banco.cuenta)

            setEmailPaypal(Paypal.email)

            setEmailZelle(Zelle.email)
            setNameZelle(Zelle.nombre_completo)

            setBancoPag(Pagomovil.banco)
            setTelPag(Pagomovil.num_tel)
            setDoc_iden(Pagomovil.doc_identidad)

            setEmail(users.correo)
            setExperiencia(users.experiencia)
            setProfesion(users.especialidad)
            setNombres(users.nombres)
            setCedula(users.cedula)
            setReligion(users.religion)
            setEdad(users.edad)
            setSexo(users.sexo)
            setPais(users.pais)
            setCiudad(users.ciudad)
            setDireccion(users.direccion)
            setnumeroPri(users.num_principal)
            setHonorarioCita(users.precio_consulta)
            setHonorarioEmergencia(users.precio_emergencia)
            setApellidos(users.apellidos)
            setState({
              picture: users.foto_personal
            })
            setStateDocTitulo({
              DocTitulo: users.titulo_universitario
            })
            setStateDocCarnet({
              DocCarnet: users.carnet_medico
            })
            setStateDocCedula({
              DocCedula: users.documento_identidad
            })
            setStateDocFirma({
              DocFirma: users.firma
            })
            setStateDocsello({
              Docsello: users.sello
            })
          }
          console.log(HonorarioCita)
          console.log(HonorarioEmergencia)
        }).catch(function (error) {
          console.log("Error getting User:", error);
          alert(error);
        });
    };
    TraerDatos();
  }, []);


  function tipoEspecialista(e) {
    setActiveBoton(e)
  }


  function handleSubmit() {
    console.log(HonorarioCita)
    console.log(HonorarioEmergencia)
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Las contraseña no son iguales")
    }
    const promises = []
    setLoading(true)
    setError("")
    if (HonorarioCita < 20) {
      setError("El monto debe ser mayor o igual a 20$")
    }
    if (HonorarioEmergencia < 20) {
      setError("El monto debe ser mayor o igual a 20$")
    } else {
      db.collection("Usuarios").doc(currentUser.uid).update({
        "nombres": Nombres,
        "apellidos": Apellidos,
        "cedula": Cedula,
        "edad": Edad,
        "correo": Email,
        "religion": Religion,
        "sexo": Sexo,
        "personalizado": Otro,
        "pais": Pais,
        "ciudad": Ciudad,
        "direccion": Direccion,
        tipos_terapia: [{ terapia: 'Infanto-Juvenil', estado: infanto },
        { terapia: 'Geriatrico', estado: Geriatrico },
        { terapia: 'Adulto', estado: Adulto },
        { terapia: 'Parejas', estado: Parejas },
        { terapia: 'Adicciones', estado: Adicciones }],
        datos_bancarios: {
          paypal: { email: EmailPaypal },
          zelle: { email: EmailZelle, nombre_completo: NameZelle },
          transferencia: { banco: Banco, cuenta: Cuenta, doc_identidad: CedulaBanco },
          pagomovil: { banco: BancoPag, doc_identidad: Doc_iden, num_tel: TelPag }
        },
        "num_principal": numeroPri,
        "experiencia": Experiencia,
        "especialidad": Profesion,
        "precio_consulta": parseInt(HonorarioCita),
        "precio_emergencia": parseInt(HonorarioEmergencia),
        "foto_personal": state.picture,
        "titulo_universitario": stateDocTitulo.DocTitulo,
        "carnet_medico": stateDocCarnet.DocCarnet,
        "documento_identidad": stateDocCedula.DocCedula,
        "firma": stateDocFirma.DocFirma,
        "sello": stateDocsello.Docsello,
      })
    }

    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }
    Promise.all(promises)
      .then(() => {
        setExito("Actualizado con éxito")
        setTimeout(res => { setExito(true) }, 3000);
      })
      .catch(() => {
        setError("No se pudo actualizar la cuenta")
      })
      .finally(() => {
        setTimeout(res => { window.location.reload() }, 1000);
        history.push("Dashboard")
        setLoading(false)
      })
  }

  function handlkeyPress() {
    var ele = document.querySelectorAll('#validanumericos')[0];
    var ele_2 = document.querySelectorAll('#validanumericos_2')[0];
    ele.onkeypress = function (e) {
      if (isNaN(this.value + String.fromCharCode(e.charCode))) {
        setErrorInput("En este campo solo es permitido numeros")
        return false;
      }
    }
    ele.onpaste = function (e) {
      e.preventDefault();
    }
  }
  const [Mostrarba, setMostrarba] = useState("")
  const [statebarra, setStatebarra] = useState(
    { barra: 0 })

  function handleChange(e) {
    setMostrarba(e)
    const file = e.target.files[0]
    console.log(file)
    const storageRef = firebase.storage().ref(`Usuarios/${currentUser.uid}/foto_personal/${file.name}`)
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      const porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setStatebarra({
        barra: porcentage
      })
    }, (error) => {
      setState({
        message: `error:${error.message}`
      })
    }, () => {
      task.snapshot.ref.getDownloadURL().then((url) => {
        const dato = url
        setState({
          message: "Foto cargada con éxito",
          picture: dato
        })
      })
    })
  }


  const [stateTitulo, setStateTitulo] = useState(
    { barra: 0 }
  )
  function onChangees() {


  }

  function handleChangeDocTiulo(e) {
    const file = e.target.files[0]
    console.log(file)
    const storageRef = firebase.storage().ref(`Usuarios/${currentUser.uid}/titulo_universitario/${file.name}`)
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      const porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setStateTitulo({
        barra: porcentage
      })
    }, (error) => {
      setStateDocTitulo({
        message: `error:${error.message}`
      })
    }, () => {
      task.snapshot.ref.getDownloadURL().then((url) => {
        const dato = url
        setStateDocTitulo({
          message: "Titulo cargado con éxito",
          DocTitulo: dato
        })
      })
    })
  }


  const [stateCarnet, setStateCarnet] = useState(
    { barra: 0 })
  function handleChangeDocCarnet(e) {
    const file = e.target.files[0]
    console.log(file)
    const storageRef = firebase.storage().ref(`Usuarios/${currentUser.uid}/carnet_medico/${file.name}`)
    const task = storageRef.put(file)
    console.log(storageRef)

    task.on('state_changed', (snapshot) => {
      const porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setStateCarnet({
        barra: porcentage
      })
    }, (error) => {
      setStateDocCarnet({
        message: `error:${error.message}`
      })
    }, () => {
      task.snapshot.ref.getDownloadURL().then((url) => {
        const dato = url
        setStateDocCarnet({
          message: "Carnet cargado con éxito",
          DocCarnet: dato
        })
      })
    })
  }

  const [stateCedula, setStateCedula] = useState(
    { barra: 0 })

  function handleChangeDocCedula(e) {
    const file = e.target.files[0]
    console.log(file)
    const storageRef = firebase.storage().ref(`Usuarios/${currentUser.uid}/cedula/${file.name}`)
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      const porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setStateCedula({
        barra: porcentage
      })
    }, (error) => {
      setStateDocCedula({
        message: `error:${error.message}`
      })
    }, () => {
      task.snapshot.ref.getDownloadURL().then((url) => {
        const dato = url
        setStateDocCedula({
          message: "Cedula cargada con éxito",
          DocCedula: dato
        })
      })
    })
  }
  const [stateFirma, setStateFirma] = useState(
    { barra: 0 })

  function handleChangeDocFirma(e) {
    const file = e.target.files[0]
    console.log(file)
    const storageRef = firebase.storage().ref(`Usuarios/${currentUser.uid}/cedula/${file.name}`)
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      const porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setStateFirma({
        barra: porcentage
      })
    }, (error) => {
      setStateDocFirma({
        message: `error:${error.message}`
      })
    }, () => {
      task.snapshot.ref.getDownloadURL().then((url) => {
        const dato = url
        setStateDocFirma({
          message: "Firma cargada con éxito",
          DocFirma: dato
        })
      })
    })
  }
  function mostrar() {
    if (Sexo === "Personalizado") {
      setOtroMostrar(true)
    } else if (Sexo !== "Personalizado") {
      setOtroMostrar(false)
    }
  }
  const [statesello, setStatesello] = useState(
    { barra: 0 })

  function handleChangeDocsello(e) {
    const file = e.target.files[0]
    console.log(file)
    const storageRef = firebase.storage().ref(`Usuarios/${currentUser.uid}/cedula/${file.name}`)
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      const porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setStatesello({
        barra: porcentage
      })
    }, (error) => {
      setStateDocsello({
        message: `error:${error.message}`
      })
    }, () => {
      task.snapshot.ref.getDownloadURL().then((url) => {
        const dato = url
        setStateDocsello({
          message: "Sello cargado con éxito",
          Docsello: dato
        })
      })
    })
  }
  function quitar() {
    setExito("")
  }


  return (
    <div>
      <input type="checkbox" id="sidebar-toggle" />
      <div className="sidebar">
        <Menu> </Menu>
      </div>
      <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
          <section className="contact_info_area sec_pad bg_color">
            <div className="row">
              <div className="upda  PanelFoto">
                {state.piture === DatosNombre.foto_personal ? <div className="imge"><img src={DatosNombre.foto_personal}></img></div> : ""}{state.piture !== DatosNombre.foto_personal ? <div className="imge"> <img src={state.picture}></img></div> : ""}
                <div className="iconoImgen"><label for="files"><img src="Camera_SVG.svg" title="Editar foto del perfil" /></label></div>
                <div className="correo">{DatosNombre.correo}</div>
                <div className="form-group barra" id="email">
                  <br />
                  <input id="files" onChange={handleChange} style={{ visibility: "hidden" }} name='foto' type="file" />
                  {Mostrarba !== "" ?
                    <div>
                      {<progress value={statebarra.barra} max='100'></progress>}
                    </div>
                    : ""}
                  <div className="mensajeExito">{state.message}</div>
                </div>
              </div>
              <div className="">
                {error && <Alert variant="danger">{error}</Alert>}
                <form>
                  <div className="PanelDatos mb-2">
                    <div className="text-center a" ><h1 >Actualizar perfil</h1></div>
                    <div className="row">
                      <div className="mr-5 ml-4 mb-3" id="email">
                        <div>
                          <div> <label><b>Nombre</b></label></div>
                          <div><input className=""
                            type="text"
                            onInput={(e) => setNombres(e.target.value)}
                            defaultValue={DatosNombre.nombres}
                          /></div>
                        </div>
                      </div>
                      <div className="mb-3" id="email">
                        <div><label><b>Apellido</b></label></div>
                        <div><input className=""
                          type="text"
                          onInput={(e) => setApellidos(e.target.value)}
                          defaultValue={DatosNombre.apellidos}
                        /></div>

                      </div>
                      <div className="ml-4 Cedu mb-3" id="email">
                        <div> <label><b>Cédula</b></label></div>
                        <div><input className=""
                          type="text"
                          defaultValue={DatosNombre.cedula}
                          onChange={(e) => setCedula(e.target.value)}
                          placeholder="Agregue su Cedula de identidad"
                        /></div>
                      </div>
                      <div className="Edad" id="email">
                        <div> <label><b>Edad</b></label></div>
                        <div> <input className=""
                          defaultValue={DatosNombre.edad}
                          type="text"
                          onChange={(e) => setEdad(e.target.value)}
                          placeholder="Agregue su edad"
                        /></div>
                      </div>
                      <div className="" id="email">
                        <div><label><b>Género</b></label></div>
                        <div><select type="select" className="" onClick={() => mostrar()} onChange={(e) => setSexo(e.target.value)} onChangeCapture={(e) => setOtro(e.target.value)}>
                          <option value="">Seleccionar</option>
                          <option value="Hombre">Hombre</option>
                          <option value="Mujer">Mujer</option>
                          <option value="Personalizado">Personalizado</option>
                        </select>
                          <p><label>Actúal:  {DatosNombre.sexo}</label></p>
                        </div>
                      </div>
                      {OtroMostrar === true ?
                        <div className="ml-2" id="email">
                          <div><label><b>Especifique</b></label></div>
                          <div> <input className=""
                            type="text"
                            onChange={(e) => setOtro(e.target.value)}
                            placeholder="Especifique"
                          /></div>
                        </div>
                        : ""}
                      <div className="mr-5 ml-4" id="email">
                        <div><label><b>País</b></label></div>
                        <div><select type="select" className="" onChange={(e) => setPais(e.target.value)}>
                          <option selected>{DatosNombre.pais}</option>
                          <option value="Afganistán">Afganistán</option>
                          <option value="Albania">Albania</option>
                          <option value="Alemania">Alemania</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antártida">Antártida</option>
                          <option value="Antigua y Barbuda">Antigua y Barbuda</option>
                          <option value="Antillas Holandesas">Antillas Holandesas</option>
                          <option value="Arabia Saudí">Arabia Saudí</option>
                          <option value="Argelia">Argelia</option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaiyán">Azerbaiyán</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrein">Bahrein</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Bélgica">Bélgica</option>
                          <option value="Belice">Belice</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermudas">Bermudas</option>
                          <option value="Bielorrusia">Bielorrusia</option>
                          <option value="Birmania">Birmania</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bosnia y Herzegovina">Bosnia y Herzegovina</option>
                          <option value="Botswana">Botswana</option>
                          <option value="Brasil">Brasil</option>
                          <option value="Brunei">Brunei</option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Bután">Bután</option>
                          <option value="Cabo Verde">Cabo Verde</option>
                          <option value="Camboya">Camboya</option>
                          <option value="Camerún">Camerún</option>
                          <option value="Canadá">Canadá</option>
                          <option value="Chad">Chad</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Chipre">Chipre</option>
                          <option value="Ciudad del Vaticano (Santa Sede)">Ciudad del Vaticano (Santa Sede)</option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comores">Comores</option>
                          <option value="Congo">Congo</option>
                          <option value="Congo, República Democrática">Congo, República Democrática</option>
                          <option value="Corea">Corea</option>
                          <option value="Corea del Norte">Corea del Norte</option>
                          <option value="Costa de Marfíl">Costa de Marfíl</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Croacia (Hrvatska)">Croacia (Hrvatska)</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Dinamarca">Dinamarca</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egipto">Egipto</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Emiratos Árabes Unidos">Emiratos Árabes Unidos</option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Eslovenia">Eslovenia</option>
                          <option value="España" >España</option>
                          <option value="Estados Unidos">Estados Unidos</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Etiopía">Etiopía</option>
                          <option value="Francia">Francia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Grecia">Grecia</option>
                          <option value="Groenlandia">Groenlandia</option>
                          <option value="Guadalupe">Guadalupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guayana">Guayana</option>
                          <option value="Guayana Francesa">Guayana Francesa</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guinea Ecuatorial">Guinea Ecuatorial</option>
                          <option value="Haití">Haití</option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hungría">Hungría</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Irak">Irak</option>
                          <option value="Irán">Irán</option>
                          <option value="Irlanda">Irlanda</option>
                          <option value="Isla Bouvet">Isla Bouvet</option>
                          <option value="Isla de Christmas">Isla de Christmas</option>
                          <option value="Islandia">Islandia</option>
                          <option value="Italia">Italia</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japón">Japón</option>
                          <option value="Jordania">Jordania</option>
                          <option value="Kazajistán">Kazajistán</option>
                          <option value="Letonia">Letonia</option>
                          <option value="Líbano">Líbano</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libia">Libia</option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lituania">Lituania</option>
                          <option value="Luxemburgo">Luxemburgo</option>
                          <option value="Malasia">Malasia</option>
                          <option value="México">México</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk">Norfolk</option>
                          <option value="Noruega">Noruega</option>
                          <option value="Nueva Caledonia">Nueva Caledonia</option>
                          <option value="Nueva Zelanda">Nueva Zelanda</option>
                          <option value="Omán">Omán</option>
                          <option value="Países Bajos">Países Bajos</option>
                          <option value="Panamá">Panamá</option>
                          <option value="Papúa Nueva Guinea">Papúa Nueva Guinea</option>
                          <option value="Paquistán">Paquistán</option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Perú">Perú</option>
                          <option value="Pitcairn">Pitcairn</option>
                          <option value="Polinesia Francesa">Polinesia Francesa</option>
                          <option value="Polonia">Polonia</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Reino Unido">Reino Unido</option>
                          <option value="República Centroafricana">República Centroafricana</option>
                          <option value="República Checa">República Checa</option>
                          <option value="República de Sudáfrica">República de Sudáfrica</option>
                          <option value="República Dominicana">República Dominicana</option>
                          <option value="República Eslovaca">República Eslovaca</option>
                          <option value="Reunión">Reunión</option>
                          <option value="Ruanda">Ruanda</option>
                          <option value="Rumania">Rumania</option>
                          <option value="Rusia">Rusia</option>
                          <option value="Sahara Occidental">Sahara Occidental</option>
                          <option value="Saint Kitts y Nevis">Saint Kitts y Nevis</option>
                          <option value="Samoa">Samoa</option>
                          <option value="Samoa Americana">Samoa Americana</option>
                          <option value="San Marino">San Marino</option>
                          <option value="San Vicente y Granadinas">San Vicente y Granadinas</option>
                          <option value="Santa Helena">Santa Helena</option>
                          <option value="Santa Lucía">Santa Lucía</option>
                          <option value="Santo Tomé y Príncipe">Santo Tomé y Príncipe</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leona">Sierra Leona</option>
                          <option value="Singapur">Singapur</option>
                          <option value="Siria">Siria</option>
                          <option value="omalia">Somalia</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="St Pierre y Miquelon">St Pierre y Miquelon</option>
                          <option value="Suazilandia">Suazilandia</option>
                          <option value="Sudán">Sudán</option>
                          <option value="Suecia">Suecia</option>
                          <option value="Suiza">Suiza</option>
                          <option value="Surinam">Surinam</option>
                          <option value="Tailandia">Tailandia</option>
                          <option value="Taiwán">Taiwán</option>
                          <option value="Tanzania">Tanzania</option>
                          <option value="Tayikistán">Tayikistán</option>
                          <option value="Territorios franceses del Sur">Territorios franceses del Sur</option>
                          <option value="Timor Oriental">Timor Oriental</option>
                          <option value="Togo">Togo</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad y Tobago">Trinidad y Tobago</option>
                          <option value="Túnez">Túnez</option>
                          <option value="Turkmenistán">Turkmenistán</option>
                          <option value="Turquía">Turquía</option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Ucrania">Ucrania</option>
                          <option value="Uganda">Uganda</option>
                          <option value="Uruguay">Uruguay</option>
                          <option value="Uzbekistán">Uzbekistán</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Vietnam">Vietnam</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Yugoslavia">Yugoslavia</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabue">Zimbabue</option>
                        </select></div>
                      </div>
                      <div className="ml-2" id="email">
                        <div><label><b>Ciudad</b></label></div>
                        <div><input className=""
                          type="text"
                          onChange={(e) => setCiudad(e.target.value)}
                          placeholder="Ciudad"
                          defaultValue={DatosNombre.ciudad}
                        /></div>
                      </div>

                    </div>
                  </div>
                  <div className="PanelDatos mb-2">
                    <div className="text-center a" ><h1 >Tipos de terapias</h1></div>
                    <div className="form-inline">
                      <div className="chek " id="email">
                        <div>
                          <div className="form-inline">
                            <div className="mr-3">
                              <input className=""
                                type="checkbox"
                                checked={infanto}
                                onChange={(e) => setinfanto(e.target.checked)}
                              />
                            </div>
                            <div>Infanto-juvenil</div>
                          </div>
                          <div className="form-inline">
                            <div className="mr-3">
                              <input className=""
                                type="checkbox"
                                checked={Geriatrico}
                                onChange={(e) => setGeriatrico(e.target.checked)}
                              /></div><div>Geriátrico</div>
                          </div>
                          <div className="form-inline">
                            <div className="mr-3">
                              <input className=""
                                type="checkbox"
                                checked={Adulto}
                                onChange={(e) => setAdulto(e.target.checked)}
                              /></div><div>Adulto</div>
                          </div>
                          <div className="form-inline">
                            <div className="mr-3">
                              <input className=""
                                type="checkbox"
                                checked={Parejas}
                                onChange={(e) => setParejas(e.target.checked)}
                              />
                            </div>
                            <div>Pareja</div>
                          </div>
                          <div className="form-inline">
                            <div className="mr-3">
                              <input className=""
                                type="checkbox"
                                onChange={(e) => setAdicciones(e.target.checked)}
                                checked={Adicciones}
                              /></div><div>Terappia de adicciones</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="PanelDatos mb-2">
                    <div className="text-center a" ><h1 >Contactos</h1></div>
                    <div className="">
                      <div className="ml-4" id="email">
                        <div><label><b>Número principal</b></label> </div>
                        <div><input className=""
                          type="text"
                          name="codigo"
                          id="validanumericos"
                          defaultValue={DatosNombre.num_principal}
                          onChange={(e) => setnumeroPri(e.target.value)}
                          placeholder="+58 Numero Telefonico"
                          onKeyPress={handlkeyPress}
                        /> </div>
                        {input && <div className="validarInput">{input}</div>}
                      </div>
                    </div>
                  </div>

                  <div className="PanelDatos mb-2">
                    <div className="text-center a" ><h1 >Seguridad</h1></div>
                    <div className="row">
                      <div className="mr-4 ml-5" id="password">
                        <div><label><b>Contraseña</b></label></div>
                        <div >
                          <input className=""
                            type="password"
                            ref={passwordRef}
                            placeholder="Dejar en blanco para mantener la misma"
                          />
                        </div>
                      </div>
                      <div className="" id="password-confirm">
                        <div><label><b>Confirmar contraseña</b></label></div>
                        <div> <input className=""
                          type="password"
                          ref={passwordConfirmRef}
                          placeholder="Dejar en blanco para mantener la misma"
                        /></div>
                      </div>
                    </div>
                  </div>

                  <div className="PanelDatos mb-2">
                    <div className="text-center a" ><h1 >Datos profesionales</h1></div>
                    <div className="row">
                      <div className="ml-5 mr-5" id="email">
                        <div><label><b>Especialidad</b></label></div>
                        <div><select type="select" className="" onChange={(e) => setProfesion(e.target.value)}>
                          <option>{DatosNombre.especialidad}</option>
                          <option value="Psicología">Psicología</option>
                          <option value="Psiquiatría">Psiquiatría</option>
                        </select></div>
                      </div>
                      <div className="ml-5" id="email">
                        <div><label><b>Años de experiencia</b></label></div>
                        <div> <input className=""
                          type="number"
                          placeholder=""
                          defaultValue={DatosNombre.experiencia}
                          onChange={(e) => setExperiencia(e.target.value)}
                        /></div>
                      </div>
                    </div>
                  </div>

                  <div className="PanelDatos_Doc mb-2">
                    <div className="text-center a" ><h1 >Documentación</h1></div>
                    <div className="">
                      <div className="Firma mb-5" id="email">
                        <div><label>Título universitario</label>
                          <br />
                          {<progress value={stateTitulo.barra} max='100'></progress>}
                          <input className=""
                            name='titulo'
                            type='file'
                            onChange={handleChangeDocTiulo}
                          />
                        </div>
                        {stateDocTitulo.DocTitulo !== "" ?
                          <div className="mt-2 mb-2"><a href={stateDocTitulo.DocTitulo} target="_blank">Ver titulo</a></div>
                          : ""}
                        <div className="mensajeExito">{stateDocTitulo.message}</div>
                      </div>
                      <div className="Firma mb-5" id="email">
                        <div><label>Carnet</label>
                          <br />
                          {<progress value={stateCarnet.barra} max='100'></progress>}
                          <input className=""
                            name='Carnet'
                            type='file'
                            onChange={handleChangeDocCarnet}
                          />
                          <p className="Leyenda">Por favor seleccionar documentos de formato PDF o JPG</p>
                        </div>
                        {stateDocCarnet.DocCarnet !== "" ?
                          <div className="mt-2 mb-2"><a href={stateDocCarnet.DocCarnet} target="_blank">Ver carnet</a></div>
                          : ""}
                        <div className="mensajeExito">{stateDocCarnet.message}</div>
                      </div>
                      <div className="Firma mb-5" id="email">
                        <div> <label>Cédula o pasaporte</label>
                          <br />
                          {<progress value={stateCedula.barra} max='100'></progress>}
                          <input className=""
                            name='CedulaDoc'
                            type='file'
                            onChange={handleChangeDocCedula}
                          />
                          <p className="Leyenda">Por favor seleccionar documentos de formato PDF o JPG</p>
                        </div>

                        {stateDocCedula.DocCedula !== "" ?
                          <div className="mt-2 mb-2"><a href={stateDocCedula.DocCedula} target="_blank">Ver cédula o pasaporte</a></div>
                          : ""}
                        <div className="mensajeExito"> {stateDocCedula.message}</div>
                      </div>
                      <div className="Firma  mb-5" id="email">
                        <div><label>Firma </label>
                          <br />
                          {<progress value={stateFirma.barra} max='100'></progress>}
                          <input className=""
                            name='CedulaDoc'
                            type='file'
                            onChange={handleChangeDocFirma}
                          />
                          <p className="Leyenda">Por favor seleccionar documentos de formato PDF o JPG</p>
                        </div>
                        {stateDocFirma.DocFirma !== "" ?
                          <div className="mt-2 mb-2"><a href={stateDocFirma.DocFirma} target="_blank">Ver firma </a></div>
                          : ""}
                        <div className="mensajeExito">{stateDocFirma.message}</div>
                      </div>
                      <div className="Firma  mb-5" id="email">
                        <div><label>Sello </label>
                          <br />
                          {<progress value={statesello.barra} max='100'></progress>}
                          <input className=""
                            name='CedulaDoc'
                            type='file'
                            onChange={handleChangeDocsello}
                          />
                          <p className="Leyenda">Por favor seleccionar documentos de formato PDF o JPG</p>
                        </div>
                        {stateDocsello.Docsello !== "" ?
                          <div className="mt-2 mb-2"><a href={stateDocsello.Docsello} target="_blank">Ver sello </a></div>
                          : ""}
                        <div className="mensajeExito">{stateDocsello.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="PanelDatos_banco mb-2">
                    <div className="text-center a" ><h1 >Datos Bancarios</h1></div>
                    <div className="header_tabla_banco form-inline mb-5 mt-3">
                      <div className={ActiveBoton == "Zelle" ? "col-3 botones_header_Activo a" : "col-3 botones_header a"} onClick={(e) => tipoEspecialista("Zelle", e)}>Zelle</div>
                      <div className={ActiveBoton == "Paypal" ? "col-3 botones_header_Activo_medio b" : "col-3 botones_header b"} onClick={(e) => tipoEspecialista("Paypal", e)}>Paypal</div>
                      <div className={ActiveBoton == "Pago móvil" ? "col-3 botones_header_Activo_medio b" : "col-3 botones_header b"} onClick={(e) => tipoEspecialista("Pago móvil", e)}>Pago móvil</div>
                      <div className={ActiveBoton == "Transferencia" ? "col-3 botones_header_Activo_right c" : "col-3 botones_header c"} onClick={(e) => tipoEspecialista("Transferencia", e)}>Transferencia</div>
                    </div>
                    {ActiveBoton === "Transferencia" ?
                      <div className="mt-4">
                        <div className="form-inline">
                          <div className="ml-4 mr-5">
                            <label><b>Banco</b></label>
                            <input type="text" defaultValue={Banco} className="select_Servi" placeholder="Banco" onChange={(e) => setBanco(e.target.value)} /></div>
                          <div className="">
                            <label className=""><b>Cuenta</b></label>
                            <input type="text" defaultValue={Cuenta} className="select_Servi" placeholder="Número de cuenta" onChange={(e) => setCuenta(e.target.value)} />
                          </div>
                          <div className="ml-4">
                            <label className=""><b>Cédula</b></label>
                            <input type="number" defaultValue={CedulaBanco} className="select_Servi" placeholder="Cedúla de identidad" onChange={(e) => setCedulaBanco(e.target.value)} />
                          </div>
                        </div>
                      </div>
                      : ""}
                    {ActiveBoton === "Zelle" ?
                      <div className="">
                        <div className="form-inline">
                          <div className="ml-4 mr-5">
                            <label><b>Nombre completo</b></label>
                            <input type="text" className="select_Servi" defaultValue={NameZelle} placeholder="Nombre completo" onChange={(e) => setNameZelle(e.target.value)} />
                          </div>
                          <div className="">
                            <label className=""><b>Correo</b></label>
                            <input type="email" className="select_Servi" defaultValue={EmailZelle} placeholder="Correo" onChange={(e) => setEmailZelle(e.target.value)} />
                          </div>
                        </div>
                      </div>
                      : ""}
                    {ActiveBoton === "Paypal" ?
                      <div className="">
                        <div className="">
                          <label className=""><b>Correo</b></label>
                          <input type="email" className="select_Servi" defaultValue={EmailPaypal} placeholder="Correo" onChange={(e) => setEmailPaypal(e.target.value)} />
                        </div>
                      </div>
                      : ""}
                    {ActiveBoton === "Pago móvil" ?
                      <div className="">
                        <div className="form-inline">
                          <div className="ml-4 mr-5">
                            <label><b>Banco</b></label>
                            <input type="text" className="select_Servi" defaultValue={BancoPag} placeholder="Describa el banco" onChange={(e) => setBancoPag(e.target.value)} /></div>
                          <div className="">
                            <label className=""><b>Número telefónico</b></label>
                            <input type="text" className="select_Servi" defaultValue={TelPag} placeholder="Número de teléfono" onChange={(e) => setTelPag(e.target.value)} />
                          </div>
                          <div className="ml-4">
                            <label className=""><b>Cédula</b></label>
                            <input type="number" className="select_Servi" defaultValue={Doc_iden} placeholder="Cédula de identidad" onChange={(e) => setDoc_iden(e.target.value)} />
                          </div>
                        </div>
                      </div>
                      : ""}
                  </div>

                  <div className="PanelDatos mb-2">
                    <div className="text-center a" ><h1 >Honorarios</h1></div>
                    <div className="">
                      {error && <Alert variant="danger">{error}<FontAwesomeIcon icon={faTrash} onClick={() => setError("")} /></Alert>}
                      <div className="form-inline Edad">
                        
                          <div className="col-6">
                            <div> <label><b>Monto de servicio emergencia:</b></label></div>
                            <div className="ml-4"> <input type="number" defaultValue={DatosNombre.precio_emergencia} className="select_Servi" placeholder="$" onChange={(e) => setHonorarioEmergencia(e.target.value, e)} />
                            </div>
                            <div className="ml-4"><p className="Leyenda">Monto expresado en $</p></div>
                          </div>

                          <div className="tex-left col-6">
                            <div ><label className=""><b>Monto de servicio citas:</b></label></div>
                            <div className="ml-5">
                              <input type="number" defaultValue={DatosNombre.precio_consulta} className="select_Servi" placeholder="$" onChange={(e) => setHonorarioCita(e.target.value, e)} />
                            </div>
                            <div className="ml-5">
                              <p className="Leyenda">Monto expresado en $</p>
                            </div>
                          </div>
                          
                      </div>

                      <div className="save_perfil text-right">
                        <img src="delete_SVG.svg" title="Guardar cambios" disabled={loading} className="mt-2" onClick={() => handleSubmit()} />
                      </div>
                      <div>{Exito &&
                        <div>
                          <div className="text-left">{Exito}</div>
                        </div>
                      }
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>
      </div>

    </div>
  )
}
