import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import './Dashboard.css';
import { db } from "../firebase"
import 'moment/locale/es'
import moment from 'moment';
import Menu from "./menu";
import HeaderPrin from "./header"
moment.locale('es');



export default function MisCitas() {
  const [error, setError] = useState("");
  const { currentUser } = useAuth("");
  const [currentDatosPa, setcurrenDatosPa] = useState([]);
  const [currentDatosPa_pe, setcurrenDatosPa_pendientes] = useState([]);
  const history = useHistory("")



  const Hamil = async () => {
    db.collection("Citas").where("uid_paciente", "==", currentUser.uid).where("tipo_cita", "==", "Consulta").onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setcurrenDatosPa(docs);
    });
    db.collection("Citas").where("uid_paciente", "==", currentUser.uid).where("estatus", "==", "Reprogramada").onSnapshot((querySnapshot) => {
      const pen = [];
      querySnapshot.forEach((doc) => {
        pen.push({ ...doc.data(), id: doc.id });
      });
      setcurrenDatosPa_pendientes(pen);
    });

  }
  useEffect(() => {
    Hamil();
  }, []);

  const [Reto,setReto]=useState("")

  const retomarCitas = (a, e, i) => {
    localStorage.setItem('IdCita', JSON.stringify(a))
    localStorage.setItem('Paciente', JSON.stringify(e))
    localStorage.setItem('id', JSON.stringify(i))
}



function ObtenerDatos(e) {
  history.push("/Profile")
  localStorage.setItem("IdPerfilMedicos", JSON.stringify(e))
}
  return (
    <>
    <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {Reto===""?<div>
      {currentDatosPa_pe.length !== 0 ?
        <div>
          <h2 className="dash-title">Reprogramadas</h2>
          <p>¡Hola! Por favor elije nueva hora y fecha para reprogramar su cita.</p>
          <div className="dash-cards ">
            {currentDatosPa_pe.length ? (
              currentDatosPa_pe.map(array => (
                <div className="card-single">
                  <div className="card-body text-center">
                    <div className="updaPrin_1">
                      <img src={array.foto_especialista} />&nbsp;{array.nombres_especialista}&nbsp;{array.apellidos_especialista}
                    </div>
                  </div>
                  <div className="horatarget_MisCitas text-center">
                    <h4>{array.hora_anterior}</h4>
                    <h5 className="">{array.fecha_anterior}</h5>
                  </div>
                  <h5 className="mt-2 text-center">{array.estatus==='Reprogramada'?<div>Reprogramar</div>:""}</h5>
                  <div className="card-footer form-inline col-12">
                    <div className="col-4 divicon_1" id="ini">
                      
                    </div>
                    
                    <div className="col-4 text-center divicon_2 " onClick={() => retomarCitas(array.id, array.uid_paciente, array.uid_especialista)}>
                    <Link to="/Index"> <img className=" icon_2" src="/Repro_icon_20.png" title="Reprogramar esta cita" /></Link>
                    </div>
                    
                    <div className="col-4 text-right divicon_3" >
                      
                    </div>
                  </div>
                </div>

              )
              )) : (
                <h2 className="dash-title">No tiene consultas</h2>
            )}
          </div>
        </div>
        : ""}
      <div>
        {currentDatosPa.length===0?<h2 className="dash-title">No tienes consultas</h2>:<h2 className="dash-title">Mis consultas</h2>}
        <div className="dash-cards ">
          {currentDatosPa ? (
            currentDatosPa.map(array => (
              <div className="card-single">
                <div className="card-body text-center">
                  <div className="updaPrin_1">
                    <img src={array.foto_especialista} onClick={() => ObtenerDatos(array.uid_especialista)} />&nbsp;{array.nombres_especialista}&nbsp;{array.apellidos_especialista}
                  </div>
                </div>
                <div className="horatarget_MisCitas text-center">
                  <h4>{array.hora}</h4>
                  <h5 className="fechaTargetMis">{array.fecha}</h5>
                </div>
                <h5 className="mt-2 text-center">{array.estatus}</h5>
              </div>
            )
            )) : (
            <div></div>
          )}
        </div>
      </div>
      </div>:""}
        </main>
        </div>  
    

    </>

  )
}
