import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { useAuth } from "../contexts/AuthContext";
import {  useHistory } from "react-router-dom";
import { db } from "../firebase"
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import moment from 'moment';
// import { faTrash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from '../components/checkbox'
import Menu from "./menu";
import HeaderPrin from "./header"

init("user_18jqcwflnGbkPgeaoNzWQ");



moment.locale('es');


export default function Video() {

    const { currentUser } = useAuth();
    const history = useHistory("")
    const [firtinforme, setFirstinforme] = useState(true)
    const [currentPerfil, setCurrentPerfil] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const frmContact = { linkRoom: '' };
    const [contact, setContact] = useState(frmContact);
    const [Exito, setExito] = useState("")
    const [currentDatosPaDe, setLinks] = useState([]);
    const [MostrarDiv, setMostrar] = useState(true);
    const [nuevoDaosHora, setNuevodatoHora] = useState([]);
    const [Categorias, setCategorias] = useState("")
    // const [AlmacenCIE10_2, setAlmacenCIE10_2] = useState("")
    const [AlmacenMSD5, setAlmacenMSD5] = useState("")
    const [AlmacenMSD5Final, setAlmacenMSD5Final] = useState("")
    const paciente = JSON.parse(localStorage.getItem('paciente_conferencia'));
    const [DatosConfe, setDatosConfe] = useState("")
    const [descripcionHistoria, setdescripcionHistoria] = useState("")
    const [descripcionRecipe, setdescripcionRecipe] = useState("")
    const [botonIniciar,setbotonIniciar]=useState(false)
    const [botonCompartir,setbotonCompartir]=useState(false)
    const [camporecipe, setcamporecipe] = useState("")
    const[HistoryMedical,setHistoryMedical]=useState(false)
    const [busqueda, setBusqueda]= useState("");
    
    const getLinks = async () => {
        db.collection("Usuarios")
            .doc(currentUser.uid)
            .get().then(function (doc) {
                let users = doc.data()
                setCurrentPerfil(users);
                // db.collection("Patologias")
                //     .doc("CIE10_1")
                //     .get().then(function (doc) {
                //         let users = doc.data().patologias
                //         setAlmacenCIE10_1(users);
                //         console.log(AlmacenCIE10_1)
                //     })
                // db.collection("Patologias")
                //     .doc("CIE10_2")
                //     .get().then(function (doc) {
                //         let users = doc.data().patologias
                //         setAlmacenCIE10_2(users);
                //     })
                db.collection("Patologias")
                    .doc("DSM5")
                    .get().then(function (doc) {
                        let users = doc.data().patologias
                        let data =users;
              let categoriaArray = [];
              console.clear();
              for (let i = 0; i <data.length; i++) {
                let Newdata = {
                  Categoria:data[i].categoria
                }
                categoriaArray.push(Newdata);
                
              }
              let unicos = [];
              for (var indice = 0; indice < categoriaArray.length; indice++) {
                const persona = categoriaArray[indice];
                let esDuplicado = false;
                for (var i = 0; i < unicos.length; i++) {
                  if (unicos[i].Categoria === persona.Categoria) {
                    esDuplicado = true;
                    break;
                  }
                }
        
                if (!esDuplicado) {
                  unicos.push(persona);
                }
              }
                        let pacientes = unicos;
                        console.log(pacientes)
                        // setLinks(pacientes)
                        setCategorias(pacientes)
                    })
            }).catch(function (error) {
                console.log("Error getting User:", error);
                alert(error);
            });

            db.collection("Usuarios")
            .doc(paciente.id)
            .get().then(function (doc) {
                let users = doc.data()
                setLinks(users);
            })

            db.collection("Conferencias").where("uid_especialista", "==", currentUser.uid).where("notificacion", "==", true)
            .onSnapshot(function (querySnapshot) {
              let Conferencias = [];
              querySnapshot.forEach(function (doc) {
                Conferencias.push({ ...doc.data(), id: doc.id })
              })
              setDatosConfe(Conferencias)
            })

    };

    useEffect(() => {
        getLinks();
    }, []);


/////////////////Buscador////////////////////////////////////////
const handleChange=e=>{
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  }
  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=AlmacenMSD5.filter((elemento)=>{
      // let nombre=elemento.nombres + elemento.apellidos
      let busqueda=terminoBusqueda.trim()
      console.log(busqueda)
      // console.log(nombre)
      if(elemento.Descripcion.toString().toLowerCase().includes(busqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    if(terminoBusqueda!==""){
        setAlmacenMSD5(resultadosBusqueda);
  }else if(terminoBusqueda===""){
    db.collection("Patologias")
    .doc("DSM5")
    .get().then(function (doc) {
        let users = doc.data().patologias
        let data =users;
let categoriaArray = [];
console.clear();
for (let i = 0; i <data.length; i++) {
let Newdata = {
  Categoria:data[i].categoria,
  Descripcion:data[i].descripcion
}
if(Newdata.Categoria===AlmacenMSD5Final){
categoriaArray.push(Newdata);
}
}
setAlmacenMSD5(categoriaArray)
console.log(categoriaArray)    
    })
      
  }
  }

/////////////////Buscador////////////////////////////////////////


    const jitsiContainerStyle = {
        width: '100%',
        height: '100%',
    }




    const idcita = JSON.parse(localStorage.getItem('IdCita'));
    const fotoe = JSON.parse(localStorage.getItem('FotoE'));
    const fotopa = JSON.parse(localStorage.getItem('FotoPa'));

    const clMedicalal = () => {

        let r = Math.random().toString(36).substring(7);

        let link = 'https://meet.jit.si' + '/' + currentUser.uid + r
        setContact({ linkRoom: link })

        const domain = 'meet.jit.si';
        const options = {
            roomName: currentUser.uid + r,
            height: 400,
            parentNode: document.getElementById('jitsi-container'),
            interfaceConfigOverwrite: {
                filmStripOnly: false,
                SHOW_JITSI_WATERMARK: false,
            },
        }
        setFirstinforme(false)

        const api = new window.exports.JitsiMeetExternalAPI(domain, options);
        api.executeCommand('displayName', 'MyName');
    };

    const uidNew=Math.random().toString(20).substring(7);

    const handleSubmit = e => {
        setbotonCompartir(true)
        e.preventDefault();
        console.log(contact)
        emailjs.send('service_e4olqec', 'template_0ffcydz', contact, 'user_18jqcwflnGbkPgeaoNzWQ')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setShowMessage(true);
            }, (err) => {
                console.log('FAILED...', err);
            });

        db.collection("Conferencias").doc(idcita).set({
            uid:idcita,
            uid_consulta: idcita,
            tipo_cita: "Consulta",
            estatus:"Agendada",
            fecha_creacion: moment().format("DD/MM/YYYY"),
            notificacion: true,
            notificacion_paciente: true,
            notificacion_especialista: false,
            nombres_especialista: currentPerfil.nombres,
            apellidos_especialista: currentPerfil.apellidos,
            fecha_notificacion: moment().format("DD/MM/YYYY"),
            hora_notificacion: moment().format("hh:mm A"),
            fecha_notificacion: moment().format("DD/MM/YYYY"),
            foto_especialista:currentPerfil.foto_personal,
            foto_paciente:currentDatosPaDe.foto_personal,
            uid_especialista: currentUser.uid,
            nombres_paciente: currentDatosPaDe.nombres,
            apellidos_paciente: currentDatosPaDe.apellidos,
            uid_paciente: currentDatosPaDe.uid,
            room_link: contact.linkRoom,
            fecha_noti: new Date(),
        }).then(resp => {
            db.collection("Citas").doc(idcita).update({
                "notificacion": true,
                "fecha_noti": new Date(),
                "notificacion_paciente":true,
            })
            
            console.log(resp)
            setExito("Noticicacion eviada exitosamente")
            setTimeout(res => { setExito(true) }, 3000);
        })
    }

   

    function Mostrar(e) {
        setbotonIniciar(true)
       
        if (e = true) {
            setMostrar(false)

        }
    }
    // function QuitarMen() {
    //     setExito("")
    // }

  
    function recipes() {
        setcamporecipe("1")
    }

 


    console.log(descripcionRecipe)
    console.log(descripcionHistoria)


    function historiaMedica() {
        db.collection("historialMedico").doc().set({
            descripcion: descripcionHistoria,
            nombre_especialista: currentPerfil.nombres,
            uid_especialista: currentUser.uid,
            uid_paciente: currentDatosPaDe.uid,
            patologias: nuevoDaosHora,
            tipo:'Consulta',
            nombre_paciente: currentDatosPaDe.nombres,
            apellido_paciente: currentDatosPaDe.apellidos,
            fecha: moment().format('DD/MM/YYYY'),
            fecha_modificacion: "",
            fecha_creacion: moment().format('DD/MM/YYYY'),
        })
            .then(resp => {
                console.log(resp)
                setExito("Historial agregado")
                setTimeout(res => { setExito(true) }, 3000);
                setcamporecipe("")
                db.collection("Citas").doc(idcita).update({
                    "notificacion": false,
                    "estatus":"Completada",
                    "notificacion_especialista":false,
                    "notificacion_paciente":false,
                })
                db.collection("Conferencias").doc(idcita).update({
                    "notificacion": false,
                    "estatus":"Completada",
                    "notificacion_especialista":false,
                    "notificacion_paciente":false,
                })
                history.push("/Historias")
                setHistoryMedical(true)
            }).catch((error) => {
                alert(error.message)
            })
        if (descripcionRecipe !== "") {
            db.collection("Recipes").doc().set({
                apellidos_especialista: currentPerfil.apellidos,
                fecha_creacion: moment().format('DD/MM/YYYY'),
                descripcion: descripcionRecipe,
                direccion_destino: "",
                estatus: "Pendiente",
                fecha: moment().format('DD/MM/YYYY'),
                email_destino: currentDatosPaDe.correo,
                firma_especialista:currentPerfil.firma,
                sello_especialista:currentPerfil.sello,
                nombres_especialista: currentPerfil.nombres,
                numero_destino: "",
                uid_especialista: currentUser.uid,
                uid_paciente: currentDatosPaDe.uid,
                apellido_paciente: currentDatosPaDe.apellidos,
                nombre_paciente: currentDatosPaDe.nombres,
            })
                .then(resp => {
                    console.log(resp)
                    setExito("Historia y récipe agregado con éxito")
                    setTimeout(res => { setExito(true) }, 3000);
                    setHistoryMedical(true)
                    db.collection("Citas").doc(idcita).update({
                        "notificacion": false,
                        "estatus":"Completada",
                    })
                    history.push("/Historias")
                }).catch((error) => {
                    alert(error.message)
                })
        }

    }
    function SelectCategotias(e){
        setAlmacenMSD5Final(e)
        db.collection("Patologias")
                    .doc("DSM5")
                    .get().then(function (doc) {
                        let users = doc.data().patologias
                        let data =users;
              let categoriaArray = [];
              console.clear();
              for (let i = 0; i <data.length; i++) {
                let Newdata = {
                  Categoria:data[i].categoria,
                  Descripcion:data[i].descripcion
                }
                if(Newdata.Categoria===e){
                categoriaArray.push(Newdata);
            }
              }
              setAlmacenMSD5(categoriaArray)
              console.log(categoriaArray)    
                    })
    }
    // function ActualizarEstado(id,idConsulta) {
    //     db.collection("Conferencias").doc(id).update({
    //         "estatus": "Completada",
    //         "notificacion": false,
    //       })
    //       db.collection("Citas").doc(idConsulta).update({
    //         "estatus": "Completada",
    //         "notificacion": false,
    //       }).
    //     then(() => { 
    //         alert("Estatus actualizado exitosamente!!!")
    //       })
    // }


    const onCheckboxClicked = (id, isChecked, value) => {
        if (isChecked === true) {
            nuevoDaosHora.push(value)
            setNuevodatoHora([...nuevoDaosHora])
        }
        else if (isChecked === false) {
            for (let i = 0; i <= nuevoDaosHora.length; i++) {
                const nuevoStateHora = nuevoDaosHora[i]
                if (nuevoStateHora >= 0) {
                    nuevoDaosHora.push(value)
                }
            }
            let filtro = nuevoDaosHora.filter(number => number !== value)
            setNuevodatoHora([...filtro])
            console.log(nuevoDaosHora)
        }

    }
    return (
    <div>
          <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {HistoryMedical===false?
<div className="Contenedor">
            <div className={botonIniciar===false?"text-center botonAgendar_profile":"text-center botonAgendar_profile_no"} >
                <button className="btn" onClick={clMedicalal} onClickCapture={(e) => Mostrar(true, e)}>Iniciar conferencia</button>
            </div>
<div className="row">
        <div className=" col-5" id="Jitsi">
            <div id="jitsi-container"  style={jitsiContainerStyle} />
        </div>
        <div className="ContRecipe col-6">
            {MostrarDiv === false ?
                <section className="text-center" >
                    <div>{Exito &&
                        <div className="mb-3 text-center">
                            {Exito}
                        </div>
                    }
                    </div>
                    <div className={botonCompartir===false?"text-center botonAgendar_profile mb-3":"text-center botonAgendar_profile_no mb-3"} >
                        <button onClick={handleSubmit}>Compartir sala al paciente</button>
                    </div>
                    
                    
                    <div className="text-center">
                        <div className="text-center">
                            <div className="PanelDatos_info text-center">
                                <h2 className="tituloInforme">Informe de consulta</h2>
                                <div className="">
                                    <div className="inp">
                                        <div className="col-lg-12">
                                            <div className="form-group text_box">
                                                <input type="text" id="name" name="name" value={currentDatosPaDe.nombres} placeholder="Nombres" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group text_box">
                                                <input type="text" id="subject" name="subject" value={currentDatosPaDe.apellidos} placeholder="Descripción" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="tituloInforme_patologias">Patologías DSM5</h5>
                                    <div className="col-lg-12">
                                        
                                        <div id="">
                                        <select id="selectCate" className="form-select" onChange={(e)=>SelectCategotias(e.target.value,e)} aria-label="Default select example">
                                            <option>Categorias</option>
                                            {Categorias.length ? (
                                                    Categorias.map(array=> (
                                               
                                                <option value={array.Categoria}>{array.Categoria}</option>
                                                ))
                                                ) : ""
                                                }
                                            </select>
                                            </div>
                                        {/* <div className="header_tabla_patologias form-inline mb-4">
                                            <div className={ActiveBoton == "CIE10_1" ? "col-6 botones_header_Activo b" : "col-6 botones_header b"} onClick={(e) => Patologias("CIE10_1", e)}>CIE-10</div>
                                            <div className={ActiveBoton == "DSM5" ? "col-12 botones_header_Activo_medio c" : "col-12 botones_header c"}>DSM5</div>
                                        </div> */}
                                        <div className="buscadorPtologias mb-4 form-inline header_tabla_patologias">
                                            <img src="Buscar_SVG.svg" />
                                            <input className="" placeholder="Buscar" type="seach" onChange={handleChange} />
                                        </div>
                                        {AlmacenMSD5.length ?
                                            <div className="PanelDatos_info_pato mt-4 mb-3 ">
                                                {AlmacenMSD5.length ? (
                                                    AlmacenMSD5.map(array=> (
                                                        <div className="DatosPatologia row">
                                                            <div className="chekc col-2 mb-3">
                                                                <Checkbox initialState={false} value={array.Descripcion} onChange={(onCheckboxClicked)} />
                                                            </div>
                                                            <div className="Name mb-3 col-10 text-left">
                                                                {array.Descripcion}
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : ""
                                                }
                                            </div>
                                            : ""}
                                        {nuevoDaosHora.length ?
                                            <div>
                                                <h5 className="tituloInforme_patologias">Patologías agregadas</h5>
                                                <div className="PanelDatos_info_pato mt-2 mb-3 text-center">

                                                    {nuevoDaosHora.length ? (
                                                        nuevoDaosHora.map(array => (
                                                            <div className="DatosPatologia row">
                                                                <div className="mb-4">{array}</div>
                                                            </div>
                                                        ))
                                                    ) : ""}
                                                </div>
                                            </div>
                                            : ""}
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <div className="form-group text_box TextRecipes">
                                            <textarea onChange={(e) => setdescripcionHistoria(e.target.value)} name="message" id="" cols="30" rows="10" placeholder="Redactar informe . . ."></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        {currentPerfil.especialidad === "Psiquiatría" ?
                                            <div>
                                                {camporecipe === "" ? <div>
                                                    <div className="form-group ml-2 botonAgendar">
                                                        <button className="btn" onClick={recipes}>Añadir recipe</button>
                                                    </div>
                                                </div> : ""}
                                            </div>
                                            : ""}
                                        {camporecipe !== "" ? <div>
                                            <div className="form-group  botonAgendar ml-2">
                                                <button className="btn" onClick={() => setcamporecipe("")}>Cancelar</button>
                                            </div>
                                            <div className="form-group text_box">
                                                <textarea onChange={(e) => setdescripcionRecipe(e.target.value)} name="message" id="message" cols="30" rows="10" placeholder="Redactar recipe . . ."></textarea>
                                            </div>
                                        </div> : ""}
                                    </div>
                                    <div className="text-center botonAgendar"><button onClick={historiaMedica} className="btn">GUARDAR Y TERMINAR CONSULTA</button></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                : <span></span>
            }
            </div>
            </div>
        </div>
        
        :""}
        </main>
        </div>
    
</div>
    )
}