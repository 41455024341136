import React from 'react';
import '../assets/main.css';
// import CustomNavbar from '../components/CustomNavbar';
import { useHistory } from "react-router-dom"
// import HRService from '../components/Service/HRService';
// import PrototypeService from '../components/Service/Sservice/PrototypeService';
// import Partner from '../components/Partner';
// import ServiceSubscribe from '../components/ServiceSubscribe';
// import FooterTwo from '../components/Footer/FooterTwo';
// import ServiceData from '../components/Service/ServiceData';
// import FooterData from '../components/Footer/FooterData';
import { useState } from 'react';
import { useAuth } from "../contexts/AuthContext";
import Reveal from 'react-reveal/Reveal';
//import db from '../firebase.config'
import { db } from "../firebase"


const titulos = [
    "Si necesitas ayuda para elegir un especialista, llena el siguiente formulario.",
    "¿Qué tipo de asesoramiento adulto estás buscando?",
    "¿Has estado alguna vez en psicoterapia?",
    "¿En qué país estás actualmente?",
    "¿Cómo calificarías tu salud mental actualmente?",
    "¿Has estado experimentado algunos de estos síntomas?",
    //----de 7 al 11-------------Tristeza----------------////

    "¿Ha sentido últimamente poco interés en la ejecución de sus labores cotidianas?",
    "¿Has tenido pensamientos de minusvalía o culpa?",
    "¿Has pensado en lastimarte de alguna manera?",
    "¿Has pensado que todo estaría mejor si murieras?",
    "¿Has tenido pensamientos suicidas?",

    //------de 12 al 19-----------Preocupaciones----------------////
    "¿Has tenido problemas para concentrarte en actividades como leer o ver televisión?",
    "¿Qué tanto te han afectado en tu desempeño cotidiano?",
    "¿Has tenido problemas para quedarte dormido, mantenerte dormido o dormir demasiado?",
    "¿Sueles sentirte cansado o con poca energía?",
    "¿Has tenido poco apetito o ganas de comer en exceso?",
    "¿Has sentido un incremento de vitalidad y energía?",
    "¿Actualmente sientes ansiedad, ataques de pánico o tienes algún miedo?",
    "¿Actualmente estás tomando alguna medicación (psicofármaco)?",
    "¿Que tipo de paciente eres?"
    //-----------------ADOLESCENTE O INFANTIL----------------////
    ,

    //--------de 21 al 32------------Soy padre-------------------////
    "¿Cómo está relacionado con el adolescente?",
    "¿Qué edad tiene su hijo/a?",
    "¿Ha estado su hijo/a en terapia anteriormente?",
    "Basado en lo que observas. ¿Cómo calificarías los hábitos de sueño de tu hijo/a?",
    "Basado en lo que observas. ¿Cómo calificarías los hábitos alimenticios de tu hijo?",
    "Basado en lo que observas. ¿Está tu hijo experimentando ansiedad, ataques de pánico o alguna fobia?",
    "¿Está experimentando tristeza, dolor, angustia o desesperanza?",
    "¿Su hijo experimenta alguna de las siguientes situaciones?",
    "Poco interés o placer en hacer cosas",
    "Tiene arrebatos de ira, grita o es violento con otros",
    "Problemas para concentrarse en actividades como ver televisión o tener una conversación",
    "Su hijo ha tenido pensamientos y/o intentos suicidas",
    //---------de 33 al 37-----------Soy adolescente-------------------////
    "¿Qué edad tienes?",
    "¿Qué problemas estás enfrentando?",
    "¿Has estado en terapia antes?",
    "¿Cómo calificarías tu relación con tus padres?",
    "¿Has tenido pensamiento suicidas?",
    "¿Qué tipo de asesoramientos estás buscando?",
    "¿En que Pais estas?:",
    "Seleccionar tipo de ayuda que necesita:",
]

let tipoEstilo = "fadeInRight"

const Service = () => {
    const [userTipo, setUserTipo] = useState('');
    const [userIndi, setUserIndi] = useState('');
    const [userSigui, setUserSigui] = useState('');
    const [userTipoA, setUserTipoA] = useState('N/A');
    //---------------------Pareja----------------------////

    //---------------------Tristeza----------------------////
    const [userEstadoPsico, setUserEstadoPsico] = useState('N/A');
    const [userSaludMental, setUserSaludMental] = useState('N/A');
    const [userParanoia, setUserParanoia] = useState('N/A');
    const [userPocoInteres, setUserPocoInteres] = useState('N/A');
    const [userPais, setPais] = useState('N/A');
    const [userMinusvalia, setuserMinusvalia] = useState('N/A');
    const [userMurieras, setuserMurieras] = useState('N/A');
    const [userSuicida, setUserSuicida] = useState('N/A');
    const [userLastimarse, setuserLastimarse] = useState('N/A');

    //-----------------Preocupaciones------------------------////
    const [userActividades, setuserActividades] = useState('N/A')
    const [userDesempeño, setuserdesempeño] = useState('N/A')
    const [userDormido, setuserDormido] = useState('N/A')
    const [userPocaenergia, setuserPocaenergia] = useState('N/A')
    const [userApetito, setuserApetito] = useState('N/A')
    const [userVitalidad, setuserVitalidad] = useState('N/A')
    const [userAnsiedad, setuserAnsiedad] = useState('N/A')
    const [userMedicacion, setuserMedicacion] = useState('N/A')

    //-----------------ADOLESCENTE O INFANTIL Gereral----------------////
    const [userPreguntaGe, setuserPreguntaGe] = useState('N/A')
    const [userPaiis, setuserPaiis] = useState('N/A')


    //----------------------Soy padre----------------------------////
    const [userTipoAsesoria, setuserTipoAsesoria] = useState('N/A')
    const [userRelacionado, setuserRelacionado] = useState('N/A')
    const [userEdadHijo, setuserEdadHijo] = useState('N/A')
    const [userTerappiaHijo, setuserTerappiaHijo] = useState('N/A')
    const [userHabitoSueño, setuserHabitoSueño] = useState('N/A')
    const [userHabitoAlimenticio, setuserHabitoAlimenticio] = useState('N/A')
    const [userFobia, setuserFobia] = useState('N/A')
    const [userDesesperanza, setuserDesesperanza] = useState('N/A')
    const [userPlacer, setuserPlacer] = useState('N/A')
    const [userViolento, setuserViolento] = useState('N/A')
    const [userProblemas, setuserProblemas] = useState('N/A')
    const [userPensamientosuici, setuserPensasuici] = useState('N/A')

    //----------------------adolecente----------------------------////
    const [userEdadAdole, setuserEdadAdole] = useState('N/A')
    const [userProblemasEfre, setuserProblemasEfre] = useState('N/A')
    const [userTerapiaAntes, setuserTerapiaAntes] = useState('N/A')
    const [userCalificarRela, setuserCalificarRela] = useState('N/A')
    const [userPensamientosMalos, setuserPensamientosMalos] = useState('N/A')

    const [userAdo, setUserAdo] = useState('N/A');
    const [firstContainer, setFirstContainer] = useState(true)
    const history = useHistory("")
    const { currentUser, logout } = useAuth();
    const [valorEspecialista, setvalorEspecialista] = useState("Psicológo")
    const [omitir, setomitir] = useState("")
    /////////////////////Guardado de formulario/////////////////////

    async function handleSubmit(e) {
        e.preventDefault()
        db.collection("Hamilton").doc(currentUser.uid).set({
            uid: currentUser.uid,
            "¿Qué tipo de asesoramiento adulto estás buscando?": userTipoA,
            "¿Has estado alguna vez en psicoterapia?": userEstadoPsico,
            "¿En qué país estás actualmente?": userPais,
            "¿Cómo calificarías tu salud mental actualmente?": userSaludMental,
            "¿Has estado experimentado algunos de estos síntomas?": userParanoia,
            "¿Ha sentido últimamente poco interés en la ejecución de sus labores cotidianas?": userPocoInteres,
            "¿Has tenido pensamientos de minusvalía o culpa?": userMinusvalia,
            "¿Has pensado en lastimarte de alguna manera?": userLastimarse,
            "¿Has pensado que todo estaría mejor si murieras?": userMurieras,
            "¿Has tenido pensamientos suicidas?": userSuicida,
            "¿Has tenido problemas para concentrarte en actividades como leer o ver televisión?": userActividades,
            "¿Qué tanto te han afectado en tu desempeño cotidiano?": userDesempeño,
            "¿Has tenido problemas para quedarte dormido, mantenerte dormido o dormir demasiado?": userDormido,
            "¿Sueles sentirte cansado o con poca energía?": userPocaenergia,
            "¿Has tenido poco apetito o ganas de comer en exceso?": userApetito,
            "¿Has sentido un incremento de vitalidad y energía?": userVitalidad,
            "¿Actualmente sientes ansiedad, ataques de pánico o tienes algún miedo?": userAnsiedad,
            "¿Actualmente estás tomando alguna medicación (psicofármaco)?": userMedicacion,
            [titulos[38]]: userPaiis,
            [titulos[37]]: userTipoAsesoria,
            [titulos[20]]: userRelacionado,
            [titulos[21]]: userEdadHijo,
            [titulos[22]]: userTerappiaHijo,
            [titulos[23]]: userHabitoSueño,
            [titulos[24]]: userHabitoAlimenticio,
            [titulos[25]]: userFobia,
            [titulos[26]]: userDesesperanza,
            [titulos[28]]: userPlacer,
            [titulos[29]]: userViolento,
            [titulos[30]]: userProblemas,
            [titulos[31]]: userPensamientosuici,
            [titulos[32]]: userEdadAdole,
            [titulos[33]]: userProblemasEfre,
            [titulos[34]]: userTerapiaAntes,
            [titulos[35]]: userCalificarRela,
            [titulos[36]]: userPensamientosMalos,
            especialista_sugerido: valorEspecialista,
        })
            .then(() => {
                db.collection("Usuarios").doc(currentUser.uid).update({
                    "especialista_sugerido": valorEspecialista,
                })
                if (valorEspecialista === "Psicológo") {
                    history.push("/Dashboard")
                }
                if (userTipoA === "Pareja") {
                    history.push("/Dashboard")
                }
                if (userParanoia === "Paranoia, sensación de persecución, interpretación de mensajes a través de señales") {
                    history.push("/Dashboard")
                }
                if (userSuicida === "Nunca") {
                    history.push("/Dashboard")
                }
                if (userSuicida === "Desde hace un tiempo") {
                    history.push("/Dashboard")
                }
                if (userSuicida === "Desde hace dos semanas") {
                    history.push("/Dashboard")
                }
                if (userSuicida === "Justo ahora") {
                    history.push("/Dashboard")
                }
                
                if (userAnsiedad === "Si") {
                    history.push("/Dashboard")
                }
                if (userMedicacion === "Si") {
                    history.push("/Dashboard")
                }
                if (userMedicacion === "No") {
                    history.push("/Dashboard")
                }
                if (userPensamientosMalos === "Nunca") {
                    history.push("/Dashboard")
                }
                if (userPensamientosMalos === "Semanas") {
                    history.push("/Dashboard")
                }
                if (userPensamientosMalos === "Justo ahora") {
                    history.push("/Dashboard")
                }
                if (userPensamientosMalos === "No lo sé") {
                    history.push("/Dashboard")
                }
                if (userPensamientosMalos === "Nunca") {
                    history.push("/Dashboard")
                }
                if (userPensamientosMalos === "Tiempo") {
                    history.push("/Dashboard")
                }
                
                if (userPensamientosuici === "No lo sé") {
                    history.push("/Dashboard")
                }
                if (userPensamientosuici === "Nunca") {
                    history.push("/Dashboard")
                }
                if (userPensamientosuici === "Desde hace un tiempo") {
                    history.push("/Dashboard")
                }
                if (userPensamientosuici === "Desde hace menos de dos semanas") {
                    history.push("/Dashboard")
                }
                if (userPensamientosuici=== "Justo ahora") {
                    history.push("/Dashboard")
                }
            }).catch(function (error) {
                console.log("Error getting User:", error);
                alert(error);
            });

    }

    /////////////////////Individual/////////////////////
    const onclickti = (e) => {
        let Tipo = e
        if (Tipo === 'Tipo') {
            setUserTipo(Tipo)
            setFirstContainer(false)
        }
    }
    const onclickvolver = (e) => {

        if (e === true) {
            setUserTipo('N/A')
            setFirstContainer(true)
        }
        if (e === 'Tipo') {
            setUserIndi('N/A')
            setUserTipo(e)
        }
        if (e === 'Individual') {
            setUserSigui('N/A')
            setUserIndi(e)
        }
        if (e === 'Tipo') {
            setUserAdo('N/A')
            setUserTipo(e)
        }
    }
    const onclickin = (e) => {
        if (e === 'Individual') {
            setUserIndi(e)
            setUserTipo('N/A')
        }
    }
    const onclickSiguiente = (e) => {
        if (e === 'Si-no') {
            setUserSigui(e)
            setUserIndi('N/A')
        }

        if (e === 'Pais') {
            setUserSigui(e)
        }
        if (e === 'SaludM') {
            setUserSigui(e)
        }
    }
    const onclicksintomas = (e) => {
        let sin = e
        /////////////////////SI RESPONDE TRISTEZA/////////////////////
        if (sin === 'Tristeza') {
            setUserSigui(sin)
        }
        if (sin === 'Pensamientos') {
            setUserSigui(sin)
        }
        if (sin === 'Lastimarte') {
            setUserSigui(sin)
        }
        if (sin === 'Murieras') {
            setUserSigui(sin)
        }
        if (sin === 'Suicida') {
            setUserSigui(sin)
        }
        /////////////////////SI RESPONDE PREOCUPACION/////////////////////
        if (sin === 'Preocupacion') {
            setUserSigui(sin)
        }
        if (sin === 'Desempeno') {
            setUserSigui(sin)
        }
        if (sin === 'Problemas') {
            setUserSigui(sin)
        }
        if (sin === 'Cansado') {
            setUserSigui(sin)
        }
        if (sin === 'Apetito') {
            setUserSigui(sin)
        }
        if (sin === 'Vitalidad') {
            setUserSigui(sin)
        }
        if (sin === 'Ataque') {
            setUserSigui(sin)
        }
        if (sin === 'Medicacion') {
            setUserSigui(sin)
        }
    }
    /////////////////////Cierre individual/////////////////////
    const onclickAdole = (e) => {
        let sin = e
        /////////////////////SI RESPONDE soy padre/////////////////////
        if (sin === 'Adolecente') {
            setUserAdo(sin)
            setUserTipo('N/A')
        }
        if (sin === 'Paiss') {
            setUserAdo(sin)
        }
        if (sin === 'TipoAse') {
            setUserAdo(sin)
        }
        if (sin === 'RelacionAdo') {
            setUserAdo(sin)
        }
        if (sin === 'Edad') {
            setUserAdo(sin)
        }
        if (sin === 'Terapia') {
            setUserAdo(sin)
        }
        if (sin === 'Sueño') {
            setUserAdo(sin)
        }
        if (sin === 'Alimenticios') {
            setUserAdo(sin)
        }
        if (sin === 'Fobia') {
            setUserAdo(sin)
        }
        if (sin === 'Experimentado') {
            setUserAdo(sin)
        }
        if (sin === 'Situaciones') {
            setUserAdo(sin)
        }
        if (sin === 'Arrebatos') {
            setUserAdo(sin)
        }
        if (sin === 'Actividades') {
            setUserAdo(sin)
        }
        if (sin === 'Pensamientosui') {
            setUserAdo(sin)
        }
        /////////////////////SI RESPONDE soy Adolecente/////////////////////
        if (sin === 'SoyAdolecente') {
            setUserAdo("Paisss")
        }
       
        if (sin === 'Problemas') {
            setUserAdo(sin)
        }
        if (sin === 'PreguntaTe') {
            setUserAdo(sin)
        }
        if (sin === 'RelacionPadre') {
            setUserAdo(sin)
        }
        if (sin === 'SuicidaAdo') {
            setUserAdo(sin)
        }
    }

    const onCheck = (e) => {
        let res = e
        setuserProblemasEfre(res)
    }


    return (
        <div className="body">
            <div className="body_wrapper">
                {/*<CustomNavbar slogo="Terappia" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
                <form onSubmit={handleSubmit}>
                    <section className="prototype_service_info">

                        <div className="symbols-pulse active">
                            <div className="pulse-1"></div>
                            <div className="pulse-2"></div>
                            <div className="pulse-3"></div>
                            <div className="pulse-4"></div>
                            <div className="pulse-x"></div>
                        </div>

                        {firstContainer === true ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[0]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" >
                                        <button id="Pareja" onClick={(e) => onclickti('Tipo', e)} value="Tipo" Style="width:80%">Llenar formulario</button>
                                        <button id="Pareja" type="submit" onClick={() => setvalorEspecialista('Psicológo')} Style="width:80%">Omitir</button>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }

                        {userTipo === "Tipo" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[39]}</h2></Reveal>

                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickin("Individual", e)} value="Individual">
                                        <button
                                            id="Pareja"
                                            onClickCapture={(e) => setUserTipoA('Individual (Adulto)', e)}
                                            Style="width:80%">
                                            Individual (Adulto)
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center">
                                        <button id="Pareja" type="submit" onClick={() => setUserTipoA("Pareja")} onClickCapture={() => setvalorEspecialista("Psicólogo")} value="Pareja" Style="width:80%">Pareja</button>
                                    </div>
                                </Reveal>

                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClickCapture={(e) => onclickAdole("Adolecente", e)} value="Adolecente">
                                        <a
                                            id="Pareja"
                                            onClickCapture={(e) => setUserTipoA('Adolescente o Infantil', e)}
                           
                                            Style="width:80%">
                                            Adolescente o infantil
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickvolver(true, e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userIndi === "Individual" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[2]}</h2></Reveal>

                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickSiguiente('Si-no', e)} value="Si-no">
                                        <a id="Pareja" className="seo_btn seo_btn_one btn_hover" onClick={(e) => setUserEstadoPsico("Si", e)} Style="width:80%">Si</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickSiguiente('Si-no', e)} value="Si-no">
                                        <a id="Pareja" onClick={(e) => setUserEstadoPsico("No", e)} className="seo_btn seo_btn_one btn_hover" Style="width:80%">No</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickvolver('Tipo', e)} value="Tipo">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Si-no" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[3]}</h2></Reveal>

                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center ">
                                        <select className="select " id="borde" Style="width:60%"  type="select" name="Pais"
                                            onChange={(e) => setPais(e.target.value, e)}
                                            onChangeCapture={(e) => onclickSiguiente('Pais', e)}  >
                                            <option>Seleccionar</option>

                                            <option value="Afganistán">Afganistán</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Alemania">Alemania</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antártida">Antártida</option>
                                            <option value="Antigua y Barbuda">Antigua y Barbuda</option>
                                            <option value="Antillas Holandesas">Antillas Holandesas</option>
                                            <option value="Arabia Saudí">Arabia Saudí</option>
                                            <option value="Argelia">Argelia</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaiyán">Azerbaiyán</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrein">Bahrein</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Bélgica">Bélgica</option>
                                            <option value="Belice">Belice</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermudas">Bermudas</option>
                                            <option value="Bielorrusia">Bielorrusia</option>
                                            <option value="Birmania">Birmania</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia y Herzegovina">Bosnia y Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Brasil">Brasil</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Bután">Bután</option>
                                            <option value="Cabo Verde">Cabo Verde</option>
                                            <option value="Camboya">Camboya</option>
                                            <option value="Camerún">Camerún</option>
                                            <option value="Canadá">Canadá</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Chipre">Chipre</option>
                                            <option value="Ciudad del Vaticano (Santa Sede)">Ciudad del Vaticano (Santa Sede)</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comores">Comores</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, República Democrática">Congo, República Democrática</option>
                                            <option value="Corea">Corea</option>
                                            <option value="Corea del Norte">Corea del Norte</option>
                                            <option value="Costa de Marfíl">Costa de Marfíl</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Croacia (Hrvatska)">Croacia (Hrvatska)</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Dinamarca">Dinamarca</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egipto">Egipto</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Emiratos Árabes Unidos">Emiratos Árabes Unidos</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Eslovenia">Eslovenia</option>
                                            <option value="España" >España</option>
                                            <option value="Estados Unidos">Estados Unidos</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Etiopía">Etiopía</option>
                                            <option value="Francia">Francia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Grecia">Grecia</option>
                                            <option value="Groenlandia">Groenlandia</option>
                                            <option value="Guadalupe">Guadalupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guayana">Guayana</option>
                                            <option value="Guayana Francesa">Guayana Francesa</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea Ecuatorial">Guinea Ecuatorial</option>
                                            <option value="Haití">Haití</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hungría">Hungría</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Irak">Irak</option>
                                            <option value="Irán">Irán</option>
                                            <option value="Irlanda">Irlanda</option>
                                            <option value="Isla Bouvet">Isla Bouvet</option>
                                            <option value="Isla de Christmas">Isla de Christmas</option>
                                            <option value="Islandia">Islandia</option>
                                            <option value="Italia">Italia</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japón">Japón</option>
                                            <option value="Jordania">Jordania</option>
                                            <option value="Kazajistán">Kazajistán</option>
                                            <option value="Letonia">Letonia</option>
                                            <option value="Líbano">Líbano</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libia">Libia</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lituania">Lituania</option>
                                            <option value="Luxemburgo">Luxemburgo</option>
                                            <option value="Malasia">Malasia</option>
                                            <option value="México">México</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk">Norfolk</option>
                                            <option value="Noruega">Noruega</option>
                                            <option value="Nueva Caledonia">Nueva Caledonia</option>
                                            <option value="Nueva Zelanda">Nueva Zelanda</option>
                                            <option value="Omán">Omán</option>
                                            <option value="Países Bajos">Países Bajos</option>
                                            <option value="Panamá">Panamá</option>
                                            <option value="Papúa Nueva Guinea">Papúa Nueva Guinea</option>
                                            <option value="Paquistán">Paquistán</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Perú">Perú</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Polinesia Francesa">Polinesia Francesa</option>
                                            <option value="Polonia">Polonia</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reino Unido">Reino Unido</option>
                                            <option value="República Centroafricana">República Centroafricana</option>
                                            <option value="República Checa">República Checa</option>
                                            <option value="República de Sudáfrica">República de Sudáfrica</option>
                                            <option value="República Dominicana">República Dominicana</option>
                                            <option value="República Eslovaca">República Eslovaca</option>
                                            <option value="Reunión">Reunión</option>
                                            <option value="Ruanda">Ruanda</option>
                                            <option value="Rumania">Rumania</option>
                                            <option value="Rusia">Rusia</option>
                                            <option value="Sahara Occidental">Sahara Occidental</option>
                                            <option value="Saint Kitts y Nevis">Saint Kitts y Nevis</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Samoa Americana">Samoa Americana</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="San Vicente y Granadinas">San Vicente y Granadinas</option>
                                            <option value="Santa Helena">Santa Helena</option>
                                            <option value="Santa Lucía">Santa Lucía</option>
                                            <option value="Santo Tomé y Príncipe">Santo Tomé y Príncipe</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leona">Sierra Leona</option>
                                            <option value="Singapur">Singapur</option>
                                            <option value="Siria">Siria</option>
                                            <option value="omalia">Somalia</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="St Pierre y Miquelon">St Pierre y Miquelon</option>
                                            <option value="Suazilandia">Suazilandia</option>
                                            <option value="Sudán">Sudán</option>
                                            <option value="Suecia">Suecia</option>
                                            <option value="Suiza">Suiza</option>
                                            <option value="Surinam">Surinam</option>
                                            <option value="Tailandia">Tailandia</option>
                                            <option value="Taiwán">Taiwán</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Tayikistán">Tayikistán</option>
                                            <option value="Territorios franceses del Sur">Territorios franceses del Sur</option>
                                            <option value="Timor Oriental">Timor Oriental</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad y Tobago">Trinidad y Tobago</option>
                                            <option value="Túnez">Túnez</option>
                                            <option value="Turkmenistán">Turkmenistán</option>
                                            <option value="Turquía">Turquía</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Ucrania">Ucrania</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistán">Uzbekistán</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Yugoslavia">Yugoslavia</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabue">Zimbabue</option>
                                        </select>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center " onClick={(e) => onclickvolver('Individual', e)} value="Individual">
                                        <a className="seo_btn seo_btn_one_1  btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Pais" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[4]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickSiguiente('SaludM', e)} value="SaludM">
                                        <a id="Pareja" className="seo_btn seo_btn_one btn_hover" onClick={(e) => setUserSaludMental('Buena', e)} Style="width:80%">Buena</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickSiguiente('SaludM', e)} value="SaludM">
                                        <a id="Pareja" className="seo_btn seo_btn_one btn_hover" onClick={(e) => setUserSaludMental('Adecuada', e)} Style="width:80%">Adecuada</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickSiguiente('SaludM', e)} value="SaludM">
                                        <a id="Pareja" className="seo_btn seo_btn_one btn_hover" onClick={(e) => setUserSaludMental('Deficiente', e)} Style="width:80%">Deficiente</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickSiguiente('Si-no', e)} value="Pais">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>

                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "SaludM" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[5]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Tristeza', e)} value="Tristeza">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setUserParanoia(e.target.value, e)}
                                            value="Tristeza profunda, dolor o desesperanza"
                                            Style="width:80%">
                                            Tristeza profunda, dolor o desesperanza
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Preocupacion', e)} value="Preocupacion">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setUserParanoia(e.target.value, e)}
                                            value="Preocupación, angustia, ansiedad e irritabilidad"
                                            Style="width:80%">
                                            Preocupación, angustia, ansiedad e irritabilidad
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setUserParanoia(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            value="Paranoia, sensación de persecución, interpretación de mensajes a través de señales"
                                            Style="width:80%">
                                            Paranoia, sensación de persecución, interpretación de mensajes a través de señales
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickSiguiente('Pais', e)} value="Pais">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Tristeza" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[6]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Pensamientos', e)} value="Pensamientos">
                                        <a id="Pareja" className="seo_btn seo_btn_one btn_hover" onClick={(e) => setUserPocoInteres('Para nada', e)} Style="width:80%">Para nada</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Pensamientos', e)} value="Pensamientos">
                                        <a id="Pareja" className="seo_btn seo_btn_one btn_hover" onClick={(e) => setUserPocoInteres('Poco', e)} Style="width:80%">Poco</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >

                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Pensamientos', e)} value="Pensamientos">
                                        <a id="Pareja" className="seo_btn seo_btn_one btn_hover" onClick={(e) => setUserPocoInteres('Más de la mitad de los días', e)} Style="width:80%">Más de la mitad de los días</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >

                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Pensamientos', e)} value="Pensamientos">
                                        <a id="Pareja" className="seo_btn seo_btn_one btn_hover" onClick={(e) => setUserPocoInteres('Casi todos los días', e)} Style="width:80%">Casi todos los días</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickSiguiente('SaludM', e)} value="SaludM">
                                        <a id="Pareja" className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Pensamientos" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[7]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Lastimarte', e)} value="Lastimarte">
                                        <a
                                            id="Pareja"
                                            className="seo_btn seo_btn_one btn_hover"
                                            onClick={(e) => setuserMinusvalia('Para nada', e)}
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Lastimarte', e)} value="Lastimarte">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserMinusvalia('Poco', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Poco
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Lastimarte', e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserMinusvalia('Más de la mitad de los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Más de la mitad de los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Lastimarte', e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserMinusvalia('Casi todos los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Tristeza', e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Lastimarte" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[8]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Murieras', e)} value="Murieras">
                                        <a
                                            id="Pareja"
                                            className="seo_btn seo_btn_one btn_hover"
                                            onClick={(e) => setuserLastimarse('Para nada', e)}
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Murieras', e)} value="Murieras">
                                        <a
                                            id="Pareja"
                                            className="seo_btn seo_btn_one btn_hover"
                                            onClick={(e) => setuserLastimarse('Poco', e)}
                                            Style="width:80%">
                                            Poco
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Murieras', e)} value="Murieras">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserLastimarse('Más de la mitad de los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Más de la mitad de los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >

                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Murieras', e)} value="Murieras">
                                        <a
                                            id="Pareja"
                                            className="seo_btn seo_btn_one btn_hover"
                                            onClick={(e) => setuserLastimarse('Casi todos los días', e)}
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Pensamientos', e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Murieras" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[9]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Suicida', e)} value="Si-no">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserMurieras('Para nada', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Suicida', e)} value="Suicida">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserMurieras('Poco', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Poco
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >

                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Suicida', e)} value="Suicida">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserMurieras('Más de la mitad de los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Más de la mitad de los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} onClick={(e) => onclicksintomas('Suicida', e)} value="Suicida">

                                    <div className="col-lg-12 text-center">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserMurieras('Casi todos los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Lastimarte', e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Suicida" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[10]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" value="Si-no">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setUserSuicida(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psicólogo")}
                                            value="Nunca"
                                            Style="width:80%">
                                            Nunca
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setUserSuicida(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psicólogo")}
                                            value="Desde hace un tiempo"
                                            Style="width:80%">
                                            Desde hace un tiempo
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setUserSuicida(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            value="Desde hace menos de dos semanas"
                                            Style="width:80%">
                                            Desde hace menos de dos semanas
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setUserSuicida(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            value="Justo ahora"
                                            Style="width:80%">
                                            Justo ahora
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Murieras', e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Preocupacion" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[11]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Desempeno', e)} value="Desempeno">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserActividades('Para nada', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Desempeno', e)} value="Desempeno">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserActividades('Poco', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Poco
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >

                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Desempeno', e)} value="Desempeno">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserActividades('Más de la mitad de los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Más de la mitad de los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Desempeno', e)} value="Desempeno">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserActividades('Casi todos los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickSiguiente('SaludM', e)} value="Tristeza">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Desempeno" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[12]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Problemas', e)} value="Problemas">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserdesempeño('Para nada', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Problemas', e)} value="Problemas">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserdesempeño('Poco', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Poco</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >

                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Problemas', e)} value="Problemas">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserdesempeño('Mucho', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Mucho
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Problemas', e)} value="Problemas">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserdesempeño('En todo', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            En todo
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Preocupacion', e)} value="Preocupacion">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Problemas" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[13]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Cansado', e)} value="Cansado">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserDormido('Para nada', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Cansado', e)} value="Cansado">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserDormido('Poco', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Poco
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Cansado', e)} value="Cansado">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserDormido('Más de la mitad de los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Más de la mitad de los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Cansado', e)} value="Cansado">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserDormido('Casi todos los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Desempeno', e)} value="Desempeno">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Cansado" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[14]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Apetito', e)} value="Apetito">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserPocaenergia('Para nada', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Apetito', e)} value="Apetito">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserPocaenergia('Poco', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Poco
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Apetito', e)} value="Apetito">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserPocaenergia('Más de la mitad de los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Más de la mitad de los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Apetito', e)} value="Apetito">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserPocaenergia('Casi todos los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Problemas', e)} value="Problemas">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Apetito" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[15]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Vitalidad', e)} value="Vitalidad">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserApetito('Para nada', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Vitalidad', e)} value="Vitalidad">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserApetito('Poco', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Poco
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Vitalidad', e)} value="Vitalidad">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserApetito('Más de la mitad de los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Más de la mitad de los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Vitalidad', e)} value="Vitalidad">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserApetito('Casi todos los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Cansado', e)} value="Cansado">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Vitalidad" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[16]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Ataque', e)} value="Ataque">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserVitalidad('Para nada', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Ataque', e)} value="Ataque">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserVitalidad('Poco', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Poco
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Ataque', e)} value="Ataque">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserVitalidad('Más de la mitad de los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Más de la mitad de los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Ataque', e)} value="Ataque">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserVitalidad('Casi todos los días', e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Apetito', e)} value="Apetito">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Ataque" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[17]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" value="Vitalidad">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserAnsiedad(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            value="Si"
                                            Style="width:80%">
                                            Si
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Medicacion', e)} value="Vitalidad">
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserAnsiedad("No", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            No
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Vitalidad', e)} value="Vitalidad">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>

                            </div>
                            :
                            <span></span>
                        }
                        {userSigui === "Medicacion" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[18]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" value="Vitalidad">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserMedicacion(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            value="Si"
                                            Style="width:80%">
                                            Si
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" value="Vitalidad">
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserMedicacion(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psicólogo")}
                                            value="No"
                                            Style="width:80%">
                                            No
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclicksintomas('Ataque', e)} value="Ataque">
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Adolecente" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[19]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Paiss", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserPreguntaGe("Soy padre", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Soy padre
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}  >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("SoyAdolecente", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setUserAdo("Soy adolescente", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Soy adolescente
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickvolver("Tipo", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Paiss" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[3]}</h2></Reveal>

                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center">
                                        <select className="select" Style="width:60%" id="borde"  type="select" name="Pais"
                                            onChange={(e) => setuserPaiis(e.target.value, e)}
                                            onChangeCapture={(e) => onclickAdole('TipoAse', e)}  >
                                            <option>Seleccionar</option>

                                            <option value="Afganistán">Afganistán</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Alemania">Alemania</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antártida">Antártida</option>
                                            <option value="Antigua y Barbuda">Antigua y Barbuda</option>
                                            <option value="Antillas Holandesas">Antillas Holandesas</option>
                                            <option value="Arabia Saudí">Arabia Saudí</option>
                                            <option value="Argelia">Argelia</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaiyán">Azerbaiyán</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrein">Bahrein</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Bélgica">Bélgica</option>
                                            <option value="Belice">Belice</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermudas">Bermudas</option>
                                            <option value="Bielorrusia">Bielorrusia</option>
                                            <option value="Birmania">Birmania</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia y Herzegovina">Bosnia y Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Brasil">Brasil</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Bután">Bután</option>
                                            <option value="Cabo Verde">Cabo Verde</option>
                                            <option value="Camboya">Camboya</option>
                                            <option value="Camerún">Camerún</option>
                                            <option value="Canadá">Canadá</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Chipre">Chipre</option>
                                            <option value="Ciudad del Vaticano (Santa Sede)">Ciudad del Vaticano (Santa Sede)</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comores">Comores</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, República Democrática">Congo, República Democrática</option>
                                            <option value="Corea">Corea</option>
                                            <option value="Corea del Norte">Corea del Norte</option>
                                            <option value="Costa de Marfíl">Costa de Marfíl</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Croacia (Hrvatska)">Croacia (Hrvatska)</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Dinamarca">Dinamarca</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egipto">Egipto</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Emiratos Árabes Unidos">Emiratos Árabes Unidos</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Eslovenia">Eslovenia</option>
                                            <option value="España" >España</option>
                                            <option value="Estados Unidos">Estados Unidos</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Etiopía">Etiopía</option>
                                            <option value="Francia">Francia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Grecia">Grecia</option>
                                            <option value="Groenlandia">Groenlandia</option>
                                            <option value="Guadalupe">Guadalupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guayana">Guayana</option>
                                            <option value="Guayana Francesa">Guayana Francesa</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea Ecuatorial">Guinea Ecuatorial</option>
                                            <option value="Haití">Haití</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hungría">Hungría</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Irak">Irak</option>
                                            <option value="Irán">Irán</option>
                                            <option value="Irlanda">Irlanda</option>
                                            <option value="Isla Bouvet">Isla Bouvet</option>
                                            <option value="Isla de Christmas">Isla de Christmas</option>
                                            <option value="Islandia">Islandia</option>
                                            <option value="Italia">Italia</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japón">Japón</option>
                                            <option value="Jordania">Jordania</option>
                                            <option value="Kazajistán">Kazajistán</option>
                                            <option value="Letonia">Letonia</option>
                                            <option value="Líbano">Líbano</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libia">Libia</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lituania">Lituania</option>
                                            <option value="Luxemburgo">Luxemburgo</option>
                                            <option value="Malasia">Malasia</option>
                                            <option value="México">México</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk">Norfolk</option>
                                            <option value="Noruega">Noruega</option>
                                            <option value="Nueva Caledonia">Nueva Caledonia</option>
                                            <option value="Nueva Zelanda">Nueva Zelanda</option>
                                            <option value="Omán">Omán</option>
                                            <option value="Países Bajos">Países Bajos</option>
                                            <option value="Panamá">Panamá</option>
                                            <option value="Papúa Nueva Guinea">Papúa Nueva Guinea</option>
                                            <option value="Paquistán">Paquistán</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Perú">Perú</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Polinesia Francesa">Polinesia Francesa</option>
                                            <option value="Polonia">Polonia</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reino Unido">Reino Unido</option>
                                            <option value="República Centroafricana">República Centroafricana</option>
                                            <option value="República Checa">República Checa</option>
                                            <option value="República de Sudáfrica">República de Sudáfrica</option>
                                            <option value="República Dominicana">República Dominicana</option>
                                            <option value="República Eslovaca">República Eslovaca</option>
                                            <option value="Reunión">Reunión</option>
                                            <option value="Ruanda">Ruanda</option>
                                            <option value="Rumania">Rumania</option>
                                            <option value="Rusia">Rusia</option>
                                            <option value="Sahara Occidental">Sahara Occidental</option>
                                            <option value="Saint Kitts y Nevis">Saint Kitts y Nevis</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Samoa Americana">Samoa Americana</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="San Vicente y Granadinas">San Vicente y Granadinas</option>
                                            <option value="Santa Helena">Santa Helena</option>
                                            <option value="Santa Lucía">Santa Lucía</option>
                                            <option value="Santo Tomé y Príncipe">Santo Tomé y Príncipe</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leona">Sierra Leona</option>
                                            <option value="Singapur">Singapur</option>
                                            <option value="Siria">Siria</option>
                                            <option value="omalia">Somalia</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="St Pierre y Miquelon">St Pierre y Miquelon</option>
                                            <option value="Suazilandia">Suazilandia</option>
                                            <option value="Sudán">Sudán</option>
                                            <option value="Suecia">Suecia</option>
                                            <option value="Suiza">Suiza</option>
                                            <option value="Surinam">Surinam</option>
                                            <option value="Tailandia">Tailandia</option>
                                            <option value="Taiwán">Taiwán</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Tayikistán">Tayikistán</option>
                                            <option value="Territorios franceses del Sur">Territorios franceses del Sur</option>
                                            <option value="Timor Oriental">Timor Oriental</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad y Tobago">Trinidad y Tobago</option>
                                            <option value="Túnez">Túnez</option>
                                            <option value="Turkmenistán">Turkmenistán</option>
                                            <option value="Turquía">Turquía</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Ucrania">Ucrania</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistán">Uzbekistán</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Yugoslavia">Yugoslavia</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabue">Zimbabue</option>
                                        </select>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole('Adolecente', e)} >
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Paisss" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[3]}</h2></Reveal>

                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center">
                                        <select className="select" Style="width:60%" id="borde"  type="select" name="Pais"
                                            onChange={(e) => setuserPaiis(e.target.value, e)}
                                            onChangeCapture={() => setUserAdo('Edadd')}  >
                                            <option>Seleccionar</option>
                                            <option value="Afganistán">Afganistán</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Alemania">Alemania</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antártida">Antártida</option>
                                            <option value="Antigua y Barbuda">Antigua y Barbuda</option>
                                            <option value="Antillas Holandesas">Antillas Holandesas</option>
                                            <option value="Arabia Saudí">Arabia Saudí</option>
                                            <option value="Argelia">Argelia</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaiyán">Azerbaiyán</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrein">Bahrein</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Bélgica">Bélgica</option>
                                            <option value="Belice">Belice</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermudas">Bermudas</option>
                                            <option value="Bielorrusia">Bielorrusia</option>
                                            <option value="Birmania">Birmania</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia y Herzegovina">Bosnia y Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Brasil">Brasil</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Bután">Bután</option>
                                            <option value="Cabo Verde">Cabo Verde</option>
                                            <option value="Camboya">Camboya</option>
                                            <option value="Camerún">Camerún</option>
                                            <option value="Canadá">Canadá</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Chipre">Chipre</option>
                                            <option value="Ciudad del Vaticano (Santa Sede)">Ciudad del Vaticano (Santa Sede)</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comores">Comores</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, República Democrática">Congo, República Democrática</option>
                                            <option value="Corea">Corea</option>
                                            <option value="Corea del Norte">Corea del Norte</option>
                                            <option value="Costa de Marfíl">Costa de Marfíl</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Croacia (Hrvatska)">Croacia (Hrvatska)</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Dinamarca">Dinamarca</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egipto">Egipto</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Emiratos Árabes Unidos">Emiratos Árabes Unidos</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Eslovenia">Eslovenia</option>
                                            <option value="España" >España</option>
                                            <option value="Estados Unidos">Estados Unidos</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Etiopía">Etiopía</option>
                                            <option value="Francia">Francia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Grecia">Grecia</option>
                                            <option value="Groenlandia">Groenlandia</option>
                                            <option value="Guadalupe">Guadalupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guayana">Guayana</option>
                                            <option value="Guayana Francesa">Guayana Francesa</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea Ecuatorial">Guinea Ecuatorial</option>
                                            <option value="Haití">Haití</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hungría">Hungría</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Irak">Irak</option>
                                            <option value="Irán">Irán</option>
                                            <option value="Irlanda">Irlanda</option>
                                            <option value="Isla Bouvet">Isla Bouvet</option>
                                            <option value="Isla de Christmas">Isla de Christmas</option>
                                            <option value="Islandia">Islandia</option>
                                            <option value="Italia">Italia</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japón">Japón</option>
                                            <option value="Jordania">Jordania</option>
                                            <option value="Kazajistán">Kazajistán</option>
                                            <option value="Letonia">Letonia</option>
                                            <option value="Líbano">Líbano</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libia">Libia</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lituania">Lituania</option>
                                            <option value="Luxemburgo">Luxemburgo</option>
                                            <option value="Malasia">Malasia</option>
                                            <option value="México">México</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk">Norfolk</option>
                                            <option value="Noruega">Noruega</option>
                                            <option value="Nueva Caledonia">Nueva Caledonia</option>
                                            <option value="Nueva Zelanda">Nueva Zelanda</option>
                                            <option value="Omán">Omán</option>
                                            <option value="Países Bajos">Países Bajos</option>
                                            <option value="Panamá">Panamá</option>
                                            <option value="Papúa Nueva Guinea">Papúa Nueva Guinea</option>
                                            <option value="Paquistán">Paquistán</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Perú">Perú</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Polinesia Francesa">Polinesia Francesa</option>
                                            <option value="Polonia">Polonia</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reino Unido">Reino Unido</option>
                                            <option value="República Centroafricana">República Centroafricana</option>
                                            <option value="República Checa">República Checa</option>
                                            <option value="República de Sudáfrica">República de Sudáfrica</option>
                                            <option value="República Dominicana">República Dominicana</option>
                                            <option value="República Eslovaca">República Eslovaca</option>
                                            <option value="Reunión">Reunión</option>
                                            <option value="Ruanda">Ruanda</option>
                                            <option value="Rumania">Rumania</option>
                                            <option value="Rusia">Rusia</option>
                                            <option value="Sahara Occidental">Sahara Occidental</option>
                                            <option value="Saint Kitts y Nevis">Saint Kitts y Nevis</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Samoa Americana">Samoa Americana</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="San Vicente y Granadinas">San Vicente y Granadinas</option>
                                            <option value="Santa Helena">Santa Helena</option>
                                            <option value="Santa Lucía">Santa Lucía</option>
                                            <option value="Santo Tomé y Príncipe">Santo Tomé y Príncipe</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leona">Sierra Leona</option>
                                            <option value="Singapur">Singapur</option>
                                            <option value="Siria">Siria</option>
                                            <option value="omalia">Somalia</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="St Pierre y Miquelon">St Pierre y Miquelon</option>
                                            <option value="Suazilandia">Suazilandia</option>
                                            <option value="Sudán">Sudán</option>
                                            <option value="Suecia">Suecia</option>
                                            <option value="Suiza">Suiza</option>
                                            <option value="Surinam">Surinam</option>
                                            <option value="Tailandia">Tailandia</option>
                                            <option value="Taiwán">Taiwán</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Tayikistán">Tayikistán</option>
                                            <option value="Territorios franceses del Sur">Territorios franceses del Sur</option>
                                            <option value="Timor Oriental">Timor Oriental</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad y Tobago">Trinidad y Tobago</option>
                                            <option value="Túnez">Túnez</option>
                                            <option value="Turkmenistán">Turkmenistán</option>
                                            <option value="Turquía">Turquía</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Ucrania">Ucrania</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistán">Uzbekistán</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Yugoslavia">Yugoslavia</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabue">Zimbabue</option>
                                        </select>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole('Adolecente', e)} >
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>}
                        {userAdo === "TipoAse" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[37]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("RelacionAdo", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserTipoAsesoria("Quiero terapia para mi hijo/a", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Quiero terapia para mi hijo/a
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("RelacionAdo", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserTipoAsesoria("Busco asesoría para padres", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Busco asesoría para padres
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("RelacionAdo", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserTipoAsesoria("No estoy seguro aún", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            No estoy seguro aún
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Paiss", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "RelacionAdo" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[20]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Edad", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserRelacionado("Soy la madre", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Soy la madre
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Edad", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserRelacionado("Soy el Padre", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Soy el Padre
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Edad", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserRelacionado("Soy el representante legal", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Soy el representante legal
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Edad", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserRelacionado("Otro", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Otro
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("TipoAse", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Edad" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[21]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center">
                                        <div className="form-group" id="email">
                                            <select className="select" id="borde" Style="width:60%" type="select" name="Pais"
                                                onChange={(e) => setuserEdadHijo(e.target.value, e)}
                                                onChangeCapture={(e) => onclickAdole('Terapia', e)}  >
                                                <option>Seleccionar</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                            </select>
                                        </div>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("RelacionAdo", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Edadd" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">Indique su edad</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center">
                                        <div className="form-group" id="email">
                                            <select className="select" id="borde" Style="width:60%" type="select" name="Pais"
                                                onChange={(e) => setuserEdadHijo(e.target.value, e)}
                                                onChangeCapture={(e) => onclickAdole('Problemas', e)}  >
                                                <option>Seleccionar</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                            </select>
                                        </div>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Paisss", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Terapia" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[22]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Sueño", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserTerappiaHijo("Si", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Si
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Sueño", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserTerappiaHijo("No", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            No
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Edad", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Sueño" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[23]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Alimenticios", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserHabitoSueño("Ideales", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Ideales
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Alimenticios", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserHabitoSueño("Regulares", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Regulares
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Alimenticios", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserHabitoSueño("Malos", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Malos
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Terapia", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Alimenticios" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[24]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Fobia", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserHabitoAlimenticio("Ideales", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Ideales
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Fobia", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserHabitoAlimenticio("Regulares", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Regulares
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Fobia", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserHabitoAlimenticio("Malos", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">Malos
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Sueño", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Fobia" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[25]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Experimentado", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserFobia("Si", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Si
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Experimentado", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserFobia("No", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            No
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Alimenticios", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Experimentado" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[26]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Situaciones", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserDesesperanza("Para nada", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Situaciones", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserDesesperanza("A veces", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            A veces
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Situaciones", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserDesesperanza("Con mucha frecuencia", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Con mucha frecuencia
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Situaciones", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserDesesperanza("Casi todos los días", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Fobia", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Situaciones" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[27]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[28]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Arrebatos", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserPlacer("Para nada", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Arrebatos", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserPlacer("A veces", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            A veces
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Arrebatos", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserPlacer("Con mucha frecuencia", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Con mucha frecuencia
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Arrebatos", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserPlacer("Casi todos los días", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Experimentado", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Arrebatos" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[29]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Actividades", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserViolento("Para nada", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Actividades", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserViolento("A veces", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            A veces
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Actividades", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserViolento("Con mucha frecuencia", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Con mucha frecuencia
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Actividades", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserViolento("Casi todos los días", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Casi todos los días
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Situaciones", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Actividades" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[30]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Pensamientosui", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserProblemas("Para nada", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Para nada
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Pensamientosui", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserProblemas("A veces", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            A veces
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Pensamientosui", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserProblemas("Con mucha frecuencia", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Con mucha frecuencia
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Pensamientosui", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserProblemas("Casi todos los días", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">Casi todos los días</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Arrebatos", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Pensamientosui" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[31]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center"  >
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserPensasuici(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psicólogo")}
                                            value="No lo sé"
                                            Style="width:80%">
                                            No lo sé
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" >
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserPensasuici(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psicólogo")}
                                            value="Nunca"
                                            Style="width:80%">
                                            Nunca
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center"   >
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserPensasuici(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            value="Desde hace un tiempo"
                                            Style="width:80%">
                                            Desde hace un tiempo 
                                        </button>

                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center"   >
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserPensasuici(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            value="Desde hace menos de dos semanas"
                                            Style="width:80%">
                                            Desde hace menos de dos semanas
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center"  >
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            onClick={(e) => setuserPensasuici(e.target.value, e)}
                                            value="Justo ahora"
                                            Style="width:80%">
                                            Justo ahora
                                        </button>

                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Actividades", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "SoyAdolecente" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[32]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center">
                                        <select className="select " Style="width:60%" id="borde"  type="select" onChange={(e) => setuserEdadAdole(e.target.value, e)} onChangeCapture={(e) => onclickAdole('Problemas', e)}  >
                                            <option>Seleccionar</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                        </select>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Adolecente", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "Problemas" ?
                            <div className="container " Style="margin:auto%">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[33]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="row containerChebox mb-3">
                                        <div className="col-6">
                                            <div className="form-inline" >
                                                <div className="nameLabel"> <label>Estrés o ansiedad</label></div>
                                                <div> <input type="checkbox"
                                                    value="Estrés o ansiedad"
                                                    onChange={(e) => onCheck(e.target.value, e)}
                                                    className="seo_btn seo_btn_one btn_hover"
                                                    Style="margin:auto" /></div>

                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"> <label>Depresión</label></div>
                                                <div> <input type="checkbox"
                                                    onChange={(e) => onCheck(e.target.value, e)}
                                                    value="Depresión"
                                                    className="seo_btn seo_btn_one btn_hover"
                                                    Style="margin:auto" />
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="nameLabel"> <label>Bullying</label></div>
                                                <div><input onChange={(e) => onCheck(e.target.value, e)} type="checkbox" value="Bullying" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" />
                                                </div>
                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"><label>Retos escolares</label></div>
                                                <div><input className="mt-2" type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Retos escolares" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" /></div>

                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"> <label>Déficit de atención  </label></div>
                                                <div><input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Déficit de atención" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" />
                                                </div>
                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"><label>Adicción</label></div>
                                                <div><input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Adicción" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" />
                                                </div>
                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"> <label>Desordenes alimenticios</label></div>
                                                <div>                                                <input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Ataques de pánico" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-inline" >
                                                <div className="nameLabel"><label>Trauma o abuso</label></div>
                                                <div className="ml-1"><input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} className="seo_btn seo_btn_one btn_hover" Style="margin:auto" /></div>
                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"><label>Relación con los amigos</label></div>
                                                <div><input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Relación con los amigos" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" />
                                                </div>
                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"><label>Pérdida de un ser amado</label></div>
                                                <div><input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Pérdida de un ser amado" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" />
                                                </div>
                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"><label>Conflictos familiares</label></div>
                                                <div> <input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Conflictos familiares" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" />
                                                </div>
                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"> <label>Miedos o fobias</label></div>
                                                <div className="nameCheckbox"><input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Miedos o fobias" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" /></div>
                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"><label>Problemas de ira</label></div>
                                                <div>                                                <input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Problemas de ira" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" />
                                                </div>
                                            </div>
                                            <div className="form-inline" >
                                                <div className="nameLabel"> <label>Ataques de pánico</label></div>
                                                <div>                                                <input type="checkbox" onChange={(e) => onCheck(e.target.value, e)} value="Ataques de pánico" className="seo_btn seo_btn_one btn_hover" Style="margin:auto" />
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("PreguntaTe", e)}>
                                        <a id="Pareja" className="seo_btn seo_btn_one btn_hover" Style="width:80%">Siguiente</a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("SoyAdolecente", e)}>
                                        <a id="Pareja" className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>

                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "PreguntaTe" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[34]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("RelacionPadre", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserTerapiaAntes("Nunca", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Nunca
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600}>
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("RelacionPadre", e)} >
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserTerapiaAntes("Brevemente", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Brevemente
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("RelacionPadre", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserTerapiaAntes("Por pocas sesiones", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Por pocas sesiones
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("RelacionPadre", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserTerapiaAntes("Más de 10 sesiones", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Más de 10 sesiones
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("Problemas", e)}>
                                        <a
                                            className="seo_btn seo_btn_one_1 btn_hover"
                                            Style="width:80%">
                                            Pregunta anterior
                                        </a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "RelacionPadre" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[35]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("SuicidaAdo", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserCalificarRela("Buena", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Buena
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("SuicidaAdo", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserCalificarRela("Regular", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Regular
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("SuicidaAdo", e)}>
                                        <a
                                            id="Pareja"
                                            onClick={(e) => setuserCalificarRela("Mala", e)}
                                            className="seo_btn seo_btn_one btn_hover"
                                            Style="width:80%">
                                            Mala
                                        </a>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("PreguntaTe", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }
                        {userAdo === "SuicidaAdo" ?
                            <div className="container">
                                <Reveal effect={tipoEstilo} duration={1600}><h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb-30">{titulos[36]}</h2></Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("", e)}>
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserPensamientosMalos(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psicólogo")}
                                            value="Nunca"
                                            Style="width:80%">
                                            Nunca
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("", e)}>
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserPensamientosMalos("Tiempo")}
                                            onClickCapture={() => setvalorEspecialista("Psicólogo")}
                                            value="Tiempo"
                                            Style="width:80%">
                                            Desde hace un tiempo
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("", e)}>
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserPensamientosMalos("Semanas")}
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            value="Semanas"
                                            Style="width:80%">
                                            Desde hace menos de dos semanas
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("", e)}>
                                        <button
                                            id="Pareja"
                                            type="submit"
                                            onClick={(e) => setuserPensamientosMalos(e.target.value, e)}
                                            onClickCapture={() => setvalorEspecialista("Psiquiatra")}
                                            value="Justo ahora"
                                            Style="width:80%">
                                            Justo ahora
                                        </button>
                                    </div>
                                </Reveal>
                                <Reveal effect={tipoEstilo} duration={1600} >
                                    <div className="col-lg-12 text-center" onClick={(e) => onclickAdole("RelacionPadre", e)}>
                                        <a className="seo_btn seo_btn_one_1 btn_hover" Style="width:80%">Pregunta anterior</a>
                                    </div>
                                </Reveal>
                            </div>
                            :
                            <span></span>
                        }


                    </section>
                </form>
                {/* <HRService ServiceData={ServiceData}/> */}
                {/* <PrototypeService/> */}
                {/* <Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/> */}
                {/* <ServiceSubscribe sClass="s_form_info_two"/> */}
                {/* <FooterTwo fClass="pt_150" FooterData={FooterData}/> */}
            </div>
        </div>
    )
}
export default Service;