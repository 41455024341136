import React, { useState, useEffect } from "react";
import './Dashboard.css';
import { db } from "../firebase"
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { init } from 'emailjs-com';
import Checkbox from '../components/checkbox'
import moment from 'moment';
import HistoriaMedica from "./HistoriasMedicas";
import HeaderPrin from "./header"
import Menu from "./menu";


init("user_18jqcwflnGbkPgeaoNzWQ");
moment.locale('es');

export default function EmergenciasAgendas() {
    const history = useHistory()
    const { currentUser } = useAuth();
    const [UserNotifications, setUserNotifications] = useState([]);
    const [FormularioRecipes, setFormularioRecipes] = useState(true);
    const [nuevoDaosHora, setNuevodatoHora] = useState([]);
    const [AlmacenMSD5, setAlmacenMSD5] = useState("")
    const [Categorias, setCategorias] = useState("")
    const [AlmacenMSD5Final, setAlmacenMSD5Final] = useState("")
    const [Exito, setExito] = useState("")
    const [currentPerfil, setCurrentPerfil] = useState("");
    const [currentPerfilEspecia, setcurrentPerfilEspecia] = useState("");
    const [HistoryMedical, setHistoryMedical] = useState(false)
    const [busqueda, setBusqueda] = useState("");

    const getLinks = async () => {
        db.collection("Usuarios")
            .doc(currentUser.uid)
            .get().then(function (doc) {
                let users = doc.data()
                NotificacionesPaciente(users.uid);
                setcurrentPerfilEspecia(users)
                // db.collection("Patologias")
                //     .doc("CIE10_1")
                //     .get().then(function (doc) {
                //         let users = doc.data().patologias
                //         setAlmacenCIE10_1(users);
                //         console.log(AlmacenCIE10_1)
                //     })
                // db.collection("Patologias")
                //     .doc("CIE10_2")
                //     .get().then(function (doc) {
                //         let users = doc.data().patologias
                //         setAlmacenCIE10_2(users);
                //     })
                db.collection("Patologias")
                    .doc("DSM5")
                    .get().then(function (doc) {
                        let users = doc.data().patologias
                        let data = users;
                        let categoriaArray = [];
                        console.clear();
                        for (let i = 0; i < data.length; i++) {
                            let Newdata = {
                                Categoria: data[i].categoria
                            }
                            categoriaArray.push(Newdata);

                        }
                        let unicos = [];
                        for (var indice = 0; indice < categoriaArray.length; indice++) {
                            const persona = categoriaArray[indice];
                            let esDuplicado = false;
                            for (var i = 0; i < unicos.length; i++) {
                                if (unicos[i].Categoria === persona.Categoria) {
                                    esDuplicado = true;
                                    break;
                                }
                            }

                            if (!esDuplicado) {
                                unicos.push(persona);
                            }
                        }
                        let pacientes = unicos;
                        console.log(pacientes)

                        setCategorias(pacientes)

                    })

            }).catch(function (error) {
                console.log("Error getting User:", error);
                alert(error);
            });

    };

    useEffect(() => {
        getLinks();
    }, []);

    /////////////////Buscador////////////////////////////////////////
    const handleChange = e => {
        setBusqueda(e.target.value);
        filtrar(e.target.value);
    }
    const filtrar = (terminoBusqueda) => {
        var resultadosBusqueda = AlmacenMSD5.filter((elemento) => {
            // let nombre=elemento.nombres + elemento.apellidos
            let busqueda = terminoBusqueda.trim()
            console.log(busqueda)
            // console.log(nombre)
            if (elemento.Descripcion.toString().toLowerCase().includes(busqueda.toLowerCase())
            ) {
                return elemento;
            }
        });
        if (terminoBusqueda !== "") {
            setAlmacenMSD5(resultadosBusqueda);
        } else if (terminoBusqueda === "") {
            db.collection("Patologias")
                .doc("DSM5")
                .get().then(function (doc) {
                    let users = doc.data().patologias
                    let data = users;
                    let categoriaArray = [];
                    console.clear();
                    for (let i = 0; i < data.length; i++) {
                        let Newdata = {
                            Categoria: data[i].categoria,
                            Descripcion: data[i].descripcion
                        }
                        if (Newdata.Categoria === AlmacenMSD5Final) {
                            categoriaArray.push(Newdata);
                        }
                    }
                    setAlmacenMSD5(categoriaArray)
                    console.log(categoriaArray)
                })

        }
    }

    /////////////////Buscador////////////////////////////////////////

    function datosPaciente(e) {
        db.collection("Usuarios")
            .doc(e)
            .get().then(function (doc) {
                let users = doc.data()
                setCurrentPerfil(users);
            });
    }

    const NotificacionesPaciente = (uid) => {
        db.collection("SalaEspera").where("uid_especialista", "==", uid).where("paciente_abandona", "==", false).where("notificacion_especialista", "==", true)
            .onSnapshot(function (querySnapshot) {
                let Conferencias = [];
                querySnapshot.forEach(function (doc) {
                    Conferencias.push({ ...doc.data(), id: doc.id })

                });
                setUserNotifications(Conferencias)
            });
    }

    function consultaEmergencia(id) {
        db.collection("SalaEspera").doc(id).update({
            "especialista_acepto": true,
            "notificacion": true,
            "estatus":"Agendada"
        }).then(() => {
            setFormularioRecipes(false)
            alert("Estatus actualizado exitosamente!!!")
        })
    }
    function AceptaConfe(e) {
        window.open(
            e,
            '_blank'
        );
    }


    const [camporecipe, setcamporecipe] = useState("")
    function recipes() {
        setcamporecipe("1")
    }

    const [descripcionHistoria, setdescripcionHistoria] = useState("")
    const [descripcionRecipe, setdescripcionRecipe] = useState("")

    function historiaMedica() {
        db.collection("historialMedico").doc().set({
            descripcion: descripcionHistoria,
            tipo: 'Emergencia',
            nombre_especialista: currentPerfilEspecia.nombres,
            apellido_especialista: currentPerfilEspecia.apellidos,
            uid_especialista: currentPerfilEspecia.uid,
            uid_paciente: currentPerfil.uid,
            patologias: nuevoDaosHora,
            nombre_paciente: currentPerfil.nombres,
            apellido_paciente: currentPerfil.apellidos,
            fecha: moment().format('DD/MM/YYYY'),
            fecha_modificacion: "",
            fecha_creacion: moment().format('DD/MM/YYYY'),
        })
            .then(resp => {
                console.log(resp)
                setExito("Historial agregado")
                setcamporecipe("")
                history.push("/Dashboard")
                setHistoryMedical(true)
            }).catch((error) => {
                alert(error.message)
            })
        if (descripcionRecipe !== "") {
            db.collection("Recipes").doc().set({
                apellidos_especialista: currentPerfilEspecia.apellidos,
                fecha_creacion: moment().format('DD/MM/YYYY'),
                descripcion: descripcionRecipe,
                direccion_destino: "",
                email_destino: "",
                firma_especialista: currentPerfilEspecia.firma,
                sello_especialista: currentPerfilEspecia.sello,
                estatus: "Pendiente",
                fecha: moment().format('DD/MM/YYYY'),
                email_destino:currentPerfil.correo,
                nombres_especialista: currentPerfilEspecia.nombres,
                numero_destino: "",
                uid_especialista: currentPerfilEspecia.uid,
                uid_paciente: currentPerfil.uid,
                apellido_paciente: currentPerfil.apellidos,
                nombre_paciente: currentPerfil.nombres,
            })
                .then(resp => {
                    console.log(resp)
                    setExito("Historia y recipe agregado con éxito")
                   history.push("/Dashboard")
                }).catch((error) => {
                    alert(error.message)
                })
        }

    }

    const [Datospatologia, setDatosPatologia] = useState("")

    // function Patologias(e) {
    //     setActiveBoton(e)
    //     if (e === "DSM5") {
    //         setDatosPatologia(AlmacenMSD5)
    //     }
    //     else if (e === "CIE10_1") {
    //         setDatosPatologia(AlmacenCIE10_1)
    //     }

    // }
    const onCheckboxClicked = (id, isChecked, value) => {
        if (isChecked === true) {
            nuevoDaosHora.push(value)
            setNuevodatoHora([...nuevoDaosHora])
        }
        else if (isChecked === false) {
            for (let i = 0; i <= nuevoDaosHora.length; i++) {
                const nuevoStateHora = nuevoDaosHora[i]
                if (nuevoStateHora >= 0) {
                    nuevoDaosHora.push(value)
                }
            }
            let filtro = nuevoDaosHora.filter(number => number !== value)
            setNuevodatoHora([...filtro])
            console.log(nuevoDaosHora)
        }

    }
    function SelectCategotias(e) {
        setAlmacenMSD5Final(e)
        db.collection("Patologias")
            .doc("DSM5")
            .get().then(function (doc) {
                let users = doc.data().patologias
                let data = users;
                let categoriaArray = [];
                console.clear();
                for (let i = 0; i < data.length; i++) {
                    let Newdata = {
                        Categoria: data[i].categoria,
                        Descripcion: data[i].descripcion
                    }
                    if (Newdata.Categoria === e) {
                        categoriaArray.push(Newdata);
                    }
                }
                setAlmacenMSD5(categoriaArray)
                console.log(categoriaArray)
            })
    }

    return (
        <div>
              <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {HistoryMedical === false ?
                <div>
                    {UserNotifications.length===0?
                    <h2 className="dash-title a "><h1>Aún no tiene emergencias </h1></h2>
                    :
                    <div>
                     {UserNotifications.length ? (
                                UserNotifications.map(array => (
                                    <div>
                                    {array.estatus==="Completada"||array.notificacion_especialista===false?
                                        ""
                                        :
                                        <div>
                                        <h2 className="dash-title a "><h1>Emergencias</h1></h2>
                                        <div className="dash-cards">
                                            {UserNotifications.length ? (
                                                UserNotifications.map(array => (
                                                    <div className="">
                                                        <div className="card-single_eme">
                                                            <div className="card-body_eme">
                                                                <div className="updaPrin_1_eme">
                                                                    <div className="nombre_eme">{array.nombres_paciente}&nbsp;{array.apellidos_paciente}</div> <div><img src={array.foto_paciente} /></div>
                                                                </div>
                                                            </div>
                                                            <div className="horatarget_eme  text-center">
                                                                <h4>{array.hora_notificacion}</h4>
                                                                <div className="icono_llamada">
                                                                    <div onClick={() => AceptaConfe(array.room_link)}>
                                                                        <img onClick={() => consultaEmergencia(array.id)} onClickCapture={() => datosPaciente(array.uid_paciente)} src="Icon_call_SVG.svg" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {array.recordatorio === true ?
                                                            <div class="espera">
            
                                                                <div>
                                                                    <p>
                                                                        El paciente ha enviado {array.num_reintentar} recordatorio...
                                                                    </p>
                                                                </div>
            
                                                            </div>
                                                            : ""}
            
                                                    </div>
                                                ))
                                            ) : ""}
                                        </div>
                                    </div>
                                }
                                    </div>
                                
                                ))
                                ) 
                        :""}
                        </div>
                        }


                    {FormularioRecipes === false ?
                        <div>
                            <section className="text-center">
                                <div className="text-center">
                                    <div className="text-center">
                                        <div className="PanelDatos_info text-center">
                                            <h2 className="tituloInforme">Informe de consulta</h2>
                                            <div className="">
                                                <div className="inp">
                                                    <div className="col-lg-12">
                                                        <div className="form-group text_box">
                                                            <input type="text" id="name" name="name" value={currentPerfil.nombres} placeholder="Nombres" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group text_box">
                                                            <input type="text" id="subject" name="subject" value={currentPerfil.apellidos} placeholder="Descripción" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="tituloInforme_patologias">Patologías DSM5</h5>
                                                <div className="col-lg-12">

                                                    <div id="">
                                                        <select id="selectCate" onChange={(e) => SelectCategotias(e.target.value, e)} className="form-select" aria-label="Default select example">
                                                            <option>Categorias</option>
                                                            {Categorias.length ? (
                                                                Categorias.map(array => (

                                                                    <option value={array.Categoria}>{array.Categoria}</option>
                                                                ))
                                                            ) : ""
                                                            }
                                                        </select>
                                                    </div>
                                                    {/* <div className="header_tabla_patologias form-inline mb-4">
                                                        <div className={ActiveBoton == "CIE10_1" ? "col-6 botones_header_Activo b" : "col-6 botones_header b"} onClick={(e) => Patologias("CIE10_1", e)}>CIE-10</div>
                                                        <div className={ActiveBoton == "DSM5" ? "col-12 botones_header_Activo_medio c" : "col-12 botones_header_medio c"}>DSM5</div>
                                                    </div> */}
                                                    <div className="buscadorPtologias mb-4 form-inline header_tabla_patologias">
                                                        <img src="Buscar_SVG.svg" />
                                                        <input className="" placeholder="Buscar" type="seach" onChange={handleChange} />
                                                    </div>

                                                    <div className="PanelDatos_info_pato mt-4 mb-3 ">
                                                        {AlmacenMSD5.length ? (
                                                            AlmacenMSD5.map(array => (
                                                                <div className="DatosPatologia row">
                                                                    <div className="chekc col-2 mb-3">
                                                                        <Checkbox initialState={false} value={array.Descripcion} onChange={(onCheckboxClicked)} />
                                                                    </div>
                                                                    <div className="Name mb-3 col-10 text-left">
                                                                        {array.Descripcion}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : ""
                                                        }
                                                    </div>

                                                    {nuevoDaosHora.length ?
                                                        <div>
                                                            <h5 className="tituloInforme_patologias">Patologías agregadas</h5>
                                                            <div className="PanelDatos_info_pato mt-2 mb-3 text-center">

                                                                {nuevoDaosHora.length ? (
                                                                    nuevoDaosHora.map(array => (
                                                                        <div className="DatosPatologia row">
                                                                            <div className="mb-4">{array}</div>
                                                                        </div>
                                                                    ))
                                                                ) : ""}
                                                            </div>
                                                        </div>
                                                        : ""}
                                                </div>
                                                <div className="col-lg-12 mt-3">
                                                    <div className="form-group text_box TextRecipes">
                                                        <textarea onChange={(e) => setdescripcionHistoria(e.target.value)} name="message" id="" cols="30" rows="10" placeholder="Redactar informe . . ."></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    {currentPerfilEspecia.especialidad === "Psiquiatría" ?
                                                        <div>
                                                            {camporecipe === "" ? <div>
                                                                <div className="form-group ml-2 botonAgendar">
                                                                    <button className="btn" onClick={recipes}>Añadir recipe</button>
                                                                </div>
                                                            </div> : ""}
                                                        </div>
                                                        : ""}
                                                    {camporecipe !== "" ? <div>
                                                        <div className="form-group  botonAgendar ml-2">
                                                            <button className="btn" onClick={() => setcamporecipe("")}>Cancelar</button>
                                                        </div>
                                                        <div className="form-group text_box">
                                                            <textarea onChange={(e) => setdescripcionRecipe(e.target.value)} name="message" id="message" cols="30" rows="10" placeholder="Redactar recipe . . ."></textarea>
                                                        </div>
                                                    </div> : ""}
                                                </div>
                                                <div className="text-center botonAgendar"><button onClick={historiaMedica} className="btn">Guardar informe</button></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>


                        </div>
                        : ""}
                </div>
                : ""}
            
        </main>
        </div>  
            
            
        </div>

    )
}