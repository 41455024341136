import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase"
import { PayPalButton } from "react-paypal-button-v2";
import moment from 'moment';
import firebase from "../firebase"
import Menu from "./menu";
import HeaderPrin from "./header"

moment.locale('es');

export default function CambioEspecialistaInicio()
 {
    const [currentDatosEmergencia, setcurrenEmergencia] = useState([]);
    const history = useHistory()
    const { currentUser } = useAuth();
    const [currentPerfil, setCurrentPerfil] = useState("");
    const [currentDatosPaDe, setLinks] = useState([]);
    const [BotonPypal,setpypal]=useState("")
    const uidNew=Math.random().toString(20).substring(7);
    /**nuevas variables */



    const [mostrarPlanes, setmostrarPlanes] = useState(false)
    const [currentDatos_3, setcurrenDatos_3] = useState("");
    const [currentDatos_porce, setcurrenDatos_porce] = useState(0);

    const [Conferencia_1, setConferencias] = useState("");
    const [Reintentar, setReintentar] = useState(false)
    const [busqueda, setBusqueda]= useState("");
    const Emerge = async () => {
        
            db.collection("Usuarios")
                .doc(currentUser.uid)
                .get().then(function (doc) {
                    let users = doc.data()

                    let Pagada =doc.data().montoEmergenciaPagado
                    console.log(users)
                    setLinks(users);

                    db.collection("Usuarios").where("especialidad", "==", "Psiquiatría").where("disponible_emergencia", "==", true).where("activo", "==", true).where("aprobado", "==", true).get().then((querySnapshot) => {
                        const array_to = [];
                        querySnapshot.forEach((doc) => {
                            let use = doc.data()
                            if(use.precio_emergencia<=Pagada){
                            console.log(use)
                            array_to.push(use);
                        }
                        });
                        setcurrenEmergencia(array_to);
                    });

                });
        


    }
    /////////////////Buscador////////////////////////////////////////
const handleChange=e=>{
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  }
  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=currentDatosEmergencia.filter((elemento)=>{
      // let nombre=elemento.nombres + elemento.apellidos
      let busqueda=terminoBusqueda.trim()
      console.log(busqueda)
      // console.log(nombre)
      if(elemento.nombres.toString().toLowerCase().includes(busqueda.toLowerCase())
      ||elemento.apellidos.toString().toLowerCase().includes(busqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    if(terminoBusqueda!==""){
        setcurrenEmergencia(resultadosBusqueda);
  }else if(terminoBusqueda===""){
    db.collection("Usuarios")
                .doc(currentUser.uid)
                .get().then(function (doc) {
                    let users = doc.data()
                    let Pagada =doc.data().montoEmergenciaPagado
                    setLinks(users);

                    db.collection("Usuarios").where("especialidad", "==", "Psiquiatría").where("precio_emergencia", "==", Pagada).where("disponible_emergencia", "==", true).where("activo", "==", true).where("aprobado", "==", true).get().then((querySnapshot) => {
                        const array_to = [];
                        querySnapshot.forEach((doc) => {
                            let use = doc.data()
                            array_to.push(use);
                        });
                        setcurrenEmergencia(array_to);
                    });

                });
  }
  }

/////////////////Buscador////////////////////////////////////////
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            db.collection("Usuarios")
                .doc(user.uid)
                .get().then(function (doc) {
                    let users = doc.data()
                    users.$key = doc.id
                    setcurrenDatos_3(users);
                });
        })
        db.collection("configuracion")
            .doc("am8lciDPAWtxD1tzDyik")
            .get().then(function (doc) {
                let users = doc.data().PorcentajesGanancia

                setcurrenDatos_porce(users);
                // GET PLANES
            });
      


        // GET PLANES
        Emerge();
    }, []);



    

    const jitsiContainerStyle = {
        width: '100%',
        height: '100%',
    }




    const clMedicalal = (e, nombres, apellidos,precio, foto) => {
        let r = Math.random().toString(36).substring(7);
        let link = 'https://meet.jit.si' + '/' + currentUser.uid + r
        db.collection("SalaEspera").doc(uidNew).set({
            uid:uidNew,
            fecha_notificacion: moment().format("DD/MM/YYYY"),
            especialista_acepto: false,
            notificacion: true,
            paciente_abandona: false,
            fecha_noti: new Date(),
            uid_especialista: e,
            fecha_creacion: moment().format("DD/MM/YYYY"),
            fecha: moment().format("DD/MM/YYYY"),
            foto_paciente: currentDatosPaDe.foto_personal,
            foto_especialista: foto,
            nombres_paciente: currentDatosPaDe.nombres,
            apellidos_paciente: currentDatosPaDe.apellidos,
            nombre_especialista: nombres,
            apellido_especialista: apellidos,
            uid_paciente: currentUser.uid,
            room_link: link,
            recordatorio: false,
            num_reintentar: 0,
            hora_notificacion: moment().format("hh:mm a"),
            fecha_notificacion: moment().format("DD/MM/YYYY")
        }).then((resp) => {
            db.collection("HistorialPagos").doc(currentDatosPaDe.uid_pago_emergencia).update({
                nombre_especialista: nombres,
                uid_especialista:e ,
                apellido_especialista: apellidos,
                monto_emergencia_especialista: precio,
            }).then(() => {
                history.push("/DoctorParaEmergencia")
                window.location.reload();
            })
        })
        
    };

    const [misHorarios, setMishorarios] = useState("");
    function ObtenerDatos(e) {
       history.push("")
    }
    function ObtenerId(e) {
        
    }
   
    return (
        <>
             <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {mostrarPlanes === false ?
                <div>
                    <section className="recent">
                        <div className="activity-card">
                            <h2 className="dash-title">Elija su especialista</h2>
                            <div className="form-inline headerPrincipal_tabla">
                                <div className="buscador_emer">
                                    <div className="ml-5">
                                        <img src="Buscar_SVG.svg" className="mr-2" />
                                        <input className="" onChange={handleChange} placeholder="Buscar" type="seach" />
                                    </div>
                                </div>

                            </div>
                            <div className="cuadro_tabla">
                                <div className="table-responsive mt-2">
                                    <div className="table_1">
                                        <table className="table">

                                            <thead>
                                                <tr>
                                                    <th><img src="View_profile_SVG.svg" /></th>
                                                    <th>Nombres</th>
                                                    <th>Apellidos</th>
                                                    <th>Profesión</th>
                                                    <th>Especialidades</th>
                                                    <th></th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentDatosEmergencia.length ? (
                                                    currentDatosEmergencia.map(array => (
                                                        <tr>
                                                            <td className="">
                                                                <div className="updaPrin_tabla"><img src={array.foto_personal} onClickCapture={() => ObtenerDatos(array.uid)}></img></div>
                                                            </td>
                                                            <td>{array.nombres}</td>
                                                            <td>{array.apellidos}</td>
                                                            <td>{array.especialidad}</td>
                                                            <td>
                                                                <div className="form-inline text-center mb-2 iconosTerapias">
                                                                    <div className="ml-1">{array.tipos_terapia[3].estado === false ? <img src="parejas_off_SVG.svg" title="Terapia de pareja" /> : <img src="parejas_on_SVG.svg" title="Terapia de pareja" />}</div>
                                                                    <div className="ml-1">{array.tipos_terapia[0].estado === false ? <img src="infanto_off_SVG.svg" title="Infanto-juvenil" /> : <img src="infanto_on_SVG.svg" title="Infanto-juvenil" />}</div>
                                                                    <div className="ml-1">{array.tipos_terapia[1].estado === false ? <img src="geriatrico_off_SVG.svg" title="Geriátrico" /> : <img src="geriatrico_on_SVG.svg" title="Geriátrico" />}</div>
                                                                    <div className="ml-1 mr-1">{array.tipos_terapia[2].estado === false ? <img src="adulto_off_SVG.svg" title="Adulto" /> : <img src="adulto_on_SVG.svg" title="Adulto" />}</div>
                                                                    <div className="">{array.tipos_terapia[4].estado === false ? <img src="Adicciones_off_SVG.svg" title="Adicciones" /> : <img src="Adicciones_on_SVG.svg" title="Adicciones" />}</div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                   
                                                                    <div className="card-footer_1 botonAgendar">
                    <button className="btn" onClick={() => clMedicalal(array.uid,array.nombres,array.apellidos,array.precio_emergencia,array.foto_personal)}> Agendar cita</button>
                  </div>
                                                                </div>
                                                            </td>
                                                            
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <p></p>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                : ""}
        </main>
        </div>  

            



        </>

    )
}
