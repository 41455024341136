import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { db } from "../firebase"
import 'moment/locale/es'
import moment from 'moment';
import Menu from "./menu";
import HeaderPrin from "./header"
moment.locale('es');


export default function PorcentajesPrecio() {
    const [currentDatosPaDe, setLinks] = useState([]);
    const [currentDatosPlanes, setLinksPlanes] = useState([]);
    const [Planes, setPanes] = useState([]);
    const [PlanesPrecios, setPanesPlanes] = useState([]);
    const [PlanesPreciosDescuento, setPlanesDescuento] = useState([]);
    const [Exito, setExito] = useState("")
    const [ExitoDescuento, setExitoDescuento] = useState("")
    const [ExitoPlanes, setExitoPlanes] = useState("")
    const [datoUID, setUID] = useState("1")
    const [datoHistorial, setHistorial] = useState("")
    const [Error, setError] = useState("")
    const history = useHistory("")
    const [Porce, setPorce] = useState("")
    const [MinCon, setMinCon] = useState("")
    const [MinEn, setMinEn] = useState("")

    const [basico, setbasico] = useState("")
    const [estandar, setestandar] = useState("")
    const [premiun, setpremiun] = useState("")
    const [descuentobasico, setdescuentobasico] = useState("")
    const [descuentoestandar, setdescuentoestandar] = useState("")
    const [descuentopremiun, setdescuentopremiun] = useState("")

    useEffect(() => {
        db.collection("configuracion")
            .doc("am8lciDPAWtxD1tzDyik")
            .get().then((doc) => {
                let users = doc.data()
                setLinks(users)
                setPorce(users.PorcentajesGanancia)
                setMinCon(users.minimoConsulta)
                setMinEn(users.minimoEmergencia)
            });

        db.collection("Planes")
            .doc("Hi86vjpXy6k9CcfscWvf")
            .get().then((doc) => {
                let user = doc.data()
                setLinksPlanes(user)
                setbasico(user.precioPlan[0])
                setestandar(user.precioPlan[1])
                setpremiun(user.precioPlan[2])

                setdescuentobasico(user.descuentoConsultas[0])
                setdescuentoestandar(user.descuentoConsultas[1])
                setdescuentopremiun(user.descuentoConsultas[2])
            });


    }, []);

    const [Tabla, setTabla] = useState("")
    const [Edi, setTEdi] = useState("")

    function PlanesDatos(e) {
        setTabla("1")
        db.collection("configuracion").onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setPanes(docs);
        });
    }
    function PlanesDatosPrecios(e) {
        setTabla("2")
        db.collection("Planes").onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setPanesPlanes(docs);
        });
    }
    function PlanesDatosdescuentoPlanes(e) {
        setTabla("3")
        db.collection("Planes").onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setPlanesDescuento(docs);
        });
    }

    function EditarPrecios(e) {
        db.collection("configuracion").doc(e).update({
            "PorcentajesGanancia":parseInt(Porce) ,
            "minimoConsulta":parseInt(MinCon) ,
            "minimoEmergencia":parseInt(MinEn) 
        }).then(() => {
            history.push("/Porcentajes")
            setExito("Este perfil fue actualizado con éxito")
            setTimeout(res => { setExito(true) }, 3000);
            setUID("1")
            setTabla("")
        })
            .catch(() => {
                setError("No se pudo actualizar la cuenta")
            })
    }

    function EditarPreciosPlanes() {
        db.collection("Planes").doc("Hi86vjpXy6k9CcfscWvf").update(
            {
                "precioPlan": [parseInt(basico),parseInt(estandar) ,parseInt(premiun),
                ],

            }).then(() => {
                history.push("/Porcentajes")
                setExitoPlanes("Este perfil fue actualizado con éxito")
                setTimeout(res => { setExitoPlanes(true) }, 3000);
                setUID("2")
                setTabla("")
            })
            .catch(() => {
                setError("No se pudo actualizar la cuenta")
            })
    }

    function EditarPreciosdescuentoPlanes() {
        db.collection("Planes").doc("Hi86vjpXy6k9CcfscWvf").update(
            {
                "descuentoConsultas": [parseInt(descuentobasico),parseInt(descuentoestandar),parseInt(descuentopremiun),],
            }).then(() => {
            history.push("/Porcentajes")
            setExitoDescuento("Este perfil fue actualizado con éxito")
            setTimeout(res => {setExitoDescuento(true) }, 3000);
            setUID("3")
                setTabla("")
        })
            .catch(() => {
                setError("No se pudo actualizar la cuenta")
            })
    }
    const [ActiveBoton, setActiveBoton] = useState("1")
    function Cambiar(e){
        setActiveBoton(e)
        setTabla("")
        setUID(e)
    }

    return (
        <>
           <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        
        
            <div className="HeaderPrecioAdmin">
            <div className="header_tabla form-inline ">
                <div className={ActiveBoton == "1" ? "col-4 botones_header_Activo a" : "col-4 botones_header a"} onClick={() => Cambiar("1")}>Precios terappiaweb</div>
                <div className={ActiveBoton == "2" ? "col-4 botones_header_Activo_medio b" : "col-4 botones_header b"} onClick={() => Cambiar("2")}>Precios planes</div>
                <div className={ActiveBoton == "3" ? "col-4 botones_header_Activo_right c" : "col-4 botones_header c"} onClick={() => Cambiar("3")}>Descuento planes</div>
              </div>
              </div>
            {datoUID === "1" ?
                <div>
                <h2>Configuracion de precios </h2>
                    <div className="cuadro_tabla">

                        <div className="table-responsive">
                            {Tabla === "" ?
                                <div className="table_1">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Porcentaje sistema</th>
                                                <th>Min Consulta</th>
                                                <th>Min Emergencia</th>
                                                <th>Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{currentDatosPaDe.PorcentajesGanancia}%</td>
                                                <td>{currentDatosPaDe.minimoConsulta}$</td>
                                                <td>{currentDatosPaDe.minimoEmergencia}$</td>
                                                <td className="agendar"><button onClick={(e) => PlanesDatos(currentDatosPaDe.tipo_plan, e)} className="btn">Mostrar</button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                : ""}
                            {Tabla === "1" ?
                                <div className="table_1">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Precios</th>
                                                <th>Consulta Minima</th>
                                                <th>Emergencia Minima</th>
                                                <th>Accion</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Planes.length ? (
                                                Planes.map(array => (
                                                    <>
                                                        <tr>
                                                            <td><input type="text" onChange={(e) => setPorce(e.target.value)} className="form-control text-center" defaultValue={array.PorcentajesGanancia}></input>%</td>
                                                            <td><input type="text" onChange={(e) => setMinCon(e.target.value)} className="form-control text-center" defaultValue={array.minimoConsulta}></input>$</td>
                                                            <td><input id="eme" type="text" onChange={(e) => setMinEn(e.target.value)} className="form-control text-center" defaultValue={array.minimoEmergencia}></input>$</td>
                                                            <td className="agendar"><button onClick={(e) => EditarPrecios(array.id, e)} className="btn">Actualizar</button></td>
                                                        </tr>
                                                    </>
                                                )
                                                )
                                            ) : (
                                                <p></p>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                : ""}

                        </div>
                    </div>
                    {Exito && <div variant="success">{Exito}</div>}
                </div>
                : ""}
            {datoUID === "2" ?
                <div>
                    <h2>Configuracion de precios planes</h2>
                    <div className="cuadro_tabla">

                        <div className="table-responsive">
                            {Tabla === "" ?
                                <div className="table_1">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Básico</th>
                                                <th>Estandar</th>
                                                <th>Premium</th>
                                                <th>Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{basico}$</td>
                                                <td>{estandar}$</td>
                                                <td>{premiun}$</td>
                                                <td className="agendar"><button onClick={(e) => PlanesDatosPrecios(currentDatosPaDe.tipo_plan, e)} className="btn">Mostrar</button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                : ""}
                            {Tabla === "2" ?
                                <div className="table_1">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Básico</th>
                                                <th>Estandar</th>
                                                <th>Premium</th>
                                                <th>Accion</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {PlanesPrecios.length ? (
                                                PlanesPrecios.map(array => (
                                                    <>
                                                        <tr>
                                                            <td><input type="text" onChange={(e) => setbasico(e.target.value)} className="form-control text-center" defaultValue={array.precioPlan[0]}></input>%</td>
                                                            <td><input type="text" onChange={(e) => setestandar(e.target.value)} className="form-control text-center" defaultValue={array.precioPlan[1]}></input>$</td>
                                                            <td><input id="eme" type="text" onChange={(e) => setpremiun(e.target.value)} className="form-control text-center" defaultValue={array.precioPlan[2]}></input>$</td>
                                                            <td className="agendar"><button onClick={() => EditarPreciosPlanes()} className="btn">Actualizar</button></td>
                                                        </tr>
                                                    </>
                                                )
                                                )
                                            ) : (
                                                <p></p>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                : ""}

                        </div>
                    </div>
                    {ExitoPlanes && <div variant="success">{ExitoPlanes}</div>}
                </div>
                : ""}

            {datoUID === "3" ?
                <div>
                    <h2>Configuracion de precios descuento planes</h2>
                    <div className="cuadro_tabla">
                        <div className="table-responsive">
                            {Tabla === "" ?
                                <div className="table_1">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Básico</th>
                                                <th>Estandar</th>
                                                <th>Premium</th>
                                                <th>Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{descuentobasico}%</td>
                                                <td>{descuentoestandar}%</td>
                                                <td>{descuentopremiun}%</td>
                                                <td className="agendar"><button onClick={(e) => PlanesDatosdescuentoPlanes(currentDatosPaDe.tipo_plan, e)} className="btn">Mostrar</button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                : ""}
                            {Tabla === "3" ?
                                <div className="table_1">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Básico</th>
                                                <th>Estandar</th>
                                                <th>Premium</th>
                                                <th>Accion</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {PlanesPreciosDescuento.length ? (
                                                PlanesPreciosDescuento.map(array => (
                                                    <>
                                                        <tr>
                                                            <td><input type="text" onChange={(e) => setdescuentobasico(e.target.value)} className="form-control text-center" defaultValue={array.descuentoConsultas[0]}></input>%</td>
                                                            <td><input type="text" onChange={(e) => setdescuentoestandar(e.target.value)} className="form-control text-center" defaultValue={array.descuentoConsultas[1]}></input>$</td>
                                                            <td><input id="eme" type="text" onChange={(e) => setdescuentopremiun(e.target.value)} className="form-control text-center" defaultValue={array.descuentoConsultas[2]}></input>$</td>
                                                            <td className="agendar"><button onClick={(e) => EditarPreciosdescuentoPlanes(array.id, e)} className="btn">Actualizar</button></td>
                                                        </tr>
                                                    </>
                                                )
                                                )
                                            ) : (
                                                <p></p>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                : ""}

                        </div>
                    </div>
                    {ExitoDescuento && <div variant="success">{ExitoDescuento}</div>}
                </div>
                : ""}
    
    </main>
        </div>  
        </>


    )
}