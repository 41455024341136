import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { faTrash ,faPowerOff, faBars, faBell, faCommentAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap"
import { db } from "../firebase"
import firebase from "../firebase"
import 'moment/locale/es'
import moment from 'moment';





moment.locale('es');


export default function AdminCL() {
  const [error, setError] = useState("");
  const { logout } = useAuth("");
  const [currentDatos, setcurrenDatos] = useState("");
  const history = useHistory("")
  const [currentDatosPaDe, setLinks] = useState([]);
  const [Exito, setExito] = useState("")
  
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      db.collection("Usuarios")
        .doc(user.uid)
        .get().then(function (doc) {
          let users = doc.data()
          setcurrenDatos(users);
        });
    })
    db.collection("Recipes").onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setLinks(docs);
    });
  }, []);



  function aprobar(id,Aprobado) {
    db.collection("Recipes").doc(id).update({
      "estatus": Aprobado,
    }).then(() => {
      history.push("/AdminCL")
      setExito("Usuario aprobado con éxito")
    })
  }


  async function handleLogout() {
    setError("")
    try {
      await logout()
      history.push("/")
    } catch {
      setError("No se pudo cerrar la sesión")
    }
  }



  function quitar() {
    setExito("")
  }
  const [ActiveBoton, setActiveBoton] = useState("")
  
  function Active(e){
      setActiveBoton(e)
  }


  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <input type="checkbox" id="sidebar-toggle" />

      <div className="sidebar">
      <div className=" form-inline  mb-5 mt-3"id="Menus">
                    <h3 className="brand">
                        <span ><img src="/logo_sidebar_128.png" /></span>
          </h3>
          <label htmlFor="sidebar-toggle"><FontAwesomeIcon icon={faBars} /></label>
        </div>

        <div className="sidebar-menu" id="sidebar">
          <ul>
            <li onClick={() => Active("")} className={ActiveBoton===""?"active":""}>
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.74 62.8"><g id="Capa_2" data-name="Capa 2"><g id="Icons"><path d="M64.64,33.2H57.23a11.24,11.24,0,0,0-2.92.39,11.08,11.08,0,0,0-9.89-6.08H31.32a11.1,11.1,0,0,0-9.9,6.08,11.17,11.17,0,0,0-2.91-.39H11.09A11.1,11.1,0,0,0,0,44.29V56.14A6.67,6.67,0,0,0,6.66,62.8H69.08a6.67,6.67,0,0,0,6.66-6.66V44.29A11.1,11.1,0,0,0,64.64,33.2ZM20.22,38.6V58.36H6.66a2.21,2.21,0,0,1-2.22-2.22V44.29a6.65,6.65,0,0,1,6.65-6.65h7.42a6.7,6.7,0,0,1,1.74.23C20.23,38.11,20.22,38.35,20.22,38.6ZM51.08,58.36H24.66V38.6a6.67,6.67,0,0,1,6.66-6.66h13.1a6.67,6.67,0,0,1,6.66,6.66ZM71.3,56.14a2.22,2.22,0,0,1-2.22,2.22H55.52V38.6c0-.25,0-.49,0-.73a6.64,6.64,0,0,1,1.74-.23h7.41a6.66,6.66,0,0,1,6.66,6.65Z" /><path d="M14.8,12.24a9.86,9.86,0,1,0,9.86,9.86A9.88,9.88,0,0,0,14.8,12.24Zm0,15.28a5.42,5.42,0,1,1,5.42-5.42A5.42,5.42,0,0,1,14.8,27.52Z" /><path d="M37.87,0A13.17,13.17,0,1,0,51,13.17,13.19,13.19,0,0,0,37.87,0Zm0,21.9a8.73,8.73,0,1,1,8.73-8.73A8.73,8.73,0,0,1,37.87,21.9Z" /><path d="M60.94,12.24a9.86,9.86,0,1,0,9.85,9.86A9.87,9.87,0,0,0,60.94,12.24Zm0,15.28a5.42,5.42,0,1,1,5.42-5.42A5.42,5.42,0,0,1,60.94,27.52Z" /></g></g></svg>
                <span>Pacientes</span>
              </div>
            </li>
            <li id="cerrar" onClick={handleLogout} >
              <div>
                <FontAwesomeIcon className="mr-3" icon={faPowerOff} /><span >Cerrar sesíon</span>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <div className="main-content">
        <header className="row" >
          <div className="search-wrapper">
          </div>
          <div className="social-icons">
            <span className="ti-bell"><FontAwesomeIcon icon={faBell} /></span>
            <span className="ti-comment"><FontAwesomeIcon icon={faCommentAlt} /></span>
            <div></div>&nbsp;{currentDatos.nombres}
          </div>
        </header>
          <main>
          
              <h3 className="c"><h1>Recipes médico</h1></h3>
             <div className="cuadro_tabla">
              <div className="table-responsive">
              <div className="table_1">
                    <table className="table">
                      <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Fecha</th>
                      <th>Dirección</th>
                      <th>Estatus</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentDatosPaDe.length ? (
                      currentDatosPaDe.map(array => (
                        <tr>
                          <td>{array.nombre_paciente}&nbsp;{array.apellido_paciente}</td>
                          <td> {array.fecha}</td>
                          <td>{array.direccion_destino}</td>
                          <td>{array.estatus}</td>
                          <td className="agendar">
                          <div className="form-inline">
                        <div className="col-4 divicon_1" id="ini">
                          <img className="icon_1" src="/Apro_icon_20.png" title="Completado" onClick={()=>aprobar(array.id,"Completado")} />
                        </div>
                        <div className="col-4 text-center divicon_2" >
                          <img className=" icon_2" src="/Repro_icon_20.png" title="En proceso" onClick={()=>aprobar(array.id,"En proceso")}/>
                        </div>
                        <div className="col-4 text-right divicon_3">
                          <img className="icon_3" src="/Cancel_icon_20.png" title="Cancelar" onClick={()=>aprobar(array.id,"Cancelado")}/>
                        </div>
                      </div>
                          </td>
                         
                        </tr>
                      )
                      )
                    ) : (
                      <p></p>
                    )}
                  </tbody>

                </table>
              </div>
              </div>
            </div>
            {Exito && <div variant="success">{Exito}&nbsp;&nbsp;<FontAwesomeIcon icon={faTrash} onClick={quitar} /></div>}
         
          </main>
      </div>
      
     
    </>


  )
}