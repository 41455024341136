import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory, Link } from "react-router-dom";
import './Dashboard.css';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap"
import { db } from "../firebase"
import firebase from "../firebase"
import 'moment/locale/es'
import moment from 'moment';
import Menu from "./menu";
import HeaderPrin from "./header"

moment.locale('es');


export default function Admin() {
  const [error, setError] = useState("");
  const { logout } = useAuth("");
  const [currentDatos, setcurrenDatos] = useState("");
  const history = useHistory("")
  const [currentDatosPaDe, setLinks] = useState([]);
  const [Exito, setExito] = useState("")
  const [busqueda, setBusqueda]= useState("");
  const [message, setmessage] = useState(false)


  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      db.collection("Usuarios")
        .doc(user.uid)
        .get().then(function (doc) {
          let users = doc.data()
          setcurrenDatos(users);
        });
    })
    db.collection("Usuarios").orderBy("fecha_creacion","desc").onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setLinks(docs);
    });
  }, []);
  /////////////////Buscador////////////////////////////////////////
  const handleChange=e=>{
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  }
  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=currentDatosPaDe.filter((elemento)=>{
      // let nombre=elemento.nombres + elemento.apellidos
      let busqueda=terminoBusqueda.trim()
      console.log(busqueda)
      // console.log(nombre)
      if(elemento.nombres.toString().toLowerCase().includes(busqueda.toLowerCase())
      ||elemento.apellidos.toString().toLowerCase().includes(busqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    if(terminoBusqueda!==""){
      setLinks(resultadosBusqueda);
  }else if(terminoBusqueda===""){
    db.collection("Usuarios").onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setLinks(docs);
    });
  }
  }

/////////////////Buscador////////////////////////////////////////

  function Direct(e) {
    db.collection("Usuarios").where("tipouser", "==", e).onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setLinks(docs);
    });
    if (e === "0") {
      db.collection("Usuarios").onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setLinks(docs);
      });
    }
  }


  const [Aprobado, setAprobado] = useState("");
  function aprobar(e, a) {
    db.collection("Usuarios").doc(e).update({
      "aprobado": Aprobado,
    }).then(() => {
      history.push("/Dashboard")
      if(Aprobado===true){
      setExito("Usuario aprobado con éxito")
      setmessage(true) 
    }else if(Aprobado===false){
      setExito("Usuario deshabilitado con éxito")
      setmessage(true) 
    }
      
    })
    setTimeout(res => { setExito(true) }, 5000);
          setTimeout(res => { setmessage(false) }, 5000);
  }


  async function handleLogout() {
    setError("")
    try {
      await logout()
      history.push("/")
    } catch {
      setError("No se pudo cerrar la sesión")
    }
  }

  function quitar() {
    setExito("")
  }

  const [Minicio, setMinicio] = useState("")
  const [ActiveBoton, setActiveBoton] = useState("")

  function Active(e) {
    setMinicio(e)
    setActiveBoton(e)
  }
  const [currentPerfil, setCurrentPerfil] = useState("");

function obetener(e) {
  history.push("/Profile")
    localStorage.setItem("IdPerfilMedicos", JSON.stringify(e))
  }
  
    
  const [currentDatosPaDere, setLinksre] = useState([]);
  const [currentDatosHistoria, setLinksHistorial] = useState([]);
  const [Datosrecimos, setDatosReciMos] = useState("")
  const [Datosreci, setDatosReci] = useState("")
  const [DatosreciHist, setDatosReciHist] = useState("")

 
  function DatosReci(e) {
    db.collection("Recipes")
      .doc(e)
      .get().then(function (doc) {
        let users = doc.data()
        let $key = doc.id
        setDatosReci(users);
      });
    setDatosReciMos("1")
  }
  function DatosReciHisto(e) {
    setDatosReciMos("1")
    db.collection("historialMedico")
      .doc(e)
      .get().then(function (doc) {
        let users = doc.data()
        let $key = doc.id
        setDatosReciHist(users);
      });

  }


  return (
    <>
    
       
            
        {Minicio === "" ?
          <>
            <h2>Listados de usuarios</h2>
            {message===true?
      <div id="flotante">
      <div class="alert alert-success text-center" role="alert">
       <h5>{Exito && <div variant="success">{Exito}</div>}</h5>
      </div>
      </div>
      :""}
            <div className="">
              <div className="header_tabla form-inline">
              <div className="col-6">
                  <img src="Buscar_SVG.svg" className="mr-2" /><input className="form-control"   onChange={handleChange} placeholder="Buscar" type="seach" />
                </div>
                <div className="col-3">
                  <select className="selectEspe " onChange={(e) => Direct(e.target.value, e)}>
                    <option value="0" >Seleccionar</option>
                    <option value="Especialista" >Especialistas</option>
                    <option value="Paciente" >Pacientes</option>
                  </select>
                </div>
                <div className="col-3 botonAgendar_Admin">
                  <Link to="/signupAdmin"><button className="btn">Añadir usuarios +</button></Link>
                </div>
               
              </div>
            </div>
            <div className="cuadro_tabla">
              <div className="table-responsive mt-2">
                <div className="table_1">
                  <table className="table">
                    <thead>
                      <tr>
                        <th><img src="View_profile_SVG.svg" /></th>
                        <th>Nombre</th>
                        <th>Estatus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentDatosPaDe.length ? (
                        currentDatosPaDe.map(array => (
                          <tr>
                            <td className="td-team">
                              <div className="updaPrin_tabla"  onClickCapture={() => obetener(array.uid)}><img src={array.foto_personal}></img></div>
                            </td>
                            <td>{array.nombres}&nbsp;{array.apellidos}</td>
                            <td><div onClick={(e) => aprobar(array.uid, e)} onClickCapture={(a) => setAprobado(a.target.checked)}>{array.tipouser === "Especialista" ? <input type="checkbox" checked={array.aprobado} /> : <span></span>}</div></td>
                          </tr>
                        )
                        )
                      ) : (
                        <p></p>
                      )}
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
            

            </>
          : ""}
        
            
       
      

    </>


  )
}