import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { Alert } from "react-bootstrap"
import { db } from "../firebase"
import 'moment/locale/es'
import moment from 'moment';
import Inicios from "./inicio"
import Menu from "./menu";
import HeaderPrin from "./header"
import InicioEspecialista from "./inicioEspecialista";
import Admin from "./admin";


moment.locale('es');


export default function Dashboard() {

  const [error, setError] = useState("");
  const [currentDatos_2, setcurrenDatos_2] = useState("");
  const { currentUser, logout } = useAuth("");
  const history = useHistory("")
  const Hamilmi = async () => {
    
    db.collection("Usuarios")
      .doc(currentUser.uid)
      .get().then(function (doc) {
        let users = doc.data()
        setcurrenDatos_2(users);
        db.collection("Usuarios").where("uid", "==", users.uid)
          .onSnapshot(function (querySnapshot) {
            var ci = [];
            querySnapshot.forEach(function (doc) {
              let da = doc.data()
              da.$key = doc.id
              ci.push(da);
            });
            setcurrenDatos_2(ci)
          });
      });
  }
 
  

  useEffect(() => {
    
    Hamilmi();
  }, []);



  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
          {currentDatos_2.length?(
            currentDatos_2.map(array=>(
            <>
            {array.tipouser==="Especialista"?
            <InicioEspecialista/>
            :""}
            {array.tipouser==="Paciente"?
            <Inicios/>:""}
            {array.tipouser==="Administrador"?
            <Admin/>:""}
            </>
            ))
          ):(<div></div>)}
            
        </main>
        </div>  
      
    </>

  )
}
