import React, { useState, useEffect } from "react";
import {  useHistory } from "react-router-dom";
import './Dashboard.css';
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase"
import Menu from "./menu";
import HeaderPrin from "./header"
import moment from 'moment';
moment.locale('es');

export default function Agendas() {

    const { currentUser } = useAuth();

    const [currentDatosPaDe, setLinks] = useState([]);
    const [currentId, setCurrentId] = useState("");
    const [Perfiles, setPerfiles] = useState("");
    const [ActiveBoton, setActiveBoton] = useState("Todos")
    const history = useHistory("")
    const [VideoConfe, setVideoConfe] = useState("")
    let Noficacioness=[] 
    const Hamil = async () => {
        if (ActiveBoton === "Todos") {
            db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("estatus", "==", "Agendada").onSnapshot((querySnapshot) => {
                const docs = [];
                Noficacioness = []
                setLinks([])
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });
    
            db.collection("Conferencias").where("uid_especialista", "==", currentUser.uid).where("tipo_cita", "==", "Emergencia").onSnapshot((querySnapshot) => {
                const docs_confe = [];
                Noficacioness = []
                setLinks([])
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });

              ///////////// //// Norificaciones de pacientes///////////////////////  
              Noficacioness = Noficacioness.concat(docs)
              Noficacioness = Noficacioness.concat(docs_confe)
              setLinks(Noficacioness.sort((a, b) => b.fecha_noti - a.fecha_noti))
            });
        
        });
        }
    }

    if (VideoConfe === "2") {
        db.collection("Usuarios")
            .doc(currentId)
            .get().then(function (doc) {
                let users = doc.data()
                setPerfiles(users);
            }).catch(function (error) {
                console.log("Error getting User:", error);
                alert(error);
            });
    }

    useEffect(() => {
        Hamil();
    }, []);



    function Estatus(e) {
        setActiveBoton(e)
        if (e === "Agendada") {
            db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("estatus", "==", "Agendada").onSnapshot((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });
                setLinks(docs);
            });
        }
        if (e === "Canceladas") {
            db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("estatus", "==", "Cancelada").onSnapshot((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });
                setLinks(docs);
            });
        }
        if (e === "Reprogramadas") {
            db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("estatus", "==", "Reprogramada").onSnapshot((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });
                setLinks(docs);
            });
        }
        if (e === "Completadas") {
            db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("estatus", "==", "Completada").onSnapshot((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });
                setLinks(docs);
            });
        }
        if (e === "Todos") {
            db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("estatus", "==", "Agendada").onSnapshot((querySnapshot) => {
                const docs = [];
                Noficacioness = []
                setLinks([])
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });
    
            db.collection("Conferencias").where("uid_especialista", "==", currentUser.uid).where("tipo_cita", "==", "Emergencia").onSnapshot((querySnapshot) => {
                const docs_confe = [];
                Noficacioness = []
                setLinks([])
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });

              ///////////// //// Norificaciones de pacientes///////////////////////  
              Noficacioness = Noficacioness.concat(docs)
              Noficacioness = Noficacioness.concat(docs_confe)
              setLinks(Noficacioness.sort((a, b) => b.fecha_noti - a.fecha_noti))
            });
        
        });
        }
        if (e === "Emergencias") {
            db.collection("Conferencias").where("uid_especialista", "==", currentUser.uid).where("tipo_cita", "==", "Emergencia").onSnapshot((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });
                setLinks(docs);
            });
        }
    }
    const [busqueda, setBusqueda]= useState("");
     /////////////////Buscador////////////////////////////////////////
  const handleChange=e=>{ 
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  }
  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=currentDatosPaDe.filter((elemento)=>{
      // let nombre=elemento.nombres + elemento.apellidos
      let busqueda=terminoBusqueda.trim()
      console.log(moment(busqueda).format('DD/MM/YYYY'))
      // console.log(nombre)
      if(elemento.fecha.toString().toLowerCase().includes(moment(busqueda).format('DD/MM/YYYY').toLowerCase())
      ||elemento.fecha.toString().toLowerCase().includes(moment(busqueda).format('DD/MM/YYYY').toLowerCase())
      ){
        return elemento;
      }
    });
    if(terminoBusqueda!==""){
      setLinks(resultadosBusqueda);
  }else if(terminoBusqueda===""){
    db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("estatus", "==", "Agendada").onSnapshot((querySnapshot) => {
        const docs = [];
        Noficacioness = []
        setLinks([])
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        });

    db.collection("Conferencias").where("uid_especialista", "==", currentUser.uid).where("tipo_cita", "==", "Emergencia").onSnapshot((querySnapshot) => {
        const docs_confe = [];
        Noficacioness = []
        setLinks([])
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        });

      ///////////// //// Norificaciones de pacientes///////////////////////  
      Noficacioness = Noficacioness.concat(docs)
      Noficacioness = Noficacioness.concat(docs_confe)
      setLinks(Noficacioness.sort((a, b) => b.fecha_noti - a.fecha_noti))
    });

});
  }
  }

/////////////////Buscador////////////////////////////////////////


    const GoConferenceDataPacient = (nombre, apellido, uid_paciente) => {
        let paciente = {
            nombre: nombre,
            apellido: apellido,
            id: uid_paciente
        }
        console.log(paciente)
        localStorage.setItem('paciente_conferencia', JSON.stringify(paciente))
        history.push("Conferencia")
    }




    const retomarCitas = (e,fecha,hora) => {
        db.collection("Citas").doc(e).update({
            "estatus": "Reprogramada",
            "notificacion": true,
            "hora": null,
            "fecha":null,
            "fecha_anterior":fecha,
            "hora_anterior":hora,
            "fecha_noti":new Date(),
            "hora_cambio":moment().format("DD/MM/YYYY"),
        })
    }
    const retomarEmergencia = (a) => {
        window.open(a)
    }
    const CompletarEmergencia = (e) => {
        db.collection("Conferencias").doc(e).update({
            "notificacion":false,
            "especialista_acepto":true,
            "estatus":'Completada',
            "fecha_noti":new Date(),
        })
        db.collection("Citas").doc(e).update({
            "notificacion":false,
            "especialista_acepto":true,
            "estatus":'Completada',
            "fecha_noti":new Date(),
        })
    }
    function Cancelar(e) {
        db.collection("Citas").doc(e).update({
            "estatus": "Cancelada",
            "notificacion": false,
        })
    }
    function ObtenerDatos(e) {
        history.push("/Profile")
        localStorage.setItem("IdPerfilMedicos", JSON.stringify(e))
      }

    return (
        <div>
             <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {VideoConfe === "" ?
                <div>
                    <div className="header_tabla_agendas form-inline ">
                        <div className={ActiveBoton == "Todos" ? "col-2 botones_header_Activo a" : "col-2 botones_header a"} onClick={(e) => Estatus("Todos", e)}>Todos</div>
                        <div className={ActiveBoton == "Agendada" ? "col-2 botones_header_Activo_medio b" : "col-2 botones_header b"} onClick={(e) => Estatus("Agendada", e)}>Agendadas</div>
                        <div className={ActiveBoton == "Completadas" ? "col-2 botones_header_Activo_medio b" : "col-2 botones_header b"} onClick={(e) => Estatus("Completadas", e)}>Completadas</div>
                        <div className={ActiveBoton == "Reprogramadas" ? "col-2 botones_header_Activo_medio b" : "col-2 botones_header b"} onClick={(e) => Estatus("Reprogramadas", e)}>Reprogramadas</div>
                        {/* <div className={ActiveBoton == "Canceladas" ? "col-2 botones_header_Activo_medio c" : "col-2 botones_header c"} onClick={(e) => Estatus("Canceladas", e)}>Canceladas</div> */}
                        <div className={ActiveBoton == "Emergencias" ? "col-2 botones_header_Activo_right c" : "col-2 botones_header c"} onClick={(e) => Estatus("Emergencias", e)}>Emergencias</div>
                    </div>
                    <div className="header_tabla_agendas form-inline ">
                    <img src="Buscar_SVG.svg" className="mr-2" /><input type="date" className="form-control" onChange={handleChange}></input>
                    </div>

                    {/* <div className="col-2"><input className="form-control" type="date" onChange={(e) => Estatus(e.target.value, e)}/></div> */}
                    <h2 className="dash-title_2 a">
                        {ActiveBoton == "Todos" ? <h1>Todas</h1> : ""}
                        {ActiveBoton == "Agendada" ? <h1>Citas agendada</h1> : ""}
                        {ActiveBoton == "Completadas" ? <h1>Citas completadas</h1> : ""}
                        {ActiveBoton == "Reprogramadas" ? <h1> Citas reprogramadas</h1> : ""}
                        {/* {ActiveBoton == "Canceladas" ? <h1>Citas canceladas</h1> : ""} */}
                        {ActiveBoton == "Emergencias" ? <h1>Citas por emergencias</h1> : ""}
                    </h2>
                    <div className="dash-cards_agenda">
                        {currentDatosPaDe.length ? (
                            currentDatosPaDe.map(array => (
                                <div className="card-single_agenda">
                                    <div className="card-body">
                                        <div className="updaPrin_1">
                                            <img  onClickCapture={() => ObtenerDatos(array.uid_paciente)} src={array.foto_paciente} />&nbsp;{array.nombres_paciente}&nbsp;{array.apellidos_paciente}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="horatarget  text-center">
                                            {array.tipo_cita === "Consulta" ?
                                                <div>
                                                    <h4>{array.hora_notificacion}</h4>
                                                    <div className="fechaTarget">{array.fecha_notificacion}</div>
                                                </div>

                                                :
                                                <div>
                                                    <h4>{array.hora_notificacion}</h4>
                                                    <div className="fechaTarget">{array.fecha_notificacion}</div>
                                                </div>
                                            }

                                        </div>
                                        <h4 className="text-center">{array.estatus}</h4>
                                    </div>
                                    {array.tipo_cita === "Consulta" ?
                                        <div>
                                            {array.estatus === "Completada" ?
                                             
                                                <div className="card-footer form-inline col-12">
                                                    {array.estatus === "Completada" ?""
                                                    :
                                                    <>
                                                    <div className="col-4 divicon_1"  id="ini">
                                                         
                                                    </div>
                                                    
                                                    <div className="col-4 text-center divicon_2" onClick={() => retomarCitas(array.id,array.fecha,array.hora)}>
                                                        <img className=" icon_2" src="/Repro_icon_20.png" title="Retomar esta cita" /> 
                                                    </div>
                                                    <div className="col-4 text-right divicon_3"  >
                                                       
                                                    </div>
                                                    </>
                                                        }
                                                </div>
                                                : ""}
                                                {array.estatus === "Reprogramada" ? 
                                                <div className="card-footer form-inline col-12">
                                                    <div className="col-4 divicon_1"  id="ini">
                                                       
                                                    </div>
                                                    <div className="col-4 text-center divicon_2" >
                                                         
                                                    </div>
                                                    <div className="col-4 text-right divicon_3" onClick={(e) => Cancelar(array.id, e)} >
                                                        <img className="icon_3" src="/Cancel_icon_20.png" title="Cancelar" /> 
                                                    </div>
                                                </div>
                                                : ""}
                                                {array.estatus === "Agendada" ? 
                                                <div className="card-footer form-inline col-12">
                                                    <div className="col-4 divicon_1" onClick={() => GoConferenceDataPacient(array.nombres_paciente, array.apellidos_paciente, array.uid_paciente)} id="ini">
                                                        <img className="icon_1" src="/Apro_icon_20.png" title="Iniciar conferencia" /> 
                                                    </div>
                                                    <div className="col-4 text-center divicon_2" onClick={() => retomarCitas(array.id,array.fecha,array.hora)}>
                                                        <img className=" icon_2" src="/Repro_icon_20.png" title="Reprogramar esta cita" /> 
                                                    </div>
                                                    <div className="col-4 text-right divicon_3" onClick={(e) => Cancelar(array.id, e)} >
                                                        <img className="icon_3" src="/Cancel_icon_20.png" title="Cancelar" /> 
                                                    </div>
                                                </div>
                                                : ""}
                                                {array.estatus === "Cancelada" ? 
                                                <div className="card-footer form-inline col-12">
                                                    
                                                </div>
                                                : ""}
                                        </div>
                                        :
                                        <div className="card-footer form-inline col-12">
                                            {array.estatus==="Completada"?
                                            ""
                                            :<>
                                            <div className="col-4 divicon_1"  id="ini" onClick={() => CompletarEmergencia(array.id, array.room_link)}>
                                            {array.notificacion === true ?"":<img className=" icon_2" src="/Repro_icon_20.png" title="Completar emergencia" />}
                                            </div>
                                            <div className="col-4 text-center divicon_5" onClick={() => retomarEmergencia(array.id, array.room_link)}>
                                            {array.notificacion === true ?"":<img className=" icon_2" src="/camara.png" title="Volver a emergencia" />}
                                            </div>
                                            <div className="col-4 text-right divicon_3"  >
                                            </div>
                                            </>}
                                        </div>
                                    }
                                </div>
                            ))
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>
                : ""}
        </main>
        </div> 
          
        </div> 
    )
}