import React, { useRef, useState } from "react"
import { Alert } from "react-bootstrap"
import { useHistory, Link } from "react-router-dom"
import { db } from "../firebase"
import { auth } from "../firebase"
import "./Signup.css"
import './TipoUser.css';
import moment from 'moment';

moment.locale('es');
const SignupLandi = () => {
  const [TipoUser, setTipoUser] = useState("Especialista")
  const [Especialista, setEspecialista] = useState("")
  const [Nombres, setNombres] = useState("")
  const [Apellidos, setApellidos] = useState("")
  const [Edad, setEdad] = useState("")
  const [Email, setEmail] = useState("")
  const [EmailConfi, setEmailConfi] = useState("")
  const [Password, setPassword] = useState("")
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory("")
  const [userPA, setUserPa] = useState('Especialista');
  const [firstContainer, setFirstContainer] = useState(false)
  const [firstContainerConfirmar, setFirstContainerConfirmar] = useState(false)


  function activarFuncion(){
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
       setError("Las contraseñas no coinciden")
       setTimeout(res => { setError(true) }, 3000);
    }else if (passwordRef.current.value === passwordConfirmRef.current.value){
    setFirstContainerConfirmar(true)
    setFirstContainer(false)
    setUserPa('')
    
  }
  }
  async function handleSubmit(e) {
    if(EmailConfi===Email){
    e.preventDefault()
   
    try {
      setError("")
      setLoading(true)

      auth.createUserWithEmailAndPassword(Email, Password)
        .then((userCredential) => {
          var user = userCredential.user.uid;
          if (TipoUser === "Especialista") {
            db.collection("Usuarios").doc(user).set({
              tipouser: TipoUser,
              especialidad: Especialista,
              nombres: Nombres,
              apellidos: Apellidos,
              correo: Email,
              uid: user,
              activo: false,
              cedula: 0,
              edad:0,
              sexo: '',
              pais: '',
              religion: '',
              ciudad: '',
              direccion: '',
              num_principal: 0,
              num_secundario: 0,
              experiencia: 0,
              tipos_terapia: [{ terapia: 'Infanto-Juvenil', estado: false },
              { terapia: 'Geriatrico', estado: false },
              { terapia: 'Adulto', estado: false },
              { terapia: 'Parejas', estado: false },
              { terapia: 'Adicciones', estado: false }],
              precio_consulta: 0,
              precio_emergencia: 0,
              foto_personal: 'https://firebasestorage.googleapis.com/v0/b/terappia-61759.appspot.com/o/Imagenes%20Comunes%2Fuser_sin_imagen.png?alt=media&token=912e81af-d2d4-4788-bdef-9804694199c6',
              titulo_universitario: '',
              carnet_medico: '',
              firma: "",
              sello: "",
              documento_identidad: "",
              datos_bancarios: {
                paypal: { email: "" },
                zelle: { email: "", nombre_completo: "" },
                transferencia: { banco: "", cuenta: "", doc_identidad: 0 },
                pagomovil: { banco: "", doc_identidad:0,num_tel:0 }
              },
              fecha_creacion: new Date(),
              disponible_emergencia: false,
              aprobado: false
            })
          }
          if (TipoUser === "Paciente") {
            db.collection("Usuarios").doc(user).set({
              Plan:"",
              planActivo: false,
              nombres: Nombres,
              apellidos: Apellidos,
              correo: Email,
              uid: user,
              cedula: 0,
              edad:parseInt(Edad) ,
              sexo: '',
              montoEmergenciaPagado:0,
              uid_pago_emergencia:'',
              emergenciaPaga:false,
              religion: '',
              pais: '',
              consultasDisponibles:0,
              ciudad: '',
              descuentoPagado:0,
              direccion: '',
              num_principal: 0,
              num_secundario: 0,
              especialista_sugerido: '',
              foto_personal: 'https://firebasestorage.googleapis.com/v0/b/terappia-61759.appspot.com/o/Imagenes%20Comunes%2Fuser_sin_imagen.png?alt=media&token=912e81af-d2d4-4788-bdef-9804694199c6',
              tipouser: 'Paciente',
              fecha_creacion: new Date(),

            })
          }
        })
        .catch((error) => {
          alert(error.message)
        })
      if (TipoUser === "Paciente") {
        history.push("/")
      }
      if (TipoUser === "Especialista") {
        history.push("/")
      }
    } catch {
      setError("No se pudo crear una cuenta el correo que intenta registrar ya existe en terappiaweb")
    }

    setLoading(false)
  }else if(EmailConfi!==Email){
    setError("Correo incorrecto")
    setTimeout(res => { setError(true) }, 3000);
  }
}

  const onclickpa = (e) => {
      setUserPa(e)
      setTipoUser(e)
      setFirstContainer(false)
  
  }

  const onclickca = (e) => {
    let ca = e
    if (ca === 'Cancelar') {
  
      setUserPa("")
      setFirstContainer(true)
    }
  }
  function Regresar(){
    setFirstContainer(true)
    setFirstContainerConfirmar(false)
  }
  return (

    <div>
      {firstContainer === true ?
        <div className="user_card  mt-5">
          <div className="d-flex justify-content-center">
            <img src="Terappia-2.png" className="brand_logo" alt="Logo" />
          </div>
          <div className="text-center mb-3">
            <div className="text-center">
              <p className="nombreLogin">Seleccione el tipo de cuenta que deseas crear</p>
              <p className="barrita"></p>
            </div>
          </div>
          <div className="form-inline  iconosRegister">
            <div className="Pacientes" onClick={(e) => onclickpa('Paciente', e)} value="Paciente">
              <img src="pac_icon_SVG.svg" />
              <p className="mt-4">Paciente</p>
            </div>
            <div className="Especialista "  onClick={(e) => onclickpa('Especialista', e)} value="Especialista">
              <img src="espe_icon_SVG.svg" />
              <p className="mt-4">Especialista</p>
            </div>
          </div>
          <div id="Inicio" className="text-center">
            <a href="http://terappiaweb.com/">
              <h5>Regresar</h5>
            </a>
          </div>
          <div className="w-100 text-center mt-2">
            ¿Ya tienes una cuenta? <Link to="/">Iniciar ahora</Link>
          </div>
        </div>

        : <span></span>
      }
      {userPA === 'Paciente' ?
        <div className="container h-100">
          <div className="d-flex justify-content-center h-100">
            <div className="user_card">
              <div className="d-flex justify-content-center lo">
                <img src="Terappia-2.png" class="brand_logo" alt="Logo" />
              </div>
              <div className="text-center">
                <div className="text-center">
                  <p className="nombreLogin">Registro paciente</p>
                  <p className="barrita"></p>
                </div>
              </div>
              <div className="form_container">
                {error && <div className="Error">{error}</div>}
        
                <form  className="miformulario">
                  <div className="form-group " id="email">
                    <b > <label className="mt-3">Nombre</label></b>
                    <input type="hidden" value={TipoUser} id="TipoUser" name="TipoUser" onInput={e => setTipoUser(e.target.value)} >
                    </input>
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Introduzca su nombre"
                      onChange={(e) => setNombres(e.target.value)}
                    />
                  </div>
                  <div className="form-group" id="email">
                    <b> <label>Apellido</label></b>
                    <input type="hidden" value={TipoUser} id="TipoUser" name="TipoUser" onInput={e => setTipoUser(e.target.value)} >
                    </input>
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Introduzca su apellido"
                      onChange={(e) => setApellidos(e.target.value)}
                    />
                  </div>
                  <div className="form-group" id="email">
                    <b> <label>Edad</label></b>
                    <input type="hidden" value={TipoUser} id="TipoUser" name="TipoUser" onInput={e => setTipoUser(e.target.value)} >
                    </input>
                    <input
                      className="form-control"
                      type="number"
                      required
                      placeholder="Introduzca su edad"
                      onChange={(e) => setEdad(e.target.value)}
                    />
                  </div>
                  <div className="form-group" id="email">
                    <b> <label>Correo</label></b>
                    <input className="form-control" type="email" value={Email} onChange={(e) => setEmail(e.target.value)} placeholder="Introduzca su correo electrónico" required />
                  </div>
                  <div className="form-group" id="password">
                    <b> <label>Contraseña</label></b>
                    <input  required className="form-control" type="password" placeholder="Introduzca su contraseña" ref={passwordRef} value={Password} onChange={(e) => setPassword(e.target.value)} required />
                  </div>
                  <div  required className="form-group" id="password-confirm">
                    <b> <label>Confirmar contraseña</label></b>
                    <input className="form-control" type="password" placeholder="Confirme su contraseña" ref={passwordConfirmRef} required />
                  </div>
                </form>
                <div className="login_container">
                    <button id="login_btn" disabled={loading} className="btn login_btn" onClick={activarFuncion} type="submit">
                      Registrarse
                    </button>
                  </div>
                <div className="login_container mt-3 ">
                  <div className="atras" onClick={(e) => onclickca('Cancelar', e)} value="Cancelar" >
                    Volver
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :

        <span></span>
      }
      {userPA === 'Especialista' ?
        <div className="container h-100 ">
          <div className="d-flex justify-content-center h-100">
            <div className="user_card">
              <div className="d-flex justify-content-center lo">
                <img src="Terappia-2.png" class="brand_logo" alt="Logo" />
              </div>
              <div className="text-center">
                <div className="text-center">
                  <p className="nombreLogin">Registro especialista</p>
                  <p className="barrita"></p>
                </div>
              </div>
              <div className="form_container">
              {error && <div className="Error">{error}</div>}
                <form>
                  <div className="form-group" id="Especialista" >
                    <b> <label>Especialidad</label></b>
                    <select className="form-control" type="select" name="Especialista" value={Especialista} onChange={(e) => setEspecialista(e.target.value)}>
                      <option>Seleccionar</option>
                      <option>Psicología</option>
                      <option>Psiquiatría</option>
                    </select>
                  </div>
                  <div className="form-group" id="email">
                    <b> <label>Nombre</label></b>
                    <input type="hidden" value={TipoUser} id="TipoUser" name="TipoUser"  ></input>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Introduzca su nombre"
                      onChange={(e) => setNombres(e.target.value)}
                    />
                  </div>
                  <div className="form-group" id="email">
                    <b> <label>Apellido</label></b>
                    <input type="hidden" value={TipoUser} id="TipoUser" name="TipoUser"  ></input>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Introduzca su apellido"
                      onChange={(e) => setApellidos(e.target.value)}
                    />
                  </div>
                  <div className="form-group" id="email">
                    <b> <label>Correo</label></b>
                    <input className="form-control" type="email" value={Email} onChange={(e) => setEmail(e.target.value)} placeholder="Introduzca su correo electrónico" required />
                  </div>
                  <div className="form-group" id="password">
                    <b> <label>Contraseña</label></b>
                    <input className="form-control" type="password" placeholder="Introduzca su contraseña" value={Password} onChange={(e) => setPassword(e.target.value)} ref={passwordRef} required />
                  </div>
                  <div className="form-group" id="password-confirm">
                    <b> <label>Confirmar contraseña</label></b>
                    <input className="form-control" type="password" placeholder="Confirme su contraseña" ref={passwordConfirmRef} required />
                  </div>
                  
                </form>
                <div className="login_container">
                    <button id="login_btn" disabled={loading} onClick={activarFuncion} className="btn " type="submit">
                      Registrarse
                    </button>
                  </div>
                <div className="login_container mt-3">
                  <div className="atras" onClick={(e) => onclickca('Cancelar', e)} value="Cancelar" type="button">
                    Volver
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :

        <span></span>
      }
   {firstContainerConfirmar === true ?
        <div className="user_card">
          <div className="d-flex justify-content-center">
            <img src="Terappia-2.png" className="brand_logo" alt="Logo" />
          </div>
          <div className="text-center mb-3">
            <div className="text-center">
              <p className="nombreLogin">Para continuar con su registro confirma el correo electronico</p>
              <p className="barrita"></p>
            </div>
          </div>
          {error && <div className="Error">{error}</div>}
          <div className="form-group" id="email">
                <b> <label>Correo:</label></b>
                <input className="form-control" type="email"  onChange={(e) => setEmailConfi(e.target.value)} placeholder="Introduzca su correo electrónico" required />
            </div>
          <div id="Inicio" className="text-center">
          <div className="login_container">
                    <button id="login_btn" disabled={loading} onClick={handleSubmit} className="btn " type="submit">
                      Confirmar
                    </button>
                  </div>
          </div>
          <div className="w-100 text-center  mt-2" >
           <a onClick={()=>Regresar()}>Regresar</a>
          </div>
        </div>

        : <span></span>
      }


    </div>
  );

};
export default SignupLandi;