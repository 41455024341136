import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { db } from "../firebase"
import firebase from "../firebase"
import 'moment/locale/es'
import moment from 'moment';
import Menu from "./menu";
import HeaderPrin from "./header"


moment.locale('es');



export default function HistoriaMedica() {
  
  const history = useHistory("")
  const { currentUser } = useAuth("");
  const [currentDatosPaDe, setLinks] = useState([]);
  const [Exito, setExito] = useState("")
  const [IdHistoria, setId] = useState("")
 


  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      db.collection("Usuarios")
        .doc(user.uid)
        .get().then(function (doc) {
          let users = doc.data()
          db.collection("historialMedico").where("uid_especialista", "==", user.uid).onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
              docs.push({ ...doc.data(), id: doc.id });
            });
              let data =docs;
              let pacienteArray = [];
              console.clear();
              for (let i = 0; i <data.length; i++) {
                let Newdata = {
                  apellido_paciente: data[i].apellido_paciente,
                  nombre_paciente: data[i].nombre_paciente,
                  uid_paciente: data[i].uid_paciente,
                  foto_paciente: data[i].foto_paciente,
                  nombre_completo: data[i].nombre_paciente+' '+data[i].apellido_paciente
                }
                pacienteArray.push(Newdata);
                
              }
              let unicos = [];
              for (var indice = 0; indice < pacienteArray.length; indice++) {
                const persona = pacienteArray[indice];
                let esDuplicado = false;
                for (var i = 0; i < unicos.length; i++) {
                  if (unicos[i].uid_paciente === persona.uid_paciente) {
                    esDuplicado = true;
                    break;
                  }
                }
        
                if (!esDuplicado) {
                  unicos.push(persona);
                }
              }
             let pacientes = unicos;
              console.log(pacientes)
              setLinks(pacientes)
          });
        });
    })
  }, []);

  const [Datosrecimos, setDatosReciMos] = useState("")
  const [DatosHitoria, setcurrenDatosHistoria] = useState("");

  // function quitar() {
  //   setExito("")
  // }

  // function Eliminar(e) {
  //   db.collection("historialMedico").doc(e).delete().then(() => {
  //     setExito("Historial medico eliminado")
  //     history.push("/DashboardEs")
  //   }).catch((error) => {
  //     console.error("Error removing document: ", error);
  //   });
  // }

  const [descripcion, setdescripcion] = useState("")
  const [Datosreci, setDatosReci] = useState("")
  const [editarHistoria,seteditarHistoria]=useState("")

  function ActualizarDescripcion() {
    db.collection("historialMedico").doc(IdHistoria).update({
      "descripcion": descripcion,
    }).then(() => {
      setExito("Historial Actulizado")
      setTimeout(res => { setExito(true) }, 3000);
      history.push("/Historias")
      setDatosReciMos("")
      seteditarHistoria("")
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }

function DatosReci(e) {
    db.collection("historialMedico").where("uid_paciente", "==", e).where("uid_especialista", "==",currentUser.uid).onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setDatosReci(docs)
    setDatosReciMos("1")
  });
  }

  // function EditaHistory(e){
  //   // db.collection('historialMedico')
  //   //     .doc(e)
  //   //     .get().then(Horario => {
  //   //       console.log(Horario);
  //   //     })
  // }
 
  return (
    <>
     <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {currentDatosPaDe.length===0 ? <h2 className="dash-title a">No posee pacientes con historias médica </h2>:
    <div>
      {DatosHitoria === "" ?
        <div>
          <h2 className="dash-title a">Historia médica</h2>
          <div className="cuadro_tabla_reci">
            {Datosrecimos === "" ? <div>
              {Exito && <div variant="success">{Exito}&nbsp;&nbsp;</div>}
              {currentDatosPaDe.length ? (
                currentDatosPaDe.map(arraya => (
                  <div>
                    <div className="Vacio">
                    </div>
                    <div className="EstiloRecipes" id="Recipes" onClick={(e) => DatosReci(arraya.uid_paciente, e)} >
                      <div className="form-inline itemsReci">
                        <div className="iconoPrin col-2"><img src="Recipes_SVG.svg" /></div>
                        <div className="col-5 ico">{arraya.nombre_completo}</div>
                        <div className="col-3 ico">{arraya.fecha}</div>
                        <div className="iconoFlecha col-2 text-right"><img src="Down_SVG.svg" /></div>
                      </div>
                    </div>
                  </div>
                )
                )
              ) : (
                <p></p>
              )}
            </div> : ""}
            {Datosrecimos === "1" ?
            <div>
            {Datosreci.length?(
              Datosreci.map(array=>(
              <div>        
                <div className="EstiloRecipesDescri"  id="Recipes">
                  <div className="itemsReci_principales form-inline">
                    <div className="text-left col-4">
                    <div id="plabel"><p>Paciente</p></div>
                      <p>{array.nombre_paciente}&nbsp;&nbsp;{array.apellido_paciente}</p>
                    </div>
                    <div className="text-left col-4">
                    <div id="plabel"><p>Origen:</p></div>
                      <p>{array.tipo}</p>
                    </div>
                    <div className="Fecha text-left col-2">
                    <div id="plabel"><p>Fecha</p></div>
                      <p>{array.fecha}</p>
                    </div>
                    <div className="Estado text-left col-2">
                      <div id="plabel"><p>Estado</p></div>
                      <p>Creado el {array.fecha_creacion}</p>
                    </div>
                   
                  </div>

                  {editarHistoria===""?
                    <div id={array.id} className="ml-3">
                      <div id="Editar"><img src="edit_SVG.svg" onClickCapture={() => setId(array.id)} onClick={()=>seteditarHistoria("1")}></img></div>
                      <div  className="Descripcion">
                      <div><label>Historial médico</label></div>
                        <p>{array.descripcion}</p>
                      </div>
                      <div  id="DescripcionPatolo">
                      <div><label>Patología</label></div>
                        <p>{array.patologias.length?(
                        array.patologias.map(array=>
                          <p>{array}</p>)
                        ):(<div></div>)}</p>
                      </div>
                    </div>
                    
                    :""}
                     {editarHistoria==="1"?
                    <div className="form-inline">
                    <div className="Descripcion" id="Descripcion">
                      <textarea defaultValue={array.descripcion} onChange={(e)=>setdescripcion(e.target.value)}></textarea>
                    </div>
                    <div id="EditarActua"><img src="delete_SVG.svg" onClick={()=>ActualizarDescripcion()} ></img></div>
                    </div>
                    :""}
                    
                  <div className="itemsReci_descripcion ">
                  
                    <div id="Up_historia" onClick={() => setDatosReciMos("")} onClickCapture={()=>seteditarHistoria("")}><img src="Up_SVG.svg" /></div>
                  </div>
                </div>
              </div>
                 )
                 )
                 ):(<p></p>)}
                 </div>

                 
              : ""}
             
              
          </div>
        </div>
        : ""}
        </div>
          }
        </main>
        </div> 
   
    </>


  )
}