import React, { useState } from "react"
import { Alert } from "react-bootstrap"

import { Link, useHistory } from "react-router-dom"
import { faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db } from "../firebase"
import { auth } from "../firebase"

function Login() {
	const [Email, setEmail] = useState("")
	const [Password, setPassword] = useState("")
	const [error, setError] = useState("")
	const [errorvali, setErrorVali] = useState("")
	const [Exito, setExito] = useState("")
	const [loading, setLoading] = useState(false)
	const history = useHistory("")

	const [correo, setcorreo] = useState("");

	async function handleSubmit(e) {
		e.preventDefault()

		try {
			setExito("")
			setError("")
			setLoading(true)
			auth.signInWithEmailAndPassword(Email, Password)
				.then((userCredential) => {
					const user = userCredential.user;
					db.collection("Usuarios")
						.doc(user.uid)
						.get().then((doc) => {
							if (doc.data().tipouser === "Paciente") {
								setExito("Iniciando")
								history.push("/Dashboard")
							}
							else if (doc.data().tipouser === "Especialista") {
								setExito("Iniciando")
								history.push("/Dashboard")
							}
							else if (doc.data().tipouser === "Administrador") {
								setExito("Iniciando")
								history.push("/Dashboard")
							}
							else if (doc.data().tipouser === "ColaboradorCl") {
								setExito("Iniciando")
								history.push("/AdminCL")
							}
							else if (doc.data().tipouser === "ColaboradorCo") {
								setExito("Iniciando")
								history.push("/AdminCO")
							}
						});
				})
				.catch((error) => {
					if (correo !== Email) {
						setTimeout(res => { setError(true) }, 4000);
						setError("Correo eléctronico o Contraseña invalida")
					} else {
						setError("")
					}
				});
		}
		catch {
			setError("Intentelo de nuevo")
		}
		setLoading(false)
	}

	function validar() {
		for (let i = 0; i < Password.length; i++) {
			if (Password[i] < 5) {
				setTimeout(res => { setErrorVali(true) }, 4000);
				setErrorVali("Su contraseña debe ser igual o mayor a  6 digitos")
			} else if (Password[i] > 4) {
				setErrorVali("")
			}
		}
	}
	return (
		<>
					<div className="user_card">
						<div className="d-flex justify-content-center">
							<img src="Terappia-2.png" className="brand_logo" />
						</div>
						<div className="text-center">
							<div className="text-center">
								<p className="nombreLogin">Iniciar sesión</p>
								<p className="barrita"></p>
							</div>
						</div>

						
						<div className="form_container">
							{Exito && <Alert variant="success" className="block text-center">{Exito}</Alert>}
							<form onSubmit={handleSubmit}>
								<div className="input-group  mb-3" id="inpus">
									<div className="input-group-append">
										<span className="form-control">
										<img src="Mail_icon.svg"></img>
										</span>
									</div>
									<input type="email" name="email" className="form-control input_pass" value={Email} onChange={(e) => setEmail(e.target.value)} placeholder="Correo electrónico" required ></input>
								</div>
								<div className="input-group  mb-2" id="password" id="inpus">
									<div className="input-group-append">
										<span className=" form-control">
										<img src="Password_icon_SVG.svg"></img>
										</span>
									</div>
									<input type="password" name="password" className="form-control" placeholder="Contraseña" value={Password} onChangeCapture={validar} onChange={(e) => setPassword(e.target.value)} required />
								</div>
								<div className="login_container mb-4">
									<div className="links">
										<Link to="/forgot-password">¿Olvido su contraseña?</Link>
									</div>
									<div className="links">
										<a href="http://terappiaweb.com/">Ir a inicio</a>
									</div>
								</div>
								<div className="Error">
								{error && <div className="Error mb-3 mt-2">{error}&nbsp;</div>}
								{errorvali && <div className="Error mb-3 mt-2">{errorvali}</div>}
								</div>
								<div className="login_container">
									<button id="login_btn" disabled={loading} type="submit" name="button" className="btn login_btn">Iniciar sesión</button>
								</div>
								<div className="login_container mt-3 mb-3 ">
									<img src="/Separador.svg"></img>
								</div>
								<div className="login_container">
									<Link to="/signup"><button id="login_btn_registro" disabled={loading} name="button" className="btn login_btn">Regístrate</button></Link>
								</div>
							</form>
						</div>
					</div>
		</>
	)
}
export default Login