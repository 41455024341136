import React, { useState, useEffect, useContext } from 'react';
import moment from "moment";
import { Title } from "../Title";
import { Monitor } from "../Monitor";
import { CalendarGrid } from "../CalendarGrid";
import styled from "styled-components";
import { db } from ".././../firebase";
import Inicios from '../inicio';
import HeaderPrin from '../header'
import Menu from '../menu'

moment.locale('es');

const ShadowWrapper = styled('div')`
  border: 1px solid rgb(117, 117, 117);
  overflow:hidden;
  width: 360px;
  height: 500px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 2px 4px 0 rgba(140, 80, 236, 0.2), 0 3px 10px 0 rgba(73, 41, 255, 0.19);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 26, 255, 0.171), 0 3px 10px 0 rgba(146, 30, 255, 0.19);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 255, 221, 0.2), 0 3px 10px 0 rgba(20, 239, 255, 0.19)
`;


function Calendario() {
  const [Inicio, setInicio] = useState("");
  const [DatosDoc, setDatosDoc] = useState("");
  const id = JSON.parse(localStorage.getItem('id'))
  const idPa = JSON.parse(localStorage.getItem('Paciente'))
  const IdCita = JSON.parse(localStorage.getItem('IdCita'))
  const [datosCita, setDatosCita] = useState("")

  moment.updateLocale('es', { week: { dow: 1 } });
  // const today = moment();
  const [today, setToday] = useState(moment())
  const startDay = today.clone().startOf('month').startOf('week');

  // window.moment = moment;

  const prevHandler = () => setToday(prev => prev.clone().subtract(1, 'month'));
  const todayHandler = () => setToday(moment())
  const nextHandler = () => setToday(prev => prev.clone().add(1, 'month'))

  const Hamilcc = async () => {
    db.collection("Usuarios")
      .doc(id)
      .get().then(async (doc) => {
        if (doc.exists) {
          let users = doc.data()
          console.log(users)
          setDatosDoc(users);
        }
      });
      if(IdCita===""){
        setDatosCita("")
      }else if(IdCita!==""){
    db.collection("Citas")
      .doc(IdCita)
      .get().then((doc) => {
        if (doc.exists) {
          let usersi = doc.data()
          setDatosCita(usersi);
        }

      });
    }
  }
  useEffect(() => {
    Hamilcc();
  }, []);

  return (
    <div>
      	  
      <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {Inicio === "" ?
        <div>
          <div className="NameEspecia">
            {IdCita!==""?
             <div>
            <div className="text-left">
              <div className="form-control">
                <label>Especialista:</label>
              </div>
              <div className="form-control">
                {DatosDoc.nombres}&nbsp;&nbsp;{DatosDoc.apellidos}
              </div>
            </div>
            <div className="form-control">
              <label>Fecha actual:</label>
            </div>
            <div className="form-control" >
              {datosCita.fecha_anterior}
            </div>
            <div className="form-control">
              <label>Hora actual:</label>
            </div>
            <div className="form-control">
              {datosCita.hora_anterior}
            </div>
            </div>
            :
            <div className="NameEspeciaMargi">
              <div className="tituloEspe_cale">
                <label>Especialista</label>
              </div>
              <div className="tituloEspe_cale_no">
                {DatosDoc.nombres}&nbsp;&nbsp;{DatosDoc.apellidos}
              </div>
            </div>
           
}
          </div>
          <div className="form-inline">
            <ShadowWrapper>
              <Title />
              <Monitor
                today={today}
                prevHandler={prevHandler}
                todayHandler={todayHandler}
                nextHandler={nextHandler}
              />
              <CalendarGrid startDay={startDay} />
            </ShadowWrapper>
          </div>
        </div>
        : ""}
      {Inicio === "1" ?
        <Inicios></Inicios>
        : ""}
        </main>
        </div>  
     
    </div>
  );
}

export default Calendario;
