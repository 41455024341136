import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './Dashboard.css';
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase"
import moment from 'moment';



moment.locale('es');

export default function InicioEspecialista() {

  
    const history = useHistory()
    const { currentUser } = useAuth();
    const [currentDatosPaDe_1, setLinks_1] = useState([]);
    const DiaHoy = moment().format("DD/MM/YYYY")
    const [currentDatosPaDe, setLinks] = useState([]);
    const [tabla, settabla] = useState(true);
    const getLinks = async () => {

        console.log(DiaHoy)
        db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("fecha", "==", DiaHoy).where("estatus", "==", "Agendada").onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setLinks_1(docs);
        });
        db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("estatus", "==", "Agendada").onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setLinks(docs);
        });

    };

    useEffect(() => {
        getLinks();
    }, []);

   function idcurren(e){
    history.push("/Profile")
    localStorage.setItem("IdPerfilMedicos", JSON.stringify(e))
    }

    function Cancelar(e) {
        db.collection("Citas").doc(e).update({
            "estatus": "Cancelada",
        })
    }

function Reprogramar(e,id) {

        // setRetomar("1")
        // setvide("")
        localStorage.setItem("Especialista", JSON.stringify(e))
        db.collection("Citas").doc(id).update({
          "estatus": "Reprogramada",
          
      })
    }
    function idCita(a) {
        localStorage.setItem("IdCita", JSON.stringify(a))
    }


    const [vide, setvide] = useState("")

   
    const GoConferenceDataPacient = (id) => {
       let e={id:id}
        localStorage.setItem('paciente_conferencia', JSON.stringify(e))
        history.push("Conferencia")
    }
 

 

    return (

        <>
           
            {vide === "" ?
                <div>
                    {/* <MyInput></MyInput> */}
                    {currentDatosPaDe_1.length === 0 ?
                        <h2 className="dash-title a "><h1>No tiene consultas para hoy</h1></h2>
                        : <h2 className="dash-title a "><h1>Consultas para hoy&nbsp;&nbsp; {DiaHoy}</h1></h2>}
                    <div className="dash-cards">
                        {currentDatosPaDe_1.length ? (
                            currentDatosPaDe_1.map(array => (
                                <div className="card-single">
                                    <div className="card-body">
                                        <div className="updaPrin_1">
                                            <img onClick={() => idcurren(array.uid_paciente)} src={array.foto_paciente} />&nbsp;{array.nombres_paciente}&nbsp;{array.apellidos_paciente}
                                        </div>
                                    </div>
                                    <div className="horatarget  text-center">
                                        <h4>{array.hora}</h4>
                                    </div>

                                    <div className="card-footer form-inline col-12">
                                        <div className="col-4 divicon_1" onClick={() => GoConferenceDataPacient(array.uid_paciente)} onClickCapture={(a) => idCita(array.id, a)} id="ini">
                                            <img className="icon_1" src="/Apro_icon_20.png" title="Iniciar conferencia" />
                                        </div>
                                        <div className="col-4 text-center divicon_2" onClick={() => Reprogramar(array.uid_especialista,array.id )} onClickCapture={(a) => idCita(array.id, a)}>
                                            <img className=" icon_2" src="/Repro_icon_20.png" title="Reprogramar esta cita" />
                                        </div>
                                        <div className="col-4 text-right divicon_3" onClick={(e) => Cancelar(array.id, e)}>
                                            <img className="icon_3" src="/Cancel_icon_20.png" title="Cancelar" />
                                        </div>
                                    </div>

                                </div>
                            ))
                        ) : (
                            <p></p>
                        )}
                    </div>

                    <section className="recent">
                    {currentDatosPaDe.length === 0 ?
                      <div>
                        
                      </div>
                        :
                        <div>
                        {tabla ? (
                            <div className="activity-card">
                               <h2>Listado de citas</h2>
                                <div className="cuadro_tabla">
                                    <div className="table-responsive mt-2">
                                        <div className="table_1">
                                            <table className="table">
                                            <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Nombre</th>
                                                        <th>Estatus</th>
                                                        <th>Fecha</th>
                                                        <th>Hora</th>
                                                        <th>Acción</th>
                                                    </tr>
                                                </thead>
                                            {currentDatosPaDe.length ? (
                                                        currentDatosPaDe.map(array => (
                                                          <>
                                                
                                                <tbody>
                                                  
                                                            <tr>
                                                                <td>
                                                                    <div className="updaPrin_tabla" onClickCapture={() => idcurren(array.uid_paciente)} onClick={() => setvide("3")}><img src={array.foto_paciente}/></div>
                                                                </td>
                                                                <td>{array.nombres_paciente}&nbsp;&nbsp;{array.apellidos_paciente}</td>
                                                                <td>{array.estatus}</td>
                                                                <td>{array.fecha}</td>
                                                                <td>{array.hora}</td>
                                                                <td className="agendar">
                                                                    <button className="btn" onClick={() => GoConferenceDataPacient( array.uid_paciente)} onClickCapture={(a) => idCita(array.id, a)}>
                                                                        Ir a consulta
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                    
                                                </tbody>
                                                </>
    )
                                                        )
                                                    ) : (
                                                        <p></p>
                                                    )}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p></p>
                        )}
                         </div>
                      }
                    </section>

                </div>
                :
                ""
            }
           
        </>
    )
}
