import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import './Dashboard.css';

import { db } from "../firebase"

import 'moment/locale/es'
import moment from 'moment';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
import Menu from "./menu";
import HeaderPrin from "./header"
init("user_Z0IcP4svfiDtq99nnXfGP");




moment.locale('es');


export default function MisRecipesAdmin() {
  const history = useHistory("")
  const [currentDatosPaDe, setLinks] = useState([]);
  const [currentDatosPaDeUser, setLinksUser] = useState([]);
  const [Exito, setExito] = useState("")
  const [message, setmessage] = useState(false)
  const [currentDatosReci, setcurrenDatosReci] = useState("");
  
  const [mostrar,setmostrar] = useState(false);
  const [NombresEspecialista,setNombresEspecialista] = useState("");
  const [Nombrpaciente,setNombrpaciente] = useState("");
  const [descripcion,setdescripcion] = useState("");
  const [firma,setfirma] = useState("");
  const [sello,setsello] = useState("");
  const [uid,setuid]=useState("")
  const [id,setid]=useState("")
  
  const [contact,setContact] = useState("");

  const [showMessage, setShowMessage] = useState(false);


  // const [Exito, setExito] = useState("")


  useEffect(() => {


    db.collection("Recipes").onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setLinks(docs);
    });
    db.collection("Usuarios").doc(id).onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setLinksUser(docs);
    });
  }, []);



  


  const handleSubmit = (e)=>{
     e.preventDefault();
    
   emailjs.send("Terappia","template_rew6wca", contact,'user_Z0IcP4svfiDtq99nnXfGP')
   .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          db.collection("Recipes").doc(uid).update({
            "estatus": "Completado",
          }).then(() => {
            history.push("/AdminRecipes")
            setExito("Récipe enviado al correo electrónico con éxito,estatus actualizado a completado")
            setmessage(true) 
            setmostrar(false)
          })
          setTimeout(res => { setExito(true) }, 5000);
          setTimeout(res => { setmessage(false) }, 5000);
   }, (err) => {
          console.log('FAILED...', err);
   });
  }





  function aprobar(id, Aprobado) {
    db.collection("Recipes").doc(id).update({
      "estatus": Aprobado,
    }).then(() => {
      history.push("/AdminRecipes")
      setExito("Estatus actalizado")
    })

  }




  function quitar() {
    setExito("")
  }

  function Mostrar(e) {
    setmostrar(true)
    db.collection("Recipes")
      .doc(e)
      .get().then((doc) => {
        let users = doc.data()
        let uid =doc.id
        setcurrenDatosReci(users);
        setNombresEspecialista(users.nombres_especialista + users.apellidos_especialista)
        setNombrpaciente(users.nombre_paciente  +   users.apellido_paciente)
        setdescripcion(users.descripcion)
        setsello(users.sello_especialista)
        setfirma(users.firma_especialista)
        setuid(uid)
        setContact({ userNombres_paciente:users.nombre_paciente  +   users.apellido_paciente,userNombres_especialista:users.nombres_especialista + users.apellidos_especialista, descripcion:users.descripcion, firma:users.firma_especialista, sello:users.sello_especialista,correo:users.email_destino} )
        console.log(uid)
      });

  }



  return (
    <>
       <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
          
            
        
    {mostrar===false?
    <div>
    
      <div className="form-inline"><div className="col-3"><h2>Recipes médico</h2></div><div className="col-9">
        
      {message===true?
      <div id="flotante">
      <div class="alert alert-success text-center" role="alert">
       <h5>{Exito && <div variant="success">{Exito}</div>}</h5>
      </div>
      </div>
      :""}
        </div></div>
      <div className="cuadro_tabla">
        <div className="table-responsive mt-2">
          <div className="table_1">
            <table className="table">
              <thead>
                <tr>
                  <th>Pacientes</th>
                  <th>Fecha</th>
                  <th>Dirección</th>
                  <th>Correo</th>
                  <th>Teléfonos</th>
                  <th>Estatus</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {currentDatosPaDe.length ? (
                  currentDatosPaDe.map(array => (
                    <tr>
                      <td>{array.nombre_paciente}&nbsp;{array.apellido_paciente}</td>
                      <td> {array.fecha}</td>
                      <td>{array.direccion_destino}</td>
                      <td>{array.email_destino}</td>
                      <td>{array.numero_destino}</td>
                      <td>{array.estatus}</td>
                      <td className="agendar_re text-center">
                        <div className="form-inline ">
                          <div className="col-6 " >
                            <img className="icon_1" src="/Apro_icon_20.png" title="Completado" onClickCapture={()=>setid(array.uid_paciente)} onClick={() => Mostrar(array.id)} />
                          </div>
                          <div className="col-6 ">
                            <img className="icon_3" src="/Cancel_icon_20.png" title="Cancelar" onClick={() => aprobar(array.id, "Cancelado")} />
                          </div>
                        </div>
                      </td>

                    </tr>
                  )
                  )
                ) : (
                  ""
                )}
                
              </tbody>

            </table>
          </div>
        </div>
      </div>
      </div>
      :""}


{mostrar===true?
      <section className="contact_info_area sec_pad bg_color">
    <div className="container">
    	<div className="row">
        <div className="contact_form col-lg-12">
		<h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Enviar por correo electrónico</h2>
		<div className="">
		
    <p>Datos del récipe</p>
		</div>
		<form className="contact_form_box" id="contactForm" onSubmit={handleSubmit}>
			  <div className="row">
				  <div className="col-6 divNombres">
				<div className="col-lg-12 ">
					<div className="form-group text_box"> Nombres especialista <br/>
						<input required className="" type="text" value={NombresEspecialista}  name="concernCategory" placeholder="Nombres"/>
					</div>
			 	 </div>
				  <div className="col-lg-12">
					<div className="form-group text_box">  Nombres  paciente <br/>
						<input required className="" type="text" value={Nombrpaciente}  name="concernCategory_2" placeholder="Apellidos"/>
					</div>
			  		</div>
					  <div className="col-lg-12">
					<div className="form-group text_box"> Correo <br/>
						<input required type="text" value={currentDatosReci.email_destino} name="userEmail"  className="" placeholder="Correo" />
					</div>
				</div>
				  </div>
				  <div className="col-6">
					<div className="col-lg-12">
					<div className="form-group text_box"> Descripción <br/>
						<textarea required name="emailDetails"  className="" placeholder="Describe tu comentario" value={descripcion}></textarea>
					</div>
          
					</div>
					</div>
          
			  
			  </div>
			  {Exito && <div variant="success">{Exito}&nbsp;&nbsp;</div>}
		 <div><button className="btn_three">Enviar</button></div>	
		</form>
		</div>
                    </div>
                </div>
            </section>
          :""}
      
      </main>
        </div>  
    </>


  )
}