import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase"
import Checkbox from './checkbox'
import Menu from "./menu";
import HeaderPrin from "./header"
import moment from 'moment';

moment.locale('es');
const Horarios = () => {
  const [ExitoHorario, setExitoHorario] = useState("")

  // const [error, setError] = useState("")
  const { currentUser } = useAuth("");
  const [nuevoDaos, setNuevodato] = useState("");
  const [nuevoDaosHora, setNuevodatoHora] = useState([]);
  const [Agregar, setAgregar] = useState(true)
  const [misHorarios, setMishorarios] = useState("");
  const [misHorariosHoras, setMishorariosHoras] = useState("")
  const [DatosUser, setDatosUser] = useState("");
  const [Orden, setOrden] = useState(0);


  // for (let i = 0; i < arrayNew.length; i++) {
  //   setIdDia(arrayNew[0])
  // }


  const onCheckboxClicked = (id, isChecked, value) => {
    if (isChecked === true) {
      setNuevodato(value)
      if (value === "lunes") {
        setOrden(1)
      }
      if (value === "martes") {
        setOrden(2)
      }
      if (value === "miércoles") {
        setOrden(3)
      }
      if (value === "jueves") {
        setOrden(4)
      }
      if (value === "viernes") {
        setOrden(5)
      }
      if (value === "sábado") {
        setOrden(6)
      }

      if (value === "domingo") {
        setOrden(7)
      }


    }
    else if (isChecked === false) {

      setNuevodato("")
    }
    console.log(nuevoDaos)
  }

  const onCheckboxClickedHora = (id, isChecked, value) => {
    if (isChecked === true) {
      nuevoDaosHora.push(value)
      setNuevodatoHora([...nuevoDaosHora])
    }
    else if (isChecked === false) {
      for (let i = 0; i <= nuevoDaosHora.length; i++) {
        const nuevoStateHora = nuevoDaosHora[i]
        if (nuevoStateHora >= 0) {
          nuevoDaosHora.push(value)
        }
      }
      let filtro = nuevoDaosHora.filter(number => number !== value)
      setNuevodatoHora([...filtro])
      console.log(nuevoDaosHora)
    }

  }

  useEffect(() => {

    const fetchBusinesses = () => {
      db.collection("Usuarios").doc(currentUser.uid).collection("Horario").orderBy("orden")
			.onSnapshot(function (querySnapshot) {
				var Horarios = [];
				var Dias = [];
				querySnapshot.forEach(function (doc) {
					let datos = doc.data()
					let datosDias = doc.data().horas
					datos.$key = doc.id
					Horarios.push(datos);
					Dias.push(datosDias);
				
         setMishorarios(Horarios) 
         let horas_total_1 = []
         let horas_total_2 = []
 let horas_total_recibido=datosDias
          Array.prototype.forEach.call(horas_total_recibido, htrecibido => {
           let hora_format_mili = moment(htrecibido, 'hh:mm A').format('HH:mm')
           horas_total_1.push(hora_format_mili)
         })

         horas_total_1.sort()

         Array.prototype.forEach.call(horas_total_1, htotal_1 => {
           let hora_format_meri = moment(htotal_1, 'HH:mm').format('hh:mm A')
           horas_total_2.push(hora_format_meri)
         })

         setMishorariosHoras(horas_total_2) 
        });

      });



      db.collection("Usuarios").where("uid", "==", currentUser.uid)
        .onSnapshot(function (querySnapshot) {
          let Conferencias = [];
          querySnapshot.forEach(function (doc) {
            Conferencias.push(doc.data().activo)
          })
          setDatosUser(Conferencias);
        })
    };
    fetchBusinesses();
  }, []);


  const state = {
    opciones: [
      { check: false, dia: "lunes" },
      { check: false, dia: "martes" },
      { check: false, dia: "miércoles" },
      { check: false, dia: "jueves" },
      { check: false, dia: "viernes" },
      { check: false, dia: "sábado" },
      { check: false, dia: "domingo" },

    ]
  };


  const statehora = {
    opcioneshora: [
      { check: false, hora: "06:00 A.M." },
      { check: false, hora: "07:00 A.M." },
      { check: false, hora: "08:00 A.M." },
      { check: false, hora: "09:00 A.M." },
      { check: false, hora: "10:00 A.M." },
      { check: false, hora: "11:00 A.M." },
      { check: false, hora: "12:00 P.M." },
      { check: false, hora: "01:00 P.M." },
      { check: false, hora: "02:00 P.M." },
      { check: false, hora: "03:00 P.M." },
      { check: false, hora: "04:00 P.M." },
      { check: false, hora: "05:00 P.M." },
      { check: false, hora: "06:00 P.M." },
      { check: false, hora: "07:00 P.M." },
      { check: false, hora: "08:00 P.M." },
      { check: false, hora: "09:00 P.M." },
    ]
  };


  function ActualizarHorario(e) {
    let a = e
    if (a === "listo") {
      db.collection("Usuarios").doc(currentUser.uid).collection("Horario").doc(nuevoDaos).set({
        dia: nuevoDaos,
        orden: Orden,
        horas: nuevoDaosHora,
        uid: currentUser.uid
      })
      db.collection('Horario')
        .where("uid", '==', currentUser.uid)
        .get().then(Horario => {
          console.log(Horario);
        })
        .then(() => {
          setExitoHorario("Su horario fue agregado con éxito")
          setTimeout(res => { setExitoHorario(true) }, 5000);
          setAgregar(true)
          setNuevodatoHora([])
          setNuevodato("")
        })
        .catch(() => {
          // setError("Error al agregar su horario intente de nuevo")
        })
    }

    console.log(misHorarios)

  }

  function Eliminar(e) {
    db.collection("Usuarios").doc(currentUser.uid).collection("Horario").doc(e).delete().then(() => {
      setExitoHorario("Día eliminado con éxito")
      setTimeout(res => { setExitoHorario(true) }, 5000);
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }

  function Vacaciones(e) {
    db.collection("Usuarios").doc(currentUser.uid).update({
      "activo": e,
    }).then(() => {
      if (e === true) {
        setExitoHorario("Su horario está habilitado en nuestro sistema")
        setTimeout(res => { setExitoHorario(true) }, 5000);
      }
      if (e === false) {
        setExitoHorario("Su horario está deshabilitado en nuestro sistema")
        setTimeout(res => { setExitoHorario(true) }, 5000);
      }
    })
  }
  function EliminarDatos() {
    setNuevodatoHora([])
    setNuevodato("")
  }
  return (
    <div>
      <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {Agregar === true ?
        <div>
          <div className="form-inline">
            {misHorarios.length ?
              misHorarios.map(hora => (
                <div className="">
                  <div className="DiasTituForma">
                    {hora.dia === "lunes" ?
                      <div className="DiasTitu">Lun</div>
                      : ""}
                    {hora.dia === "martes" ?
                      <div className="DiasTitu">Mar</div>
                      : ""}
                    {hora.dia === "miércoles" ?
                      <div className="DiasTitu">Mie</div>
                      : ""}
                    {hora.dia === "jueves" ?
                      <div className="DiasTitu">Jue</div>
                      : ""}
                    {hora.dia === "viernes" ?
                      <div className="DiasTitu">Vie</div>
                      : ""}
                    {hora.dia === "sábado" ?
                      <div className="DiasTitu">Sáb</div>
                      : ""}
                    {hora.dia === "domingo" ?
                      <div className="DiasTitu">Dom</div>
                      : ""}
                  </div>
                </div>
              )) : (
                <p></p>
              )} 
            {misHorarios.length === 0 ? <div>Configure su horario</div>: ""}
            <div className="DiasTituForma">
              <div className="DiasTitu HorarioAgregar" title="Agregar dias" onClick={() => setAgregar(false)}><img src="add_SVG.svg" /></div>
            </div>
            {misHorarios.length !== 0 ?
              <div>
                {DatosUser ? (
                  DatosUser.map(dato => (
                    <div>
                      {dato === false ?
                        <div className="DiasTituForma_Activar">
                          <div className="DiasTitu HorarioAgregar" title="¿Desea activar su horario?" onClick={() => Vacaciones(true)}>On</div>
                        </div>
                        : <div className="DiasTituForma_Desactivar">
                          <div className="DiasTitu HorarioAgregar" title="¿Desea Desactivar su horario?" onClick={() => Vacaciones(false)}>Off</div>
                        </div>
                      }
                    </div>
                  ))
                ) : ""}
              </div>
              : ""}
            <div>
              {ExitoHorario &&
                <div className="form-inline">
                  <div className={ExitoHorario === "Su horario está deshabilitado en nuestro sistema" ? "mensajeDesabi" : "mensajeExitoso"}>
                    {ExitoHorario}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="cuadro_tabla_horarios">
            <div className="table-responsive form-inline">
              <div className="table_1_horarios  form-inline">
                {misHorarios.length ?
                  misHorarios.map(hora => (
                    <div className="card-single_horarios">
                      <div className="EliminarHoario text-right">
                        <img title="Eliminar este dia" src="save_SVG.svg" onClick={(e) => Eliminar(hora.dia, e)} /></div>
                      <div className="card-body_horarios">
                        <div className="tituLo">
                          <div className="text-center">
                            <h3>{hora.dia}</h3>
                          </div>
                        </div>
                        <div className="form-inline">
                        {misHorariosHoras.length ?
                           misHorariosHoras.map(hora => (
                              <div className="form-inline">
                                <div className="Forma_hora">
                                  {hora}
                                </div>
                              </div>
                           )) : (
                            <p></p>
                          )}
                        </div>
                      </div>
                    </div>
                  )) : (
                    <p></p>
                  )}
              </div>
            </div>
          </div>
        </div>
        : <span></span>}


      {Agregar === false ?
        <div className="cuadro_tabla_horarios">
          <div className="table-responsive form-inline">
            <div className="table_1_horarios  form-inline">
              <div className="card-single_horarios_marcados">
                <div className="text-center">
                  {nuevoDaos === "" ?
                    <div className="">
                      <div id="title"><h3>Días</h3></div>
                      <div className="botonesHorarios">
                        <img src="save_SVG.svg" title="Cerrar" onClick={() => setAgregar(true)} />
                      </div>
                      <div className="form-inline" id="">
                        {state.opciones.map((checkbox, i) => {
                          return (
                            <div className="form-inline">
                              <div className="Forma_hora_dia form-inline">
                              <div className="mr-1"><Checkbox  initialState={false} id={i + 1} value={checkbox.dia} onChange={(onCheckboxClicked)} /></div>
                                <div className="nombreDia">{checkbox.dia}</div>
                              </div>

                            </div>
                          )
                        })}
                      </div>

                    </div>
                    :
                    <div>
                      <div className="" id="title">
                        <h3>Se ha seleccionado el día</h3>
                        <div className="tituLo_ti"><h1>{nuevoDaos}</h1></div>
                      </div>
                      <div className="form-inline text-center">
                        <div className="botonesHorarios_d"><img src="delete_SVG.svg" title="Guardar día" onClick={(e) => ActualizarHorario("listo", e)} /></div>
                        <div className="botonesHorarios_A"><img src="save_SVG.svg" title="Vaciar todo" onClick={(e) => EliminarDatos()} /></div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              {nuevoDaos !== "" ?
                <div className="card-single_horarios_marcados_horas">
                  <div className="text-center">
                    <div id="title"><h3>Horas</h3></div>
                    <div className="form-inline" id="">
                      {statehora.opcioneshora.map((checkbox, i) => {
                        return (
                          <div className="form-inline">
                            <div className="Forma_hora_ho form-inline">
                            <div className="mr-1 mt-1"><Checkbox initialState={false} id={i + 1} value={checkbox.hora} onChange={(onCheckboxClickedHora)} /></div>
                            <div className="">{checkbox.hora}</div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  {nuevoDaosHora.length !== 0 ?
                    <div className="text-center" id="title">
                      <h3>Horas marcadas</h3>
                      <div className="text-center">
                        <div className="form-inline" id="">
                          {nuevoDaosHora.map(hora => (
                            <div className="form-inline">
                              <div className="Forma_hora_ho">
                                {hora}
                              </div></div>
                          ))}
                        </div>
                      </div>
                    </div>
                    : ""}
                </div>
                : ""}
            </div>
          </div>
        </div>
        : <span></span>}
        </main>
        </div>  
      

    </div>

  );
}
export default Horarios;