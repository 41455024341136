import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import './Dashboard.css';
import { db } from "../firebase"
import { PayPalButton } from "react-paypal-button-v2";
import moment from 'moment';
import firebase from "../firebase"
import Menu from "./menu";
import HeaderPrin from "./header"

moment.locale('es');

export default function PagarEmergencia() {
    const [currentPerfil, setCurrentPerfil] = useState("");
    const [currentDatos_3, setcurrenDatos_3] = useState("");
    const id = JSON.parse(localStorage.getItem('idEmergencia'))
    const [currentDatos_porce, setcurrenDatos_porce] = useState(0);
    /**nuevas variables */
    const { currentUser } = useAuth();
    const history = useHistory()



    const Emerge = async () => {
        
          
        db.collection("Usuarios")
            .doc(id)
            .get().then(function (doc) {
                let users = doc.data()
                let Tipousers = doc.data().tipouser
                setCurrentPerfil(users);
            })

    }
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            db.collection("Usuarios")
                .doc(user.uid)
                .get().then(function (doc) {
                    let users = doc.data()
                    users.$key = doc.id
                    setcurrenDatos_3(users);
                });
        })
        db.collection("configuracion")
            .doc("am8lciDPAWtxD1tzDyik")
            .get().then(function (doc) {
                let users = doc.data().PorcentajesGanancia

                setcurrenDatos_porce(users);
                // GET PLANES
            });
        // GET PLANES
        Emerge();
    }, []);


    const jitsiContainerStyle = {
        width: '100%',
        height: '100%',
    }
    const uidNewHisto = Math.random().toString(20).substring(7);
    const clMedicalal = (e, details, data, precio, nombres, apellidos, foto) => {
        let r = Math.random().toString(36).substring(7);
        let link = 'https://meet.jit.si' + '/' + currentUser.uid + r

        db.collection("SalaEspera").doc(uidNewHisto).set({
            uid:uidNewHisto,
            fecha_notificacion: moment().format("DD/MM/YYYY"),
            especialista_acepto: false,
            notificacion: true,
            estatus:"Agendada",
            notificacion_especialista: true,
            notificacion_paciente: false,
            paciente_abandona: false,
            fecha_noti: new Date(),
            uid_especialista: e,
            fecha_creacion: moment().format("DD/MM/YYYY"),
            fecha: moment().format("DD/MM/YYYY"),
            foto_paciente: currentDatos_3.foto_personal,
            foto_especialista: foto,
            nombres_paciente: currentDatos_3.nombres,
            apellidos_paciente: currentDatos_3.apellidos,
            nombre_especialista: nombres,
            apellido_especialista: apellidos,
            uid_paciente: currentUser.uid,
            room_link: link,
            recordatorio: false,
            num_reintentar: 0,
            hora_notificacion: moment().format("hh:mm A"),
            fecha_notificacion: moment().format("DD/MM/YYYY")
        }).then((resp) => {
            console.log(resp)
        })

        let datos = currentDatos_3
        datos.emergenciaPaga = true
        datos.montoEmergenciaPagado = precio
        datos.uid_pago_emergencia = uidNewHisto
        console.log(datos)

        db.collection("Usuarios").doc(currentDatos_3.$key).set(datos)
            .then(resp => {
                db.collection("HistorialPagos").doc(datos.uid_pago_emergencia).set({
                    uid: datos.uid_pago_emergencia,
                    tipo_pago: "Emergencia",
                    estatusAdmin: false,
                    nombres_paciente: currentDatos_3.nombres,
                    uid_paciente: currentDatos_3.$key,
                    cedula_paciente: currentDatos_3.cedula,
                    nombre_especialista: '',
                    uid_especialista: '',
                    fecha_cancelacion: '',
                    pago_cancelado: false,
                    apellido_especialista: '',
                    apellidos_paciente: currentDatos_3.apellidos,
                    estatusPago: details.status,
                    num_principal_paciente: currentDatos_3.num_principal,
                    fecha_Pago: moment().format("DD/MM/YYYY"),
                    idPago: details.id,
                    monto_emergencia: precio,
                    monto_emergencia_especialista: precio,
                    monto_porcentaje_terapia: precio / 100 * currentDatos_porce,
                    fechaPagoPaypal: details.create_time,
                    direccionPagador: details.payer.address,
                    emailPagador: details.payer.name,
                    monto: details.purchase_units[0].amount,
                    facilitatorAccessToken: data.facilitatorAccessToken,
                    orderID: data.orderID,
                    payerID: data.payerID,
                    Plan: "Individual",
                    tipo_plan: "Individual",
                }).then(() => {
                    history.push("/DoctorParaEmergencia")
                    alert("Se realizo su emergencia exitosamente!!!")
                })
            })
    };

    return (
        <>
            <input type="checkbox" id="sidebar-toggle" />
            <div className="sidebar">
                <Menu> </Menu>
            </div>
            <div className="main-content">
                <header className="row">
                    <HeaderPrin></HeaderPrin>
                </header>
                <main>
                    
                                
                                    <div className="card-single_perEmer">
                                        <div className="text-right cerrar" >
                                            <Link to="/DoctorParaEmergencia"><img src="save_SVG.svg"></img></Link>
                                        </div>
                                        
                                        <div className="updaPrin_per">
                                            <div className="FoEmer">
                                         
                                                <img src={currentPerfil.foto_personal} />&nbsp;{currentPerfil.nombres}&nbsp;{currentPerfil.apellidos}
                                                
                                                </div>
                                               
                                        </div>
                                        
                                        <div className="updaPrin_per">
                                        <h5 className="text-center mt-3">Costo de su emergencia</h5>
                                        <div className="TitlePreciosEmer">
                                                    <div className="signodolar"><h5>$</h5></div>
                                                    <div className="TitleMonto"><h1>{currentPerfil.precio_emergencia / 100 * currentDatos_porce + currentPerfil.precio_emergencia}</h1></div>
                                                </div>                                        </div>

                                        <div className="" id="">
                                            
                                        <div className="BotonPaypal">
                                                        <PayPalButton
                                                            amount={currentPerfil.precio_emergencia / 100 * currentDatos_porce + currentPerfil.precio_emergencia}
                                                            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                            onSuccess={(details, data) => {
                                                                // OPTIONAL: Call your server to save the transaction
                                                                fetch("/paypal-transaction-complete", {
                                                                    method: "post",
                                                                    body: JSON.stringify({
                                                                        orderId: data.orderID
                                                                    })
                                                                })
                                                                    .then(resp => {
                                                                        clMedicalal(currentPerfil.uid, details, data, currentPerfil.precio_emergencia, currentPerfil.nombres, currentPerfil.apellidos, currentPerfil.foto_personal)
                                                                    })
                                                            }}
                                                            catchError={(error) => {
                                                                console.log(error)

                                                            }}
                                                            options={{
                                                                clientId: "AV5h0ivhjvRnCQYyPCjnCzumB7lKEJP1IADFcBoqSRNVGfdTd3EkFFdDUVmFzxr2-gVQKS9evl1_E_Er",
                                                                currency: "USD"
                                                            }}
                                                        />
                                                    </div>
                                           
                                        </div>
                                    </div>
        
                     
                </main>
            </div>


        </>

    )
}
