import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import './Dashboard.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase"
import 'moment/locale/es'
import moment from 'moment';


moment.locale('es');


export default function HeaderPrin() {

  const [error, setError] = useState("");
  const [Especialidad, setEspecialidad] = useState("");
  const [currentDatos_Consigue, setcurrenDatos_Consigue] = useState("");
  const [UserNotifications, setUserNotifications] = useState([]);
  const [UserNotificationsEspe, setUserNotificationsEspe] = useState([]);
  const [UserNotificationss, setUserNotificationss] = useState([]);
  const [UserNotificationsEspes, setUserNotificationsEspes] = useState([]);
  const { currentUser, logout } = useAuth("");
  const history = useHistory("")
  const [currentDatos, setcurrenDatos] = useState("");
  const [TipoUser, setTipoUser] = useState("")
  const [ActiveBoton, setActiveBoton] = useState("")
  const [currentDatosEme, setcurrenDatosEme] = useState("");


  let Noficaciones = []
  let TodaNoficaciones = []
  let Noficacioness = []
  let TodaNoficacioness = []
  const Hamilmi = async () => {

    db.collection("Usuarios")
      .doc(currentUser.uid)
      .get().then(function (doc) {
        let users = doc.data()
        let Tipo = doc.data().tipouser
        let Especia = doc.data().especialidad
        setTipoUser(Tipo)
        setEspecialidad(Especia)
        setcurrenDatos(users);
        setcurrenDatosEme(users.disponible_emergencia);
        db.collection("Usuarios").where("uid", "==", users.uid)
          .onSnapshot(function (querySnapshot) {
            var ci = [];
            querySnapshot.forEach(function (doc) {
              let da = doc.data()
              da.$key = doc.id
              ci.push(da);
            });
            setcurrenDatos_Consigue(ci);
            setcurrenDatosEme(ci);

          });
      });

    // db.collection("Conferencias").where("uid_paciente", "==", currentUser.uid)
    //   .onSnapshot(function (querySnapshot) {
    //     let Conferencias = [];
    //     querySnapshot.forEach(function (doc) {
    //       Conferencias.push({ ...doc.data(), id: doc.id })
    //     })
    //     setUserNotifications(Conferencias)
    //   })
    // db.collection("Citas").where("uid_paciente", "==", currentUser.uid).where("tipo_cita", "==", "Consulta").orderBy("hora_notificacion","desc")
    //   .onSnapshot(function (querySnapshot) {
    //     let Conferencias = [];
    //     querySnapshot.forEach(function (doc) {
    //       Conferencias.push({ ...doc.data(), id: doc.id })
    //     })
    //     setUserNotifications(Conferencias)
    //   })
    // db.collection("Conferencias").where("uid_paciente", "==", currentUser.uid).where("tipo_cita", "==", "Emergencia").orderBy("fecha_creacion","desc")
    // .onSnapshot(function (querySnapshot) {
    //   let Conferencias = [];
    //   querySnapshot.forEach(function (doc) {
    //     Conferencias.push({ ...doc.data(), id: doc.id })
    //   })
    //   setUserNotificationsEme(Conferencias)
    // })

    db.collection("Conferencias").where("uid_paciente", "==", currentUser.uid).onSnapshot((querySnapshot) => {
      const Confe = [];
      Noficaciones = []
      TodaNoficaciones = []
      setUserNotifications([])
      setUserNotificationsEspe([])
      querySnapshot.forEach((doc) => {
        Confe.push({ ...doc.data(), id: doc.id });
      });


      db.collection("Citas").where("uid_paciente", "==", currentUser.uid).where("estatus", "==", "Reprogramada").onSnapshot((querySnapshot) => {
        const pen = [];
        Noficaciones = []
        TodaNoficaciones = []
        setUserNotificationsEspe([])
        setUserNotifications([])
        querySnapshot.forEach((doc) => {
          pen.push({ ...doc.data(), id: doc.id });
        });


        db.collection("Citas").where("uid_especialista", "==", currentUser.uid)
          .onSnapshot(function (querySnapshot) {
            const Citas = [];
            TodaNoficaciones = []
            Noficaciones = []
            setUserNotificationsEspe([])
            setUserNotifications([])

            querySnapshot.forEach((doc) => {
              Citas.push({ ...doc.data(), id: doc.id })
            });
            db.collection("SalaEspera").where("uid_especialista", "==", currentUser.uid)
              .onSnapshot(function (querySnapshot) {
                const Conferencias = [];
                TodaNoficaciones = []
                Noficaciones = []

                setUserNotificationsEspe([])
                setUserNotifications([])
                querySnapshot.forEach((doc) => {
                  Conferencias.push({ ...doc.data(), id: doc.id })
                })

                TodaNoficaciones = TodaNoficaciones.concat(Citas)
                TodaNoficaciones = TodaNoficaciones.concat(Conferencias)
                TodaNoficaciones = TodaNoficaciones.concat(pen)
                TodaNoficaciones = TodaNoficaciones.concat(Confe)
                Noficaciones = Noficaciones.concat(pen)
                Noficaciones = Noficaciones.concat(Confe)
                Noficaciones = Noficaciones.concat(Citas)
                Noficaciones = Noficaciones.concat(Conferencias)
                setUserNotifications(Noficaciones.sort((a, b) => b.fecha_noti - a.fecha_noti))
                setUserNotificationsEspe(TodaNoficaciones.sort((a, b) => b.fecha_noti - a.fecha_noti))
              })
          })
      })
    })

    db.collection("Conferencias").where("uid_paciente", "==", currentUser.uid).where("notificacion", "==", true).onSnapshot((querySnapshot) => {
      const Confe = [];
      const Docs = []
      Noficaciones = []
      TodaNoficaciones = []
      setUserNotifications([])
      setUserNotificationsEspe([])
      querySnapshot.forEach((doc) => {
        Docs.push({ ...doc.data(), id: doc.id });
        let data = Docs;
        let pacienteArray = [];
        for (let i = 0; i < data.length; i++) {
          let Newdata = {
            Tipo_Notificacion: "Conferencias",
            apellidos_especialista: data[i].apellido_especialista ,
            apellidos_paciente: data[i].apellidos_paciente,
            estatus: data[i].estatus,
            fecha: data[i].fecha ,
            fecha_creacion: data[i].fecha_creacion,
            fecha_noti: data[i].fecha_noti,
            fecha_notificacion: data[i].fecha_notificacion,
            foto_especialista:data[i].foto_especialista,
            foto_paciente:data[i].foto_paciente ,
            hora: data[i].hora,
            hora_notificacion: data[i].hora_notificacion,
            id: data[i].id,
            nombres_especialista: data[i].nombre_especialista,
            nombres_paciente:data[i].nombres_paciente,
            notificacion: data[i].notificacion,
            notificacion_especialista: data[i].nombre_especialista,
            notificacion_paciente: data[i].notificacion_paciente,
            uid: data[i].uid,
            uid_especialista: data[i].uid_especialista,
            uid_paciente: data[i].uid_paciente,
        }
        pacienteArray.push(Newdata);

      }
              Confe.push(pacienteArray);
      console.log(Confe)
    });


    db.collection("Citas").where("uid_paciente", "==", currentUser.uid).where("estatus", "==", "Reprogramada").onSnapshot((querySnapshot) => {
      const Citas_1 = [];
      const Docs = [];
      Noficacioness = [];
      TodaNoficacioness = []
      setUserNotificationsEspes([])
      setUserNotificationss([])
      querySnapshot.forEach((doc) => {
        Docs.push({ ...doc.data(), id: doc.id });
        let data = Docs;
        let pacienteArray = [];
        for (let i = 0; i < data.length; i++) {
          let Newdata = {
            apellidos_especialista: data[i].apellidos_especialista,
            apellidos_paciente: data[i].apellidos_paciente,
            estatus: data[i].estatus,
            fecha: data[i].fecha,
            fecha_creacion: data[i].fecha_creacion,
            fecha_noti: data[i].fecha_noti,
            fecha_notificacion: data[i].fecha_notificacion,
            foto_especialista: data[i].foto_especialista,
            foto_paciente: data[i].foto_paciente,
            hora: data[i].hora,
            hora_notificacion: data[i].hora_notificacion,
            id: data[i].id,
            nombres_especialista: data[i].nombres_especialista,
            nombres_paciente: data[i].nombres_paciente,
            notificacion: data[i].notificacion,
            notificacion_especialista: data[i].notificacion_especialista,
            notificacion_paciente: data[i].notificacion_paciente,
            uid: data[i].uid,
            uid_especialista: data[i].uid_especialista,
            uid_paciente: data[i].uid_paciente,
            Tipo_Notificacion: "Citas"
          }
          pacienteArray.push(Newdata);

        }
        Citas_1.push(pacienteArray);
        console.log(Citas_1)

      });


      db.collection("Citas").where("uid_especialista", "==", currentUser.uid).where("notificacion", "==", true)
        .onSnapshot(function (querySnapshot) {
          const Citas_2 = [];
          const Docs = [];
          TodaNoficacioness = []
          Noficacioness = []
          setUserNotificationsEspes([])
          setUserNotificationss([])
          querySnapshot.forEach((doc) => {
            Docs.push({ ...doc.data(), id: doc.id })
            let data = Docs;
            let pacienteArray = [];
            for (let i = 0; i < data.length; i++) {
              let Newdata = {
                apellidos_especialista: data[i].apellidos_especialista,
                apellidos_paciente: data[i].apellidos_paciente,
                estatus: data[i].estatus,
                fecha: data[i].fecha,
                fecha_creacion: data[i].fecha_creacion,
                fecha_noti: data[i].fecha_noti,
                fecha_notificacion: data[i].fecha_notificacion,
                foto_especialista: data[i].foto_especialista,
                foto_paciente: data[i].foto_paciente,
                hora: data[i].hora,
                hora_notificacion: data[i].hora_notificacion,
                id: data[i].id,
                nombres_especialista: data[i].nombres_especialista,
                nombres_paciente: data[i].nombres_paciente,
                notificacion: data[i].notificacion,
                notificacion_especialista: data[i].notificacion_especialista,
                notificacion_paciente: data[i].notificacion_paciente,
                uid: data[i].uid,
                uid_especialista: data[i].uid_especialista,
                uid_paciente: data[i].uid_paciente,
                Tipo_Notificacion: "Citas"
              }
              pacienteArray.push(Newdata);

            }
            Citas_2.push(pacienteArray);
            console.log(Citas_2)
          });
          db.collection("SalaEspera").where("uid_especialista", "==", currentUser.uid).where("notificacion", "==", true)
            .onSnapshot(function (querySnapshot) {
              const SalaEspera = [];
              const Docs = [];
              TodaNoficacioness = []
              Noficacioness = []
              setUserNotificationsEspes([])
              setUserNotificationss([])
              querySnapshot.forEach((doc) => {
                Docs.push({ ...doc.data(), id: doc.id })
                let data = Docs;
                let pacienteArray = [];
                for (let i = 0; i < data.length; i++) {
                  let Newdata = {
                    apellido_especialista: data[i].apellido_especialista,
                    apellidos_paciente: data[i].apellidos_paciente,
                    especialista_acepto: data[i].especialista_acepto,
                    estatus: data[i].estatus,
                    fecha: data[i].fecha,
                    fecha_creacion: data[i].fecha_creacion,
                    fecha_noti: data[i].fecha_noti,
                    fecha_notificacion: data[i].fecha_notificacion,
                    foto_especialista: data[i].foto_especialista,
                    foto_paciente: data[i].foto_paciente,
                    hora_notificacion: data[i].hora_notificacion,
                    id: data[i].id,
                    nombre_especialista: data[i].nombre_especialista,
                    nombres_paciente: data[i].nombres_paciente,
                    notificacion: data[i].notificacion,
                    notificacion_especialista: data[i].nombres_especialista,
                    notificacion_paciente: data[i].nombres_paciente,
                    num_reintentar: data[i].num_reintentar,
                    paciente_abandona: data[i].paciente_abandona,
                    recordatorio: data[i].recordatorio,
                    room_link: data[i].room_link,
                    uid: data[i].uid,
                    uid_especialista: data[i].uid_especialista,
                    uid_paciente: data[i].uid_paciente,
                    Tipo_Notificacion: "SalaEspera",
                  }
                  pacienteArray.push(Newdata);

                }
                SalaEspera.push(pacienteArray);
                console.log(SalaEspera)
              })
              ///////////// //// Norificaciones de pacientes///////////////////////     
              TodaNoficacioness = TodaNoficacioness.concat(Citas_1)
              TodaNoficacioness = TodaNoficacioness.concat(Citas_2)
              TodaNoficacioness = TodaNoficacioness.concat(SalaEspera)
              TodaNoficacioness = TodaNoficacioness.concat(Confe)
              ///////////// //// Norificaciones de pacientes///////////////////////  
              Noficacioness = Noficacioness.concat(Citas_1)
              Noficacioness = Noficacioness.concat(Citas_2)
              Noficacioness = Noficacioness.concat(SalaEspera)
              Noficacioness = Noficacioness.concat(Confe)
              setUserNotificationss(Noficacioness.sort((a, b) => b.fecha_noti - a.fecha_noti))
              setUserNotificationsEspes(TodaNoficacioness.sort((a, b) => b.fecha_noti - a.fecha_noti))

            })
        })
    })
  })
}



useEffect(() => {

  Hamilmi();
}, []);


async function handleLogout() {
  setError("")
  try {
    await logout()
    history.push("/")
  } catch {
    setError("No se pudo cerrar la sesión")
  }
}
function OnActivo(e) {
  db.collection("Usuarios").doc(currentUser.uid).update({
    "disponible_emergencia": e,
  })
}
function idCita(a, b, fotoE, fotoPa) {
  let paciente = {
    id: a,
  }
  localStorage.setItem("paciente_conferencia", JSON.stringify(paciente))
  localStorage.setItem("IdCita", JSON.stringify(b))
  localStorage.setItem("FotoE", JSON.stringify(fotoE))
  localStorage.setItem("FotoPa", JSON.stringify(fotoPa))
  history.push("/Conferencia")
}


function cambiarEstatus(a, id) {
  db.collection("SalaEspera").doc(id).update({
    "especialista_acepto": true,
  }).then(() => {
    alert("Estatus actualizado exitosamente!!!")
  })

  window.open(a)
  setAnchorEl(false);

}

function ActualizarGlobo() {
  let data = UserNotificationsEspes
  for (let i = 0; i < data.length; i++) {
    let datos = data[i]
    for (let i = 0; i < datos.length; i++) {
      db.collection(datos[i].Tipo_Notificacion).doc(datos[i].id).update({
        "notificacion": false,
      }).then(() => {

      })
    }

  }

}


const [anchorEl, setAnchorEl] = React.useState(false);

const handleClick = (event) => {
  if (anchorEl === false) {
    setAnchorEl(true);
  }
  if (anchorEl === true) {
    setAnchorEl(false);
  }
};
function handleClose() {
  setAnchorEl(false);
};



function iniciarSala(e, a) {
  window.open(e)
  setAnchorEl(false);
}

function iniciarSalaEmergencia(e, id) {
  db.collection("Conferencias").doc(id).update({
    "notificacion": false,
    "estatus": "Completada"
  }).then(() => {
    alert("Estatus actualizado exitosamente!!!")
    setAnchorEl(false);
  })
  console.log(e)
  window.open(e)
}



return (
  <>
    {TipoUser === "Paciente" ?
      <>
        {currentDatos_Consigue.length ? (
          currentDatos_Consigue.map(array => (
            <div>
              <div className="todoHeader"></div>
              {array.planActivo === true ?
                <div className="social_icons">
                  <div className="titu"> Plan activo: {array.Plan}</div>
                  <div className=" form-inline consul">
                    <div className="items form-inline">
                      <div className="mr-1 ConDis">
                        Consultas disponibles
                      </div>
                      <div className="NumDis">
                        {array.consultasDisponibles}
                      </div>
                    </div>
                  </div>
                </div>
                : <div className="BotonAc_1">
                  <Link to="/Planes"> <div className="ConsPlan" > Adquiere un plan</div></Link>
                </div>}
            </div>
          ))
        ) : (<div></div>)}

        <div className="notificacion">
          <div>
            <div
              className="ImgNotificacion"
              onClick={handleClick}
              onClickCapture={ActualizarGlobo}
            >{UserNotificationss.length === 0 ? ""
              : <p>
                {UserNotificationss.length}</p>
              }

              <img src="Icon_notification_White_SVG.svg" />
            </div>
            {anchorEl === true ?
              <div
                className="PaneldatosNotifi"
              >
                <div className="header_tabla_noti" onClick={handleClose}>
                  {UserNotifications.length === 0 ? <div><p>No hay notificaciones</p></div> : <h4>Notificaciones</h4>}                  </div>


                {UserNotifications.map((UserNotifications) => (

                  <div key={UserNotifications.uid_paciente}>
                    <div className={UserNotifications.notificacion_paciente === false ? "Globos_noti_leida " : "Globos_noti "}>
                      <div className="row">
                        <div className="imgNoti col-2">
                          <img src={UserNotifications.foto_especialista} />
                        </div>
                        <div className="NombresNoti text-left col-6">
                          <div className={UserNotifications.notificacion_paciente === true ? "" : ""}>{UserNotifications.nombres_especialista}&nbsp;{UserNotifications.apellidos_especialista}</div>
                          {UserNotifications.estatus === "Reprogramada" ?
                            <div className="TipoNoti form-inline"><div className="mr-1">Ha reprogramado esta</div><div className="p">{UserNotifications.tipo_cita}</div></div>
                            : ""
                          }
                          {UserNotifications.estatus === "Agendada" ?
                            <div className="TipoNoti form-inline"><div className="mr-1">Ha iniciado una</div><div className="p">{UserNotifications.tipo_cita}</div></div>
                            : ""}
                          {UserNotifications.tipo_cita === "Emergencia" ?
                            <div>
                              {UserNotifications.estatus === "Completada" ?
                                <div className="TipoNoti form-inline"><div className="pemergen">{UserNotifications.tipo_cita}</div><div className="ml-1">Completada</div></div>
                                : ""}
                            </div>
                            : ""}
                          {UserNotifications.tipo_cita === "Consulta" ?
                            <div>
                              {UserNotifications.estatus === "Completada" ?
                                <div className="TipoNoti form-inline"><div className="p">{UserNotifications.tipo_cita}</div><div className="ml-1">Completada</div></div>
                                : ""}
                            </div>
                            : ""}
                        </div>
                        {UserNotifications.estatus === "Agendada" ?
                          <div className={UserNotifications.notificacion_paciente === true ? "agendar_noti_lei col-4" : "agendar_noti col-4"}>
                            <div>
                              <button onClick={() => iniciarSala(UserNotifications.room_link, UserNotifications.uid)}>
                                Ir a consulta
                              </button>
                              <div className="form-inline hora_noti mt-1">
                                <div className="mr-1">{UserNotifications.fecha_notificacion}</div>
                                <div>{UserNotifications.hora_notificacion}</div>
                              </div>
                              <div>
                              </div>
                            </div>
                          </div>
                          : <div className="form-inline hora_noti">
                            <div className="mr-2">{UserNotifications.fecha_creacion}</div>
                            <div>{UserNotifications.hora_notificacion}</div>
                          </div>}
                      </div>
                    </div>
                  </div>

                ))}
              </div>
              : ""}
          </div>
        </div>
        <div className="backgro_1">
          {/* {currentDatos_2.length ?
              currentDatos_2.map(array => (
                <div>
                  {array.nombres}&nbsp;{array.apellidos}
                </div>
              )) : <div></div>} */}
        </div>
      </>

      : ""}
    {TipoUser === "Especialista" ?
      <>
        <div className="social-icons  form-inline ">
          <div className="ml-2 social mr-2">
            {currentDatos.especialidad === "Psiquiatría" ?
              <label>¿Está activo para recibir emergencias?</label>
              : <div id="backgro_especia"></div>
            }
          </div>
          <div className="BotonAc">
            {Especialidad === "Psiquiatría" ?
              <div>
                {currentDatosEme.length ?
                  currentDatosEme.map(array => (
                    <div>{array.disponible_emergencia === false ?
                      <div>
                        <img className="imgheader" src="Inactive_no_shadow_194.png" id="" onClick={() => OnActivo(true)} />
                      </div>

                      : <div>
                        <img className="imgheader" src="active_shadow_194.png" id="" onClick={() => OnActivo(false)} />
                      </div>
                    }
                    </div>
                  )) : <div></div>}
              </div>
              :
              ""
            }
          </div>
          <div className="notificacion" id="form-inline">
            <div>
              <div
                className="ImgNotificacion "
                onClick={handleClick}
                onClickCapture={ActualizarGlobo}
              >{UserNotificationsEspes.length === 0 ? ""
                : <p>{UserNotificationsEspes.length}</p>}
                <img src="Icon_notification_White_SVG.svg" />
              </div>
              {anchorEl === true ?
                <div
                  className="PaneldatosNotifi"
                >
                  <div className="header_tabla_noti " onClick={handleClose}>
                    {UserNotificationsEspe.length === 0 ? <div><p>No hay notificaciones</p></div> : <h4>Notificaciones</h4>}
                  </div>

                  {UserNotificationsEspe.map((UserNotifications) => (
                    <div key={UserNotifications.uid_paciente}>
                      <div className={UserNotifications.notificacion_especialista === false ? "Globos_noti_leida " : "Globos_noti "}>
                        <div className="row">
                          <div className="imgNoti col-2">
                            <img src={UserNotifications.foto_paciente} />
                          </div>
                          {UserNotifications.tipo_cita === "Consulta" ?
                            <div className="NombresNoti text-left col-6">
                              <div className={UserNotifications.notificacion === true ? "" : ""}>{UserNotifications.nombres_paciente}&nbsp;{UserNotifications.apellidos_paciente}</div>
                              {UserNotifications.estatus === "Agendada" ?
                                <div className="TipoNoti form-inline"><div className="mr-1">Ha tomado una</div><div className="p mr-1">Consulta</div></div>
                                : ""}
                              {UserNotifications.estatus === "Completada" ?
                                <div>
                                  <div className="TipoNoti form-inline">
                                    <div className="p mr-1 mr-1">Consulta</div><div> Completada</div>
                                  </div>
                                </div> : ""}
                              {UserNotifications.estatus === "Reprogramada" ?
                                <div>
                                  <div className="TipoNoti form-inline">
                                    <div className="p mr-1 mr-1">Consulta</div><div> en reprogramacion</div>
                                  </div>
                                </div> : ""}
                            </div>
                            :
                            <div className="NombresNoti text-left col-6">
                              <div className={UserNotifications.notificacion_especialista === true ? "" : ""}>{UserNotifications.nombres_paciente}&nbsp;{UserNotifications.apellidos_paciente}</div>

                              {UserNotifications.notificacion_especialista === true ?
                                <div>
                                  {UserNotifications.paciente_abandona === true ?
                                    <div className="TipoNoti form-inline">
                                      <div className="mr-1">El paciente abandono la</div>
                                      <div className="pemergen">Emergencia</div>
                                    </div>
                                    :
                                    <div>
                                      {UserNotifications.estatus === "Agendada" ?
                                        <div className="TipoNoti form-inline">
                                          <div className="mr-1">Ha iniciado una</div>
                                          <div className="pemergen">Emergencia</div>
                                        </div>
                                        :
                                        <div className="TipoNoti form-inline">
                                          <div className="mr-1">Emergencia</div>
                                          <div className="pemergen">Completada</div>
                                        </div>


                                      }
                                    </div>
                                  }
                                </div>
                                :
                                <div>
                                  <div className="TipoNoti form-inline">
                                    <div className={UserNotifications.tipo_cita === "Consulta" ? "p mr-1" : "pemergen mr-1"}>
                                      {UserNotifications.tipo_cita}</div><div> Completada</div>

                                  </div>

                                </div>
                              }
                            </div>


                          }

                          {UserNotifications.tipo_cita === "Consulta" ?
                            <div className={UserNotifications.notificacion_especialista === true ? "agendar_noti_lei col-4" : "agendar_noti col-4"}>
                              <div>
                                {UserNotifications.estatus === "Agendada" ?
                                  <div>
                                    <button onClick={() => idCita(UserNotifications.uid_paciente, UserNotifications.id, UserNotifications.foto_especialista, UserNotifications.foto_paciente)}>
                                      Ir a consulta
                                    </button>
                                    <div className="form-inline mt-1 hora_noti">
                                      <div className="FechaNoti mr-1">{UserNotifications.fecha_creacion}</div>
                                      <div>{UserNotifications.hora_notificacion}</div>
                                    </div>
                                  </div>
                                  :
                                  <div className="form-inline mt-1 hora_noti">
                                    <div className="FechaNoti mr-1">{UserNotifications.fecha_creacion}</div>
                                    <div>{UserNotifications.hora_notificacion}</div>
                                  </div>
                                }

                              </div>
                            </div>
                            :
                            <div className={UserNotifications.notificacion_especialista === true ? "agendar_noti_lei col-4" : "agendar_noti col-4"}>

                              <div>
                                {UserNotifications.paciente_abandona === false ?
                                  <div>
                                    <button onClickCapture={() => cambiarEstatus(UserNotifications.room_link, UserNotifications.id)}>
                                      Ir a emergencia
                                    </button>
                                    <div className="form-inline mt-1 hora_noti">
                                      <div className="FechaNoti mr-1">{UserNotifications.fecha_creacion}</div>
                                      <div>{UserNotifications.hora_notificacion}</div>
                                    </div>
                                  </div>
                                  :
                                  <div className="form-inline mt-3 hora_noti">
                                    <div className="FechaNoti mr-1">{UserNotifications.fecha_creacion}</div>
                                    <div>{UserNotifications.hora_notificacion}</div>
                                  </div>
                                }
                              </div>
                            </div>}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                : ""}
            </div>
          </div>
          {/* <div>
              <div className={currentDatos.especialidad === "Psiquiatría" ? "updaPrin" : "updaPrin_1_Psico"} >
                  {currentDatosEme.length ?
                      currentDatosEme.map(array => (
                          <div >
                              <div className="tituName">
                                  <img src={array.foto_personal} />
                                  {array.nombres}&nbsp;{array.apellidos}
                              </div>
                          </div>
                      )) : <div></div>}
              </div>
              {currentDatos.especialidad === "Psiquiatría" ?
                  <div>
                      {currentDatosEme.length ?
                          currentDatosEme.map(array => (
                              <div>{array.disponible_emergencia === true ?
                                  <div id="PuntoActivo">
                                      <img src="Activo_icon.svg" />
                                  </div>
                                  : <div id="PuntoInactivo">
                                      <img src="Inactivo_icon.svg" />
                                  </div>}
                              </div>
                          )) : <div></div>}
                  </div>
                  : ""}
          </div> */}

        </div>
      </>
      : ""}
    {TipoUser === "Administrador" ?
      <>
        <header className="row" >
          <div className="search-wrapper">
          </div>
          <div className="social-icons">
            <span className="ti-bell"><FontAwesomeIcon icon={faBell} /></span>
            <span className="ti-comment"><FontAwesomeIcon icon={faCommentAlt} /></span>
            <div></div>&nbsp;{currentDatos.nombres}
          </div>
        </header>
      </>
      : ""}
  </>

)
}