import React, { useState, useEffect } from "react";
import './Dashboard.css';
import { db } from "../firebase"
import 'moment/locale/es'
import moment from 'moment';
import Menu from "./menu";
import HeaderPrin from "./header"
moment.locale('es');


export default function HistorialCitas() {

  const [datoHistorial, setHistorial] = useState("")
  const [busqueda, setBusqueda] = useState("");
  const [panelPerfil, setpanelPerfil] = useState("")
  const [currentPerfil, setCurrentPerfil] = useState("");
  const [DatosBancarios, setDatosBancarios] = useState(false)
  const [DatosBancarios_2, setDatosBancarios_2] = useState(false)
  const [DatosBancarios_3, setDatosBancarios_3] = useState(false)
  const [DatosBancarios_4, setDatosBancarios_4] = useState(false)
  const [misHorarios, setMishorarios] = useState("");

  useEffect(() => {

    db.collection("HistorialPagos").where("tipo_pago", "!=", "Compra de plan")
      .onSnapshot(function (querySnapshot) {
        var citas = [];
        querySnapshot.forEach(function (doc) {
          citas.push({ ...doc.data(), id: doc.id });
        });
        setHistorial(citas);
        console.log(citas)
      })
  }, []);


  /////////////////Buscador////////////////////////////////////////
  const handleChange = e => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  }
  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = datoHistorial.filter((elemento) => {
      // let nombre=elemento.nombres + elemento.apellidos
      let busqueda = terminoBusqueda.trim()
      console.log(busqueda)
      // console.log(nombre)
      if (elemento.nombre_especialista.toString().toLowerCase().includes(busqueda.toLowerCase())
        || elemento.apellido_especialista.toString().toLowerCase().includes(busqueda.toLowerCase())
        || elemento.apellidos_paciente.toString().toLowerCase().includes(busqueda.toLowerCase())
        || elemento.nombres_paciente.toString().toLowerCase().includes(busqueda.toLowerCase())
      ) {
        return elemento;
      }
    });
    if (terminoBusqueda !== "") {
      setHistorial(resultadosBusqueda);
    } else if (terminoBusqueda === "") {
      db.collection("HistorialPagos").where("tipo_pago", "!=", "Compra de plan")
        .onSnapshot(function (querySnapshot) {
          var citas = [];
          querySnapshot.forEach(function (doc) {
            citas.push({ ...doc.data(), id: doc.id });
          });
          setHistorial(citas);
          console.log(citas)
        })
    }
  }

  /////////////////Buscador////////////////////////////////////////

  function actulizarPago(e, c) {
    if (c === true) {
      db.collection("HistorialPagos").doc(e).update({
        "estatusAdmin": false,
      })
    }
    if (c === false) {
      db.collection("HistorialPagos").doc(e).update({
        "estatusAdmin": true,
      })
    }
  }
  function ObtenerDatos(e) {
    db.collection("Usuarios")
      .doc(e)
      .get().then(async (doc) => {
        if (doc.exists) {
          let users = doc.data()
          let Bancos = doc.data().datos_bancarios.pagomovil
          let transfe = doc.data().datos_bancarios.transferencia
          let zelle = doc.data().datos_bancarios.zelle
          let paypal = doc.data().datos_bancarios.paypal
          console.log(users)
          setCurrentPerfil(users)
          setDatosBancarios(Bancos)
          setDatosBancarios_2(zelle)
          setDatosBancarios_3(transfe)
          setDatosBancarios_4(paypal)
           
        }
      }).catch(function (error) {
        console.log("Error getting User:", error);
        alert(error);
      });
    db.collection("Usuarios").doc(e).collection("Horario").orderBy("orden").onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push(doc.data());
      });
      setMishorarios(docs)
    });
  }



  return (
    <>
      <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
    {panelPerfil === "" ?
      <div>
        <div className="header_tabla form-inline">
          <div className="col-6">
            <img src="Buscar_SVG.svg" className="mr-2" /><input className="form-control" onChange={handleChange} placeholder="Buscar" type="seach" />
          </div>
        </div>

        <h2>Historial de Citas</h2>
        <div className="cuadro_tabla">
          <div className="table-responsive mt-2">
            <div className="table_1">
              <table className="table">
                <thead>
                  <tr>
                    <th><img src="View_profile_SVG.svg" /></th>
                    <th>Especialista</th>
                    <th>Paciente</th>
                    <th>Tipo</th>
                    <th>Monto terappia</th>
                    <th>Monto especialista</th>
                    <th>Total</th>
                    <th>Estatus</th>
                    <th>Pagada</th>

                  </tr>
                </thead>
                <tbody>
                  {datoHistorial.length ? (
                    datoHistorial.map(array => (
                      <tr>
                        <td className="td-team">
                          <div className="updaPrin_tabla" ><img src="View_profile_SVG.svg" onClick={() => setpanelPerfil("3")} onClickCapture={() => ObtenerDatos(array.uid_especialista)} /></div>
                        </td>
                        <td>{array.nombre_especialista} {array.apellido_especialista}</td>
                        <td>{array.nombres_paciente} {array.apellidos_paciente}</td>
                        <td>{array.tipo_pago}</td>
                        <th>{array.monto_porcentaje_terapia}</th>
                        {array.tipo_pago === "Emergencia" ?
                          <th>{array.monto_emergencia}</th>
                          : <th>{array.monto_Consulta}</th>}
                        <td>{array.monto.value}</td>
                        <td>{array.estatusPago === "COMPLETED" ? <div>Completado</div> : <div>Completado</div>}</td>
                        <td><input type="checkbox" checked={array.estatusAdmin} onClick={() => actulizarPago(array.id, array.estatusAdmin)} /></td>
                      </tr>
                    )
                    )
                  ) : (
                    <p></p>
                  )}
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>:""}

      {panelPerfil === "3" ? (
        <div className="card-single_per">
          <div className="text-right cerrar" onClick={() => setpanelPerfil("")} ><img src="save_SVG.svg"></img></div>

          <div className="updaPrin_per row ml-1">
            <div className="Fo ">
              <img src={currentPerfil.foto_personal} />&nbsp;{currentPerfil.nombres}&nbsp;{currentPerfil.apellidos}
              
            </div>
          
          <div className="datos">
          <div className="">
                <div className="titulodatospro"><h5>Zelle</h5></div>
              </div>
              <div className="porCita">
                <label>Correo electrónico</label>
                <p>{DatosBancarios_2.email}</p>
              </div>
              <div className="porEmer">
                <label>Nombres</label>
                <p>{DatosBancarios_2.nombre_completo}</p>
              </div>
          </div>
          </div>
          <div className="row  mt-2" id="target_datos">
            <div className="datos_1">
              <div className="titulodatospro"><h5>Pago móvil</h5></div>
              <div className="porCita">
                <label>Banco</label>
                <p>{DatosBancarios.banco}</p>
              </div>
              <div className="porCita">
                <label>Cédula</label>
                <p>{DatosBancarios.doc_identidad}</p>
              </div>
              <div className="porCita">
                <label>Teléfono</label>
                <p>{DatosBancarios.num_tel}</p>
              </div>
            </div>
            <div className="datos_2">
              <div className="">
                <div className="titulodatospro"><h5>Pyapal</h5></div>
              </div>
              <div className="espe">
                <label>Correo electrónico</label>
                <div className="form-inline  mb-2 iconosTerapias">
                <p>{DatosBancarios_4.email}</p>
                </div>
              
              </div>
            </div>

            <div className="datos_3">
              <div className="">
                <div className="titulodatospro"><h5>Transferenia</h5></div>
              </div>
              <div className="porCita">
                <label>Banco</label>
                <p>{DatosBancarios_3.banco }</p>
              </div>
              <div className="porCita">
                <label>N° de cuenta</label>
                <p>{DatosBancarios_3.cuenta }</p>
              </div>
              <div className="porEmer">
                <label>Cédula</label>
                <p>{DatosBancarios_3.doc_identidad}</p>
              </div>

            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
</main>
        </div>  
    </>


  )
}