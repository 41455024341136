import React, { useState, useEffect } from "react";
import './Dashboard.css';
import { db } from "../firebase"
import { useAuth } from "../contexts/AuthContext";
import 'moment/locale/es'
import moment from 'moment';
import Menu from "./menu";
import HeaderPrin from "./header"
moment.locale('es');


export default function HistorialPagosEspecialista() {

    const [datoHistorial, setHistorial] = useState("")
    const [datoPerfil, setPerfil] = useState("")
    const { currentUser } = useAuth("");

    
    useEffect(() => {
        db.collection("Usuarios")
        .doc(currentUser.uid)
        .get().then(function (doc) {
          let users = doc.data()
          setPerfil(users);
        db.collection("HistorialPagos").where("uid_especialista","==",users.uid).onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setHistorial(docs);
        });
    });
       }, []); 
    return (
        <>
         <input type="checkbox" id="sidebar-toggle" />
    <div className="sidebar">
    <Menu> </Menu>
    </div>
    <div className="main-content">
        <header className="row">
          <HeaderPrin></HeaderPrin>
        </header>
        <main>
        {datoHistorial.length===0 ?<h3 className="dash-title a"><h1>No posee datos de pago</h1></h3>: 
                <div>
                        <h3 className="dash-title a"><h1>Historial de pago</h1></h3>
                        <div className="cuadro_tabla">
                        <div className="table-responsive mt-2">
                  <div className="table_1">
                    <table className="table">
                      <thead>
                                    <tr>
                                        <th>Paciente</th>
                                        <th>Fecha</th>
                                        <th>Plan</th>
                                        <th>Tipo</th>
                                        <th>Estatus</th>
                                        <th>Monto</th>
                                        <th>Pago</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datoHistorial.length ? (
                                        datoHistorial.map(array => (
                                            <tr>
                                                <td>{array.nombres_paciente} {array.apellidos_paciente}</td>
                                                <td>{array.fecha_Pago} </td>
                                                <td>{array.Plan}</td>
                                                <td>{array.tipo_pago}</td>
                                                <td>{array.estatusPago}</td>
                                                {array.tipo_pago==="Emergencia"?<td>{datoPerfil.precio_emergencia}</td>:<td>{datoPerfil.precio_consulta}</td>}
                                                <td>{array.estatusAdmin===true?<div>Procesado</div>:<div>Pendiente</div>}</td>
                                            </tr>
                                        )
                                        )
                                    ) : (
                                        <p></p>
                                    )}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
                </div>
                }
            
        </main>
        </div> 
        
        

        </>


    )
}